<template>
  <div>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-layout row wrap>
        <v-flex xs10> </v-flex>

        <!-- <v-flex xs2 v-if="jobType == 1">
          <v-btn
            style="background: orange !important"
            flat
            v-if="detail.jobStatus != -1"
            v-permissions="'assign-driver-jobs'"
            :disabled="
              this.$props.isAssignDriver ||
              detail.jobStatus == 10 ||
              viewOnly == 'view'
            "
            @click="checkAssign(detail.jobId, tabOrder, jobType)"
            prepend-icon
            color="white darken-1"
            :_id="detail._id"
            >Assign Drivers</v-btn
          >
        </v-flex> -->
      </v-layout>
      <v-card-text>
        <v-layout row wrap v-if="assignments">
          <v-flex xs3 pr-4>
            <div class="subheading muted">Job Start Date</div>

            <div class="heading">{{ getTime(assignments[0].pickupDate) }}</div>
          </v-flex>
          <v-flex xs3 pr-4>
            <div class="subheading muted">Job Pickup time</div>
            <div
              v-if="assignments[0].bookingTimeZone && assignments[0].pickupTime"
              class="heading"
            >
              {{ assignments[0].pickupTime }} ({{
                assignments[0].bookingTimeZone
              }})
            </div>
            <div v-else-if="assignments[0].pickupTime" class="heading">
              {{ assignments[0].pickupTime }}
            </div>
          </v-flex>
          <v-flex xs3 pr-4>
            <div v-if="jobType == 1" class="subheading muted">
              Number of Trucks
            </div>
            <div v-if="jobType == 2" class="subheading muted">
              Number of Cargo planes
            </div>
            <div v-if="jobType == 3" class="subheading muted">
              Number of Containers
            </div>
            <div class="heading">{{ assignments.length }}</div>
          </v-flex>
          <v-flex xs3 pr-4>
            <div v-if="jobType == 1" class="subheading muted">Truck Type</div>
            <div v-if="jobType == 2" class="subheading muted">Cargo Type</div>
            <div v-if="jobType == 3" class="subheading muted">
              Containers Type
            </div>

            <div
              v-if="assignments[0].truckType && jobType == 1"
              class="heading"
            >
              {{ getText(truckTypes, assignments[0].truckType.toString()) }}
            </div>
            <div
              v-else-if="assignments[0].truckType && jobType == 2"
              class="heading"
            >
              {{ getText(airTypes, assignments[0].truckType.toString()) }}
            </div>
            <div
              v-else-if="assignments[0].truckType && jobType == 3"
              class="heading"
            >
              {{ getText(seaTypes, assignments[0].truckType.toString()) }}
            </div>
            <div v-else>NA</div>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="pt-3" v-if="assignments">
          <v-flex xs3 pr-4>
            <div class="subheading muted">Job Type</div>
            <div class="heading" v-if="assignments[0].jobType">
              {{ getJobType(assignments[0].jobType) }}
            </div>
          </v-flex>
          <v-flex xs3 pr-4>
            <div class="subheading muted">Job Booking Type</div>
            <div class="heading" v-if="assignments[0].isFrieghtDocument">
              {{ getJobBooking(assignments[0].isFrieghtDocument) }}
            </div>
            <div class="heading" v-else>Load movement</div>
          </v-flex>
          <v-flex xs3 pr-4>
            <div class="subheading muted">Job Geography Type</div>
            <div class="heading" v-if="assignments[0].jobGeoType">
              {{ getJobGeography(assignments[0].jobGeoType) }}
            </div>
            <div class="heading" v-else>NA</div>
          </v-flex>
          <v-flex xs3 pr-4>
            <div class="subheading muted">Job Mode Type</div>
            <div
              class="heading"
              v-if="assignments[0].jobMode && assignments[0].loadType"
            >
              {{ getJobMode(assignments[0].jobMode) }} ({{
                getLoadtype(assignments[0].loadType)
              }})
            </div>
            <div class="heading" v-else-if="assignments[0].jobMode">
              {{ getJobMode(assignments[0].jobMode) }}
            </div>
            <div class="heading" v-else>NA</div>
          </v-flex>

          <!-- <v-flex xs3>
            <div class="subheading muted">Weight(in Tons)</div>
            <div class="heading" v-if="detail.weightInTons">
              {{ detail.weightInTons || NA }}
            </div>

            <div class="heading" v-else>NA</div>
          </v-flex> -->
        </v-layout>

        <v-divider class="my-1 mb-4" />
        <div class="heading orange--text pb-2">Trip Information</div>

        <v-layout align-end wrap>
          <v-flex xs4 class="px-2">
            <v-text-field
              :disabled="readOnly"
              :id="`autocomplete1-${tabOrder}`"
              label="Source Location"
              placeholder="Provide source location"
              v-model="updateInfo.sourceLocation"
              :value="updateInfo.sourceLocation"
            ></v-text-field>
          </v-flex>

          <v-flex xs2 class="px-2">
            <v-text-field
              :disabled="readOnly"
              label="Source City"
              :id="`autocomplete1City-${tabOrder}`"
              placeholder="Source City"
              v-model="updateInfo.sourceCity"
              :value="updateInfo.sourceCity"
              @input="updateSourceCity()"
            ></v-text-field>
          </v-flex>

          <v-flex xs2 class="px-2">
            <!-- <v-text-field
              :disabled="readOnly"
              label="Source Country"
              v-model="updateInfo.sourceCountry"
              :value="updateInfo.sourceCountry"
            ></v-text-field> -->
            <v-autocomplete
              label="Source Country"
              placeholder="Source Country"
              :items="countries"
              item-text="country_name"
              :disabled="readOnly"
              class="pt-0"
              v-model="updateInfo.sourceCountry"
              autocomplete="offfadsf"
              :value="updateInfo.sourceCountry"
              @change="updateSourceCountry()"
              required
            />
          </v-flex>
          <v-flex
            xs2
            class="px-2"
            v-if="
              assignments &&
              assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'
            "
          >
            <v-text-field
              :disabled="readOnly"
              label="Source City (in Arabic)"
              v-model="updateInfo.sourceCityArabic"
              :value="updateInfo.sourceCityArabic"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs2
            class="px-2"
            v-if="
              assignments &&
              assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'
            "
          >
            <v-text-field
              :disabled="readOnly"
              label="Source Country (in Arabic)"
              v-model="updateInfo.sourceCountryArabic"
              :value="updateInfo.sourceCountryArabic"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4 class="px-2">
            <v-text-field
              :disabled="readOnly"
              :id="`autocomplete2-${tabOrder}`"
              placeholder="Provide destination location"
              label="Destination Location"
              v-model="updateInfo.destinationLocation"
              :value="updateInfo.destinationLocation"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 class="px-2">
            <v-text-field
              :disabled="readOnly"
              :id="`autocomplete2City-${tabOrder}`"
              label="Destination City"
              placeholder="Destination City"
              v-model="updateInfo.destinationCity"
              :value="updateInfo.destinationCity"
              @input="updateDestinationCity()"
            ></v-text-field>
          </v-flex>

          <v-flex xs2 class="px-2">
            <!-- <v-text-field
              :disabled="readOnly"
              label="Destination Country"
              v-model="updateInfo.destinationCountry"
              :value="updateInfo.destinationCountry"
            ></v-text-field> -->
            <v-autocomplete
              label="Destination Country"
              :items="countries"
              item-text="country_name"
              placeholder="Destination City"
              :disabled="readOnly"
              v-model="updateInfo.destinationCountry"
              autocomplete="offfadsf"
              :value="updateInfo.destinationCountry"
              @change="updateDestinationCountry()"
              required
            />
          </v-flex>
          <v-flex
            xs2
            class="px-2"
            v-if="
              assignments &&
              assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'
            "
          >
            <v-text-field
              :disabled="readOnly"
              label="Destination City (in Arabic)"
              v-model="updateInfo.destinationCityArabic"
              :value="updateInfo.destinationCityArabic"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs2
            class="px-2"
            v-if="
              assignments &&
              assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'
            "
          >
            <v-text-field
              :disabled="readOnly"
              label="Destination Country (in Arabic)"
              v-model="updateInfo.destinationCountryArabic"
              :value="updateInfo.destinationCountryArabic"
            ></v-text-field>
          </v-flex>
          <v-spacer />
        </v-layout>
        <v-layout>
          <v-flex xsauto>
            <div v-if="!detail.isJobActivatedByScript">
              <v-btn
                v-if="
                  readOnly &&
                  !detail.isCustomerReceiptInitiated &&
                  !this.$props.isAssignCustomerRecipt &&
                  !this.$props.totalAdditionalAmountTab > 0
                "
                v-permissions="'change-location-jobs'"
                @click.native="check()"
                :loading="profileUpdateLoading"
                :disabled="detail.jobStatus == 10 || viewOnly == 'view'"
                >Change Location</v-btn
              >
              <v-btn
                color="orange darken-2"
                v-if="
                  !readOnly &&
                  !detail.isCustomerReceiptInitiated &&
                  !this.$props.isAssignCustomerRecipt
                "
                class="white--text"
                @click.native="updateLocation"
                :disabled="detail.jobStatus == 10 || viewOnly == 'view'"
                :loading="profileUpdateLoading"
                >Update Location</v-btn
              >
              <v-btn
                v-if="
                  !readOnly &&
                  !detail.isCustomerReceiptInitiated &&
                  !this.$props.isAssignCustomerRecipt
                "
                @click.native="reset()"
                >Cancel</v-btn
              >
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
  </div>
</template>

<script>
import { StorageKeys, countries } from "../../../constants/constants";
import { EventBus } from "../../../event-bus.js";
import {
  TruckTypes,
  PackingTypes,
  NatureOfCargos,
  JobTypes,
  SeaTypes,
  AirTypes,
} from "../../../constants/constants";
import SendMessage from "@/components/Pop-ups/SendMessage";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { updateJob } from "../../../constants/api-urls";
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.min.css";
export default {
  created() {
    this.jobTypes = JobTypes;

    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete(this.tabOrder);
          this.initLoadingAutocompleteCity(this.tabOrder);
          this.initUnloadingAutocomplete(this.tabOrder);
          this.initUnloadingAutocompleteCity(this.tabOrder);
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);

    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    this.t = config.headers.authorization;
    if (this.assignments && this.assignments.length > 0) {
      this.updateInfo = {
        sourceLocation: this.assignments[0].sourceLocation,
        sourceCity: this.assignments[0].sourceCity,
        sourceCityArabic: this.assignments[0].sourceCityArabic,

        sourceCountry: this.assignments[0].sourceCountry,
        sourceCountryArabic: this.assignments[0].sourceCountryArabic,
        destinationLocation: this.assignments[0].destinationLocation,
        destinationCity: this.assignments[0].destinationCity,
        destinationCityArabic: this.assignments[0].destinationCityArabic,

        destinationCountry: this.assignments[0].destinationCountry,
        destinationCountryArabic: this.assignments[0].destinationCountryArabic,
        sourceCityError: false,
        destinationCityError: false,
        destinationlong: this.assignments[0].destinationLatLong[0],
        destinationlat: this.assignments[0].destinationLatLong[1],
        sourcelong: this.assignments[0].sourceLatLong[0],
        sourcelat: this.assignments[0].sourceLatLong[1],
      };
    }
  },
  components: {
    Loading,
    ErrorBox,
    SendMessage,
    SuccessDialog,
    ProofOfDelivery,
  },
  props: [
    "detail",
    "assignments",
    "isAssignDriver",
    "jobId",
    "isAssignCustomerRecipt",
    "totalAdditionalAmountTab",
    "job",
    "loader",
    "userDetail",
    "jobType",
    "tabOrder",
    "viewOnly",
  ],
  watch: {
    assignments(val) {
      this.updateInfo = Object.assign({
        sourceLocation: val[0].sourceLocation,
        sourceCity: val[0].sourceCity,
        sourceCityArabic: val[0].sourceCityArabic,

        sourceCountry: val[0].sourceCountry,
        sourceCountryArabic: val[0].sourceCountryArabic,
        destinationLocation: val[0].destinationLocation,
        destinationCity: val[0].destinationCity,
        destinationCityArabic: val[0].destinationCityArabic,
        sourceCityError: false,
        destinationCityError: false,
        destinationCountry: val[0].destinationCountry,
        destinationCountryArabic: val[0].destinationCountryArabic,
        destinationlong: val[0].destinationLatLong[0],
        destinationlat: val[0].destinationLatLong[1],
        sourcelong: val[0].sourceLatLong[0],
        sourcelat: val[0].sourceLatLong[1],
      });
    },

    tabOrder(val) {
      this.tabOrder = val;
    },
  },
  data() {
    return {
      charge: "",
      operationName: "",
      natureOfCargo: "",
      packingType: "",
      countries: countries,
      currency: [
        { currency: "AED", abbr: "aed" },
        { currency: "SAR", abbr: "sar" },
      ],
      x: {
        message: "",
        loading: false,
        success: false,
        error: null,
      },
      dialog: false,
      loading: false,
      error: null,
      errorType: false,
      readOnly: true,
      profileUpdateLoading: false,
      natureOfCargos: NatureOfCargos,
      truckTypes: TruckTypes,
      airTypes: AirTypes,
      seaTypes: SeaTypes,
      packingTypes: PackingTypes,
      updateInfo: {
        sourceLocation: null,
        sourceCity: null,
        sourceCityArabic: null,
        sourceCountry: null,
        sourceCountryArabic: null,
        destinationLocation: null,
        destinationCity: null,
        destinationCityArabic: null,
        destinationCountry: null,
        sourceCityError: null,
        destinationCityError: null,
        destinationCountryArabic: null,
        destinationlong: null,
        destinationlat: null,
        sourcelong: null,
        sourcelat: null,
      },
    };
  },
  updated: function () {},
  methods: {
    async checkAssign(id, tabOrder, jobType) {
      this.loading = true;
      this.operationName = "assign-driver-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        this.driverJob(id, tabOrder, jobType);
      } else {
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    async check() {
      this.profileUpdateLoading = true;
      this.operationName = "change-location-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.profileUpdateLoading = false;
        this.readOnly = false;
        setTimeout(() => {
          return new Promise((resolve, reject) => {
            let script = document.createElement("script");
            script.onload = () => {
              this.initLoadingAutocomplete(this.tabOrder);
              this.initLoadingAutocompleteCity(this.tabOrder);
              this.initUnloadingAutocomplete(this.tabOrder);
              this.initUnloadingAutocompleteCity(this.tabOrder);
            };
            script.src =
              "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
            document.head.appendChild(script);
          });
        }, 2500);
      } else {
        this.profileUpdateLoading = false;
        return;
      }
    },

    getTime(time) {
      return moment(time).format("DD/MM/YYYY");
    },
    notifyDrivers() {
      let url = "/dmsAdmin/sendSMStoDriver";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: this.$route.params.id };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;

          this.x.loading = false;
          this.x.success = true;
          this.x.message = "Drivers notified";
        },
        (error) => {
          this.loading = false;
          this.error = "Failed to update inquiry status";
        }
      );
    },
    driverJob(id, tabOrder, jobType) {
      this.$router.push(`/driverfilters/${id}/${tabOrder}/${jobType}`);
    },
    removeEmptyOrNull(myObj) {
      Object.keys(myObj).forEach(
        (key) =>
          (myObj[key] == null ||
            myObj[key] == "" ||
            myObj[key] === undefined) &&
          delete myObj[key]
      );

      return myObj;
    },
    closeMessage() {
      this.x.success = false;
      this.$emit("updateJobs");
      this.reset();
    },
    getLoadtype(type) {
      switch (type) {
        case "1":
          return "LTL";
        case "2":
          return "FTL";
      }
    },
    getJobMode(type) {
      switch (type) {
        case "1":
          return "Import";
        case "2":
          return "Export";
        case "3":
          return "Local";
      }
    },
    getJobGeography(type) {
      switch (type) {
        case "1":
          return "International";
        case "2":
          return "Domestic";
      }
    },
    getJobBooking(type) {
      switch (type) {
        case "1":
          return "Documentation";
        case "2":
          return "Load movement";
      }
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Complete";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    getText(arr = [], val) {
      let finalEl = arr
        .filter((val) => val !== undefined)
        .find((el) => el.value === val);
      return finalEl ? finalEl.text : "NA";
    },
    getLocation(...arr) {
      return arr ? arr.reduce((prev, curr) => prev + ", " + curr) : "NA";
    },
    reset() {
      this.readOnly = true;
      if (this.assignments && this.assignments.length > 0) {
        this.updateInfo = {
          sourceLocation: this.assignments[0].sourceLocation,
          sourceCity: this.assignments[0].sourceCity,
          sourceCityArabic: this.assignments[0].sourceCityArabic,
          sourceCityError: false,
          destinationCityError: false,
          sourceCountry: this.assignments[0].sourceCountry,
          sourceCountryArabic: this.assignments[0].sourceCountryArabic,

          destinationLocation: this.assignments[0].destinationLocation,
          destinationCity: this.assignments[0].destinationCity,
          destinationCityArabic: this.assignments[0].destinationCityArabic,

          destinationCountry: this.assignments[0].destinationCountry,
          destinationCountryArabic:
            this.assignments[0].destinationCountryArabic,

          destinationlong: this.assignments[0].destinationLatLong[0],
          destinationlat: this.assignments[0].destinationLatLong[1],
          sourcelong: this.assignments[0].sourceLatLong[0],
          sourcelat: this.assignments[0].sourceLatLong[1],
        };
      }
      this.updateInfo = Object.assign(
        {},
        {
          sourceLocation: this.updateInfo.sourceLocation,
          sourceCity: this.updateInfo.sourceCity,
          sourceCityArabic: this.updateInfo.sourceCityArabic,
          sourceCityError: false,
          destinationCityError: false,
          sourceCountry: this.updateInfo.sourceCountry,
          sourceCountryArabic: this.updateInfo.sourceCountryArabic,
          destinationLocation: this.updateInfo.destinationLocation,
          destinationCityArabic: this.updateInfo.destinationCityArabic,
          destinationCountry: this.updateInfo.destinationCountry,
          destinationCountryArabic: this.updateInfo.destinationCountryArabic,

          destinationlong: this.updateInfo.destinationLatLong[0],
          destinationlat: this.updateInfo.destinationLatLong[1],
          sourcelong: this.updateInfo.sourceLatLong[0],
          sourcelat: this.updateInfo.sourceLatLong[1],
        }
      );
    },
    initLoadingAutocomplete(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.updateInfo.sourceLocation = place.formatted_address;
        this.updateInfo.sourcelat = place.geometry.location.lat();
        this.updateInfo.sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.sourceCityError = false;
        } else {
          this.updateInfo.sourceCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.sourceCountry = x[1];
          this.updateInfo.sourceCity = x[0];
        } else {
          this.updateInfo.sourceCity = y[0];
          if (y.length == 0) {
            this.updateInfo.sourceCityError = true;
          }
        }
        if (!this.updateInfo.sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.sourceCity = "";
          this.updateInfo.sourceCityError = true;
        }
      });
    },
    initLoadingAutocompleteCity(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1City-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        // this.updateInfo.sourceLocation = place.formatted_address;
        this.updateInfo.sourcelat = place.geometry.location.lat();
        this.updateInfo.sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.sourceCityError = false;
        } else {
          this.updateInfo.sourceCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.sourceCountry = x[1];
          this.updateInfo.sourceCity = x[0];
        } else {
          if (y.length == 0) {
            this.updateInfo.sourceCityError = true;
          }
          this.updateInfo.sourceCity = y[0];
        }
        if (!this.updateInfo.sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.sourceCity = "";
          this.updateInfo.sourceCityError = true;
        }
      });
    },
    updateSourceCity() {
      if (this.updateInfo.sourceCityError == false) {
        this.updateInfo.sourceCityError = true;
      }
    },
    updateDestinationCity() {
      if (this.updateInfo.destinationCityError == false) {
        this.updateInfo.destinationCityError = true;
      }
    },
    updateDestinationCountry() {
      this.updateInfo.destinationCityError = true;
      this.updateInfo.destinationCity = "";
    },
    updateSourceCountry() {
      this.updateInfo.sourceCity = "";
      this.updateInfo.sourceCityError = true;
    },
    initUnloadingAutocomplete(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete2-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.updateInfo.destinationLocation = place.formatted_address;
        this.updateInfo.destinationlong = place.geometry.location.lng();
        this.updateInfo.destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.destinationCityError = false;
        } else {
          this.updateInfo.destinationCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.destinationCountry = x[1];
          this.updateInfo.destinationCity = x[0];
        } else {
          if (y.length == 0) {
            this.updateInfo.destinationCityError = true;
          }
          this.updateInfo.destinationCity = y[0];
        }
        if (!this.updateInfo.destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.destinationCity = "";
          this.updateInfo.destinationCityError = true;
        }
      });
    },
    initUnloadingAutocompleteCity(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete2City-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        // this.updateInfo.destinationLocation = place.formatted_address;
        this.updateInfo.destinationlong = place.geometry.location.lng();
        this.updateInfo.destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];

        if (place.address_components) {
          this.updateInfo.destinationCityError = false;
        } else {
          this.updateInfo.destinationCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.destinationCountry = x[1];
          this.updateInfo.destinationCity = x[0];
        } else {
          // this.updateInfo.destinationCountry = y[1];
          if (y.length == 0) {
            this.updateInfo.destinationCityError = true;
          }
          this.updateInfo.destinationCity = y[0];
        }
        if (!this.updateInfo.destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.destinationCity = "";
          this.updateInfo.destinationCityError = true;
        }
      });
    },
    async updateLocation() {
      this.profileUpdateLoading = true;
      this.operationName = "change-location-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (
          this.updateInfo.sourceCityError == null ||
          this.updateInfo.sourceCityError == true
        ) {
          this.updateInfo.sourceCityError = true;
          this.error = "Please choose valid city ";
          this.profileUpdateLoading = false;
          return;
        }
        if (
          this.updateInfo.destinationCityError == null ||
          this.updateInfo.destinationCityError == true
        ) {
          this.updateInfo.destinationCityError = true;
          this.error = "Please choose valid city ";
          this.profileUpdateLoading = false;
          return;
        }
        if (
          this.assignments[0].jobGeoType == 1 &&
          this.updateInfo.sourceCountry == this.updateInfo.destinationCountry
        ) {
          this.error =
            "Please select different country address because your job geo type is international";
          this.profileUpdateLoading = false;
          return false;
        }
        if (
          this.assignments[0].jobGeoType == 2 &&
          this.updateInfo.sourceCountry !== this.updateInfo.destinationCountry
        ) {
          this.error =
            "Please select same country address because your job geo type is domestic";
          this.profileUpdateLoading = false;
          return false;
        }
        if (!this.updateInfo.sourcelong && !this.updateInfo.sourcelat) {
          this.error =
            "Please select source address from the suggestions dropdown!";
          this.profileUpdateLoading = false;
          return;
        }

        if (
          !this.updateInfo.destinationlong &&
          !this.updateInfo.destinationlat
        ) {
          this.error =
            "Please select destination address from the suggestions dropdown!";
          this.profileUpdateLoading = false;
          return;
        }

        if (!this.updateInfo.sourceCity) {
          this.error = "Please select source city before moving ahead!";
          this.profileUpdateLoading = false;
          return;
        }
        // if (!this.updateInfo.sourceCity.match(/^[ A-Za-z]*$/)) {
        //   this.error = "Please enter valid source city before moving ahead!";
        //   this.profileUpdateLoading = false;
        //   return;
        // }

        if (!this.updateInfo.sourceCountry) {
          this.error = "Please select source country before moving ahead!";
          this.profileUpdateLoading = false;
          return;
        }

        if (!this.updateInfo.destinationCity) {
          this.error = "Please select destination city before moving ahead!";
          this.profileUpdateLoading = false;
          return;
        }
        // if (!this.updateInfo.destinationCity.match(/^[ A-Za-z]*$/)) {
        //   this.error =
        //     "Please enter valid destination city before moving ahead!";
        //   this.profileUpdateLoading = false;
        //   return;
        // }

        if (!this.updateInfo.destinationCountry) {
          this.error = "Please select destination country before moving ahead!";
          this.profileUpdateLoading = false;
          return;
        }
        let { jobId } = this.detail;
        var jobTabOrder = this.tabOrder;
        jobId = jobId.toString();
        var tabOrder = jobTabOrder.toString();
        var jobType = this.jobType ? this.jobType.toString() : "1";

        let {
          sourceLocation,
          sourceCity,
          sourceCityArabic,
          sourceCountry,
          sourceCountryArabic,
          destinationLocation,
          destinationCity,
          destinationCityArabic,
          destinationCountry,
          destinationCountryArabic,
          destinationlong,
          destinationlat,
          sourcelong,
          sourcelat,
        } = this.updateInfo;

        destinationlong = destinationlong.toString();
        destinationlat = destinationlat.toString();
        sourcelong = sourcelong.toString();
        sourcelat = sourcelat.toString();

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios
          .post(
            `${this.constants.apiUrl}/dmsAdmin/assignmentLocation`,
            {
              jobId,
              sourceLocation,
              sourceCity,
              sourceCityArabic,
              sourceCountry,
              sourceCountryArabic,
              destinationLocation,
              destinationCity,
              destinationCityArabic,
              destinationCountry,
              destinationCountryArabic,
              destinationlong,
              destinationlat,
              sourcelong,
              sourcelat,
              tabOrder,
              jobType,
            },
            config
          )
          .then(
            (response) => {
              const { status, data } = response.data;
              if (status === 200) {
                this.x.success = true;
                this.x.message = "Location updated successfully";
                this.updateInfo = Object.assign(
                  {},
                  {
                    sourceLocation,
                    sourceCity,
                    sourceCityArabic,
                    sourceCountry,
                    sourceCountryArabic,
                    destinationLocation,
                    destinationCity,
                    destinationCityArabic,
                    destinationCountry,
                    destinationCountryArabic,
                    sourcelat,
                    sourcelong,
                    destinationlong,
                    destinationlat,
                  }
                );
              }
              this.profileUpdateLoading = false;
              this.readOnly = true;
              this.$eventBus.$emit("refresh-job");
            },
            (error) => {
              this.error = error.response.data.message;
              this.profileUpdateLoading = false;
            }
          );
      } else {
        this.profileUpdateLoading = false;
        return;
      }
    },

    updateJob(jobId) {
      this.loading = true;
      let url = updateJob;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: jobId.toString(),
        customerCurrency: this.currency.currency,
        // paymentEntity: this.charge.abbr,
        paymentEntity: this.charge,
        packingType: this.packingType.toString(),
        natureOfCargo: this.natureOfCargo.toString(),
      };
      body = this.removeEmptyOrNull(body);
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.message;
          this.loading = false;
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.send-message {
  margin: 0 auto;
}
.status {
  margin: 0 20px;
}
.dropdown-width {
  width: 80%;
}
.location {
  align-self: flex-start;
}
.heading {
  word-break: break-all;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.track-job-button {
  background-color: darkorange;
  color: #ffffff;
  padding: 10px;
  text-decoration: none;
}
.driverbtn {
  position: absolute;
  right: 270px;
  top: 25px;
}
.notifybtn {
  position: absolute;
  right: 430px;
  top: 25px;
}
.v-stepper__header {
  justify-content: left !important;
}
</style>
