<template>
  <div class="log">
    <v-btn
      class="messagebtn blue-button"
      title="Assign"
      :disabled="jobStatus == 10 || viewOnly == 'view'"
      @click.stop="dialog = true"
      @click="
        fetchAssets();
        viewAssetDetail();
      "
      >Assign</v-btn
    >

    <v-dialog v-model="dialog" max-width="55%" persistent>
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat style="background-color: #fee9d0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Assign Asset</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="address-pop">
            <v-card-text class="nospace">
              <v-container>
                <v-layout v-if="this.assetIds && this.country != 'PAK'">
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="showTracking"
                    row
                    single-line
                    :mandatory="true"
                  >
                    <div>
                      <div class>
                        <v-layout>
                          <v-radio
                            label="Show full tracking"
                            value="fullTracking"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>
                          <v-radio
                            label="Show only current location"
                            value="onlyCurrentLocation"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>
                        </v-layout>
                      </div>
                    </div>
                  </v-radio-group>
                </v-layout>
                <v-layout>
                  <v-flex md7>
                    <v-layout pb-4>
                      <v-flex xs6 md11>
                        <div class="subheading orange-color">ASSET LABEL</div>
                        <div class="heading">
                          <v-text-field
                            label="Asset label"
                            v-model="assetLabel"
                            single-line
                            class="pt-0 currencyTitle"
                            counter
                            maxlength="50"
                          />
                        </div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>
                    <v-layout pb-4>
                      <v-flex xs12 md12>
                        <div class="subheading grey-color">LAST REPORTED</div>
                        <div v-if="this.date" class="heading">
                          {{ getTime(this.date) }}
                        </div>
                        <div v-else class="heading">N/A</div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>
                    <v-layout pb-5>
                      <v-flex xs12 md12>
                        <div class="subheading grey-color">STATUS</div>
                        <div class="heading" v-if="this.status == 'parked'">
                          Parked
                        </div>
                        <div class="heading" v-else-if="this.status == 'idle'">
                          Idle
                        </div>
                        <div
                          class="heading"
                          v-else-if="this.status == 'moving'"
                        >
                          Moving
                        </div>
                        <div v-else>N/A</div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>

                    <v-layout pb-4>
                      <v-flex xs6 md11>
                        <div class="subheading grey-color">ASSIGN IMEI</div>
                        <div class="heading">
                          <v-flex xs6 md12>
                            <v-autocomplete
                              :items="assets"
                              label="Enter number here...."
                              class="pt-0 currencyTitle"
                              item-text="imeiNumber"
                              single-line
                              item-value="_id"
                              v-model="assetIds"
                            ></v-autocomplete>
                          </v-flex>
                        </div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>
                  </v-flex>
                  <v-flex md6 xs3>
                    <div>
                      <GmapMap
                        :options="{
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: false,
                        }"
                        :center="center"
                        :zoom="3"
                        style="width: 330px; height: 340px"
                      >
                        <GmapMarker
                          :key="m.markers"
                          v-for="m in markers"
                          :position="m.position"
                          :clickable="true"
                          :draggable="true"
                          @click="center = m.position"
                        ></GmapMarker>
                      </GmapMap>
                      <div>
                        <!-- <v-btn
                        :disbled="assetIds"
                          color="orange darken-1"
                          class="white--text tilt"
                          round
                          target="_blank"
                          v-bind:href="this.constants.mappingUrl+'/#/track-truck?assignmentId='+assignId+'&key='+t+'&username='+u"
                          style="text-transform: none !important ;    margin-left: 200px; "
                          :loading="loading"
                        >
                          Navigate
                          <v-icon right style="transform: rotate(45deg);" size="17">navigation</v-icon>
                        </v-btn> -->
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
                <div
                  class="red--text"
                  style="width: 50%; margin: auto; text-align: center"
                  v-if="x.error !== null"
                >
                  {{ x.error }}
                </div>
                <v-spacer />
                <v-layout pt-3 style="text-align: center">
                  <v-flex xs12 md12>
                    <v-spacer />
                    <v-btn
                      color="orange darken-1"
                      class="white--text"
                      style="text-transform: none; !important"
                      :loading="loading"
                      @click="assignAsset()"
                      >Assign</v-btn
                    >
                    <v-spacer />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { EventBus } from "../../event-bus.js";
import {
  editLabel,
  viewAssetDetails,
  assignAsset,
} from "../../constants/api-urls";
export default {
  created() {
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.u = localStorage.getItem("user") ? localStorage.getItem("user") : "";
    this.t = config.headers.authorization ? config.headers.authorization : "";

    this.$eventBus.$on("show-nav-items", (val) => {
      this.toShowNavItems = val;
      this.adminName = localStorage.getItem("user");
      this.adminRole = localStorage.getItem("role");
    });
    this.$eventBus.$on("hide-navbar", () => {
      this.show = false;
    });

    EventBus.$on("logged-in-success", (data) => {
      this.adminName = data.name;
      this.adminRole = data.roleType;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `${token}`,
        },
      };
      this.u = localStorage.getItem("user");
      this.t = config.headers.authorization;
    });
  },

  data() {
    return {
      center: {
        lat: 10.0,
        lng: 10.0,
      },
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        },
        {
          position: {
            lat: 0,
            lng: 0,
          },
        },
      ],
      assetIds: null,
      assetLabel: "",
      status: "",
      showTracking: "fullTracking",
      date: "",
      assets: [],
      id: "",
      x: {
        error: null,
      },

      items: [],

      title: null,
      message: null,

      processing: false,
      dialog: false,

      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    jobStatus: Number,
    assignId: Number,
    assetId: String,
    viewOnly: String,
    country: String,
  },

  methods: {
    getTimes(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    editLabel() {
      this.x.error = null;
      this.loading = true;
      if (!this.label) {
        this.x.error = "Please enter asset label";
        this.loading = false;
        return;
      }
      let url = editLabel;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        assignmentId: this.assignId.toString(),
        label: this.assetLabel,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
        },
        (error) => {
          this.x.error = error.response.data.message;
          this.loading = false;
        }
      );
    },

    fetchAssets() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      //var data = {};
      const { apiUrl } = this.constants;

      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = workingCountry;
      }
      let data = {
        workingCountry: this.workingCountry,
      };
      this.axios.post(`${apiUrl}/assets/freeAssetsListing`, data, config).then(
        (response) => {
          this.assets = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.x.error = this.$eventBus.parse(error);
        }
      );
    },
    viewAssetDetail() {
      this.loading = true;
      this.x.error = null;
      let url = viewAssetDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        assignmentId: this.assignId.toString(),
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.assetLabel = response.data.data[0].assetLabel;

          this.loading = false;
          if (response.data.data[0].assetData) {
            this.status = response.data.data[0].assetData.movingStatus;
            this.date = response.data.data[0].assetData.updatedAt;
          } else {
            this.status = "N/A";
            this.date = "N/A";
          }

          if (response.data.data[0].assetData.currentLatLong) {
            this.center.lat = response.data.data[0].assetData.currentLatLong[1];
            this.center.lng = response.data.data[0].assetData.currentLatLong[0];
          }
          if (!response.data.data[0].assetData.centerLatLong) {
            this.center.lat = response.data.data[0].sourceLatLong[1];
            this.center.lng = response.data.data[0].sourceLatLong[0];
          }
          this.markers[0].position.lat = response.data.data[0].sourceLatLong[1];
          this.markers[0].position.lng = response.data.data[0].sourceLatLong[0];
          this.markers[1].position.lat =
            response.data.data[0].destinationLatLong[1];
          this.markers[1].position.lng =
            response.data.data[0].destinationLatLong[0];
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
        }
      );
    },
    assignAsset() {
      this.x.error = null;
      this.loading = true;
      if (!this.assetIds) {
        this.x.error = "Please select IMEI number";
        this.loading = false;
        return;
      }
      let url = assignAsset;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        assignmentId: this.assignId.toString(),
        assetId: this.assetIds,
        label: this.assetLabel,
      };
      if (this.country != "PAK") {
        if (this.showTracking === "fullTracking") {
          body.isFullTrackingOn = true;
        }
        if (this.showTracking === "onlyCurrentLocation") {
          body.isFullTrackingOn = false;
        }
      }
      if (this.assetIds) {
        let y = this.assets.filter((x) => {
          if (this.assetIds === x._id) {
            return x.imeiNumber;
          }
        });

        body.imeiNumber = y[0].imeiNumber;
      }

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          // this.$eventBus.$emit("assign-asset");
          this.dialog = false;
          this.$eventBus.$emit("refresh-job");
          this.loading = false;
        },
        (error) => {
          this.x.error = error.response.data.message;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.dropdowns-content a {
  color: black;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:hover {
  background-color: #ddd;
}

.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.blue-button {
  border-radius: 5px;
  background-color: blue !important;
  color: #ffffff !important;
  border: 2px solid blue !important;
}
.grey-color {
  color: grey;
}
.orange-color {
  color: orange;
}
.navigate {
  position: fixed;
  bottom: 150px;
}
.messagebtn {
  margin: 0 auto;
}
</style>
