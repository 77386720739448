<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-card-text class="nospace">
        <loading :active.sync="loading" />

        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex xs5 pt-3
            ><span class="board-style">Driver Details : </span>
            {{
              assignmentData.driverName
                ? assignmentData.driverName + " | "
                : "N.A"
            }}
            <span
              v-if="assignmentData.driverName"
              style="position: relative; top: 5px"
            >
              <img src="../../assets/TruckIcon.svg" height="18px"
            /></span>
            <span
              v-if="assignmentData.driverName"
              style="position: relative; top: 3px"
              >{{ assignmentData.truckPrefix }}-{{
                assignmentData.truckNumber
              }}</span
            ></v-flex
          >
          <v-flex xs3 pt-3
            ><span class="board-style">Driver Price :</span>
            {{
              assignmentData.paymentAmount
                ? assignmentData.paymentAmount
                : "N.A"
            }}
            {{
              assignmentData.paymentAmount
                ? assignmentData.customerCurrency
                : ""
            }}
          </v-flex>
          <v-flex xs3 pt-3
            ><span class="board-style">Balanced Amount : </span>
            <span v-if="assignmentData.paymentAmount"
              >{{
                assignmentData.paymentAmount - assignmentData.advanceAmount
              }}
              {{ assignmentData.customerCurrency }}</span
            >
            <span v-else>N.A</span>
          </v-flex>
          <v-flex
            v-if="
              !assignmentData.driverDocStatus &&
              assignmentData.assignmentStatus >= 2 &&
              assignmentData.assignmentStatus <= 6
            "
            xs4
            pl-5
            style="text-align: end"
            class="pb-2"
          >
            <v-btn
              v-if="
                assignmentData.assignmentStatus >= 2 &&
                assignmentData.assignmentStatus < 7
              "
              class="ma-2"
              @click="confirmationDialog = true"
              outline
              color="grey"
            >
              MARK DOC STATUS
            </v-btn>
          </v-flex>
          <v-flex style="text-align: end" class="pb-2">
            <addDriverDoc
              :assId="assignmentData._id"
              v-on:update-Assignment="componentKey = +1"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="TransporterListTable"
        hide-actions
        :headers="headers"
        :items="items"
        style="word-break: break-all"
      >
        <template slot="items" slot-scope="props">
          <td style="width: 20%">{{ getTime3(props.item.createdAt) }}</td>
          <td
            style="width: 20%; word-break: break-all"
            v-if="props.item.documentType == 5 && props.item.description"
          >
            {{ getType(documentType, props.item.documentType) }} -
            {{ props.item.description }}
          </td>
          <td style="width: 20%" v-else>
            {{ getType(documentType, props.item.documentType) }}
          </td>
          <td style="width: 20%">{{ props.item.uploadedFrom }}</td>
          <td style="width: 20%">
            <v-layout>
              <ul style="list-style: none; display: flex">
                <li
                  :key="i"
                  v-for="(doc, i) in props.item.document"
                  class="list"
                  ref="documentList"
                >
                  <a
                    :href="doc"
                    class="document-link"
                    style="text-decoration: none; outline: none"
                    v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                    target="_blank"
                  >
                    <img src="../../assets/pfd.png" height="40" width="32" />
                  </a>
                  <a
                    :href="doc"
                    style="text-decoration: none; outline: none"
                    class="document-link"
                    target="_blank"
                    v-else
                  >
                    <img
                      src="../../assets/docimg.webp"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
              </ul>
            </v-layout>
          </td>
        </template>
      </v-data-table>

      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/dmsAdmin/getShipmentDeliveryDoc`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <v-dialog v-model="confirmationDialog" max-width="40%" persistent>
        <v-form ref="form">
          <v-card style="overflow-x: hidden">
            <v-card-title
              style="
                background-color: #f5f5f5;
                padding-bottom: 5px;
                padding-top: 5px;
              "
            >
              <span class="headline">Mark Received</span>
              <v-spacer></v-spacer>
              <v-btn icon @click.prevent="confirmationDialog = false">
                <v-icon small class="heading grey--text text--darken-4"
                  >close</v-icon
                >
              </v-btn>
            </v-card-title>

            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
              class="title-layout-size"
            >
              <v-layout
                class="text--darken-4"
                style="color: lightgrey; font-size: 16px"
                pt-2
                pl-4
                pb-2
              >
                <span
                  >Do you confirm that you have received the driver documents?
                </span>
              </v-layout>
            </v-container>
            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
              class="title-layout-size"
            >
            </v-container>
            <v-spacer></v-spacer>
            <v-card-actions style="padding: 28px !important">
              <v-spacer></v-spacer>
              <v-btn
                color="black darken-1"
                flat
                @click="confirmationDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                style="background: darkorange !important"
                flat
                @click="markDocStatus()"
                :disabled="loading"
                prepend-icon
                color="white darken-1"
                >Yes, I do</v-btn
              >
            </v-card-actions>
            <v-spacer></v-spacer>
          </v-card>
        </v-form>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import addDriverDoc from "@/components/Pop-ups/addDriverDoc";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
  CancellationType,
} from "../../constants/constants";

import PaginationButtons from "@/components/Pagination/Pagination-New";

export default {
  created() {
    this.getDocumentType();
    this.cancellationType = CancellationType;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
          this.currency = key.currency;
        }
      });
    }
    this.$eventBus.$on("success-driver-doc-list", () => {
      this.x.success = true;
    });
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val.value;
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.loading = true;
    });
    this.payload = {
      skip: "0",
      limit: "15",
      assignmentId: this.$route.params._id,
      workingCountry: this.workingCountry,
    };
    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    addDriverDoc,
    SuccessDialog,
    ErrorBox,
    Loading,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
  },

  data() {
    return {
      workingCountry: null,
      JobTypes: [],
      assignmentData: {},
      documentType: [],
      jobType: "",
      error: "",
      truckNumber: "",
      truckPrefix: "",
      invoiceNumber: "",
      weight: "",
      invoiceValue: "",
      confirmationDialog: false,
      assignID: "",
      id: "",
      assignmentId: "",
      loading: true,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: 0,

      payload: {},

      headers: [
        {
          text: "Date & Time",
          sortable: false,
          value: "dateAndTime",
          class: "table-header-item",
        },
        {
          text: "Document Name",
          sortable: false,
          value: "document",
          class: "table-header-item",
        },
        {
          text: "Uploaded By",
          sortable: false,
          value: "uploadedBy",
          class: "table-header-item",
        },
        {
          text: "Document ",
          sortable: false,
          value: "document",
          class: "table-header-item",
        },
      ],
      items: [],
    };
  },
  watch: {},
  methods: {
    getType(arr = [], val) {
      let finalEl = arr
        .filter((val) => val !== undefined)
        .find((el) => el.documentNumber === val);
      return finalEl ? finalEl.documentName : "NA";
    },
    getDocumentType() {
      this.loading = true;
      let Url = "/dmsAdmin/getShipmentDeliveryDocTypes";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.documentType = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.success = false;
          this.loading = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },
    getTime3(date) {
      return moment(date).format("ll LT ");
    },

    open(assignmentId, _id) {
      this.cancelDialog = true;
      this.id = assignmentId;
      this.assignmentId = _id;
    },

    verified(assignmentId, _id) {
      this.loading = true;
      let Url = "/dmsAdmin/verifyShipmentDocument";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        id: _id,
        assignmentId: assignmentId,
        isVerify: true,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.x.success = true;
          this.loading = false;
          //this.cancelDialog = false;
        },
        (error) => {
          this.success = false;
          this.loading = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },
    markDocStatus() {
      this.loading = true;
      this.processing = true;
      let Url = "/dmsAdmin/markShipmentDocStatus";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.$route.params._id,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.x.success = true;
          this.confirmationDialog = false;
        },
        (error) => {
          this.x.success = false;
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
    },
    callback(res) {
      this.loading = false;
      this.$eventBus.$emit("off-load");
      this.items = res.list;
      this.assignmentData = { ...res.assignmentDetail[0] };
      this.totalPages = Math.ceil(res.totalData / 15);
      window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.board-style {
  color: darkorange;
  font-size: 16px;
  font-weight: 700;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
</style>
