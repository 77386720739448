<template>
  <div class="log" style="margin-right: 10px; padding-left: 10px">
    <button
      v-if="
        !assignmentData.imeiInstallStatus &&
        assignmentData.imeiInstallStatus != 'collected'
      "
      type="button"
      class="trac-ass-btn"
      @click.stop="dialog = true"
    >
      Collect GPS Device
    </button>
    <button
      v-if="
        assignmentData.imeiInstallStatus &&
        (assignmentData.imeiInstallStatus == 'installed' ||
          assignmentData.imeiInstallStatus != 'collected')
      "
      type="button"
      class="trac-ass-btn"
      @click.stop="dialog = true"
    >
      Collect GPS Device
    </button>
    <!-- <button
      v-if="
        assignmentData.imeiInstallStatus &&
          assignmentData.imeiInstallStatus == 'collected'
      "
      type="button"
      disabled
      class="track-disabled-ass-btn"
      @click.stop="dialog = true"
    >
      GPS Device Collected
    </button> -->
    <v-flex
      v-if="
        type == 'imeiSection' &&
        assignmentData.imeiInstallStatus &&
        assignmentData.imeiInstallStatus == 'collected'
      "
    >
      Gps device collected
    </v-flex>

    <v-flex
      v-if="
        type != 'imeiSection' &&
        assignmentData.imeiInstallStatus &&
        assignmentData.imeiInstallStatus == 'collected'
      "
    >
      Gps device collected ({{ assignmentData.imeiNumber }})</v-flex
    >

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Confirmation </span>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12 md12 class="text-style">
                Are you sure want to collect gps device from the driver?
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 md12>
                <v-text-field label="Comments" v-model="description" />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" :disabled="processing"  flat @click="close()">NO</v-btn>
            <v-btn color="blue darken-1" :disabled="processing" flat @click="sendComment">YES</v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { StorageKeys } from "@/constants/constants";
import { gpsCollectionPopup } from "../../../constants/api-urls";
export default {
  props: {
    assignmentData: Object,
    type: String,
  },
  data: () => ({
    processing: false,
    valid: true,
    lazy: false,
    description: "",
    processing: false,
    operationName: "",
    rules: {
      required: (value) => !!value || "This field is required.",
      amount: (v) =>
        /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
      number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
    },
    dialog: false,
    error: null,
    banks: [],
  }),
  created() {},
  methods: {
    async check() {
      this.processing = true;
      this.type == "imeiSection" ? (this.operationName = "collect-gps") : "";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.open();
        this.getBankName();
      } else {
        this.processing = false;
        return;
      }
    },

    close() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.description = "";
      this.processing = false;
      this.error = null;
    },

    sendComment() {
      if (this.$refs.form.validate()) {
        this.error = "";

        this.processing= true;
        let url = gpsCollectionPopup;

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const data = {
          assignmentId: this.$props.assignmentData.assignmentId.toString(),
          assetId: this.$props.assignmentData.assetId,
        };
        if (this.description) {
          data.comment = this.description;
        }

        this.axios.post(this.constants.apiUrl + url, data, config).then(
          (response) => {
            this.dialog = false;
            this.processing= false;

            this.$refs.form.resetValidation();
            this.description = "";
            this.error = "";

            this.type == "imeiSection" ? this.$emit("refresh-list") : "";
            this.type != "imeiSection"
              ? this.$eventBus.$emit("refresh-job")
              : "";
          },
          (error) => {
            this.processing = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.text-style {
  font-size: 18px;
  color: darkgrey;
}
.trac-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 9px;
}
.track-disabled-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: lightgrey;
  border-radius: 5px;
  border: 2px solid grey;
  padding: 6px 9px;
}
.pay-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid orange;
  padding: 4px 12px;
  border-radius: 5px;
}
.pay-not-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 4px 12px;
  border-radius: 5px;
}
</style>
