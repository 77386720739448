<template>
  <div class="log">
    <v-btn
      class="messagebtn"
      style="background: black !important"
      flat
      prepend-icon
      color="white darken-1"
      @click.stop="check()"
      >View Milestone Logs</v-btn
    >
    <v-dialog v-model="dialog" max-width="50%">
      <v-form ref="form">
        <v-card>
          <v-toolbar fixed flat>
            <v-toolbar-title>View Milestone Logs </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="spacerclass">
            <v-card-text v-if="activity.length">
              <v-timeline>
                <v-timeline-item v-for="(data, i) in activity" :key="i" small>
                  <template v-slot:opposite>
                    <span
                      :class="`headline font-weight-bold`"
                      v-text="data.mileStoneName"
                    ></span>
                  </template>
                  <div class="py-4">
                    <h2
                      :class="`amber heading user lighten-1 justify-end mb-4`"
                    >
                      Actual Milestone Date:-<br />
                      {{
                        data.updatedDate ? getTime(data.updatedDate) : "N.A."
                      }}
                    </h2>
                    <div>
                      Comment:- {{ data.comments ? data.comments : "N.A." }}
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <h2 v-else class="activityLog">No Comments Found</h2>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import { StorageKeys } from "../../constants/constants";
import { trackingStatus } from "@/constants/api-urls.js";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
export default {
  data() {
    return {
      x: {
        error: null,
      },
      operationName: "",
      activity: [],
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    jobId: Number,
    assignmentId: Number,
  },
  created() {
    this.getActivityLog();
  },
  components: {
    ErrorBox,
  },
  methods: {
    async check() {
      this.operationName = "view-comments-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
        this.getActivityLog();
      } else {
        return;
      }
    },

    getTime(date) {
      return moment(date).format("ll  LT");
    },
    getDate(date) {
      return moment(date).format("ll");
    },
    getActivityLog() {
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let url = trackingStatus;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          assId: this.$props.assignmentId.toString(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.activity = response.data.data;

            this.processing = false;
          },
          (error) => {
            this.x.error = "Failed to Fetch Comments";
            this.processing = false;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.log {
  margin: auto 20px auto 0px;
}
.comments-view .v-toolbar {
  width: 50%;
  margin-left: 25%;
}
.v-toolbar--fixed {
  position: inherit;
}
</style>
