<template>
  <v-container fluid>
    <v-btn fab flat @click.native="goback" v-if="notrue">
      <v-icon class="pointer" v-ripple>arrow_back</v-icon>
    </v-btn>
    <v-layout class="errorbox" v-if="notrue">
      <v-flex
        >Seems like you don't have any permissions. Please contact
        administrator</v-flex
      >
    </v-layout>
    <v-card class v-if="!notrue">
      <v-card-title class="bg-clr p-t-0">
        <v-layout wrap>
          <v-btn fab flat @click.native="goback">
            <v-icon class="pointer" v-ripple>arrow_back</v-icon>
          </v-btn>
          <v-flex>
            <v-layout>
              <v-flex>
                <span class="color-orange breakword">Documents </span></v-flex
              ></v-layout
            >
            <v-layout>
              <v-flex>
                <span class="color-grey-light"
                  >Job Id-{{ this.jobId }}, Assignment Id:{{
                    this.assignId
                  }}</span
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-title>
    </v-card>
    <v-card raised v-if="!notrue">
      <v-flex xs12 pt-4>
        <v-tabs
          id="documents"
          v-model="TabStatus"
          class="doc"
          fixed-tabs
          style="
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
          "
          background-color="darkorange"
          dark
        >
          <v-tab
            :href="`#shipmentDocument`"
            v-permissions="'add-documents-jobs'"
            @click="checkShipment('shipmentDocument')"
            >Shipment Doc</v-tab
          >
          <!-- <v-tab
            :href="`#AdditionalDocument`"
            v-permissions="'edit-additional-charges'"
            @click="checkAdditional('AdditionalDocument')"
            >Additional Charges Doc</v-tab
          > -->
          <!-- <v-tab
            :href="`#AdvanceDocument`"
            v-permissions="'generate-advance-jobs'"
            @click="checkAdvance('AdvanceDocument')"
            >Generate Advance</v-tab
          > -->
          <v-tab
            :href="`#DeliveryDocument`"
            v-permissions="'Add-proof-of-Delivery'"
            @click="checkDelivery('DeliveryDocument')"
            >Proof of Delivery</v-tab
          >
          <!-- <v-tab
           :href="`#driverDoc`"
           @click="viewTab('driverDoc')"
            >Receive Driver Doc</v-tab
          > -->
        </v-tabs>
        <!-- <v-flex
          :href="`#driverDoc`"
          class="m-t-20"
          v-if="currentTab == 'driverDoc'"
          md12
        >
          <receive-driver-doc
            :customerStatus="customerStatus"
            :tabFilter="currentTab"
          />
        </v-flex> -->
        <v-flex
          :href="`#shipmentDocument`"
          class="m-t-20"
          v-if="currentTab == 'shipmentDocument'"
          md12
        >
          <documentList
            :customerStatus="customerStatus"
            :tabFilter="currentTab"
          />
        </v-flex>
        <v-flex
          :href="`#AdditionalDocument`"
          class="m-t-20"
          v-if="currentTab == 'AdditionalDocument'"
          md12
        >
          <additionalChargesList
            :customerStatus="customerStatus"
            :tabFilter="currentTab"
          />
        </v-flex>
        <v-flex
          :href="`#AdvanceDocument`"
          class="m-t-20"
          v-if="currentTab == 'AdvanceDocument'"
          md12
        >
          <generateAdvanceList
            :customerStatus="customerStatus"
            :tabFilter="currentTab"
          />
        </v-flex>
        <v-flex
          :href="`#DeliveryDocument`"
          class="m-t-20"
          v-if="currentTab == 'DeliveryDocument'"
          md12
        >
          <deliveryProofList
            :customerStatus="customerStatus"
            :tabFilter="currentTab"
          />
        </v-flex>
      </v-flex>
    </v-card>
  </v-container>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import documentList from "./documentList";
import generateAdvanceList from "./generateAdvanceList";
import deliveryProofList from "./deliveryProofList";
import additionalChargesList from "./additionalChargesList";
import receiveDriverDoc from "./receiveDriverDoc";
import Loading from "vue-loading-overlay";

export default {
  components: {
    receiveDriverDoc,
    Loading,
    documentList,
    additionalChargesList,
    generateAdvanceList,
    deliveryProofList,
  },
  created() {
    let roles = JSON.parse(localStorage.getItem("permissions")).roles;

    let documentRole = [
      "edit-additional-charges",
      "edit-additional-charges",
      "generate-advance-jobs",
      "Add-proof-of-Delivery",
    ];
    let operationss = {};

    roles.filter((x) => {
      if (x.role.roleName == "jobs" || x.role.roleName == "procurement") {
        operationss = x.operations.map((y) => y.operationName);
        if (
          x.operations == "Add-proof-of-Delivery" ||
          x.operations == "edit-additional-charges" ||
          x.operations == "add-documents-jobs"
        ) {
          if (
            operationss.includes("Add-proof-of-Delivery") &&
            operationss.includes("edit-additional-charges") &&
            operationss.includes("add-documents-jobs")
          ) {
            this.notrue = false;
          }
          if (
            operationss.includes("Add-proof-of-Delivery") &&
            operationss.includes("edit-additional-charges")
          ) {
            this.notrue = false;
          }
          if (
            operationss.includes("Add-proof-of-Delivery") &&
            operationss.includes("edit-additional-charges") &&
            operationss.includes("add-documents-jobs")
          ) {
            this.notrue = false;
          }
          if (
            operationss.includes("Add-proof-of-Delivery") &&
            operationss.includes("add-documents-jobs")
          ) {
            this.notrue = false;
          }
          if (
            operationss.includes("add-documents-jobs") &&
            operationss.includes("edit-additional-charges")
          ) {
            this.notrue = false;
          }
          // if (

          //   operationss.includes("add-documents-jobs") &&
          //   operationss.includes("edit-additional-charges")
          // ) {
          //   this.notrue = false;
          // }
          // if (

          //   operationss.includes("add-documents-jobs")
          // ) {
          //   this.notrue = false;
          // }
          if (
            operationss.includes("add-documents-jobs") &&
            operationss.includes("edit-additional-charges")
          ) {
            this.notrue = false;
          }
          if (
            operationss.includes("generate-advance-jobs") &&
            operationss.includes("edit-additional-charges")
          ) {
            this.notrue = false;
          }
          // if (

          //   operationss.includes("edit-additional-charges")
          // ) {
          //   this.notrue = false;
          // }
          if (
            operationss.includes("Add-proof-of-Delivery") &&
            operationss.includes("add-documents-jobs")
          ) {
            this.notrue = false;
          }
          // if (
          //   operationss.includes("Add-proof-of-Delivery")
          // ) {
          //   this.notrue = false;
          // }
          if (operationss.includes("Add-proof-of-Delivery")) {
            this.notrue = false;
          }
          if (operationss.includes("edit-additional-charges")) {
            this.notrue = false;
          }
          // if (operationss.includes("generate-advance-jobs")) {
          //   this.notrue = false;
          // }
          if (operationss.includes("add-documents-jobs")) {
            this.notrue = false;
          }
        } else {
          this.notrue = false;
        }
      }
    });

    this.$eventBus.$on("data", (val) => {
      this.jobId = val.jobId;
      this.assignId = val.assignmentId;
    });

    let userPermissions = JSON.parse(
      localStorage.getItem("permissions")
    ).permissions;
    // let tabs=['shipmentDocumnet','DeliveryDocument','AdvanceDocument','DeliveryDocument']
    let object = {
      "add-documents-jobs": "shipmentDocument",
      "edit-additional-charges": "AdditionalDocument",
      "generate-advance-jobs": "AdvanceDocument",
      "Add-proof-of-Delivery": "DeliveryDocument",
    };
    let object1 = {};
    for (var i = 0; i < userPermissions.length; i++) {
      if (object[userPermissions[i].operationName]) {
        if (
          this.$route.query.type === "Driver_Proof" &&
          operationss.includes("Add-proof-of-Delivery")
        ) {
          this.currentTab = "DeliveryDocument";
          this.TabStatus = "DeliveryDocument";
        } else {
          this.currentTab = object[userPermissions[i].operationName];
          this.TabStatus = object[userPermissions[i].operationName];
        }
        break;
      }
    }
    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
  },
  data() {
    return {
      operationName: "",
      notrue: true,
      currentTab: "DeliveryDocument",
      TabStatus: "DeliveryDocument",
      jobId: "",
      customerStatus: "",
      assignId: "",
      items: {
        list: [],
      },
      active: "0",

      loading: true,
      search: "",

      payload: {
        skip: "0",
        limit: "15",
      },
    };
  },
  methods: {
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    async checkShipment(section) {
      this.operationName = "add-documents-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.viewTab(section);
      } else {
        return;
      }
    },
    async checkAdvance(section) {
      this.operationName = "generate-advance-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.viewTab(section);
      } else {
        return;
      }
    },
    async checkDelivery(section) {
      this.operationName = "Add-proof-of-Delivery";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.viewTab(section);
      } else {
        return;
      }
    },
    async checkAdditional(section) {
      this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.viewTab(section);
      } else {
        return;
      }
    },
    goback() {
      this.$router.go(-1);
    },
    viewTab(flag) {
      this.currentTab = flag;
    },

    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },

    load(event) {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: #484848;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #efefef;
}
.track-btn {
  background-color: #ffeeda !important;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.p-16 {
  padding: 0 16px !important;
}
.pull--right {
  float: right;
}
.color-orange {
  color: darkorange !important;
  font-size: 25px;
  padding-top: 15px;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.color-grey-light {
  color: grey;
}
</style>
