<template>
  <div
    style="background:#f57c0026;font-family:Helvetica Neue,Helvetica,Helvetica,sans-serif;letter-spacing:0.4px;width:100%;"
  >
    <div
      style="width:100%;max-width:600px;height:auto;margin:15px auto;background:#fff;box-sizing:border-box"
    >
      <div style="padding:20px 40px;height:auto;background:#f57c00ba;">
        <h1 style="font-size:30px;color:#ffffff;line-height:48px;margin:0px;">
          Track Your Shipment
        </h1>
        <!-- <img src="https://s3-us-west-2.amazonaws.com/beehivebucket/profilePic_397013465202.0814.png" style="width:100%;height:120px;object-fit:cover"> -->
      </div>
      <div style="padding:24px 40px">
        <p
          style="font-size:16px;color:#737D8F;font-weight:300;line-height:24px;margin:0px;margin-bottom:40px"
        >
          Ananay Mahajan,<br />Greetings from Trukkin!<br />
          <br />
          We are writing to inform you about your Job with ID {{ jobDetail }}.
        </p>
        <br />
        <table style="width: 100%; text-align: left; margin-bottom: 40px;">
          <thead>
            <th
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding: 10px 0px; text-transform: uppercase; color: #737D8F; font-size: 16px; font-weight: bold; line-height: 24px;"
            >
              Job Details
            </th>
            <th
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding: 10px 0px; text-transform: uppercase; color: #737D8F; font-size: 16px; font-weight: bold; line-height: 24px;text-align:right;"
            >
              <a
                style="border-radius:4px;padding:15px;color:#fff;text-decoration:none;box-sizing:border-box;text-align:center;background-color:#f57c00ba;width:200px;height:48px;display:block;float:right;font-weight:bold;"
                >TRACK YOUR JOB</a
              >
            </th>
          </thead>
          <tbody>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Job ID</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>14697</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Job Status</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Active</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Vehicle Type</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>12 mtr box truck</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Loading Date</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>12th July 2019</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Onloading Location</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Delhi, India</span>
                x
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Offloading Location</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Chandigarh, India</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Last Updated On</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>19th July 2019</span>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table
          style="width: 100%; text-align: left; margin-bottom: 40px;padding:5px;border:1px solid #dbdbdb;"
        >
          <thead>
            <th
              colspan="2"
              style="background-color: #ff8c0036;padding: 10px 0px; text-transform: uppercase; color: #737D8F; font-size: 16px; font-weight: bold; line-height: 24px;"
            >
              Assignment - #[12345]
            </th>
          </thead>
          <tbody>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Checkpoint</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Picked Up</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Status Date</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>19th July 2019</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Driver</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Mohit Gupta</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Vehicle No.</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>DW 123 909</span>
              </td>
            </tr>
          </tbody>
          <thead>
            <th
              colspan="2"
              style="background-color: #ff8c0036;padding: 10px 0px; color: #737D8F; font-size: 14px;padding:10px; font-weight: normal; line-height: 24px;"
            >
              <strong>Comments</strong> - This is a dummy comment not to be used
              for legal prposes. Make sure comments are descriptive enough for
              user understanding.
            </th>
          </thead>
        </table>
        <br />
        <table
          style="width: 100%; text-align: left; margin-bottom: 40px;padding:5px;border:1px solid #dbdbdb;"
        >
          <thead>
            <th
              colspan="2"
              style="background-color: #ff8c0036;padding: 10px 0px; text-transform: uppercase; color: #737D8F; font-size: 16px; font-weight: bold; line-height: 24px;"
            >
              Assignment - #[12345]
            </th>
          </thead>
          <tbody>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Checkpoint</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Picked Up</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Status Date</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>19th July 2019</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Driver</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>Mohit Gupta</span>
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;"
              >
                <span>Vehicle No.</span>
              </td>
              <td
                style="padding-top: 4px; color: #737D8F; font-size: 16px; font-weight: 400; line-height: 20px;text-align:right;"
              >
                <span>DW 123 909</span>
              </td>
            </tr>
          </tbody>
          <thead>
            <th
              colspan="2"
              style="background-color: #ff8c0036;padding: 10px 0px; color: #737D8F; font-size: 14px;padding:10px; font-weight: normal; line-height: 24px;"
            >
              <strong>Comments</strong> - This is a dummy comment not to be used
              for legal prposes. Make sure comments are descriptive enough for
              user understanding.
            </th>
          </thead>
        </table>
        <!-- <div style="margin:30px auto;">
                <a href="#" style="border-radius:4px;padding:15px;color:#fff;text-decoration:none;box-sizing:border-box;text-align:center;background-color:#f57c00ba;width:200px;height:48px;display:block;margin:70px auto;font-weight:bold;" target="_blank">TRACK YOUR JOB</a>
            </div>         -->
        <br />
        <div>
          <p
            style="font-size:16px;color:#737d8f;font-weight:500;line-height:22px;margin:0px;margin-bottom:0px"
          >
            At your service,
          </p>
          <p
            style="font-size:16px;color:#737d8f;font-weight:500;line-height:22px;margin:0px;margin-bottom:0px"
          >
            Trukkin Team
          </p>
          <br />
          <img
            style="font-size:30px;color:#737d8f9c;font-weight:600;line-height:48px;margin:0px;margin-bottom:0px;height:76px!important;"
            src="https://s3.amazonaws.com/truckkinapi/profilePic_10061345.png"
          />
        </div>
      </div>

      <div style="padding:20px 40px;background-color: #f57c00ba;">
        <div>
          <p
            style="font-size:12px;color:#ffffff;font-weight:500;line-height:22px;margin:0px;margin-bottom:0px"
          >
            Serving: KSA | UAE | Kuwait | Bahrain | Oman | Jordan | Egypt | Iraq
            | Lebanon
          </p>
          <p
            style="font-size:12px;color:#ffffff;font-weight:500;line-height:22px;margin:0px;margin-bottom:0px"
          >
            UAE: +971 56 368 1471 | +971 54 368 0337 | 800 TRUKKIN
          </p>
          <p
            style="font-size:12px;color:#ffffff;font-weight:500;line-height:22px;margin:0px;margin-bottom:0px"
          >
            KSA: +966 55 300 4352 | +966 50 116 9040 | 800 TRUKKIN
          </p>
          <p
            style="font-size:12px;color:#ffffff;font-weight:500;line-height:22px;margin:0px;margin-bottom:0px"
          >
            For any queries contact us at
            <a
              href="mailto:contact@trukkin.com"
              style="text-decoration:none;color:black;"
              >contact@trukkin.com</a
            >
            |
            <a
              href="https://trukkin.com/terms-conditions/"
              target="_blank"
              style="text-decoration:none;color:black;"
              >Terms & Conditions</a
            >
            |
            <a
              href="https://trukkin.com/policy/"
              target="_blank"
              style="text-decoration:none;color:black;"
              >Privacy Policy</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
export default {
  mounted() {
    this.fetchDetails();
  },
  props: ["jobId"],
  data() {
    return {
      jobDetail: this.$props.jobId,
    };
  },
  methods: {
    fetchDetails() {},
  },
};
</script>
