var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log",staticStyle:{"margin-right":"10px","padding-left":"10px"}},[(
      !_vm.assignmentData.imeiInstallStatus &&
      _vm.assignmentData.imeiInstallStatus != 'collected'
    )?_c('button',{staticClass:"trac-ass-btn",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_vm._v(" Collect GPS Device ")]):_vm._e(),(
      _vm.assignmentData.imeiInstallStatus &&
      (_vm.assignmentData.imeiInstallStatus == 'installed' ||
        _vm.assignmentData.imeiInstallStatus != 'collected')
    )?_c('button',{staticClass:"trac-ass-btn",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_vm._v(" Collect GPS Device ")]):_vm._e(),(
      _vm.type == 'imeiSection' &&
      _vm.assignmentData.imeiInstallStatus &&
      _vm.assignmentData.imeiInstallStatus == 'collected'
    )?_c('v-flex',[_vm._v(" Gps device collected ")]):_vm._e(),(
      _vm.type != 'imeiSection' &&
      _vm.assignmentData.imeiInstallStatus &&
      _vm.assignmentData.imeiInstallStatus == 'collected'
    )?_c('v-flex',[_vm._v(" Gps device collected ("+_vm._s(_vm.assignmentData.imeiNumber)+")")]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Confirmation ")])]),_c('v-card-text',[_c('v-layout',[_c('v-flex',{staticClass:"text-style",attrs:{"xs12":"","md12":""}},[_vm._v(" Are you sure want to collect gps device from the driver? ")])],1),_c('v-layout',[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-text-field',{attrs:{"label":"Comments"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.processing,"flat":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("NO")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.processing,"flat":""},on:{"click":_vm.sendComment}},[_vm._v("YES")])],1)],1),(_vm.error)?_c('v-snackbar',{staticClass:"white--text",attrs:{"timeout":3000,"bottom":"","color":"red darken-2"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }