
 

<template>
   <div>
    <span class="muted caption" > Currency*</span>
    <v-select :items="items" label="Select Currency" v-model="model"  class="pt-0 currencyTitle" single-line :rules="[rules.required]">
    </v-select>
   </div>
</template>

<script>
export default {
  props: ["onselect"],
  data() {
    return {
      items: ["AED", "SAR"],
      model: null,
      rules: {
        required: value => !!value || "This field is required."
      }
    };
  },
  watch: {
    model(val) {
      if (this.onselect) {
        this.onselect(this.model);
      }
    }
  }
};
</script>

<style>
.currencyTitle {
  margin-top: -2px !important;
}
</style>
