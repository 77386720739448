<template>
  <div>
    <button
      v-if="
        status > 1 &&
        status !== 4 &&
        status !== 6 &&
        status !== 3 &&
        status !== 7 &&
        status !== 8 &&
        !advance &&
        totalAmount &&
        type == '1'
      "
      outline
      class="tra-ass-btn"
      type="button"
      @click.stop="
        check();
        getBankName();
      "
    >
      Generate
    </button>
    <v-btn
      class="ma-2"
      outline
      v-if="!advance && !type && totalAmount"
      color="orange"
      flat
      prepend-icon
      :disabled="
        status <= 1 ||
        status === 4 ||
        status === 6 ||
        status === 3 ||
        status === 7 ||
        status === 8 ||
        viewOnly == 'view' ||
        customerStatus == 'view'
      "
      @click.stop="
        check();
        getBankName();
      "
    >
      + Request Advance
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="45%"
      persistent
      class="layout__spacing"
    >
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        height="100%"
        onSubmit="return false;"
        class="layout__spacing"
      >
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline">Request Advance</span>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="spacerclassAssign">
            <v-layout class="span__padding">
              <v-flex xs6>
                <span class="dashboard-style"
                  >Driver’s Payment credit period</span
                >
                :
                {{
                  driverCreditDuration !== 0 &&
                  driverCreditDuration !== undefined
                    ? driverCreditDuration
                    : "N.A"
                }}
                {{
                  driverCreditDuration !== 0 &&
                  driverCreditDuration !== undefined
                    ? "days"
                    : ""
                }}
              </v-flex>
            </v-layout>

            <v-layout style="padding: 10px; !important" class="pb-0">
              <v-flex md6 class="payadvance">
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>Advance amount to be paid to driver*</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="advancePayment"
                        class="pt-0 currencyTitle"
                        type="number"
                        box
                        placeholder="Enter amount"
                        :rules="[rules.required, rules.number]"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>Total amount to be paid to driver*</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        readonly
                        v-model="totalAmount"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Total amount to be paid to driver."
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-card-title class="title-layout-size">
                  <span>Comments</span>
                </v-card-title>
                <v-card-text class="title-layout-size">
                  <v-flex xs12>
                    <v-textarea
                      box
                      name="input-7-4"
                      placeholder="Comments"
                      v-model="comments"
                    ></v-textarea>
                  </v-flex>
                </v-card-text>
              </v-flex>
            </v-layout>
            <v-layout class="pt-0">
              <v-flex class="span__padding">
                <span class="heading__span">Driver Payment Details</span>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6>
                <v-layout row class="span__padding">
                  <v-flex xs12 pt-0 pl-0>
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="modeOfPayment"
                      row
                      :mandatory="true"
                      @change="this.$refs.form.resetValidation()"
                      :rules="[rules.required]"
                    >
                      <div>
                        <div class>
                          <v-layout>
                            <v-radio
                              label="Cash"
                              value="Cash"
                              color="black"
                              class="col-md-6"
                            ></v-radio>
                            <v-radio
                              label="Bank Transfer"
                              value="Bank Transfer"
                              color="black"
                              class="col-md-6"
                            ></v-radio>
                          </v-layout>
                        </div>
                      </div>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout v-if="modeOfPayment === 'Bank Transfer'">
                  <v-flex md12 class="payadvance pt-2">
                    <v-card-title class="title-layout-size">
                      <span>Account Holder Name</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="accountHolderName"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Enter account holder name"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
                <v-layout v-else>
                  <v-flex md12 class="payadvance pt-2">
                    <v-card-title class="title-layout-size">
                      <span>Account Holder Name</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="accountHolderName"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Enter account holder name"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
                <v-layout v-if="modeOfPayment === 'Bank Transfer'">
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>IBAN Number</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="ifscCode"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="IBAN Number"
                        :rules="[rules.noWhiteSpace, rules.required]"
                        maxlength="25"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
                <v-layout v-else>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>IBAN Number</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="ifscCode"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="IBAN Number"
                        maxlength="25"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 v-if="modeOfPayment === 'Bank Transfer'">
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <!-- <span>Bank Details</span> -->
                    </v-card-title>

                    <!-- <v-card-text
                      v-if="currency == 'AED'"
                      class="title-layout-size"
                    >
                      <v-autocomplete
                        label="Choose Bank"
                        :items="banks"
                        box
                        class="pt-0 currencyTitle"
                        v-model="bankName"
                        :disabled="
                          !this.$props.invoiceCity ||
                            !this.$props.invoiceCompany
                        "
                        item-text="text"
                        single-line
                        :rules="[rules.required]"
                        item-value="value"
                      />
                    </v-card-text> -->
                    <!-- 
                    <v-card-text v-else class="title-layout-size">
                      <v-text-field
                        v-model="bankName"
                        label="Bank Name"
                        class="pt-0 currencyTitle"
                        placeholder="Bank Details"
                        :value="bankName"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-card-text> -->
                  </v-flex>
                </v-layout>
                <v-layout v-if="modeOfPayment === 'Bank Transfer'">
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>Account Number</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="accountNumber"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Account number"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>Swift Number</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="swiftNumber"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Enter Swift Number"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 v-else>
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>Account Number</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="accountNumber"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Account number"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-card-title class="title-layout-size">
                      <span>Swift Number</span>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="swiftNumber"
                        class="pt-0 currencyTitle"
                        box
                        placeholder="Enter Swift Number"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                :disabled="processing"
                flat
                @click="close()"
                >Close</v-btn
              >
              <v-btn
                color="white darken-1"
                style="background: orange !important"
                :disabled="processing"
                flat
                @click="checkAdvance()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
      <v-dialog v-model="confirmationDialog" max-width="23%" persistent>
        <v-card>
          <!-- <v-layout>
            <v-flex style="text-align: end;">
              <v-btn icon>
                <v-icon
                  small
                  @click.prevent="confirmationDialog = false"
                  :disabled="loading"
                  >close</v-icon
                >
              </v-btn>
            </v-flex>
          </v-layout> -->
          <v-spacer class="spacerclass">
            <v-card-text style="padding-top: 0px !important">
              <v-layout class="pt-4" style="text-align: center">
                <v-flex pr-2 pb-3>
                  <v-icon x-large color="orange">info</v-icon></v-flex
                >
              </v-layout>
              <v-layout>
                <v-flex pb-3 style="text-align: center">
                  Advance amount exceeds the driver price.</v-flex
                >
              </v-layout>
              <v-layout>
                <v-flex style="text-align: center">
                  <v-btn
                    color="white darken-1"
                    style="
                      background: orange !important;
                      min-width: 66px !important;
                    "
                    flat
                    @click.prevent="generateAdvance()"
                    :loading="processing"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>

        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys, Banks } from "../../constants/constants";
import { generateAdvance } from "@/constants/api-urls.js";
import ErrorBox from "@/components/Common/ErrorBox";
export default {
  components: {
    ErrorBox,
  },

  data() {
    return {
      operationName: "",
      banks: [],
      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      bankName: "",
      activity: [],
      modeOfPayment: "Cash",
      customerStatus: "",
      accountHolderName: "",
      comments: "",
      accountNumber: "",
      swiftNumber: "",
      ifscCode: "",
      valid: true,
      lazy: false,
      reason: "",
      processing: false,
      dialog: false,
      disStatus: false,
      loading: false,
      advancePayment: null,
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
      x: {
        error: "",
      },
    };
  },
  watch: {
    modeOfPayment() {
      this.$refs.form.resetValidation();
    },
  },
  props: {
    assId: Number,
    totalAmount: Number,
    totalAdvanceAmount: Number,
    maximumAdvanceLeftAmount: Number,
    driverCreditDuration: Number,
    advance: Number,
    currency: String,
    invoiceCity: String,
    invoiceCompany: String,
    isCancel: Boolean,
    viewOnly: Boolean,
    status: Number,
    advancedialog: Boolean,
    additionalCharges: Number,
    totaladvancePaid: Number,
    assignedToTransporter: Boolean,
    ownerType: String,
    type: String,
  },
  created() {
    this.customerStatus = localStorage.getItem("cust-status");
    this.totalPayment = this.$props.totalAmount;

    // this.getActivityLog();
  },
  methods: {
    getBankName() {
      if (this.$props.invoiceCity == "1" && this.$props.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Dammam";
      } else if (
        this.$props.invoiceCity == "2" &&
        this.$props.invoiceCompany == "1"
      ) {
        this.bankName = "Alinma Bank - Jeddah";
      } else if (
        this.$props.invoiceCity == "3" &&
        this.$props.invoiceCompany == "1"
      ) {
        this.bankName = "Alinma Bank - Riyadh";
      } else if (
        this.$props.invoiceCompany == "2" &&
        (this.$props.invoiceCity == "1" || this.$props.invoiceCity == "2")
      ) {
        this.banks = [
          "HB-81591",
          "ENBD-80701",
          "ADCB-20001",
          "MB-60847",
          "MB-6094",
        ];
      } else {
        return;
      }
    },
    async check() {
      if (this.$route.name == "Accounts")
        this.operationName = "add-advance-for-accounts";
      if (this.$route.name != "Accounts")
        this.operationName = "generate-advance-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
        this.modeOfPayment = "Cash";
      } else {
        return;
      }
    },
    close() {
      this.dialog = false;
      this.advancePayment = null;
      this.modeOfPayment = "Cash";

      this.accountHolderName = "";
      this.accountNumber = "";
      this.swiftNumber = "";
      this.comments = "";
      this.ifscCode = "";
      this.bankName = "";
      this.$refs.form.resetValidation();
      this.totalPayment = this.$props.totalAmount;
    },
    getTime(date) {
      return moment(date).format("YYYY-MM-DD hh:mm A");
    },
    checkAdvance() {
      this.processing = true;
      let isAdvancePaidMoreThanRequested =
        this.$props.totalAdvanceAmount < this.totaladvancePaid ? true : false;
      if (this.$refs.form.validate()) {
        if (!this.advancePayment) {
          this.x.error = "Please enter advance amount";
          this.processing = false;
          return;
        }
        if (this.advancePayment <= 0) {
          this.x.error = "Advance amount should be greater than or equal to 1";
          this.processing = false;
          return;
        }
        // if (parseInt(this.advancePayment) > this.maximumAdvanceLeftAmount) {
        //   this.confirmationDialog = true;
        //   return;
        // }
        if (
          parseInt(this.advancePayment) >
          this.totalAmount + this.additionalCharges - this.totaladvancePaid
        ) {
          this.processing = false;
          console.log("--------------1--");
          // if (
          //   this.$props.assignedToTransporter ||
          //   this.$props.ownerType == "Transporter"
          // ) {
          //   this.x.error =
          //     "Advance amount can't be greater than total amount to be paid to driver";
          //   return;
          // } else {
          this.processing = false;
          this.confirmationDialog = true;
          return;
          // }
        }
        if (isAdvancePaidMoreThanRequested) {
          if (
            this.totaladvancePaid + Number(this.advancePayment) >
            this.totalAmount + this.additionalCharges
          ) {
            // if (
            //   this.$props.assignedToTransporter ||
            //   this.$props.ownerType == "Transporter"
            // ) {
            //   this.x.error =
            //     "Advance amount can't be greater than total amount to be paid to driver";
            //   return;
            // } else {
            this.processing = false;
            this.confirmationDialog = true;
            return;
          } else {
            this.generateAdvance();
          }
        } else {
          if (
            this.$props.totalAdvanceAmount + Number(this.advancePayment) >
            this.totalAmount + this.additionalCharges
          ) {
            // if (
            //   this.$props.assignedToTransporter ||
            //   this.$props.ownerType == "Transporter"
            // ) {
            //   this.x.error =
            //     "Advance amount can't be greater than total amount to be paid to driver";
            //   return;
            // } else {
            this.processing = false;
            this.confirmationDialog = true;
            return;
            // }
          } else {
            this.generateAdvance();
          }
        }
      } else {
        this.processing = false;
        return;
      }
    },
    async generateAdvance() {
      this.processing = true;
      if (this.$route.name == "Accounts")
        this.operationName = "add-advance-for-accounts";
      if (this.$route.name != "Accounts")
        this.operationName = "generate-advance-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;

        if (this.$refs.form.validate()) {
          if (!this.advancePayment) {
            this.x.error = "Please enter advance amount";
            this.processing = false;
            return;
          }
          if (this.advancePayment <= 0) {
            this.x.error =
              "Advance amount should be greater than or equal to 1";
            this.processing = false;
            return;
          }

          this.processing = true;
          let url = generateAdvance;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {
            assignmentId: this.$props.assId.toString(),
            advancePayment: this.advancePayment.toString(),
            totalPayment: this.$props.totalAmount.toString(),
            creditorModeOfPayment: this.modeOfPayment,
          };
          // if (this.bankName) {
          //   body.bank = this.bankName;
          // }
          if (this.ifscCode) {
            body.IBANNumber = this.ifscCode;
          }
          if (this.accountHolderName) {
            body.accountHolderName = this.accountHolderName;
          }
          if (this.accountNumber) {
            body.accountNumber = this.accountNumber;
          }
          if (this.swiftNumber) {
            body.swiftNumber = this.swiftNumber;
          }
          if (this.comments) {
            body.comment = this.comments;
          }

          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              this.x.success = true;
              this.dialog = false;
              this.processing = false;
              this.$refs.form.resetValidation();
              this.$emit("assignment-list-refresh");
              this.modeOfPayment = "";
              this.accountHolderName = "";
              this.accountNumber = "";
              this.swiftNumber = "";
              this.comments = "";
              this.ifscCode = "";
              this.bankName = "";
              this.x.message = response.data.message;
              this.$emit("updateAssignment");
              this.advancePayment = null;
              this.$route.name != "Accounts"
                ? this.$eventBus.$emit("refresh-job")
                : "";
              this.$emit("generateAdvance");
            },
            (error) => {
              this.processing = false;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.layout__spacing .v-input__slot {
  min-height: auto !important;
}
.layout__spacing .v-text-field__slot input {
  margin-top: 0px !important;
}
</style>
<style scoped>
.spacerclassAssign {
  overflow-y: scroll !important;
  max-height: calc(104vh - 176px) !important;
}
.tra-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: white;
  background-color: orange;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 6px;
}
.heading__span {
  font-size: 20px !important;
  line-height: 32px !important;
  font-weight: bold;
}
.span__padding {
  padding: 10px 10px 10px 24px;
}
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.messagebtn {
  margin: 0 auto;
}
.title-layout-size {
  padding: 0px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
</style>
