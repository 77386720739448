<template>
  <div>
    <v-card>
      <v-container fluid class="p-16">
        <v-divider class="my-1 mb-4" />
        <div class="heading orange--text">Pricing details</div>

        <v-layout row class="py-4">
          <v-flex xs4 v-if="pricing">
            <v-text-field
              label="Customer Price"
              type="number"
              :disabled="readOnly"
              v-model="pricing.customerCost"
            ></v-text-field>

            <v-flex
              v-if="
                detail.jobStatus != 2 &&
                detail.jobStatus != 9 &&
                detail.jobStatus != -1
              "
            >
              <div v-if="!detail.isJobActivatedByScript">
                <customerAccepted
                  :jobId="detail.jobId"
                  :detail="detail"
                  v-permissions="'edit-price'"
                  :working="workingcountry"
                  :viewOnly="viewOnly"
                  :subJobDetail="subJobDetail"
                />
              </div>
            </v-flex>

            <!--  <span class="muted">Customer rate should be for entire shipment*</span>-->
          </v-flex>
          <v-flex xs1></v-flex>
          <v-flex xs3 v-if="pricing">
            <v-text-field
              label="Driver Price "
              type="number"
              :disabled="readOnly"
              v-model="pricing.driverCost"
            ></v-text-field>

            <v-flex
              v-if="
                detail.jobStatus == 2 ||
                detail.jobStatus == 5 ||
                detail.jobStatus == 6 ||
                detail.jobStatus == 7 ||
                detail.jobStatus == 8 ||
                detail.jobStatus == 9 ||
                detail.jobStatus == 11 ||
                detail.jobStatus == 12
              "
            >
              <div v-if="!detail.isJobActivatedByScript">
                <editDriverPrice
                  :jobId="detail.jobId"
                  v-if="!subJobDetail.isCustomerReceiptInitiated"
                  :detail="detail"
                  v-permissions="'edit_driver_price'"
                  :working="workingcountry"
                  :viewOnly="viewOnly"
                  :subJobDetail="subJobDetail"
                />
              </div>
            </v-flex>
          </v-flex>
          <v-flex xs1></v-flex>
          <v-flex xs3>
            <v-text-field
              v-if="pricing.dutyCharges"
              label="Total Duty Amount"
              type="number"
              :disabled="readOnly"
              v-model="pricing.dutyCharges"
            ></v-text-field
          ></v-flex>
        </v-layout>
      </v-container>
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
    </v-card>
  </div>
</template>
<script>
import { StorageKeys } from "../../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import customerAccepted from "@/components/Pop-ups/customerAccepted";
import editDriverPrice from "@/components/Pop-ups/editDriverPrice";
import { sendQuote } from "../../../constants/api-urls";
import { EventBus } from "../../../event-bus.js";
export default {
  created() {
    this.driverCost = null;
    this.$eventBus.$on("additional-charges-added", (value) => {
      this.additionalCharges = this.additionalCharges + parseInt(value);
      this.trukkinEarning = this.trukkinEarning - this.additionalCharges;
      this.$eventBus.$emit("refresh-job");
    });

    this.quoteToCustomer = this.detail.jobPrice;
    this.trukkinEarning = this.quoteToCustomer - this.driverCost;

    if (this.detail.additionalCharges) {
      this.additionalCharges = this.detail.additionalCharges;
      this.trukkinEarning = this.trukkinEarning - this.additionalCharges;
    }
  },
  props: {
    userId: Number,
    jobId: Number,
    currency: String,
    detail: Object,
    subJobDetail: Object,
    assignment: Array,
    pricing: Object,
    deliveryProof: Array,
    costJobtype: Number,
    viewOnly: String,
    workingcountry: String,
  },
  // props: [
  //   "userId",
  //   "jobId",
  //   "currency",
  //   "detail",
  //   "subJobDetail",
  //   "assignment",
  //   "pricing",
  //   "deliveryProof",
  //   "perAssignmentPrice",
  //   "subJobPrice",
  //   "tabOrder",
  //   "jobType",
  //   "workingcountry",
  //   "viewOnly",
  // ],
  components: {
    ErrorBox,
    SuccessDialog,
    customerAccepted,
    editDriverPrice,
  },
  data() {
    return {
      getJobType(type) {
        switch (type) {
          case "1":
            return "Land";
          case "2":
            return "Air";
          case "3":
            return "Sea";
          default:
            return "Land";
        }
      },
      quoteToCustomer: "",
      driverCost: "",
      trukkinEarning: "",
      processing: false,
      readOnly: true,
      isEmail: true,
      x: {
        error: null,
        message: "",
        loading: false,
        success: false,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  watch: {},
  methods: {
    closeMessage() {
      this.processing = false;
      this.x.success = false;
      this.isEmail = true;
      this.$emit("updateJobs");
    },
    sendQuote() {
      if (
        !this.userId ||
        !this.jobId ||
        !this.driverCost.trim() ||
        !this.quoteToCustomer.trim()
      ) {
        this.x.error = "Please fill all fields";
      } else {
        if (this.processing) {
          return;
        } else {
          if (!this.currency) {
            this.processing = false;
            this.x.error = "Please Select the currency.";
            setTimeout(() => (this.x.error = null), 6001);
          } else {
            this.processing = true;
            let url = sendQuote;
            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            let config = {
              headers: {
                authorization: `bearer ${token}`,
              },
            };
            let body = {
              userId: this.userId.toString(),
              jobId: this.jobId.toString(),
              appCost: "0",
              DriverCost: this.driverCost,
              QuoteToCust: this.quoteToCustomer,
            };
            this.axios.post(this.constants.apiUrl + url, body, config).then(
              (response) => {
                this.x.message = response.data.message;
                this.quoteToCustomer = " ";
                this.driverCost = " ";
                this.trukkinEarning = " ";
                this.trukkinEarning = " ";
                this.$forceUpdate();
                this.processing = false;
                this.x.success = true;
                this.readOnly = true;
                this.isEmail = true;
              },
              (error) => {
                this.processing = false;
                this.dialog = true;
                this.x.error = "Unable to Send Quote.";
              }
            );
          }
        }
      }
    },
  },
};
</script>
<style>
.p-16 {
  padding: 16px !important;
}
</style>
