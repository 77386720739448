import { StorageKeys } from "@/constants/constants";
import Swal from "sweetalert2";
let API_END_POINT = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {};
  },
  created: function () {},
  methods: {
    forceDownload(url, fileName) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    },

    DownloadExcel(url, fileName) {
      let token = localStorage.getItem(StorageKeys.SessionKey);

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("authorization", `bearer ${token}`);

      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    },
    // DownloadExcel(url, fileName) {
    //   let token = localStorage.getItem(StorageKeys.SessionKey);

    //   var xhr = new XMLHttpRequest();
    //   xhr.open("GET", url, true);
    //   xhr.setRequestHeader("authorization", `bearer ${token}`);
    //   xhr.responseType = "blob";
    //   xhr.onload = function () {
    //     var urlCreator = window.URL || window.webkitURL;
    //     var imageUrl = urlCreator.createObjectURL(this.response);
    //     var tag = document.createElement("a");
    //     tag.href = imageUrl;
    //     tag.download = fileName;
    //     document.body.appendChild(tag);
    //     tag.click();
    //     document.body.removeChild(tag);
    //   };
    //   xhr.send();
    // },
  },
};
