<template>
  <div>
    <button
      type="button"
      v-permissions="'assign-driver-jobs'"
      class="track-assign-btn mt-1"
      :disabled="jobStatus == 10 || viewOnly == 'view'"
      style="width: 118px"
      @click.stop="open"
    >
      Change driver
    </button>
    <v-dialog v-model="toShow" persistent>
      <v-card style="height: 100% !important">
        <v-card-title
          class="headline bg-clr padding-left"
          style="color: black !important"
        >
          Change Driver
          <v-spacer />

          <div @click="closeDialog">
            <v-icon class="pointer">close</v-icon>
          </div>
        </v-card-title>
        <v-spacer class="customScroll scrollDialog">
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              onsubmit="return false;"
            >
              <div>
                <v-container fluid>
                  <v-flex xs12 md6>
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="driverType"
                      row
                      single-line
                      :disabled="loading"
                      :mandatory="true"
                    >
                      <div>
                        <div class>
                          <v-layout>
                            <v-radio
                              label="Transporter's Driver"
                              value="Transporter"
                              color="orange"
                              @change="
                                searchTypeTransporter($event);
                                isSearchResult = false;
                                loading = true;
                              "
                              class="col-md-6"
                            ></v-radio>
                            <v-radio
                              label="Owner Drivers"
                              value="Owner"
                              v-if="!assignmentDetail.assignedToTransporter"
                              @change="
                                searchTypeOwner($event);
                                isSearchResult = false;
                                loading = true;
                              "
                              color="orange"
                              class="col-md-6"
                            ></v-radio>
                            <!-- <v-radio
                              label="Transporters"
                              value="ownTransporter"
                              v-if="
                                !assignmentDetail.assignedToTransporter &&
                                !transporterDriverShow
                              "
                              color="orange"
                              @change="
                                searchTypeOwnTransporter($event);
                                isSearchResult = false;
                                loading = true;
                              "
                              class="col-md-6"
                            ></v-radio> -->
                          </v-layout>
                        </div>
                      </div>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-layout>
                      <v-flex xs3 pr-1 v-if="driverType == 'Transporter'">
                        <div class="heading">
                          <v-autocomplete
                            style="background-color: white !important"
                            attach
                            :items="transporters"
                            v-model="transporterId"
                            label="All Transporter"
                            :disabled="assignmentDetail.assignedToTransporter"
                            @change="
                              searchTransporter();
                              isSearchResult = true;
                            "
                            single-line
                            class="pt-0 currencyTitle"
                            required
                          ></v-autocomplete>
                        </div>
                      </v-flex>

                      <v-flex xs8>
                        <v-text-field
                          class="pt-0 currencyTitle"
                          :label="
                            driverType != 'ownTransporter'
                              ? 'Driver Name, Driver Id & Emirates Id*'
                              : 'Transporter name, Phone number and ID*'
                          "
                          :loading="loading"
                          v-model="searchText"
                          :value="searchText"
                          @keyup.enter="searchDriver"
                          @click:append="searchDriver"
                        />
                      </v-flex>

                      <v-flex xs2 pl-1>
                        <v-layout>
                          <v-btn
                            color="white"
                            @click="searchDriver()"
                            :loading="loading"
                          >
                            <v-icon small>search</v-icon>&nbsp;Search
                          </v-btn>
                          <v-btn
                            flat
                            class="reset-btn-color"
                            prepend-icon
                            color="orange darken-1"
                            @click="
                              resetSearch();
                              isSearchResult = false;
                            "
                          >
                            Reset
                            <v-icon color="orange">donut_large</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-flex>
                      <v-flex xs2></v-flex>
                      <v-flex xs8 v-if="driverType == 'Transporter'">{{
                        messageAssignmentPrice
                      }}</v-flex>
                      <v-flex xs6 v-else>{{ messageAssignmentPrice }}</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-layout>
                    <v-flex xs6 md8>
                      <v-layout
                        align-baseline
                        row
                        wrap
                        pt-0
                        style="
                          box-shadow: none;
                          height: 500px !important;
                          border: none !important;
                          margin: 0px;
                          padding: 0px 10px 0px 0px !important;
                        "
                      >
                        <v-flex xs8 v-if="isSearchResult == false">
                          <div class="ft20 pt-2 pb-1" v-if="items.length > 0">
                            All Results
                            <span style="color: black; font-size: 11px"
                              >{{ totalData }} results</span
                            >
                          </div></v-flex
                        >
                        <v-flex xs8 v-if="isSearchResult == true">
                          <div class="ft20 pt-2 pb-1" v-if="items.length > 0">
                            Search Results
                            <span style="color: black; font-size: 11px"
                              >{{ totalData }} results</span
                            >
                          </div></v-flex
                        >

                        <v-flex xs12 v-if="items.length > 0">
                          <v-simple-table
                            v-if="
                              items.length > 0 && driverType != 'ownTransporter'
                            "
                          >
                            <v-spacer class="spacerFix" ref="tableList">
                              <thead>
                                <tr>
                                  <th class="postionMain">Driver Name</th>
                                  <th
                                    class="postionMain"
                                    style="padding-left: 20px"
                                  >
                                    Status
                                  </th>
                                  <th class="postionMain">Driver ID</th>
                                  <th class="postionMain">Last Location</th>

                                  <th class="postionMain">Total Booking</th>
                                  <th class="postionMain">Emirates Id</th>
                                  <th
                                    class="postionMain"
                                    style="padding-left: 20px"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, i) in items"
                                  :key="i"
                                  style="text-align: left"
                                >
                                  <td style="width: 20%; word-break: break-all">
                                    {{ item.driverName }}<br /><span
                                      style="color: grey"
                                      >{{ item.countryCode }}-{{
                                        item.phoneNo
                                      }}</span
                                    >
                                  </td>
                                  <td
                                    style="width: 10%"
                                    v-if="item.availabilityStatus"
                                  >
                                    <div style="color: green">Available</div>
                                  </td>
                                  <td style="width: 10%" v-else>
                                    <div style="color: red">Busy</div>
                                  </td>
                                  <td style="width: 10%; word-break: break-all">
                                    {{ item.value }}
                                  </td>
                                  <td
                                    style="width: 10%"
                                    v-if="item.assignmentData"
                                  >
                                    {{
                                      item.assignmentData.destinationCity || "-"
                                    }}
                                  </td>

                                  <td style="width: 10%" v-else>-</td>

                                  <td style="width: 10%">
                                    {{ item.completeAssignCount }}
                                  </td>
                                  <td style="width: 10%; word-break: break-all">
                                    {{ item.emiratesID || "-" }}
                                  </td>
                                  <td
                                    style="width: 10%; padding: 0px !important"
                                  >
                                    <v-btn
                                      v-if="!item.isSelect"
                                      class="ma-2"
                                      outline
                                      color="orange"
                                      @click="
                                        setDriver(item);
                                        fetchVendor(item.value);
                                      "
                                    >
                                      Unselected
                                    </v-btn>
                                    <v-btn
                                      v-if="item.isSelect"
                                      color="orange darken-1"
                                      class="white--text"
                                      style="width: 110px !important"
                                      @click="
                                        resetSelect();
                                        item.isSelect = false;
                                      "
                                    >
                                      selected
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </v-spacer>
                          </v-simple-table>
                          <v-simple-table
                            v-if="
                              items.length > 0 && driverType == 'ownTransporter'
                            "
                          >
                            <v-spacer class="spacerFix" ref="tableList">
                              <thead>
                                <tr>
                                  <th class="postionMain">Transporter Name</th>

                                  <th class="postionMain">Transporter ID</th>
                                  <th class="postionMain">Total Drivers</th>

                                  <th class="postionMain">Total Bookings</th>
                                  <th
                                    class="postionMain"
                                    style="padding-left: 20px"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, i) in items"
                                  :key="i"
                                  style="text-align: left"
                                >
                                  <td style="width: 20%; word-break: break-all">
                                    {{ item.transporterName }}<br /><span
                                      style="color: grey"
                                      >{{ item.countryCode }}-{{
                                        item.phoneNo
                                      }}</span
                                    >
                                  </td>
                                  <td style="width: 10%">
                                    <div style="color: green">
                                      {{ item.transporterId }}
                                    </div>
                                  </td>
                                  <td
                                    v-if="item.totalDriver != 0"
                                    style="width: 10%"
                                  >
                                    <span style="color: orange">
                                      {{ item.freeDriver }}</span
                                    >/{{ item.totalDriver }}
                                  </td>
                                  <td v-else style="width: 10%">-</td>

                                  <td style="width: 10%; word-break: break-all">
                                    {{ item.totalBooking }}
                                  </td>
                                  <td
                                    style="width: 10%; padding: 0px !important"
                                  >
                                    <v-btn
                                      v-if="!item.isSelect"
                                      class="ma-2"
                                      outline
                                      color="orange"
                                      @click="setDriver(item)"
                                    >
                                      Unselected
                                    </v-btn>
                                    <v-btn
                                      v-if="item.isSelect"
                                      color="orange darken-1"
                                      class="white--text"
                                      style="width: 110px !important"
                                      @click="
                                        resetSelect();
                                        item.isSelect = false;
                                      "
                                    >
                                      selected
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </v-spacer>
                          </v-simple-table>
                        </v-flex>

                        <PaginationButtons
                          v-if="toShow"
                          :url="`/newDriverFlow/transporterOwnerAndDriver`"
                          :payload="payload"
                          :callback="callback"
                          :componentKey="componentKey"
                          v-on:pagination-payload="paginationPayload"
                        />
                      </v-layout>
                    </v-flex>

                    <v-divider vertical></v-divider>

                    <v-flex xs6 md4 class="pl-3">
                      <v-layout
                        v-if="driverType == 'ownTransporter'"
                        align-baseline
                        row
                        wrap
                        style="
                          height: 500 !important;
                          box-shadow: none;
                          border: none !important;
                          padding: 0px;
                          margin: 0px;
                        "
                      >
                        <v-flex xs12 class="pr-2 pt-3">
                          <div class="subheading muted caption">
                            Transporter rate for this assignment*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              readonly
                              :suffix="currency"
                              type="number"
                              single-line
                              v-model="paymentAmount"
                              :value="paymentAmount"
                              :rules="[rules.required, rules.number]"
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="
                            this.driver.transporterName &&
                            !this.driver.vatNumber
                          "
                        >
                          <div class="subheading muted caption">
                            {{
                              currency != "PKR"
                                ? "Vendor VAT Number"
                                : "Vendor STRN Number*"
                            }}
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="vendorVatNumber"
                              :value="vendorVatNumber"
                              counter
                              :rules="currency != 'PKR' ? [] : [rules.required]"
                              single-line
                              maxlength="20"
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class
                          v-if="
                            this.driver.transporterName && !this.driver.address
                          "
                        >
                          <div class="subheading muted caption">
                            Vendor's Address*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="VendorAddress"
                              :value="VendorAddress"
                              single-line
                              :rules="[rules.required]"
                            />
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        align-baseline
                        row
                        wrap
                        style="
                          height: 460px;
                          box-shadow: none;
                          border: none !important;
                          padding: 0px;
                          margin: 0px;
                        "
                      >
                        <v-flex
                          xs6
                          class="pr-2 pt-3"
                          v-if="driverType != 'ownTransporter'"
                        >
                          <div class="subheading muted caption">
                            Driver rate for this assignment*
                          </div>
                          <div class="heading">
                            <!-- <div v-if="assignmentDetail.isFrieghtDocument==1">N.A</div>   -->
                            <div>
                              <v-text-field
                                class="pt-0 currencyTitle"
                                readonly
                                :suffix="currency"
                                type="number"
                                single-line
                                v-model="paymentAmount"
                                :value="paymentAmount"
                                :rules="[rules.required, rules.number]"
                              />
                            </div>
                          </div>
                        </v-flex>
                        <v-flex
                          xs5
                          class="pr-2"
                          v-if="driverType != 'ownTransporter'"
                        >
                          <div class="subheading muted caption">
                            Attach Hardware
                          </div>
                          <div class="heading">
                            <v-autocomplete
                              :items="assets"
                              class="pt-0 currencyTitle"
                              item-text="imeiNumber"
                              item-value="_id"
                              v-model="assetId"
                            >
                            </v-autocomplete>
                          </div>
                        </v-flex>
                        <v-flex
                          xs1
                          class="pr-2 reset-postion"
                          pt-2
                          v-if="driverType != 'ownTransporter' && assetId"
                        >
                          <v-icon
                            color="orange"
                            class="pointer"
                            @click="assetId = null"
                            >donut_large</v-icon
                          >
                        </v-flex>

                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Owner'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Name*
                          </div>
                          <div class="heading">
                            <v-combobox
                              class="pt-0 currencyTitle"
                              :items="vendorNames"
                              v-model="vendorName"
                              item-text="vendorName"
                              item-value="vendorName"
                              @change="checkVendorName()"
                            ></v-combobox>
                          </div>
                          <span v-if="this.errorName"
                            >Please provide vendor's name</span
                          >
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Transporter'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Name*
                          </div>
                          <div class="heading">
                            <v-combobox
                              class="pt-0 currencyTitle"
                              :items="vendorNames"
                              v-model="vendorName"
                              :disabled="transporterName || !vendorNames.length"
                              item-text="transporterName"
                              item-value="transporterName"
                              @change="checkVendorName()"
                            ></v-combobox>
                          </div>
                          <span v-if="this.errorName"
                            >Please provide vendor's name</span
                          >
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Transporter'"
                        >
                          <div class="subheading muted caption">
                            {{
                              currency != "PKR"
                                ? "Vendor VAT Number"
                                : "Vendor STRN Number*"
                            }}
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="vendorVatNumber"
                              :value="vendorVatNumber"
                              :rules="currency != 'PKR' ? [] : [rules.required]"
                              :disabled="
                                vendorName &&
                                (vendorName.vendorVatNumber ||
                                  vendorName.vatNumber ||
                                  transporterVAT)
                                  ? true
                                  : false
                              "
                              counter
                              single-line
                              maxlength="20"
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Owner'"
                        >
                          <div class="subheading muted caption">
                            {{
                              currency != "PKR"
                                ? "Vendor VAT Number"
                                : "Vendor STRN Number"
                            }}
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="vendorVatNumber"
                              :value="vendorVatNumber"
                              :disabled="
                                (vendorName && vendorName.vendorVatNumber) ||
                                vendorName.vatNumber ||
                                transporterVAT
                                  ? true
                                  : false
                              "
                              counter
                              single-line
                              maxlength="20"
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType != 'ownTransporter'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Country Code*
                          </div>
                          <div class="heading">
                            <v-autocomplete
                              class="pt-0 currencyTitle"
                              style="background-color: white !important"
                              :close-on-content-click="true"
                              attach
                              :disabled="
                                (vendorName && vendorName.countryCode) ||
                                driver.countryCode
                                  ? true
                                  : false
                              "
                              item-text="dialing_code"
                              item-value="dialing_code"
                              :items="countryCodes"
                              v-model="countryCode"
                              single-line
                              :rules="[rules.noWhiteSpace, rules.required]"
                              required
                            />
                          </div>
                        </v-flex>

                        <v-flex
                          xs12
                          md6
                          class
                          v-if="driverType != 'ownTransporter'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Mobile Number*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              :disabled="
                                (vendorName && vendorName.phoneNo) ||
                                driver.phoneNo
                                  ? true
                                  : false
                              "
                              v-model="VendorNumber"
                              :value="VendorNumber"
                              single-line
                              :rules="[rules.noWhiteSpace, rules.required]"
                            />
                          </div>
                        </v-flex>

                        <v-flex
                          xs12
                          md6
                          v-if="
                            driver.name &&
                            driverType != 'ownTransporter' &&
                            prefixTrucks.includes(driver.truckPrefix)
                          "
                        >
                          <div
                            v-if="!driver.truckPrefix"
                            class="subheading muted caption"
                          >
                            Prefix*
                          </div>
                          <div v-if="!driver.truckPrefix" class="heading">
                            <v-autocomplete
                              v-if="prefixTrucks.includes(driver.truckPrefix)"
                              class="pt-0 currencyTitle"
                              :items="prefixTrucks"
                              :readonly="driver.truckPrefix"
                              style="padding-top: 10px"
                              v-model="truckPrefix"
                              :rules="[rules.required]"
                              single-line
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          v-if="
                            driverType != 'ownTransporter' &&
                            driver.name &&
                            !prefixTrucks.includes(driver.truckPrefix)
                          "
                        >
                          <div
                            v-if="!driver.truckPrefix"
                            class="subheading muted caption"
                          >
                            Prefix*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-if="!driver.truckPrefix"
                              :readonly="driver.truckPrefix"
                              v-model="truckPrefix"
                              :value="truckPrefix"
                              single-line
                              :rules="[rules.noWhiteSpace, rules.required]"
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          v-if="driverType != 'ownTransporter' && driver.name"
                          xs6
                          md6
                          class="pl-2"
                        >
                          <div
                            v-if="!driver.truckNumber"
                            class="subheading muted caption"
                          >
                            Truck Number*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              v-if="!driver.truckNumber"
                              :readonly="driver.truckNumber"
                              v-model="truckNumber"
                              single-line
                              :rules="[rules.noWhiteSpace, rules.required]"
                            />
                          </div>
                        </v-flex>

                        <v-flex
                          xs12
                          md6
                          class
                          v-if="driverType == 'Transporter'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Address*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="VendorAddress"
                              :value="VendorAddress"
                              :rules="[rules.noWhiteSpace, rules.required]"
                              :disabled="
                                vendorName
                                  ? vendorName.vendorAddress ||
                                    vendorName.address ||
                                    transporterAddress
                                  : false
                              "
                              single-line
                            />
                          </div>
                        </v-flex>
                        <v-flex xs12 md6 class v-if="driverType == 'Owner'">
                          <div class="subheading muted caption">
                            Vendor's Address
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="VendorAddress"
                              :value="VendorAddress"
                              :disabled="
                                vendorName
                                  ? vendorName.vendorAddress ||
                                    vendorName.address ||
                                    transporterAddress
                                  : false
                              "
                              single-line
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pl-2"
                          v-if="driverType != 'ownTransporter'"
                        >
                          <div class="subheading muted caption">
                            Hardware label
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="assetLabel"
                              single-line
                              counter
                              maxlength="50"
                            />
                          </div>
                        </v-flex>
                        <v-flex md12>
                          <v-layout row pt-0>
                            <v-flex md12 v-if="!this.$props.milestoneId">
                              <div class="subheading muted caption">
                                Set Milestone*
                              </div>
                              <v-select
                                v-model="milestone"
                                :items="milestoneItems"
                                item-text="pickupLocation"
                                item-value="_id"
                                class="pt-0 currencyTitle"
                                label="Select a milestone"
                                persistent-hint
                                @change="getChecklist()"
                                single-line
                              >
                                <template slot="item" slot-scope="data"
                                  >{{ data.item.pickupLocation }} -
                                  {{ data.item.dropOffLocation }}</template
                                >
                              </v-select>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex
                              xs6
                              v-if="transporterName || driver.transporterName"
                            >
                              <div class="ft20 pb-1">Selected Transporter</div>
                              <v-card class="mx-auto card-style">
                                <v-layout row>
                                  <v-flex xs6 md11 v-if="this.transporterName"
                                    ><span style="color: gray !important"
                                      >{{ this.transporterName }}<br />{{
                                        this.transporterCountryCode
                                      }}-{{ this.transporterPhone }}</span
                                    ></v-flex
                                  >
                                  <v-flex xs6 md11 v-if="driver.transporterName"
                                    ><span style="color: gray !important"
                                      >{{ driver.transporterName }}<br />{{
                                        driver.countryCode
                                      }}-{{ driver.phoneNo }}</span
                                    ></v-flex
                                  >
                                  <!-- <v-flex
                                    ><v-icon
                                      small
                                      @click="resetSelect()"
                                      class="iconStyle"
                                      >close</v-icon
                                    ></v-flex
                                  > -->
                                </v-layout>
                              </v-card>
                            </v-flex>
                            <v-flex xs6 pl-2 v-if="driver.name">
                              <div class="ft20 pb-1">Selected Driver</div>
                              <v-card class="mx-auto card-style">
                                <v-layout row>
                                  <v-flex xs6 md11
                                    ><span style="color: gray !important">{{
                                      driver.name
                                    }}</span
                                    ><br />{{ driver.countryCode }}-{{
                                      driver.phoneNo
                                    }}</v-flex
                                  >
                                  <v-flex
                                    ><v-icon
                                      small
                                      @click="resetSelect()"
                                      class="iconStyle"
                                      >close</v-icon
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </v-form>
          </v-card-text>

          <v-divider v-if="milestone"></v-divider>
          <v-layout v-if="milestone">
            <v-flex
              xs12
              md12
              class="heading orange--text pt-2 pl-5"
              style="text-align: initial"
            >
              Milestone Information <br />
            </v-flex>
          </v-layout>
          <v-layout v-if="milestone">
            <v-flex xs12 md12 class="cont">
              <ul class="timelineHori">
                <li v-for="(item, i) in checkList" :key="i">
                  <span class="postion-set">{{ item.name }}</span>
                </li>
              </ul>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>
        </v-spacer>
        <v-layout>
          <v-flex
            class="red--text"
            style="
              text-align: center !important;
              padding-top: 10px;
              padding-left: 150px;
            "
            v-if="errorDriver !== null"
          >
            {{ errorDriver }}
          </v-flex>
          <v-spacer />
          <v-flex
            class="red--text"
            style="text-align: right !important; padding-top: 10px"
            v-if="error !== null"
          >
            {{ error }}
          </v-flex>
          <v-btn
            color="orange darken-1"
            class="white--text"
            v-if="driverType != 'ownTransporter'"
            @click.native="checkAssign()"
            :disabled="!driver.name || loading"
            :loading="loading"
            >Change Driver</v-btn
          >
          <v-btn
            color="orange darken-1"
            class="white--text"
            v-if="driverType == 'ownTransporter'"
            @click.native="checkAssign()"
            :disabled="!driver.transporterName"
            :loading="loading"
            >Assign Transporter</v-btn
          >
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import moment from "moment";
import { EventBus } from "../../../event-bus.js";
import Loading from "vue-loading-overlay";
import ErrorBox from "@/components/Common/ErrorBox";
import {
  StorageKeys,
  countries,
  TruckPrefix,
} from "../../../constants/constants";
export default {
  mounted() {},
  components: {
    CustomerCurrency,
    PaginationButtons,
    ErrorBox,
    Loading,
  },
  props: {
    // toShow: Boolean,
    assignmentId: Number,
    country: String,
    milestoneId: String,
    viewOnly: String || Boolean,
    jobStatus: Number,
    onclose: Function,
    currency: String,
    assignmentDetail: Object,
    type: String,
  },
  data: () => ({
    driverType: "Transporter",
    toShow: false,
    showTracking: "fullTracking",
    checkList: [],
    transporters: [],
    ownerType: "",
    isSearchResult: false,
    transporterName: "",
    transporterVAT: null,
    transporterAddress: null,
    transporterPhone: "",
    transporterCountryCode: "",
    transporterId: "",
    payload: {},
    dialog: false,
    message: "",
    options: {
      color: "#ffc04c",
      width: 560,
      zIndex: 200,
    },

    workingCountry: "",
    totalPages: 0,
    totalData: "",
    isSelect: false,
    input: null,
    errorDriver: null,
    milestone: null,
    input: null,
    transporterDriverShow: false,
    componentKey: 0,
    milestoneItems: [],
    currentMilestoneId: "",
    currentMilestone: 0,
    milestoneTrack: {
      milestone: [],
      assId: null,
    },
    vendorId: "",
    valid: true,
    lazy: false,
    countryCodes: [],
    countryCode: "",
    radios: "",
    vendorNames: [],
    vendorName: null,
    toggle_exclusive: 0,
    truckNumber: null,
    truckPrefix: null,
    prefixTrucks: [],
    assetLabel: null,

    error: null,
    loading: true,
    errorName: false,
    searchText: null,
    driver: {
      truckNumber: null,
      truckPrefix: null,
      id: null,
      name: null,
      isBid: false,
      bidAmount: null,
      bidCurrency: null,
    },
    items: [],

    VendorNumber: null,
    vendorVatNumber: null,
    VendorAddress: null,
    paymentCurrency: null,
    paymentAmount: null,
    waybillNo: null,
    pickupTime: null,
    assetId: null,
    messageAssignmentPrice: "",
    assets: [],
    currencies: ["AED", "SAR"],
    rules: {
      required: (value) => !!value || "This field is required.",
      requiredlength: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "Enter valid value.",
      vatNumber: (v) =>
        /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Vat Number",
      emailRules: (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
        "E-mail must be valid",
      number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Rate",
      countryCode: (v) =>
        /^(\+?\d{1,3}|\d{1,4})$/.test(v) || "Please Enter Valid Country Code.",
      phoneNumber: (v) =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Please Enter Valid Mobile No.",
    },
  }),
  computed: {
    hasError() {
      return this.error !== null;
    },
  },
  created() {
    this.prefixTrucks = TruckPrefix;
    this.countryCodes = countries;
    this.payload = {
      skip: "0",
      limit: "15",
      status: !this.$props.assignmentDetail.assignedToTransporter
        ? "ownTransporter"
        : "Transporter",
      assignmentId: this.$props.assignmentId,
      assignedToTransporter: this.$props.assignmentDetail.assignedToTransporter,
    };
  },
  watch: {},
  methods: {
    getChecklist() {
      if (this.milestone && this.milestone.length) {
        this.milestoneItems.filter((x) => {
          if (this.milestone === x._id) {
            this.checkList = x.checkList;
          }
        });
        setTimeout(() => {
          var objDiv = document.getElementsByClassName("scrollDialog");
          for (var i = 0; i < objDiv.length; i++) {
            objDiv[i].scrollTo({
              top: objDiv[i].scrollHeight,
              left: 0,
              behavior: "smooth",
            });
          }
        }, 1000);
      }
    },
    open() {
      this.paymentAmount = this.assignmentDetail.paymentAmount;
      this.messageAssignmentPrice = `Customer Price For Assignment Id ${this.assignmentDetail.assignmentId} : ${this.assignmentDetail.perAssignmentPrice} ${this.currency}`;
      this.toShow = true;
      this.$props.assignmentDetail.assignedToTransporter = false;
      this.fetchAssets();
      this.getMilestones();
      this.getTransporterList();
      this.driverType = "Transporter";

      this.payload = {
        skip: "0",
        limit: "15",
        status: this.driverType,
        assignmentId: this.$props.assignmentId,
        assignedToTransporter:
          this.$props.assignmentDetail.assignedToTransporter,
      };
    },
    checkTransporter() {
      this.transporters.forEach((e) => {
        if (e.value == this.transporterId) {
          this.transporterName = e.text;
          this.transporterCountryCode = e.countryCode;
          this.transporterPhone = e.phoneNo;
        }
      });
    },
    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
    },
    searchTypeTransporter(event) {
      this.resetDriver();
      this.transporterName = "";
      this.searchText = "";
      this.payload = {
        skip: "0",
        limit: "15",
        status: event,
        assignmentId: this.$props.assignmentId,
        assignedToTransporter:
          this.$props.assignmentDetail.assignedToTransporter,
      };
    },
    searchTypeOwnTransporter(event) {
      this.resetDriver();
      this.transporterName = "";
      this.searchText = "";
      this.payload = {
        skip: "0",
        limit: "15",
        status: event,
        assignmentId: this.$props.assignmentId,
        assignedToTransporter:
          this.$props.assignmentDetail.assignedToTransporter,
      };
    },
    searchTypeOwner(event) {
      this.resetDriver();
      this.transporterName = "";
      this.searchText = "";
      this.transporterId = "";
      this.payload = {
        skip: "0",
        limit: "15",
        status: event,
        assignmentId: this.$props.assignmentId,
        assignedToTransporter:
          this.$props.assignmentDetail.assignedToTransporter,
      };
    },

    callback(res) {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      this.errorDriver = "";
      this.totalData = res.totalData;
      this.vendorName = [];
      this.driver.name = "";
      this.driver.transporterName = "";
      this.transporterVAT = null;
      this.transporterAddress = null;
      this.totalPages = Math.ceil(res.totalData / 15);
      const drivers = res.list;
      if (res.assignData.length) {
        this.ownerType = res.assignData[0].ownerType;
        if (res.assignData[0].ownerType == "Transporter") {
          this.paymentAmount = res.assignData[0].paymentAmount
            ? Math.round(res.assignData[0].paymentAmount)
            : "";
        }
      }
      if (
        drivers &&
        drivers.length > 0 &&
        this.driverType != "ownTransporter"
      ) {
        this.items = drivers.map((driver) => {
          return {
            driverName: driver.firstName + " " + driver.lastName,
            phoneNo: driver.phoneNo,
            transporterName: driver.transporterName,
            transporterId: driver.transporterId,
            countryCode: driver.countryCode,
            emiratesID: driver.emiratesID,
            value: driver.driverId,
            isBid: driver.isBid,
            _id: driver._id,
            assignmentData: driver.assignmentData[0],
            availabilityStatus: driver.availabilityStatus,
            completeAssignCount: driver.completeAssignCount,
            isSelect: driver.isSelect == false,
            truckNumber: driver.truckNumber,
            truckPrefix: driver.truckPrefix,
            bidAmount: driver.bidAmount,
            bidCurrency: driver.bidCurrency,
            container: driver.truckNumber,
          };
        });
      } else if (
        drivers &&
        drivers.length > 0 &&
        this.driverType == "ownTransporter"
      ) {
        this.items = drivers.map((driver) => {
          return {
            transporterName: driver.transporterName,
            transporterId: driver.transporterId,
            phoneNo: driver.phoneNo,
            countryCode: driver.countryCode,
            isSelect: driver.isSelect == false,
            freeDriver: driver.freeDriver,
            totalDriver: driver.totalDriver,
            totalBooking: driver.assignCount || "-",
            vatNumber: driver.vatNumber,
            address: driver.address,
            _id: driver._id,
          };
        });
      } else {
        if (this.driverType == "ownTransporter") {
          this.errorDriver = "No transporters found";
        } else {
          this.errorDriver = "No drivers found";
        }
      }

      if (this.$refs.tableList) {
        this.$refs.tableList.scrollTo(0, 0);
      }
      // this.$refs.tableList.scrollTop(0);
    },
    getTransporterList() {
      this.userId = localStorage.getItem("userId");
      let url = "/dmsAdmin/transporterForDriverDetail";
      delete this.axios.defaults.headers.common["token"];
      let body = {
        workingCountry: this.country,
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;

          response.data.data.list.forEach((e) => {
            this.transporters.push({
              value: e._id,
              text: e.transporterName,
              phoneNo: e.phoneNo,
              countryCode: e.countryCode,
            });
          });
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to fetch transporters";
        }
      );
    },
    getTime3(date) {
      return moment(date).format("DD/MM/YYYY HH:mm ");
    },
    resetDriver() {
      this.items = [];
      this.showTracking = "fullTracking";
      this.VendorNumber = null;
      this.transporterAddress = null;
      this.transporterVAT = null;
      this.assetId = null;
      this.countryCode = null;
      this.errorDriver = null;
      this.vendorVatNumber = null;
      this.vendorNames = [];
      this.vendorName = [];
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.items.forEach((key, index) => {
        if (key.isSelect) {
          key.isSelect = false;
        }
      });
      this.driver.name = null;
      this.driver.id = null;
      this.error = null;
      this.driver.phoneNo = null;
      this.driver.countryCode = null;
      this.driver.bidAmount = null;
      this.driver.bidCurrency = null;
      this.assetLabel = null;
      this.radio = null;
      this.$refs.form.resetValidation();
    },
    resetTranporterSearch() {
      this.resetSelect();
      if (this.searchText) {
        this.payload = {
          skip: "0",
          limit: "15",
          status: this.driverType,
          assignmentId: this.$props.assignmentId,
          searchText: this.searchText,
          assignedToTransporter:
            this.$props.assignmentDetail.assignedToTransporter,
        };
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          status: this.driverType,
          assignmentId: this.$props.assignmentId,
          assignedToTransporter:
            this.$props.assignmentDetail.assignedToTransporter,
        };
      }
    },
    resetSelect() {
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.transporterAddress = null;
      this.transporterVAT = null;
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.errorDriver = null;
      this.items.forEach((key, index) => {
        if (key.isSelect) {
          key.isSelect = false;
        }
      });
      this.driver.name = null;
      this.driver.id = null;
      this.error = null;
      this.driver.bidAmount = null;
      this.driver.bidCurrency = null;
      this.assetLabel = null;
      this.radio = null;
      this.$refs.form.resetValidation();
    },
    resetSearch() {
      this.resetDriver();
      this.searchText = "";
      if (this.ownerType != "Transporter") {
        this.transporterName = "";
        this.transporterId = "";
      }
      this.payload = {
        skip: "0",
        limit: "15",
        status: this.driverType,
        assignmentId: this.$props.assignmentId,
        assignedToTransporter:
          this.$props.assignmentDetail.assignedToTransporter,
      };
    },
    async checkAssign() {
      console.log("checkassign");

      this.loading = true;
      this.operationName = "assign-driver-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (this.driverType != "ownTransporter") {
          this.assignDriver();
        } else {
          this.assignTransporter();
        }
      } else {
        this.loading = false;
        return;
      }
    },
    // getTrackingStatus() {
    //   let url = "/dmsAdmin/trackingStatus";
    //   delete this.axios.defaults.headers.common["token"];
    //   let token = localStorage.getItem(StorageKeys.SessionKey);
    //   let config = {
    //     headers: {
    //       authorization: `bearer ${token}`,
    //     },
    //   };
    //   let assignmentId = this.$props.assignmentId.toString();
    //   let body = {
    //     assId: assignmentId,
    //   };
    //   this.axios.post(this.constants.apiUrl + url, body, config).then(
    //     (response) => {
    //       this.milestoneTrack.milestone = response.data.data;
    //       for (
    //         let index = 0;
    //         index < this.milestoneTrack.milestone.length;
    //         index++
    //       ) {
    //         if (!this.milestoneTrack.milestone[index].isChecked) {
    //           this.currentMilestone = index;
    //           break;
    //         }
    //       }
    //       this.milestoneTrack.assId = assignmentId;
    //     },
    //     (error) => {
    //       this.error = "Failed to Fetch Milestone Status";
    //     }
    //   );
    // },
    checkDriverAvailability(id) {
      let url = "/dmsAdmin/checkDriverAvailability";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        driverId: id.toString(),
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {},
        (error) => {
          this.errorDriver = error.response.data.message;
        }
      );
    },
    clearMilestone() {
      this.milestone = null;
      this.getMilestones();
    },
    getMilestones() {
      let url = "/dmsAdmin/milestoneList";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        workingCountry: [this.country],
        assignDriver: "true",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneItems = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    up() {
      if (this.truckPrefix && this.truckNumber) {
        assetLabel = this.truckPrefix + " " + this.truckNumber;
      }
    },
    fetchVendor(id) {
      this.transporterAddress = null;
      this.transporterVAT = null;
      this.errorDriver = null;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      var data = {
        driverId: id.toString(),
        ownerType: this.driverType,
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/newDriverFlow/vendorDriverTransporter`, data, config)
        .then(
          (response) => {
            if (this.driverType == "Owner") {
              this.vendorNames = response.data.data.list.filter((x) => {
                if (x.vendorName) {
                  if (this.driver.name == x.vendorName) {
                    this.vendorName = x.vendorName || this.driver.name;
                    this.VendorNumber =
                      this.vendorName.VendorNumber || this.driver.phoneNo;
                    this.countryCode =
                      this.vendorName.vendorCountry || this.driver.countryCode;
                  }
                  return x;
                } else {
                  if (x.vatNumber) {
                    this.vendorVatNumber = x.vatNumber;
                  }

                  this.vendorName = this.driver.name;
                  this.vendorId = this.driver._id;
                  this.VendorNumber =
                    this.vendorName.VendorNumber || this.driver.phoneNo;
                  this.countryCode =
                    this.vendorName.vendorCountry || this.driver.countryCode;
                }
              });
            } else {
              if (
                response.data.data.list.length &&
                response.data.data.list[0].transporterData
              ) {
                this.vendorNames =
                  response.data.data.list[0].transporterData.filter((x) => {
                    if (x.transporterName) {
                      if (this.transporterId == x._id) {
                        this.vendorId = x._id;
                        this.vendorName = x.transporterName;
                        this.VendorAddress = x.address;
                        this.vendorVatNumber = x.vatNumber;
                        this.transporterAddress = x.address;
                        this.transporterVAT = x.vatNumber;
                        this.VendorNumber = x.phoneNo || this.driver.phoneNo;
                        this.countryCode =
                          x.countryCode || this.driver.countryCode;
                      }
                      return x;
                    }
                  });
              } else {
                this.loading = false;
                this.errorDriver = "Driver don't have vendor data!";
              }
            }
          },
          (error) => {
            this.loading = false;
            this.error = "Failed to fetch vendor data";
          }
        );
    },
    checkVendorName() {
      this.VendorNumber =
        this.vendorName.VendorNumber ||
        this.vendorName.phoneNo ||
        this.driver.phoneNo;
      this.vendorVatNumber =
        this.vendorName.vendorVatNumber || this.vendorName.vatNumber;
      this.countryCode =
        this.vendorName.vendorCountry ||
        this.vendorName.countryCode ||
        this.driver.countryCode;
      this.VendorAddress =
        this.vendorName.vendorAddress || this.vendorName.address;
      this.vendorId = this.vendorName._id || this.driver._id;
      this.error = null;
      this.loading = false;
      this.errorName = false;
    },
    fetchAssets() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      const { apiUrl } = this.constants;

      let data = {
        workingCountry: [this.country],
      };
      this.axios.post(`${apiUrl}/assets/freeAssetsListing`, data, config).then(
        (response) => {
          this.assets = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = this.$eventBus.parse(error);
        }
      );
    },
    closeDialog() {
      this.toShow = false;
      this.error = "";
      this.errorDriver = null;
      this.items = [];
      this.isSearchResult = false;
      this.transporterName = null;
      this.transporterId = null;
      this.driverType = "Owner";
      this.searchText = null;
      (this.milestone = null), (this.VendorAddress = null);
      this.countryCode = null;
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.assetLabel = null;
      this.VendorNumber = null;
      this.paymentAmount =
        this.error =
        this.pickupTime =
        this.driver.id =
        this.driver.name =
        this.assetId =
          null;
      this.driver.bidAmount = this.driver.bidCurrency = null;
      this.$emit("driverAssignClose");
      this.toggle_exclusive = "";
      this.$refs.form.resetValidation();
    },
    destroyed() {
      this.items = [];
      this.errorDriver = null;
      this.isSearchResult = false;
      this.searchText = null;
      this.transporterName = "";
      this.driver.id = null;
      this.driver.bidCurrency = null;
      this.driver.bidAmount = null;
      this.driver.name = null;
      this.assetId = null;
      this.milestone = null;
      this.paymentAmount = null;
      this.vendorName = [];
      this.VendorNumber = null;
      this.vendorVatNumber = null;
      this.VendorAddress = null;
      this.countryCode = "";
      this.truckNumber = null;
    },
    setDriver(item) {
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.errorDriver = null;
      this.items.forEach((key, index) => {
        if (key.isSelect) {
          key.isSelect = false;
        }
      });

      this.errorDriver = null;
      if (item) {
        this.driver.id = item.value;
        this.driver.name = item.driverName;
        this.driver.phoneNo = item.phoneNo;
        this.driver.vatNumber = item.vatNumber;
        this.driver.address = item.address;
        this.driver.transporterName = item.transporterName;
        this.driver.transporterId = item.transporterId;
        this.driver.truckNumber = item.truckNumber;
        this.driver.isBid = item.isBid;
        this.driver._id = item._id;
        this.driver.countryCode = item.countryCode;
        item.isSelect = true;
        this.driver.bidAmount = item.bidAmount;
        this.driver.bidCurrency = item.bidCurrency;
        this.driver.availabilityStatus = item.availabilityStatus;
        this.truckNumber = item.truckNumber;
        this.truckNumber = this.driver.truckNumber;
        this.truckPrefix = this.driver.truckPrefix;
        this.driver.truckPrefix = item.truckPrefix;
        this.truckPrefix = this.driver.truckPrefix;
        // this.paymentAmount = item.bidAmount;
        if (this.driverType != "ownTransporter") {
          this.checkDriverAvailability(item.value);
        }
        this.$refs.form.resetValidation();
        if (this.driver.truckNumber && this.driver.truckPrefix) {
          this.assetLabel = this.truckPrefix + "-" + this.truckNumber;
        } else if (this.driver.truckNumber) {
          this.assetLabel = this.truckNumber;
        } else {
          this.assetLabel = "";
        }
      }
    },
    async OverPriceAccepted() {
      this.loading = true;
      this.operationName = "assign-driver-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (this.driverType != "ownTransporter") {
          this.assignDriver("overPriced");
        } else {
          this.assignTransporter("overPriced");
        }
      } else {
        this.loading = false;
        return;
      }
    },
    assignDriver(item) {
      console.log(item);
      this.errorName = false;
      this.errorDriver = null;
      if (this.vendorName != undefined) {
        if (this.vendorName.length == 0) {
          this.loading = false;
          this.error = "Please provide vendor's name";
          return false;
        }
      }
      if (!this.vendorName) {
        this.loading = false;
        this.error = "Please provide vendor's name";
        return false;
      }
      if (this.$refs.form.validate()) {
        this.error = null;
        this.loading = true;
        this.errorName = false;
        const { id, name, bidAmount, bidCurrency } = this.driver;
        if (!this.paymentAmount || this.paymentAmount <= 0) {
          this.loading = false;
          this.error = "Please provide payment amount above 0";
          return false;
        }
        if (this.vendorName != undefined) {
          if (this.vendorName.length == 0) {
            this.loading = false;
            this.error = "Please provide vendor's name";
            return false;
          }
        }
        if (!this.vendorName) {
          this.loading = false;
          this.error = "Please provide vendor's name";
          return false;
        }
        const data = {
          driverId: id.toString(),
          assignmentId: this.assignmentId.toString(),
          paymentAmount: this.paymentAmount.toString(),
          paymentCurrency: this.$props.currency,
          vendorName: this.vendorName,
          VendorNumber: this.VendorNumber,
          truckNumber: this.truckNumber || "",
          truckPrefix: this.truckPrefix,
          vendorVatNumber: this.vendorVatNumber
            ? this.vendorVatNumber.trim()
            : "",
          vendorAddress: this.VendorAddress ? this.VendorAddress.trim() : "",
          vendorCountry: this.countryCode.dialing_code || this.countryCode,
          ownerId: this.vendorId || this.driver._id,
          ownerType: this.driverType,
          type: true,
        };
        // if(this.VendorAddress) data['vendorAddress'] = this.VendorAddress;
        // if(this.vendorVatNumber) data['vendorVatNumber'] = this.vendorVatNumber;
        if (
          this.driverType != "ownTransporter" &&
          this.assetId &&
          this.country != "PAK"
        ) {
          if (this.showTracking === "fullTracking") {
            data.isFullTrackingOn = true;
          }
          if (this.showTracking === "onlyCurrentLocation") {
            data.isFullTrackingOn = false;
          }
        }
        if (this.milestone && this.milestone.length > 0) {
          data.milestoneId = this.milestone;
        }
        if (this.assetLabel) {
          data.label = this.assetLabel.trim();
        }

        if (this.assetId) {
          data.assetId = this.assetId.toString();

          let y = this.assets.filter((x) => {
            if (data.assetId === x._id) {
              return x.imeiNumber;
            }
          });

          data.imeiNumber = y[0].imeiNumber;
        }
        if (this.vendorName.vendorName) {
          data.vendorName = this.vendorName.vendorName;
        }
        if (this.vendorName.transporterName) {
          data.vendorName = this.vendorName.transporterName;
        }
        // if (!this.vendorName.vendorName) {
        //   data.vendorName = this.vendorName;
        // }

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        if (item == "overPriced") {
          console.log("overPriced");
          this.axios
            .post(`${apiUrl}/newDriverFlow/partiallyAssignDriver`, data, config)
            .then(
              (response) => {
                this.dialog = false;
                const { status, data } = response.data;
                if (this.$props.type === "2") {
                  this.$emit("refresh-assign-list");
                } else {
                  this.$eventBus.$emit("job-driver-assign-success", {
                    driverId: id,
                    driverName: name,
                    assignmentId: this.$props.assignmentId,
                  });
                  this.$eventBus.$emit("refresh-job");
                  this.$emit("updateJobs");
                }

                this.destroyed();
                this.toShow = false;
                if (this.onclose) {
                  this.onclose({ driverId: id, driverName: name });
                }
                (this.truckPrefix = ""),
                  (this.truckNumber = ""),
                  (this.loading = false);

                this.$refs.form.resetValidation();
                this.$emit("transporter-success");
              },
              (error) => {
                this.dialog = false;
                this.error = error.response.data.message;
                this.loading = false;
              }
            );
        } else {
          this.axios
            .post(`${apiUrl}/newDriverFlow/newAssignDriver`, data, config)
            .then(
              (response) => {
                const { status, data } = response.data;
                if (this.$props.type === "2") {
                  this.$emit("refresh-assign-list");
                } else {
                  this.$eventBus.$emit("job-driver-assign-success", {
                    driverId: id,
                    driverName: name,
                    assignmentId: this.$props.assignmentId,
                  });
                  this.$eventBus.$emit("refresh-job");
                  this.$emit("updateJobs");
                }

                this.destroyed();
                this.toShow = false;
                if (this.onclose) {
                  this.onclose({ driverId: id, driverName: name });
                }
                (this.truckPrefix = ""),
                  (this.truckNumber = ""),
                  (this.loading = false);

                this.$refs.form.resetValidation();
                this.$emit("transporter-success");
              },
              (error) => {
                this.error = error.response.data.message;
                this.loading = false;
              }
            );
        }
      } else {
        this.loading = false;
      }
    },
    assignTransporter(item) {
      this.errorName = false;
      this.errorDriver = null;
      this.loading = false;
      if (this.$refs.form.validate()) {
        this.error = null;
        this.loading = true;
        this.errorName = false;
        const { id, name, bidAmount, bidCurrency } = this.driver;
        if (!this.paymentAmount || this.paymentAmount <= 0) {
          this.loading = false;
          this.error = "Please provide payment amount above 0";
          return false;
        }

        const data = {
          transporterId: this.driver._id,
          assignmentId: this.assignmentId.toString(),
          paymentAmount: this.paymentAmount.toString(),
          paymentCurrency: this.$props.currency,
        };

        if (this.vendorVatNumber) {
          data.vendorVatNumber = this.vendorVatNumber.trim();
        }
        if (this.VendorAddress) {
          data.vendorAddress = this.VendorAddress.trim();
        }

        if (this.milestone && this.milestone.length > 0) {
          data.milestoneId = this.milestone;
        }
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        // this.dialog = true;
        this.transporterDriverShow = true;
        this.loading = false;

        // if (item == "overPriced") {
        //   this.axios
        //     .post(
        //       `${apiUrl}/newDriverFlow/partiallyAssignTransporter`,
        //       data,
        //       config
        //     )
        //     .then(
        //       (response) => {
        //         this.dialog = false;
        //         const { status, data } = response.data;
        //         this.$emit("transporter-success");
        //         // this.$eventBus.$emit("job-driver-assign-success", {
        //         //   driverId: id,
        //         //   driverName: name,
        //         //   assignmentId: this.$props.assignmentId,
        //         // });
        //         this.destroyed();
        //         this.toShow = false;
        //         if (this.onclose) {
        //           this.onclose({ driverId: id, driverName: name });
        //         }
        //         (this.truckPrefix = ""),
        //           (this.truckNumber = ""),
        //           (this.loading = false);
        //         this.$refs.form.resetValidation();
        //       },
        //       (error) => {
        //         this.dialog = false;
        //         this.error = error.response.data.message;
        //         this.loading = false;
        //       }
        //     );
        // } else {
        //   this.axios
        //     .post(`${apiUrl}/newDriverFlow/newAssignTransporter`, data, config)
        //     .then(
        //       (response) => {
        //         const { status, data } = response.data;
        //         this.$emit("transporter-success");
        //         // this.$eventBus.$emit("job-driver-assign-success", {
        //         //   driverId: id,
        //         //   driverName: name,
        //         //   assignmentId: this.$props.assignmentId,
        //         // });
        //         this.destroyed();
        //         this.toShow = false;
        //         if (this.onclose) {
        //           this.onclose({ driverId: id, driverName: name });
        //         }
        //         (this.truckPrefix = ""),
        //           (this.truckNumber = ""),
        //           (this.loading = false);
        //         this.$refs.form.resetValidation();
        //       },
        //       (error) => {
        //         this.error = error.response.data.message;
        //         this.loading = false;
        //       }
        //     );
        // }
      }
    },
    searchDriver() {
      this.resetDriver();
      if (this.searchText) {
        this.isSearchResult = true;
        if (this.transporterId) {
          this.payload = {
            skip: "0",
            limit: "15",
            status: this.driverType,
            assignmentId: this.$props.assignmentId,
            transporterId: this.transporterId,
            isFilter: true,
            searchText: this.searchText,
            assignedToTransporter:
              this.$props.assignmentDetail.assignedToTransporter,
          };
        } else {
          this.payload = {
            skip: "0",
            limit: "15",
            status: this.driverType,
            assignmentId: this.$props.assignmentId,
            searchText: this.searchText,
            assignedToTransporter:
              this.$props.assignmentDetail.assignedToTransporter,
          };
        }
      } else {
        if (this.transporterId) {
          this.payload = {
            skip: "0",
            limit: "15",
            status: this.driverType,
            assignmentId: this.$props.assignmentId,
            transporterId: this.transporterId,
            assignedToTransporter:
              this.$props.assignmentDetail.assignedToTransporter,
            isFilter: true,
          };
        } else {
          this.isSearchResult = false;
          this.payload = {
            skip: "0",
            limit: "15",
            status: this.driverType,
            assignmentId: this.$props.assignmentId,
            assignedToTransporter:
              this.$props.assignmentDetail.assignedToTransporter,
          };
        }
      }
    },
    searchTransporter() {
      this.resetDriver();
      this.checkTransporter();
      if (this.searchText) {
        this.payload = {
          skip: "0",
          limit: "15",
          status: this.driverType,
          assignmentId: this.$props.assignmentId,
          transporterId: this.transporterId,
          isFilter: true,
          searchText: this.searchText,
          assignedToTransporter:
            this.$props.assignmentDetail.assignedToTransporter,
        };
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          status: this.driverType,
          assignmentId: this.$props.assignmentId,
          transporterId: this.transporterId,
          isFilter: true,
          assignedToTransporter:
            this.$props.assignmentDetail.assignedToTransporter,
        };
      }
    },
  },
};
</script>
<style scoped>
.postion-set {
  word-break: break-all;
}
.cont {
  width: 100%;
  position: relative;
  z-index: 1;
}
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
  border: 2px solid white;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li:first-child:after {
  content: none;
}

.timelineHori li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background: orange;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.timelineHori li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: orange;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li {
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  list-style-type: none;
}

.customScroll {
  scroll-behavior: smooth !important;
  overflow-y: scroll !important;
  max-height: calc(100vh - 200px) !important;
}
.borderColor {
  border-top-style: solid !important;
  border-top-color: #dcdcdc !important;
}
.borderColorRight {
  border-right-style: solid !important;
  border-right-color: #dcdcdc !important;
}
.ft20 {
  font-size: 20px !important;
  color: lightgrey;
}
.spacerclass {
  max-height: 550px !important;
}
.spacerclassTable {
  max-height: 300px !important;
}
.iconStyle {
  font-size: 16px;
  position: absolute;
  top: 10 !important;
  right: 7;
}
.card-style {
  border-radius: 7px !important;
  border: 1px lightgrey solid !important;
  padding: 10px !important;
}
.track-assign-btn- {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 3px 12px;
}
</style>

<style lang="scss">
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  padding-top: 20px;
  overflow-y: hidden;
  &:-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
  }
}
.spacerFix {
  max-height: 300px !important;
}

.spacerFix:-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.spacerFix::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
  border-radius: 10px;
}

.spacerFix::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.spacerFix::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.spacerFix::-webkit-scrollbar-thumb {
  background-color: lightgray !important;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
}
.postionMain {
  color: gray !important;
  background: white !important;
  width: 10%;
  text-align: left;
}
.noShadow {
  box-shadow: none;
  border: none !important;
}

.padding-left {
  padding: 10px 30px 10px 30px !important;
}
.reset-postion {
  position: relative;
  top: 26px;
}
</style>
