<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-card-text class="nospace">
        <loading :active.sync="loading" />

        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex style="text-align: end" class="pb-2"> </v-flex>
          <div v-if="!(jobSalesInvoiceSameComp || assignSalesGenSameComp)">
            <addProofOfDelivery :assId="this.$route.params._id" />
          </div>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="ListTable"
        hide-actions
        :headers="headers"
        :items="items"
        style="word-break: break-all"
      >
        <template slot="items" slot-scope="props">
          <td
            v-if="props.item.description"
            style="word-break: break-all; width: 10%"
          >
            {{ props.item.description }}
          </td>
          <td v-else>-</td>
          <td v-if="props.item.updatedAt">
            {{ getTime3(props.item.updatedAt) }}
          </td>
          <td v-else>-</td>
          <td v-if="props.item.uploadedBy">{{ props.item.uploadedBy }}</td>
          <td v-else>-</td>

          <td style="width: 10%">
            <v-layout>
              <v-flex xs12>
                <ul
                  style="
                    position: relative;
                    list-style-type: none;
                    display: inline-block;
                    width: 300px;
                    padding-left: 0px !important;
                  "
                >
                  <li
                    :key="i"
                    style="display: inline-block"
                    v-for="(doc, i) in props.item.document"
                    class="list"
                    ref="documentList"
                  >
                    <a
                      :href="doc"
                      class="document-link"
                      style="text-decoration: none; outline: none"
                      v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" height="40" width="32" />
                    </a>
                    <a
                      :href="doc"
                      style="text-decoration: none; outline: none"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="40"
                        width="40"
                      />
                    </a>
                  </li>
                </ul>
              </v-flex>
            </v-layout>
          </td>
          <td style="word-break: break-word !important">
            {{ props.item.rejectedReason || "-" }}
          </td>
          <td>
            <div v-if="!(jobSalesInvoiceSameComp || assignSalesGenSameComp)">
              <editProofOfDelivery
                :details="props.item"
                :assId="$route.params._id"
              />
            </div>
          </td>
        </template>
      </v-data-table>

      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/dmsAdmin/viewProofOfDeliveryForAssignment`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <!-- <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      /> -->
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import { proofOfDeliveryList } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import moment from "moment";
import { StorageKeys, JobTypes, JobListTypes } from "../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import addProofOfDelivery from "../Pop-ups/addProofOfDelivery";
import editProofOfDelivery from "../Pop-ups/editProofOfDelivery";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.payload = {
      skip: "0",
      limit: "15",
      assignmentId: this.$route.params._id,
      workingCountry: this.workingCountry,
    };
    this.$eventBus.$on("delivery-proof-dialog", () => {
      this.componentKey += 1;
    });
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });

    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    addProofOfDelivery,
    editProofOfDelivery,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
  },

  data() {
    return {
      workingCountry: null,
      JobTypes: [],
      jobType: "",
      error: "",
      loading: true,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {},

      headers: proofOfDeliveryList,
      items: [],
    };
  },
  watch: {},
  methods: {
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    callback(res) {
      console.log(res, "--profflistData");
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.assignSalesGenSameComp =
        res.assignmentDetails[0].isSalesInvoiceGenerated;
      this.jobSalesInvoiceSameComp =
        res.assignmentDetails[0].jobData.isSalesInvoiceGenerated;
      this.loading = false;
      this.$eventBus.$emit("off-load");
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
</style>
