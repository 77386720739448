<template>
  <v-layout>
    <v-btn
      class="messagebtn"
      style="background: orange !important"
      flat
      prepend-icon
      color="white darken-1"
      @click.stop="dialog = true"
      >Add Comment</v-btn
    >
    <v-dialog v-model="dialog" persistent max-width="40%">
      <v-form ref="form" onSubmit="return false;">
        <v-card>
          <v-card-title>
            <span class="headline">Attach Comment with the Milestone</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md12>
                  <v-select
                    :items="milestoneTrack.milestone"
                    item-text="mileStoneName"
                    item-value="mileStoneId"
                    v-model="checkListId"
                    label="Select Milestone"
                    class="pt-0 currencyTitle"
                    single-line
                  ></v-select>
                </v-flex>
                <v-textarea
                  autocomplete="comments"
                  label="Type your comment here"
                  v-model="comments"
                ></v-textarea>
              </v-layout>
            </v-container>
            <small class="success" v-show="this.res">{{ this.res }}</small>
            <small class="error" v-show="this.x.error">{{
              this.x.error
            }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="dialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" flat @click="sendComment"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-layout>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import {
  trackingStatus,
  updateAssignmentsMilestone,
} from "../../constants/api-urls";
export default {
  created() {
    this.getTrackingStatus();
  },
  props: ["jobId", "assignmentId"],
  computed: {
    assignmentReturn() {
      return this.assignmentId;
    },
  },
  data() {
    return {
      res: "",
      checkListId: "",
      milestoneItems: [],
      milestoneTrack: {
        milestone: [],
        assId: null,
      },
      dialog: false,
      comments: "",
      loading: false,
      processing: false,
      x: {
        error: "",
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    ErrorBox,
  },
  methods: {
    getTrackingStatus() {
      let url = trackingStatus;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assId: this.$props.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneTrack.milestone = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    sendComment() {
      if (this.processing) {
        return;
      } else {
        this.processing = true;

        if (!this.checkListId) {
          this.x.error = "Please select a checklist";
          setTimeout(() => (this.x.error = ""), 2000);
          this.processing = false;
          return false;
        }

        if (!this.comments) {
          this.x.error = "Please provide comments for the checklist";
          setTimeout(() => (this.x.error = ""), 2000);
          this.processing = false;
          return false;
        }

        this.dialog = true;
        let url = updateAssignmentsMilestone;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          comments: this.comments,
          assId: this.$props.assignmentId.toString(),
          checkListId: this.checkListId,
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.processing = false;
            this.checkListId = "";
            this.res = "Comment has been successfully added";
            this.comments = "";
            this.getTrackingStatus();
            setTimeout(() => {
              this.res = "";
              this.dialog = false;
            }, 3000);
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.x.error = "Unable to add comment.";
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: #fff;
}
.error {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: #fff;
}
.messagebtn {
  margin-left: 75%;
}
</style>
