<template>
  <div>
    <v-btn
      v-if="currency == 'PKR'"
      class="ma-2"
      outline
      color="orange"
      :disabled="status == 1 || status == -1 || customerStatus == 'view'"
      @click="check()"
    >
      + Add Shipment Document
    </v-btn>
    <v-btn
      v-else
      class="ma-2"
      outline
      color="orange"
      :disabled="
        status == 1 ||
        status == 3 ||
        status == 4 ||
        status == -1 ||
        customerStatus == 'view'
      "
      @click="check()"
    >
      + Add Shipment Document
    </v-btn>
    <v-dialog v-model="dialog" max-width="55%" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline">Shipment Documents</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="closeDialog()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class="spacerclass">
            <v-card-text>
              <v-container grid-list-md>
                <v-layout>
                  <v-flex xs4>
                    <span>Invoice Value</span>
                    <v-text-field
                      label="Invoice Value"
                      v-model="invoiceValue"
                      :rules="[rules.required]"
                      single-line
                      class="pt-0 currencyTitle"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 v-if="currency == 'PKR'">
                    <span>Invoice Number</span>
                    <v-text-field
                      label="Invoice Number"
                      :rules="[rules.required, rules.noWhiteSpace]"
                      v-model="invoiceNumber"
                      single-line
                      class="pt-0 currencyTitle"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 v-else>
                    <span>Customer Reference Number</span>
                    <v-text-field
                      label="Customer Reference Number"
                      :rules="[rules.required, rules.noWhiteSpace]"
                      v-model="invoiceNumber"
                      single-line
                      class="pt-0 currencyTitle"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <span>Weight (In Tonnes)</span>
                    <v-text-field
                      label="Weight"
                      v-model="weight"
                      single-line
                      type="number"
                      class="pt-0 currencyTitle"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout :key="i" v-for="(checklist, i) in shipmentDocument">
                  <v-flex xs2 class="pt-2" style="word-break: break-all">
                    <v-select
                      :items="documentType"
                      label="Type"
                      item-text="documentName"
                      :rules="[rules.required]"
                      item-value="documentNumber"
                      v-model="checklist.documentNumber"
                      class="pt-0 currencyTitle"
                      single-line
                    ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <ul style="position: relative">
                      <li
                        :key="j"
                        v-for="(doc, j) in checklist.document"
                        class="list"
                        ref="documentList"
                      >
                        <button
                          type="button"
                          v-show="document"
                          @click="removeImage($key, i)"
                        >
                          <span class="imageClose">&times;</span>
                        </button>

                        <a
                          :href="doc"
                          class="document-link"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                          style="text-decoration: none; outline: none"
                        >
                          <img
                            src="../../assets/pfd.png"
                            height="40"
                            width="32"
                          />
                        </a>
                        <a
                          style="text-decoration: none; outline: none"
                          :href="doc"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../assets/docimg.webp"
                            height="40"
                            width="40"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-flex>

                  <v-flex xs2>
                    <v-flex class="upload-btn-wrapper">
                      <button
                        type="button"
                        v-show="checklist.document.length < 5"
                        class="btn"
                      >
                        + Upload
                      </button>
                      <input
                        :disabled="processing"
                        :id="'fileUpload' + i"
                        :ref="'fileUpload' + i"
                        type="file"
                        accept=".pdf, .jpeg, .jpg, .png"
                        @change="uploadDocument($event, i)"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex v-if="shipmentDocument.length != 1" xs2 class="pt-2">
                    <div class="upload-btn-wrapper">
                      <button
                        style="border: 2px solid red; color: red"
                        @click="close(i)"
                        type="button"
                        class="btn"
                      >
                        Remove
                      </button>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <div
                    v-if="this.shipmentDocument.length <= 10"
                    class="upload-btn-wrapper"
                  >
                    <button
                      style="border: 2px solid green; color: green"
                      @click="addMore"
                      class="btn"
                      type="button"
                    >
                      + Add More
                    </button>
                  </div>
                </v-layout>

                <v-layout>
                  <v-flex xs6>
                    <v-checkbox
                      v-model="checkbox1"
                      label=" Documents shown to customer"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-container>
              <small class="success" v-show="this.res">{{ this.res }}</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="closeDialog()"
                >Close</v-btn
              >
              <v-btn color="orange darken-1" flat @click="addDocument"
                >Save</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
      <!-- <ErrorBox :error="x.error" /> -->
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
  import { StorageKeys } from "../../constants/constants";
  import ErrorBox from "@/components/Common/ErrorBox";
  import { jobDocument } from "../../constants/api-urls";
  export default {
    created() {
      this.customerStatus = localStorage.getItem("cust-status");
    },
    data() {
      return {
        operationName: "",
        customerStatus: "",
        checkbox1: false,
        document: [],
        documentType: [],
        valid: true,
        lazy: false,
        weight: "",
        index: 0,
        invoiceNumber: "",
        invoiceValue: "",
        shipmentDocument: [
          {
            i: 0,
            document: [],
            documentNumber: "",
          },
        ],
        res: "",
        dialog: false,
        selected: ["email"],
        message: null,
        loading: false,
        processing: false,
        x: {
          error: "",
        },
        rules: {
          noWhiteSpace: (v) =>
            (v != null && v.trim().length > 0) || "This field is required.",
          required: (value) => !!value || "This field is required.",
          requiredLength: (value) => !value.length || "This field is required.",
        },
      };
    },
    components: {
      ErrorBox,
    },
    props: ["assId", "status", "currency"],
    methods: {
      async check() {
        this.operationName = "add-documents-jobs";
        let y = await this.checkOpertaionPermission(this.operationName);
        if (y) {
          this.dialog = true;
          this.getShipmentType();
        } else {
          return;
        }
      },
      getShipmentType() {
        this.loading = true;
        let Url = jobDocument.getShipmentTypeForPopUP;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          assignmentId: this.$props.assId,
          skip: "0",
          limit: "15",
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.documentType = response.data.data.data;
            this.x.success = true;
            if (response.data.data.assginData.inoviceNumber) {
              this.invoiceNumber = response.data.data.assginData.inoviceNumber;
            }
            if (response.data.data.assginData.invoiceValue) {
              this.invoiceValue = response.data.data.assginData.invoiceValue;
            }
            this.weight = response.data.data.assginData.weight;
            this.loading = false;
            //this.$refs.form.resetValidation();
          },
          (error) => {
            this.success = false;
            this.loading = false;
            this.error = error.response.data.message;
            setTimeout(() => {
              this.error = "";
            }, 6000);
          }
        );
      },
      addMore() {
        let el = this.shipmentDocument.length;
        let checklist = {
          i: this.shipmentDocument[el - 1] + 1,
          documentNumber: null,
          document: [],
        };
        this.shipmentDocument.push(checklist);
      },
      closeDialog(i) {
        this.dialog = false;
        this.$refs.form.resetValidation();
        this.checkbox1 = false;
        this.shipmentDocument.splice(1, this.shipmentDocument.length);
        this.shipmentDocument = [
          {
            i: 0,
            document: [],
            documentNumber: "",
          },
        ];
      },
      close(i) {
        if (this.shipmentDocument.length == 1) {
          return;
        } else {
          let el = this.shipmentDocument;
          el.splice(i, 1);
          this.shipmentDocument = el;
        }
      },
      removeImage(key, i) {
        this.shipmentDocument[i].document.splice(key, 1);
      },
      uploadDocument(e, i) {
        if (this.processing) {
          return;
        } else {
          this.processing = true;
          this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          // $("#fileUpload")[0].value = '';

          if (!files.length) return;
          this.createImage(files[0], 1, i);
        }
      },
      createImage(file, status, i) {
        var temp = "fileUpload" + i;
        document.getElementById(temp).value = "";

        //this.$refs['fileUpload'+i].value = "";

        if (
          file.type
            .toString()
            .substr(file.type.toString().lastIndexOf(".") + 1) !==
            "image/png" &&
          file.type
            .toString()
            .substr(file.type.toString().lastIndexOf(".") + 1) !==
            "image/jpg" &&
          file.type
            .toString()
            .substr(file.type.toString().lastIndexOf(".") + 1) !==
            "image/jpeg" &&
          file.type
            .toString()
            .substr(file.type.toString().lastIndexOf(".") + 1) !==
            "application/pdf"
        ) {
          this.loading = false;
          this.x.error = "Please upload jpg/png/pdf file only!";

          this.processing = false;
          return false;
        } else {
          var image = new Image();
          var reader = new FileReader();
          var vm = this;

          reader.onload = (e) => {
            vm.image = e.target.result;
          };
          reader.readAsDataURL(file);
          this.upload(file, status, i);
        }
      },
      upload(file, statusChange, i) {
        this.loading = true;
        const formData = new FormData();
        formData.append("image", file);
        this.axios({
          url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
          method: "POST",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(
          (response) => {
            const status = response.status;
            const { imageUrl } = response.data;
            this.processing = false;
            if (status === 200) {
              this.loading = false;
              if (statusChange == 1) {
                //this.document.push(response.data.data.original);
                this.shipmentDocument[i].document.push(
                  response.data.data.original
                );
              }
            } else {
              this.x.error = "Failed to upload image";
            }
            this.loading = false;
          },
          (error) => {
            false;
            this.x.error = "Failed to upload image";
            this.loading = false;
          }
        );
      },
      async addDocument() {
        this.operationName = "add-documents-jobs";
        let y = await this.checkOpertaionPermission(this.operationName);
        if (y) {
          if (this.processing) {
            return;
          }
          const size = this.shipmentDocument.length;
          for (var n = 0; n < size; n++) {
            if (!this.shipmentDocument[n].document.length) {
              this.x.error = "Please upload shipment document";
              this.processing = false;
              this.loading = false;
              return false;
            }
          }
          if (this.$refs.form.validate()) {
            this.processing = true;
            let url = jobDocument.uploadShipmentDocument;
            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            let config = {
              headers: {
                authorization: `bearer ${token}`,
              },
            };
            let shipmentDocument1 = [];
            this.shipmentDocument.forEach((element) => {
              delete element["i"];
              shipmentDocument1.push(element);
            });
            let body = {
              assignmentId: this.assId,
              shipmentDocument: shipmentDocument1,
              invoiceValue: this.invoiceValue.toString(),
              inoviceNumber: this.invoiceNumber.toString(),
              documentShowToCustomer: this.checkbox1,
            };
            if (this.weight) {
              body.weight = this.weight;
            }
            this.axios.post(this.constants.apiUrl + url, body, config).then(
              (response) => {
                this.res = response.data.message;
                this.document = [];
                this.res = "";
                this.dialog = false;
                this.$refs.form.resetValidation();
                this.checkbox1 = false;
                this.shipmentDocument.splice(1, this.shipmentDocument.length);
                this.shipmentDocument = [
                  {
                    i: 0,
                    document: [],
                    documentNumber: "",
                  },
                ];
                this.processing = false;
                (this.checkbox1 = false), this.$emit("updateAssignment");
                this.$eventBus.$emit("success-shipment-doc");
                this.$eventBus.$emit("shpiment-dialog-close");
              },
              (error) => {
                this.processing = false;
                //this.dialog = true;
                this.x.error = error.response.data.message;
              }
            );
          } else {
            return;
          }
        }
      },
    },
  };
</script>
<style scoped>
  .p-16 {
    padding: 8px !important;
  }

  .message {
    display: inline-block;
    margin: 0 50px;
  }
  .communication {
    width: 40%;
  }
  .btn-wrapper {
    border: 2px solid red;
    color: red;
    background-color: white;
    padding: 8px 20px;
    cursor: pointer !important;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
  }
  .success {
    font-size: 18px;
    font-weight: 600;
  }
  .messagebtn {
    margin: 0 auto;
  }
  .list {
    display: inline-block;
    position: relative;
  }
  .imageClose {
    position: absolute;
    width: 15px;
    bottom: 50px;
    height: 15px;
    background: #000;
    border-radius: 50%;
    color: #fff !important;
    line-height: 15px;
    top: -3px;
    left: -3px;
  }
  .thumb {
    width: 80px;
    height: 80px;
  }

  .custom-file-upload {
    border: none;
    display: inline-block;
    background: orange;
    font-size: 16px;
    color: #fff;
    padding: 10px 12px;
    cursor: pointer;
  }
</style>
