<template>
  <v-dialog v-if="toShow" v-model="toShow" persistent max-width="600">
    <loading :active.sync="loading" />
    <v-card>
      <v-card-text>
        <div class="sub-headline muted pb-3">{{ title || "Confirmation" }}</div>
        <div class="heading pb-2">{{ content }}</div>
        <div v-if="type == 1" class="heading pb-2">
          <v-layout>
            <v-flex xs12 md12 pb-1 pt-1>
              <div class="labelheading">
                <label>
                  If, Yes enter date and time when it was collected</label
                >
              </div>
            </v-flex></v-layout
          >
          <v-layout>
            <v-flex xs7>
              <span class="muted"></span>
              <v-menu
                ref="FilterDateBool"
                lazy
                v-model="FilterDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="startDate"
              >
                <v-text-field
                  slot="activator"
                  :label="'Select Date '"
                  required
                  v-model="startDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="startDate"
                  class="minHeight"
                  @change="$refs.FilterDateBool.save(startDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 pl-2 style="position: relative; padding-top: 18px">
              <vue-timepicker
                class="fonttime pt-0 currencyTitle"
                :minute-interval="30"
                v-model="pickupTime"
                input-width="10em"
                :label="'Select Time'"
                placeholder="Select Time"
                close-on-complete
              ></vue-timepicker>
            </v-flex>
          </v-layout>
        </div>
        <div v-if="error$" class="red--text">
          <b></b> <br />
          {{ error$ }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-layout>
          <v-spacer />
          <v-btn flat class="button-width" @click.native="close">Close</v-btn>

          <v-btn
            :loading="loading$"
            :disabled="loading"
            color="orange darken-2"
            class="white--text button-width"
            @click.native="confirm"
            >{{ confirmTitle || "Confirm" }}</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import Loading from "vue-loading-overlay";
export default {
  props: {
    toShow: Boolean,
    title: String,
    type: Number,
    content: String,
    confirmTitle: String,
    onConfirm: Function,
    onClose: Function,
    valueCallback: Function,
    loading: {
      type: Boolean,
      default: false,
    },
    error: String,
  },
  data() {
    return {
      value: Number,
      startDate: "",

    };
  },
  components: {
    VueTimepicker,
    Loading,
  },
  computed: {
    loading$() {
      return this.loading;
    },
    error$() {
      return this.error;
    },
  },
  methods: {
    close() {
      if (this.onClose) {
        this.onClose();
      }
      this.$eventBus.$emit("close-confirmation-dialog");
    },
    confirm() {

      this.onConfirm();
      this.$eventBus.$emit("close-confirmation-dialog");
    },
  },
};
</script>

<style>
.fonttime {
  font-size: 15px;
}
.fonttime .dropdown.drop-down {
  top: -14%;
}
div ::-webkit-scrollbar-thumb {
  background: darkorange;
}
.labelheading {
  color: grey;
}
</style>
