<template>
  <div class="log">
    <v-btn
      slot="activator"
      color="primary"
      class="communicate-btn"
      @click="assigments()"
      dark
      >Mark Doc Status</v-btn
    >
    <v-dialog v-model="dialog" max-width="60%" persistent>
      <v-form ref="form">
        <v-card style="padding: 0px !important; overflow-x: hidden">
          <v-toolbar
            dark
            style="background-color: #dcdcdc; color: black; padding-left: 10px"
            flat
          >
            <v-toolbar-title>Mark Driver Documents </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="dialog = false">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="customScrollAir">
            <v-card-text class="nospace">
              <v-layout class="pl-3 pt-3">
                <v-flex xs3 class="pt-2">
                  <v-select
                    :items="assignment"
                    label="Assignment Id"
                    item-text="text"
                    item-value="value"
                    v-model="assignmentId"
                    @change="getList()"
                    class="pt-0 currencyTitle"
                    single-line
                  ></v-select>
                </v-flex>
                <v-flex
                  xs2
                  v-if="!assignmentData.driverDocStatus"
                  style="text-align: end"
                  class="pb-2 pl-3"
                >
                  <v-btn
                    class="ma-2"
                    @click="markDocStatus()"
                    outline
                    color="orange"
                  >
                    MARK DOC STATUS
                  </v-btn>
                </v-flex>
                <v-flex
                  xs2
                  v-if="assignmentData.driverDocStatus"
                  style="text-align: end"
                  class="pb-2"
                >
                  <v-flex xs2 style="text-align: end" class="pb-2 pl-3">
                    <v-btn class="ma-2" outline disabled color="grey">
                      MARKED<v-icon small style="padding-left: 5px"
                        >check_circle</v-icon
                      >
                    </v-btn>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout style="border-top: 1px solid #efefef" pb-4>
                <v-flex xs6 pt-3 pl-3
                  ><span class="board-style">Driver Details : </span>
                  {{
                    assignmentData.driverName
                      ? assignmentData.driverName + " | "
                      : "N.A"
                  }}
                  <span
                    v-if="assignmentData.driverName"
                    style="position: relative; top: 5px"
                  >
                    <img src="../../../assets/TruckIcon.svg" height="18px"
                  /></span>
                  <span
                    v-if="assignmentData.truckNumber"
                    style="position: relative; top: 1px"
                    >{{ assignmentData.truckPrefix }}-{{
                      assignmentData.truckNumber
                    }}</span
                  ></v-flex
                >
                <v-flex xs3 pt-3
                  ><span class="board-style">Driver Price :</span>
                  {{
                    assignmentData.paymentAmount
                      ? assignmentData.paymentAmount
                      : "N.A"
                  }}
                  {{
                    assignmentData.paymentAmount
                      ? assignmentData.customerCurrency
                      : ""
                  }}
                </v-flex>
                <v-flex xs3 pt-3
                  ><span class="board-style">Balanced Amount : </span>
                  <span v-if="assignmentData.paymentAmount"
                    >{{
                      assignmentData.paymentAmount -
                      assignmentData.advanceAmount
                    }}
                    {{ assignmentData.customerCurrency }}</span
                  >
                  <span v-else>N.A</span></v-flex
                >
              </v-layout>
            </v-card-text>

            <success-dialog
              :content="x.message"
              :show="x.success"
              :onclose="closeMessage"
            />
            <!-- <v-dialog v-model="confirmationDialog" max-width="40%" persistent>
              <v-form ref="form">
                <v-card style="overflow-x: hidden">
                  <v-card-title
                    style="
                background-color: #f5f5f5;
                padding-bottom: 5px;
                padding-top: 5px;
              "
                  >
                    <span class="headline">Mark Received</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.prevent="confirmationDialog = false">
                      <v-icon small class="heading grey--text text--darken-4"
                        >close</v-icon
                      >
                    </v-btn>
                  </v-card-title>

                  <v-container
                    bg
                    fill-height
                    grid-list-md
                    text-xs-center
                    class="title-layout-size"
                  >
                    <v-layout
                      class="text--darken-4"
                      style="color: lightgrey; font-size: 16px"
                      pt-2
                      pl-4
                      pb-2
                    >
                      <span
                        >Do you confirm that you have received the driver
                        documents?
                      </span>
                    </v-layout>
                  </v-container>
                  <v-container
                    bg
                    fill-height
                    grid-list-md
                    text-xs-center
                    class="title-layout-size"
                  >
                  </v-container>
                  <v-spacer></v-spacer>
                  <v-card-actions style="padding: 28px !important">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="black darken-1"
                      flat
                      @click="confirmationDialog = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      style="background: darkorange !important"
                      flat
                      prepend-icon
                      :disabled="processing"
                      @click="markDocStatus()"
                      color="white darken-1"
                      >Yes, I do</v-btn
                    >
                  </v-card-actions>
                  <v-spacer></v-spacer>
                </v-card>
              </v-form>
              <v-snackbar
                :timeout="3000"
                bottom
                color="red darken-2"
                v-model="x.error"
                class="white--text"
                v-if="x.error"
                >{{ x.error }}</v-snackbar
              >
            </v-dialog> -->

            <v-snackbar
              :timeout="3000"
              color="red darken-2"
              v-model="error"
              class="white--text"
              v-if="error"
              >{{ error }}</v-snackbar
            >
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../../event-bus.js";
import Loading from "vue-loading-overlay";
import SuccessDialog from "@/components/Common/SuccessDialog";
import addDriverDoc from "@/components/Pop-ups/addDriverDoc";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
} from "../../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import {
  markShipmentDocStatus,
  deleteAdditionalCharges,
  getShipmentDeliveryDoc,
} from "../../../constants/api-urls";
export default {
  created() {
    window.addEventListener("online", () => {
      this.componentKey += 1;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
          this.currency = key.currency;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.$eventBus.$on("success-driver-doc", () => {
      this.componentKey += 1;
    });
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("success");
    this.$eventBus.$off("edit-success");
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    addDriverDoc,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
    assignId: String,
    viewOnly: Boolean,
    jobCurrency: String,
    tabOrder: Number,
  },

  data() {
    return {
      workingCountry: null,
      totalAddtionalCharges: "",
      additionalchargesId: "",
      documentType: [],
      title: null,
      message: null,
      assignmentData: {},
      statusComments: "",
      confirmationDialog: false,
      dialog: false,
      dialogDelete: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      operationName: "",
      chargesType: "",
      customerStatus: "",
      customerId: "",
      JobTypes: [],
      jobId: "",
      status: "",
      assignID: "",
      currency: "",
      jobType: "",
      error: "",
      documents: [],
      document: [],
      loading: true,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,
      assignment: [],
      assignmentId: "",
      totalPages: "",

      payload: {},

      headers: [
        {
          text: "Date & Time",
          sortable: false,
          value: "dateAndTime",
          class: "table-header-item",
        },
        {
          text: "Document Name",
          sortable: false,
          value: "document",
          class: "table-header-item",
        },
        {
          text: "Uploaded By",
          sortable: false,
          value: "uploadedBy",
          class: "table-header-item",
        },
        {
          text: "Document ",
          sortable: false,
          value: "document",
          class: "table-header-item",
        },
      ],
      items: [],
    };
  },
  watch: {},
  methods: {
    getType(arr = [], val) {
      let finalEl = arr
        .filter((val) => val !== undefined)
        .find((el) => el.documentNumber === val);
      return finalEl ? finalEl.documentName : "NA";
    },
    assigments() {
      this.loading = true;
      const data = {
        jobId: this.$route.params.id,
        tabOrder: this.$props.tabOrder ? this.$props.tabOrder.toString() : "1",
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/assignmentForEditDriverPrice`, data, config)
        .then(
          (response) => {
            this.loading = false;

            if (!response.data.data.length) {
              this.dialog = false;
              this.$emit("error");
              return;
            } else {
              this.assignmentId = response.data.data[0]._id;
              response.data.data.forEach((e) => {
                this.assignment.push({ value: e._id, text: e.assignmentId });
              });
              this.dialog = true;
              this.getDocumentsInfo();
              this.loading = false;
            }
          },
          (error) => {
            this.error = error.response.data.message;
            // this.dailog = false;
            this.loading = false;
          }
        );
    },

    markDocStatus() {
      let Url = markShipmentDocStatus;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.assignmentId,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          // this.x.success = true;
          // this.confirmationDialog = false;
          this.getList();
        },
        (error) => {
          this.x.success = false;
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getList() {
      this.dialog = true;
      this.processing;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
            this.currency = key.currency;
          }
        });
      }

      EventBus.$on("countryChanged", (val) => {
        if (this.$route.name == "documents") {
          this.$router.push(`/jobs`);
        }
        this.workingCountry = val.value;
        this.loading = true;
      });
      this.getDocumentsInfo();
    },
    async deleteDevice() {
      this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = true;
        //this.processing = true;
        let Url = deleteAdditionalCharges;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: this.jobId,
          assignmentId: this.assignId,
          additionalchargesId: this.additionalchargesId,
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.loading = false;
            this.dialogDelete = false;
            this.componentKey += 1;
          },
          (error) => {
            this.x.success = false;
            this.dialogDelete = false;
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTime3(date) {
      return moment(date).format("MMMM Do YYYY , hh:mm a ");
    },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      this.assignmentData = { ...res.assignmentDetail[0] };
      this.totalPages = Math.ceil(res.totalData / 15);
      // window.scrollTo(0, 0);
    },
    getDocumentsInfo() {
      this.loading = true;
      let Url = getShipmentDeliveryDoc;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        skip: "0",
        limit: "15",
        assignmentId: this.assignmentId,
        workingCountry: this.workingCountry,
      };

      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.assignmentData = { ...response.data.data.assignmentDetail[0] };
          this.loading = false;
        },
        (error) => {
          this.success = false;
          this.loading = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },
  },
};
</script>
<style scoped>
.customScrollAir {
  overflow-y: scroll !important;
  max-height: calc(100vh - 200px) !important;
}
</style>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.board-style {
  color: darkorange;
  font-size: 16px;
  font-weight: 700;
}
.orange-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 5px 15px;
}
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.communicate-btn {
  border: 2px solid darkorange !important;
  color: darkorange !important;
  background-color: transparent !important;
}
</style>
