<template>
  <v-container fluid v-if="profile">
    <loading :active.sync="loading" />
    <v-alert v-if="error !== null" outline>{{ error }}</v-alert>
    <v-layout column justify-center>
      <v-flex>
        <JobsProfileDetail
          :detail="profile"
          :workingcountry="profile.workingCountry"
          :userDetail="customerDetails"
          v-on:updateJobs="updateJobs"
          :viewOnly="viewOnly"
        />

        <ConfirmationDialog
          :toShow="confirmationDialog.toShow"
          :title="confirmationDialog.title"
          :content="confirmationDialog.content"
          :onConfirm="confirmationDialog.callback"
          :valueCallback="confirmationDialog.valueCallback"
          :viewOnly="viewOnly"
        />
      </v-flex>
      <v-stepper v-model="e1">
        <v-stepper-header class="SubTypeTab">
          <template v-for="n in steps" class="bg-clr-tab">
            <v-stepper-step
              :complete="e1 == n"
              :key="`${n}-step`"
              :step="n"
              :editable="true"
              color="orange darken-1"
              complete-icon
              class="bg-clr-tab"
            >
              <span>{{ getJobType(jobTypeArray[n - 1]) }} {{ n }} </span>
            </v-stepper-step>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
            <v-flex>
              <JobsProfileSubJobDetail
                :detail="profile"
                :isAssignDriver="isAssignDriver[n - 1]"
                :isAssignCustomerRecipt="isAssignCustomerRecipt[n - 1]"
                :totalAdditionalAmountTab="totalAdditionalAmountTab[n - 1]"
                :assignments="assignments[n - 1]"
                :userDetail="customerDetails"
                :jobId="profile.jobId"
                v-on:updateJobs="updateJobs"
                :job="jobID"
                :loader="loading"
                :tabOrder="n"
                :jobType="jobTypeArray[n - 1]"
                :viewOnly="viewOnly"
              />
              <SendQuote
                v-permissions="'send-quotes-jobs'"
                :detail="profile"
                :userId="profile.customerId"
                :jobId="profile.jobId"
                :workingcountry="profile.workingCountry"
                :deliveryProof="deliveryProof"
                :assignment="assignments[n - 1]"
                :costJobtype="costJobtype[n - 1]"
                :currency="profile.customerCurrency"
                v-on:updateJobs="updateJobs"
                :pricing="prices[n - 1]"
                :subJobDetail="subJobArray[n - 1]"
                :viewOnly="viewOnly"
              />
            </v-flex>
            <v-flex class="my-2">
              <JobsProfileAssignmentList
                :assignments="assignments[n - 1]"
                :userDetail="customerDetails"
                :driverDoc="driverDoc[n - 1]"
                :milestoneItemsList="milestoneItemArray"
                :jobId="profile.jobId"
                v-on:updateJobs="updateJobs"
                :job="jobID"
                :journeyDis="journeyDis"
                :journeyDays="journeyDays"
                :loader="loading"
                :jobData="jobData"
                :milestoneList="milestoneItemArray"
                :profile="profile"
                :workingcountry="profile.workingCountry"
                :tabOrder="n"
                :jobType="jobTypeArray[n - 1]"
                :viewOnly="viewOnly"
              />
            </v-flex>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
import SendQuote from "./SendQuote";
import { EventBus } from "../../../event-bus.js";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import JobsProfileAssignmentList from "./JobsProfileAssignmentList";
import JobsProfileDetail from "./JobsProfileDetail.vue";
import JobsProfileSubJobDetail from "./JobsProfileSubJobDetail.vue";
import {
  TruckTypes,
  PackingTypes,
  NatureOfCargos,
} from "../../../constants/constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.min.css";
import { StorageKeys } from "../../../constants/constants";
import { milestoneList, generateWorkOrder } from "../../../constants/api-urls";

export default {
  created() {
    this.$eventBus.$on("job-details-sales-invoice", (data) => {
      console.log(data);
      if (this.$route.params.id == data.socketSalesInvoiceData.jobId) {
        this.profile = { ...data.socketSalesInvoiceData };
      }
    });
    this.$eventBus.$on("job-details-sales-order", (data) => {
      console.log(data);
      if (this.$route.params.id == data.socketSalesOrderData.jobId) {
        this.profile = { ...data.socketSalesOrderData };
      }
    });

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.getMilestones();
    }

    EventBus.$on("countryChanged", (val) => {
      if (
        this.$route.name == "jobs-profile" ||
        this.$route.name == "jobs-profile-view"
      ) {
        this.$router.go(-1);
      }
    });
    this.$eventBus.$on("searchText", function (e) {});
    this.$eventBus.$on("refresh-job", () => {
      console.log("refresh-jobsss");
      const { id } = this.$route.params;
      this.jobID = this.$route.params.id;
      this.fetchDetailFromParam(id);
    });
    this.$eventBus.$on("refresh-milstoneChange", () => {
      console.log("refresh-jobsss");
      const { id } = this.$route.params;
      this.jobID = this.$route.params.id;
      this.fetchDetailFromParam(id);
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("refresh-job");
  },
  mounted() {
    const { id } = this.$route.params;
    this.jobID = this.$route.params.id;

    if (this.$route.query && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }
      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );
    }
    this.viewOnly = this.$route.params.viewOnly
      ? this.$route.params.viewOnly
      : "";

    this.truckTypes = TruckTypes;
    this.natureOfCargos = NatureOfCargos;
    this.packingTypes = PackingTypes;
    this.jobTypeArray = [];
    this.fetchDetailFromParam(id);
    this.$eventBus.$on("close-confirmation-dialog", () => {
      this.confirmationDialog.toShow = false;
    });

    this.$eventBus.$on("driver-assignment", (data) => {
      const { assignmentId, driverId, driverName } = data;
      const assignment = this.assignments.find(
        (a) => a.assignmentId === assignmentId
      );
      if (assignment) {
        assignment.driverId = driverId;
        assignment.driverName = driverName;
      }
    });
  },
  components: {
    JobsProfileAssignmentList,
    ConfirmationDialog,
    JobsProfileDetail,
    Loading,
    SendQuote,
    JobsProfileSubJobDetail,
  },
  data: () => ({
    milestoneItems: [],
    workingCountry: "",
    country: "",
    prices: [],
    milestoneItems: [],
    journeyDays: "",
    journeyDis: "",
    jobDetails: [],
    e1: 1,
    steps: 1,
    costJobtype: [],
    editable: false,
    showQuote: false,
    loading: false,
    deliveryProof: [],
    driverAssignModal: false,
    driverId: null,
    newDriverName: null,
    customerDetails: null,
    assignments: [],
    isAssignDriver: [],
    isAssignCustomerRecipt: [],
    totalAdditionalAmountTab: [],
    driverDoc: [],
    confirmationDialog: {
      toShow: false,
      title: null,
      content: null,
      callback: null,
      valueCallback: null,
    },
    readMode: true,
    menu: false,
    error: null,
    startDateStr: null,
    profile: {
      jobId: null,
      from: null,
      to: null,
      workingCountry: "",
      amount: "",
      notes: null,
      driverName: null,
      waybillNumber: "NA",
      paymentAmount: "",
      status: 0,
      customer: null,
      customerCurrency: null,
      jobStatus: null,
      startDate: null,
      deliveryDate: null,
      otherDetails: null,
      packingType: null,
      orderType: 1,
      quantity: null,
      sourceCity: null,
      chargesType: null,
      sourceCountry: null,
      destinationCity: null,
      isTransporterJob: false,
      isDriverJob: false,
      destinationCountry: null,
      sourceLocation: null,
      destinationLocation: null,
      invoiceCity: null,
      invoiceCountry: null,
      sourceLatLong: [],
      destinationLatLong: [],
      natureOfCargo: null,
      truckType: null,
      invoicingCompany: "",
      invoicingCity: "",
      numberOfAssignmentsRequired: null,
    },
    jobData: "",
    truckTypes: [],
    natureOfCargos: [],
    packingTypes: [],
    jobID: null,
    viewOnly: "",
    jobTypeArray: [],
    milestoneItemArray: [],
    isDriverJobArray: [],
    isTransporterJobArray: [],
    amountForTransporterArray: [],
    subJobArray: [],
  }),
  watch: {
    jobID() {},
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  computed: {
    natureOfCargoStr() {
      return this.natureOfCargo
        ? this.getText(this.natureOfCargos, this.natureOfCargo)
        : "";
    },
    truckTypeStr() {
      return this.truckType
        ? this.getText(this.truckTypes, this.truckType)
        : "";
    },
    packingTypeStr() {
      return this.packingType
        ? this.getText(this.packingTypes, this.packingType)
        : "";
    },
    jobStartDateStr: {
      get() {
        return this.profile && this.profile.startDate
          ? this.getTime(this.profile.startDate)
          : null;
      },
      set(val) {
        const epoch = moment(val, "DD/MM/YYYY").unix();
        this.profile.startDate = epoch;
      },
    },
  },
  methods: {
    roundOff(value, decimals) {
      return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    },
    retry(jobId) {
      let url = generateWorkOrder;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: jobId.toString };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {},
        (error) => {
          this.error = "Failed to Generate Work Order";
        }
      );
    },
    checkAllassignments(assignments) {
      let complete = true;
      assignments.forEach((element) => {
        if (element.assignmentStatus == 1 || element.assignmentStatus == 2) {
          complete = false;
        }
      });
      return !complete;
    },
    updateJobs() {
      const { id } = this.$route.params;
      this.fetchDetailFromParam(id);
    },

    paymentAmountCb(amount) {
      this.profile.paymentAmount = amount;
    },
    save(date) {
      this.profile.startDate = moment(date, "YYYY-MM-DD").unix();
      this.$refs.menu.save(date);
    },
    getNow() {
      return moment().unix();
    },
    cancelJob() {
      this.profile.jobStatus = -1;
    },
    finishJob() {
      this.profile.jobStatus = 2;
    },
    makePayment() {
      this.profile.jobStatus = 3;
    },

    showConfirmation(
      job = "Operation",
      confirmCallback,
      valueCallback,
      description = ""
    ) {
      this.loading = false;
      this.confirmationDialog = {
        toShow: true,
        title: `${job} Confirmation`,
        content: !description ? `Are you sure to ${job}?` : description,
        callback: confirmCallback,
        valueCallback,
      };
    },
    defaultDialogCloseCallback() {
      this.confirmationDialog = {
        toShow: false,
        title: null,
        content: null,
      };
    },
    getTime(time) {
      if (!time) {
        return;
      }
      const unixMoment = moment.unix(time);
      const formatStr = unixMoment.format("DD/MM/YYYY");
      return formatStr;
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Completed";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment-OffLoaded";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";
        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    closeModal() {
      this.$eventBus.$emit("close-job-profile");
    },
    updateJob() {
      this.readMode = true;
    },
    getText(arr = [], val) {
      let finalEl = arr.find((el) => el.value === val);
      return finalEl ? finalEl.value : "NA";
    },
    getMilestones() {
      let url = milestoneList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        workingCountry: this.workingCountry,
        assignDriver: "true",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          localStorage.removeItem("mileStone");
          this.milestoneItemArray = response.data.data;
          if (response.data.data) {
            localStorage.setItem(
              "mileStone",
              JSON.stringify(response.data.data)
            );
          }
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    fetchDetailFromParam(id) {
      this.loading = true;
      if (id === "new") {
        return; // default profile
      }
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: id,
      };
      let { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/jobDetails`, body, config).then(
        (response) => {
          const data = response.data.data;
          const status = response.status;
          const { jobInfo = null, assignmentList = [] } = data;
          if (status === 200 && jobInfo) {
            this.profile = Object.assign({}, jobInfo);
            var size = assignmentList.length;
            this.steps = size;
            this.assignments = [];
            this.costJobtype = [];
            this.jobTypeArray = [];
            this.isTransporterJobArray = [];
            this.isDriverJobArray = [];
            this.prices = [];
            this.amountForTransporterArray = [];
            this.subJobArray = [];
            for (var i = 0; i < size; i++) {
              this.assignments[i] = assignmentList[i].assignmentListing;
              this.isAssignDriver[i] = assignmentList[i].isAssignDriver;
              this.isAssignCustomerRecipt[i] =
                assignmentList[i].isCustomerReceiptInitiated;
              this.totalAdditionalAmountTab[i] =
                assignmentList[i].totalAdditionalAmount;
              this.driverDoc[i] = assignmentList[i].driverDoc;
              this.costJobtype[i] = assignmentList[i].subJobPrice;
              this.jobTypeArray[i] = assignmentList[i].jobType || "1";
              this.isDriverJobArray[i] = assignmentList[i].isDriverJob
                ? true
                : false;
              this.isTransporterJobArray[i] = assignmentList[i].isTransporterJob
                ? true
                : false;
              this.subJobArray[i] = assignmentList[i];

              var prices = {
                driverCost: 0,
                customerCost: null,
                trukkinCost: null,
                AdditionalCost: null,
                dutyCharges: null,
              };
              assignmentList[i].assignmentListing.forEach((e) => {
                e.isDriverAssignPopUp = false;
                e.isDriverFormPopUp = false;
                e.isDriverpdfPopUp = false;
                e.isDriverUnAssignPopUp = false;
                e.isTransporterUnAssignPopUp = false;
                e.isDriverAssetPopUp = false;
                if (e.paymentAmount && e.assignmentStatus != -1)
                  prices.driverCost = prices.driverCost + e.paymentAmount;
              });
              prices.customerCost = assignmentList[i].subJobPrice;
              prices.dutyCharges = assignmentList[i].totalDutyValue;
              prices.trukkinCost = prices.customerCost - prices.driverCost;
              if (assignmentList[i].additionalCharges) {
                prices.additionalCost =
                  assignmentList[i].additionalCharges.toFixed(2);
                prices.trukkinCost = prices.trukkinCost;
              }
              this.prices[i] = prices;
            }
            this.deliveryProof = data.jobInfo.deliveryProof;

            this.customerDetails = data.userData;
            this.jobData = data.jobData;
            this.journeyDays = data.journeyDays;
            this.journeyDis = data.journeyDis;
            this.showQuote = this.checkAllassignments(this.assignments);
          } else {
            this.error = "Something went wrong. Please try again later!";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Something went wrong. Please try again later!";
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  justify-content: left !important;
}
</style>
