<template>
  <v-dialog v-if="show" v-model="show" persistent max-width="600">
    <v-card>
      <v-card-text>
        <div class="sub-headline muted pb-3">Confirmation of Payment</div>
        <v-layout wrap align-center>
          <v-flex xs12 md12>
            <div class="sub-heading muted">Assignment ID</div>
            <div class="heading">{{ assignmentId }}</div>
          </v-flex>
          <v-flex xs12 md6 class="pr-4">
            <v-text-field
              required
              :suffix="paymentCurrency"
              label="Payment Amount"
              v-model="paymentAmount"
              :value="paymentAmount"
            ></v-text-field>
          </v-flex>
          <v-spacer />
          <v-flex xs12 md6>
            <v-select
              required
              :items="['Cash', 'Cheque', 'Demand Draft(DD)', 'Bank Transfer']"
              v-model="paymentMode"
              label="Choose Payment Mode"
              single-line
            />
          </v-flex>
        </v-layout>
        <!-- <v-layout
          v-if="profile.invoicingCompany != '3'"
         pr-5
        >
          <v-flex md6 style="padding:0px !important" class="payadvance " v-if="paymentMode == 'Bank Transfer'">
            <v-card-title style="padding:0px !important"  class="title-layout-size">
              <span class="subheading muted caption">Bank Details*</span>
            </v-card-title>
            <v-card-text  style="padding:0px !important" v-if="profile.invoicingCompany == '2'">
              <v-autocomplete
                label="Bank Details"
                :items="banks"
                class="pt-0 currencyTitle"
                v-model="bankName"
                
                item-text="text"
                item-value="value"
                single-line
              />
            </v-card-text>
            <v-card-text style="padding:0px !important" v-else class="title-layout-size">
              <v-text-field
                v-model="bankName"
                class="pt-0 currencyTitle"
                placeholder="Bank Details"
                :value="bankName"
                readonly
              ></v-text-field>
            </v-card-text>
          </v-flex>
          
        </v-layout> -->

        <div v-if="error" class="red--text">
          <b>Action failed:</b>
          <br />
          {{ error }}
        </div>
      </v-card-text>
      <v-card-actions>
        <!-- <v-container>
                    
                </v-container> -->
        <v-layout>
          <v-spacer />
          <v-btn flat @click.native="close">Close</v-btn>
          <v-btn
            :loading="loading"
            color="orange darken-2"
            class="white--text"
            @click.native="confirm"
            >Make Payment</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import { assignmentPayment, settleFnfRequest } from "@/constants/api-urls.js";
export default {
  props: {
    show: Boolean,
    onConfirm: Function,
    onClose: Function,
    profile: Object,
    assignmentId: Number,
    paymentAmount: Number,
    paymentCurrency: String,
  },
  data: () => ({
    paymentMode: null,
    loading: false,
    error: null,
    banks: [],
    bankName: "",
  }),
  created() {
    //this.getBankName();
  },
  methods: {
    getBankName() {
      if (
        this.$props.profile.invoicingCity == "1" &&
        this.$props.profile.invoicingCompany == "1"
      ) {
        this.bankName = "Alinma Bank - Dammam";
      } else if (
        this.$props.profile.invoicingCity == "2" &&
        this.$props.profile.invoicingCompany == "1"
      ) {
        this.bankName = "Alinma Bank - Jeddah";
      } else if (
        this.$props.profile.invoicingCity == "3" &&
        this.$props.profile.invoicingCompany == "1"
      ) {
        this.bankName = "Alinma Bank - Riyadh";
      } else if (
        this.$props.profile.invoicingCompany == "2" &&
        (this.$props.profile.invoicingCity == "1" ||
          this.$props.profile.invoicingCity == "2")
      ) {
        this.banks = [
          "HB-81591",
          "ENBD-80701",
          "ADCB-20001",
          "MB-60847",
          "MB-6094",
        ];
      } else {
        return;
      }
    },
    close() {
      if (this.onClose) {
        this.onClose();
        this.bankName = "";
        this.paymentMode = "";
        // this.getBankName();
        this.error = null;
      }
      this.$eventBus.$emit("close-confirmation-dialog");
    },
    settleFNF() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const url = `${this.constants.apiUrl}${settleFnfRequest}`;
      this.axios
        .post(
          url,
          {
            assignmentId: this.assignmentId.toString(),
          },
          config
        )
        .then((response) => {
          this.loading = false;
          return true;
        })
        .catch((e) => {
          this.error = "Payment failed. Try again later";
          return false;
        });
    },
    confirm() {
      this.error = null;
      if (!this.paymentAmount || !this.paymentMode) {
        this.error = "All fields are mandatory";
        return;
      }
      this.loading = true;
      // const url = `${this.constants.serverUrl}/api/assignments/payment`;
      let url = assignmentPayment;

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const data = {
        assignmentId: this.assignmentId.toString(),
        paymentAmount: this.paymentAmount.toString(),
        paymentMode: this.paymentMode.toString(),
      };
      //   if(this.$props.profile.invoicingCompany!='3'){
      //       if(this.bankName && this.paymentMode=='Bank Transfer'){
      //           data.bankName=this.bankName;

      //       }
      //   }
      data.paymentAmount = data.paymentAmount.toString();
      this.axios.post(this.constants.apiUrl + url, data, config).then(
        (response) => {
          const { statusCode = 400 } = response.data;
          if (statusCode === 200) {
            let settleFNFRequest = this.settleFNF();
            if (this.onConfirm) {
              this.onConfirm();
            }

            this.$eventBus.$emit("close-confirmation-dialog");
          } else {
            this.error = "Payment failed. Try again later";
          }
          this.$eventBus.$emit("payment-completed");
          this.loading = false;
        },
        ({ response }) => {
          this.loading = false;
          let message = "Payment Failed. Please try later";
          if (response) {
            const { statusCode = 400, data = null } = response.data;
            if (data && data.message) {
              message = data.message;
            }
          }
          this.error = "Payment failed. Try again later";
        }
      );
    },
  },
};
</script>

<style></style>
