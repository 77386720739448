<template>
  <div>
    <button
      type="button"
      v-permissions="'assign-driver-jobs'"
      class="track-assign-btn"
      v-if="
        !assignmentDetail.isGenerated && assignmentDetail.assignmentStatus === 1
      "
      :disabled="jobStatus == 10 || viewOnly == 'view'"
      @click.stop="open"
    >
      Assign
    </button>
    <v-dialog v-model="toShow" persistent max-width="90%">
      <v-card style="height: 100% !important">
        <v-card-title
          class="headline bg-clr padding-left"
          style="color: black !important"
          persistent
        >
          Assign Driver
          <v-spacer />
          <div @click="closeDialog">
            <v-icon class="pointer">close</v-icon>
          </div>
        </v-card-title>
        <v-spacer class="customScrollAir fullairDialog">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div>
                <v-container fluid>
                  <v-flex xs12 md4>
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="driverType"
                      row
                      disabled
                      single-line
                      :mandatory="true"
                    >
                      <div>
                        <div class>
                          <v-layout>
                            <v-radio
                              label="Transporter"
                              value="Transporter"
                              color="orange"
                              class="col-md-6"
                            ></v-radio>
                            <v-radio
                              label="Owner Drivers"
                              value="Owner"
                              color="orange"
                              class="col-md-6"
                            ></v-radio>
                          </v-layout>
                        </div>
                      </div>
                    </v-radio-group>
                  </v-flex>

                  <v-flex xs12 md12>
                    <v-layout>
                      <v-flex xs8>
                        <v-text-field
                          class="pt-0 currencyTitle"
                          label="Search Driver Name, Driver Id..."
                          :loading="loading"
                          v-model="searchText"
                          :value="searchText"
                          @keyup.enter="searchDriver"
                          @click:append="searchDriver"
                        />
                      </v-flex>
                      <v-flex xs2 pl-1>
                        <v-layout>
                          <v-btn
                            color="white"
                            @click="searchDriver"
                            :loading="loading"
                          >
                            <v-icon small>search</v-icon>&nbsp;Search
                          </v-btn>
                          <v-btn
                            flat
                            class="reset-btn-color"
                            prepend-icon
                            color="orange darken-1"
                            @click="
                              resetSearch();
                              isSearchResult = false;
                            "
                          >
                            Reset
                            <v-icon color="orange">donut_large</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-flex>
                      <v-flex xs2></v-flex>
                      <v-flex xs6>{{ messageAssignmentPrice }}</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-layout>
                    <v-flex xs6 md8>
                      <v-layout
                        align-baseline
                        row
                        wrap
                        pt-3
                        style="
                          box-shadow: none;
                          height: 470px;
                          border: none !important;
                          margin: 0px;
                          padding: 0px 10px 0px 0px !important;
                        "
                      >
                        <!-- </v-layout> -->
                        <v-flex xs8 v-if="isSearchResult == false">
                          <div class="ft20 pt-1 pb-1" v-if="items.length > 0">
                            All Results
                            <span style="color: black; font-size: 11px"
                              >{{ totalData }} results</span
                            >
                          </div></v-flex
                        >
                        <v-flex xs8 v-if="isSearchResult == true">
                          <div class="ft20 pt-1 pb-1" v-if="items.length > 0">
                            Search Results
                            <span style="color: black; font-size: 11px"
                              >{{ totalData }} results</span
                            >
                          </div></v-flex
                        >
                        <v-flex xs12 v-if="items.length > 0">
                          <v-simple-table v-if="items.length > 0">
                            <v-spacer class="spacerFixAir" ref="tableList1">
                              <thead>
                                <tr>
                                  <th class="postionMain">Driver Name</th>
                                  <th
                                    class="postionMain"
                                    style="padding-left: 20px"
                                  >
                                    Status
                                  </th>
                                  <th class="postionMain">Driver ID</th>
                                  <th class="postionMain">Last Location</th>

                                  <th class="postionMain">Total Booking</th>
                                  <th class="postionMain">Emirates Id</th>
                                  <th
                                    class="postionMain"
                                    style="padding-left: 20px"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, i) in items" :key="i">
                                  <td style="width: 20%; word-break: break-all">
                                    {{ item.driverName }}<br /><span
                                      style="color: grey"
                                      >{{ item.countryCode }}-{{
                                        item.phoneNo
                                      }}</span
                                    >
                                  </td>
                                  <td
                                    style="width: 10%"
                                    v-if="item.availabilityStatus"
                                  >
                                    <div style="color: green">Available</div>
                                  </td>
                                  <td style="width: 10%" v-else>
                                    <div style="color: red">Busy</div>
                                  </td>
                                  <td style="width: 10%">{{ item.value }}</td>
                                  <td
                                    style="width: 10%"
                                    v-if="item.assignmentData"
                                  >
                                    {{
                                      item.assignmentData.destinationCity || "-"
                                    }}
                                  </td>
                                  <td style="width: 10%" v-else>-</td>

                                  <td style="width: 10%">
                                    {{ item.completeAssignCount }}
                                  </td>
                                  <td style="width: 10%; word-break: break-all">
                                    {{ item.emiratesID || "-" }}
                                  </td>
                                  <td
                                    style="width: 10%; padding: 0px !important"
                                  >
                                    <v-btn
                                      v-if="!item.isSelect"
                                      class="ma-2"
                                      outline
                                      color="orange"
                                      @click="
                                        setDriver(item);
                                        fetchVendor(item.value);
                                      "
                                    >
                                      Unselected
                                    </v-btn>
                                    <v-btn
                                      v-if="item.isSelect"
                                      color="orange darken-1"
                                      class="white--text"
                                      style="width: 110px !important"
                                      @click="
                                        resetSelect();
                                        item.isSelect = false;
                                      "
                                    >
                                      selected
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </v-spacer>
                          </v-simple-table>
                        </v-flex>
                        <PaginationButtons
                          v-if="toShow"
                          :url="`/newDriverFlow/transporterOwnerAndDriver`"
                          :payload="payload"
                          :callback="callback"
                          :componentKey="componentKey"
                          v-on:pagination-payload="paginationPayload"
                        />
                      </v-layout>
                    </v-flex>
                    <v-divider vertical></v-divider>
                    <v-flex xs6 md4 class="pl-3">
                      <v-layout
                        align-baseline
                        row
                        wrap
                        style="
                          height: 470px;
                          box-shadow: none;
                          border: none !important;
                          padding: 0px;
                          margin: 0px;
                        "
                      >
                        <v-flex xs12 md6 class="pr-2 pt-3">
                          <div class="subheading muted caption">
                            Driver rate for this assignment*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              :readonly="driver.bidAmount"
                              :suffix="currency"
                              type="number"
                              single-line
                              v-model="paymentAmount"
                              :value="paymentAmount"
                              :rules="[rules.required, rules.number]"
                            />
                          </div>
                        </v-flex>
                        <v-flex xs12 md6 class="pr-2">
                          <div class="subheading muted caption">
                            Vendor's Name*
                          </div>
                          <div class="heading">
                            <v-combobox
                              class="pt-0 currencyTitle"
                              :items="vendorNames"
                              v-model="vendorName"
                              item-text="vendorName"
                              item-value="vendorName"
                              @change="checkVendorName()"
                            ></v-combobox>
                          </div>
                          <span v-if="this.errorName"
                            >Please provide vendor's name</span
                          >
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Transporter'"
                        >
                          <div class="subheading muted caption">
                            {{
                              currency != "PKR"
                                ? "Vendor VAT Number*"
                                : "Vendor STRN Number*"
                            }}
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="vendorVatNumber"
                              :disabled="
                                vendorName && vendorName.vendorVatNumber
                                  ? true
                                  : false
                              "
                              :value="vendorVatNumber"
                              :rules="currency != 'PKR' ? [] : [rules.required]"
                              counter
                              single-line
                              maxlength="20"
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Owner'"
                        >
                          <div class="subheading muted caption">
                            {{
                              currency != "PKR"
                                ? "Vendor VAT Number"
                                : "Vendor STRN Number"
                            }}
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="vendorVatNumber"
                              :disabled="
                                vendorName && vendorName.vendorVatNumber
                                  ? true
                                  : false
                              "
                              :value="vendorVatNumber"
                              counter
                              single-line
                              maxlength="20"
                            />
                          </div>
                        </v-flex>
                        <v-flex xs12 md6 class="pr-2">
                          <div class="subheading muted caption">
                            Vendor's Country Code*
                          </div>
                          <div class="heading">
                            <v-autocomplete
                              class="pt-0 currencyTitle"
                              style="background-color: white !important"
                              :close-on-content-click="true"
                              attach
                              :items="countryCodes"
                              item-text="dialing_code"
                              item-value="dialing_code"
                              v-model="countryCode"
                              :disabled="vendorName ? true : false"
                              single-line
                              :rules="[rules.noWhiteSpace, rules.required]"
                              required
                            />
                          </div>
                        </v-flex>
                        <v-flex xs12 md6 class="pr-2">
                          <div class="subheading muted caption">
                            Vendor's Mobile Number*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="VendorNumber"
                              :disabled="vendorName ? true : false"
                              :value="VendorNumber"
                              single-line
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Owner'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Address
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="VendorAddress"
                              :disabled="
                                vendorName ? vendorName.vendorAddress : ''
                              "
                              :value="VendorAddress"
                              single-line
                            />
                          </div>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          class="pr-2"
                          v-if="driverType == 'Transporter'"
                        >
                          <div class="subheading muted caption">
                            Vendor's Address*
                          </div>
                          <div class="heading">
                            <v-text-field
                              class="pt-0 currencyTitle"
                              type="text"
                              v-model="VendorAddress"
                              :rules="[rules.noWhiteSpace, rules.required]"
                              :disabled="
                                vendorName ? vendorName.vendorAddress : ''
                              "
                              :value="VendorAddress"
                              single-line
                            />
                          </div>
                        </v-flex>
                        <v-layout>
                          <v-flex xs12 md6 class="pr-2">
                            <div class="subheading muted caption">
                              Flight Number*
                            </div>
                            <div class="heading">
                              <v-text-field
                                class="pt-0 currencyTitle"
                                type="text"
                                v-model="flightNo"
                                :value="flightNo"
                                single-line
                                :rules="[rules.noWhiteSpace, rules.required]"
                              />
                            </div>
                          </v-flex>
                        </v-layout>
                        <v-flex md12>
                          <v-layout row v-if="!this.$props.milestoneId">
                            <v-flex md11>
                              <div class="subheading muted caption">
                                Set Milestone*
                              </div>
                              <v-select
                                v-model="milestone"
                                :items="milestoneItems"
                                item-text="pickupLocation"
                                item-value="_id"
                                class="pt-0 currencyTitle"
                                label="Select a milestone"
                                @change="getChecklist"
                                persistent-hint
                                single-line
                              >
                                <template slot="item" slot-scope="data"
                                  >{{ data.item.pickupLocation }} -
                                  {{ data.item.dropOffLocation }}</template
                                >
                              </v-select>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs6 v-if="driver.name">
                              <div class="ft20 pb-1">Selected Driver</div>
                              <v-card class="mx-auto card-style">
                                <v-layout row>
                                  <v-flex xs6 md11
                                    ><span style="color: gray">{{
                                      driver.name
                                    }}</span
                                    ><br />{{ driver.countryCode }}-{{
                                      driver.phoneNo
                                    }}</v-flex
                                  >
                                  <v-flex
                                    ><v-icon
                                      small
                                      @click="resetSelect()"
                                      class="iconStyle"
                                      >close</v-icon
                                    ></v-flex
                                  >
                                </v-layout>
                              </v-card>
                              <!-- <v-text-field
                              class="pt-0 currencyTitle"
                              label="Selected Driver"
                              v-model="driver.name"
                              :value="driver.name"
                            /> -->
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </v-form>
          </v-card-text>
          <v-divider v-if="milestone"></v-divider>
          <v-layout v-if="milestone">
            <v-flex
              xs12
              md12
              class="heading orange--text pt-2 pl-5"
              style="text-align: initial"
            >
              Milestone Information <br />
            </v-flex>
          </v-layout>
          <v-layout v-if="milestone">
            <v-flex xs12 md12 class="cont">
              <ul class="timelineHori">
                <li v-for="(item, i) in checkList" :key="i">
                  <span class="postion-set">{{ item.name }}</span>
                </li>
              </ul>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>
        </v-spacer>
        <v-layout>
          <v-spacer />
          <div
            class="red--text"
            style="text-align: right !important; padding-top: 10px"
            v-if="errorDriver !== null"
          >
            {{ errorDriver }}
          </div>
          <div
            class="red--text"
            style="text-align: right !important; padding-top: 10px"
            v-if="error !== null"
          >
            {{ error }}
          </div>
          <v-btn
            color="orange darken-1"
            class="white--text"
            @click.native="assignDriver"
            :loading="loading"
            :disabled="!driver.name"
            >Assign Driver</v-btn
          >
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark color="orange white--text " dense flat>
          <v-toolbar-title class="white--text"
            >High Driver Price Confirmation</v-toolbar-title
          >
          <v-spacer />
          <div @click="dialog = false">
            <v-icon class="pointer">close</v-icon>
          </div>
        </v-toolbar>
        <v-card-text class="pa-4"> {{ message }} </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            medium
            class="pr-2"
            style="text-transform: none !important"
            darken-1
            :disabled="loading"
            text
            @click="dialog = false"
            >No</v-btn
          >
          <div class="pl-2">
            <v-btn
              :disabled="loading"
              style="text-transform: none !important"
              color="orange white--text "
              @click.native="assignDriver('overPriced')"
              >Yes</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import ErrorBox from "@/components/Common/ErrorBox";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import { EventBus } from "../../../event-bus.js";
import moment from "moment";
import {
  StorageKeys,
  countries,
  TruckPrefix,
} from "../../../constants/constants";
import {} from "@/constants/api-urls.js";
import {
  milestoneList,
  driverAvailablityCheck,
} from "../../../constants/api-urls.js";
export default {
  mounted() {},
  components: {
    CustomerCurrency,
    PaginationButtons,
    ErrorBox,
  },
  props: {
    jobStatus: Number,
    assignmentId: Number,
    assignmentDetail: Object,
    viewOnly: String || Boolean,
    country: String,
    milestoneId: String,
    onclose: Function,
    currency: String,
  },
  data: () => ({
    driverType: "Owner",
    toShow: false,
    checkList: [],
    componentKey: 0,
    isSearchResult: false,
    transporters: [],
    transporterId: "",
    payload: {},
    options: {
      color: "#ffc04c",
      width: 560,
      zIndex: 200,
    },
    totalPages: 0,
    workingCountry: "",
    milestoneItems: [],
    totalData: "",
    milestone: null,
    currentMilestoneId: "",
    currentMilestone: 0,
    milestoneTrack: {
      milestone: [],
      assId: null,
    },
    vendorId: "",
    valid: true,
    lazy: false,
    countryCodes: [],
    countryCode: "",
    radios: "",
    vendorNames: [],
    vendorName: [],
    toggle_exclusive: 0,
    truckNumber: null,
    truckPrefix: null,
    prefixTrucks: [],
    dialog: false,
    message: "",
    error: null,
    loading: false,
    errorName: false,
    errorDriver: null,
    searchText: null,
    messageAssignmentPrice: "",
    driver: {
      truckNumber: null,
      truckPrefix: null,
      id: null,
      name: null,
      isBid: false,
      bidAmount: null,
      bidCurrency: null,
      flightNo: null,
    },
    items: [],

    VendorNumber: null,
    vendorVatNumber: null,
    VendorAddress: null,
    paymentCurrency: null,
    paymentAmount: null,
    waybillNo: null,
    flightNo: null,
    pickupTime: null,
    assetId: null,
    assets: [],
    currencies: ["AED", "SAR"],
    rules: {
      required: (value) => !!value || "This field is required.",
      requiredlength: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "Enter valid value.",
      vatNumber: (v) =>
        /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Vat Number",
      number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Rate",
      emailRules: (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
        "E-mail must be valid",
      countryCode: (v) =>
        /^(\+?\d{1,3}|\d{1,4})$/.test(v) || "Please Enter Valid Country Code.",
      phoneNumber: (v) =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Please Enter Valid Mobile No.",
    },
  }),
  computed: {
    hasError() {
      return this.error !== null;
    },
  },
  created() {
    this.prefixTrucks = TruckPrefix;
    this.countryCodes = countries;
    this.payload = {
      skip: "0",
      limit: "15",
      status: "Owner",
      assignmentId: this.$props.assignmentId,
    };
  },
  watch: {
    // toShow(val) {
    //   if (!val) {
    //     this.closeDialog();
    //   }
    //   if (val) {
    //     this.payload = {
    //       skip: "0",
    //       limit: "15",
    //       status: "Owner",
    //       assignmentId: this.$props.assignmentId,
    //     };
    //     this.fetchAssets();
    //     //this.fetchVendor();
    //     this.getMilestones();
    //   }
    // },
  },
  methods: {
    getChecklist() {
      if (this.milestone) {
        this.milestoneItems.filter((x) => {
          if (this.milestone == x._id) {
            this.checkList = x.checkList;
          }
        });
        setTimeout(() => {
          var objDiv = document.getElementsByClassName("fullairDialog");
          for (var i = 0; i < objDiv.length; i++) {
            objDiv[i].scrollTo({
              top: objDiv[i].scrollHeight,
              left: 0,
              behavior: "smooth",
            });
          }
        }, 1000);
      }
    },
    open() {
      this.messageAssignmentPrice = `Customer Price For Assignment Id ${this.assignmentDetail.assignmentId} : ${this.assignmentDetail.perAssignmentPrice} ${this.currency}`;
      this.toShow = true;

      this.fetchAssets();
      this.getMilestones();

      this.payload = {
        skip: "0",
        limit: "15",
        status: "Owner",
        assignmentId: this.$props.assignmentId,
      };
      // this.fetchAssets();
      //this.fetchVendor();
      // this.getMilestones();
    },
    callback(res) {
      this.loading = false;
      this.totalData = res.totalData;
      this.totalPages = Math.ceil(res.totalData / 15);
      const drivers = res.list;
      this.driver.name = "";
      this.errorDriver = "";
      if (drivers && drivers.length > 0) {
        this.items = drivers.map((driver) => {
          return {
            driverName: driver.firstName + " " + driver.lastName,
            phoneNo: driver.phoneNo,
            _id: driver._id,
            countryCode: driver.countryCode,
            totalOutStandingAmount: driver.totalOutStandingAmount || "-",
            emiratesID: driver.emiratesID,
            value: driver.driverId,
            isBid: driver.isBid,
            assignmentData: driver.assignmentData[0],
            availabilityStatus: driver.availabilityStatus,
            completeAssignCount: driver.completeAssignCount,
            isSelect: driver.isSelect == false,
            truckNumber: driver.truckNumber,
            truckPrefix: driver.truckPrefix,
            bidAmount: driver.bidAmount,
            bidCurrency: driver.bidCurrency,
            container: driver.truckNumber,
          };
        });
      } else {
        this.errorDriver = "No drivers found";
      }
      if (this.$refs.tableList1) {
        this.$refs.tableList1.scrollTo(0, 0);
      }
    },
    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
    },
    getTime3(date) {
      return moment(date).format("DD/MM/YYYY HH:mm ");
    },
    resetDriver() {
      this.errorDriver = null;
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.items = [];
      this.items.forEach((key, index) => {
        if (key.isSelect) {
          key.isSelect = false;
        }
      });
      this.driver.name = null;
      this.driver.id = null;
      this.error = null;
      this.driver.bidAmount = null;
      this.driver.bidCurrency = null;
      this.flightNo = null;
      this.radio = null;
      (this.paymentAmount = null), this.$refs.form.resetValidation();
    },
    resetSelect() {
      this.errorDriver = null;
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.items.forEach((key, index) => {
        if (key.isSelect) {
          key.isSelect = false;
        }
      });

      this.driver.name = null;
      this.driver.id = null;
      this.error = null;
      this.driver.bidAmount = null;
      this.driver.bidCurrency = null;
      this.flightNo = null;
      this.radio = null;
      (this.paymentAmount = null), this.$refs.form.resetValidation();
    },
    clearMilestone() {
      this.milestone = null;
      this.getMilestones();
    },
    getMilestones() {
      let url = milestoneList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        workingCountry: [this.country],
        assignDriver: "true",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneItems = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    checkDriverAvailability(id) {
      let url = driverAvailablityCheck;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        driverId: id.toString(),
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {},
        (error) => {
          this.errorDriver = error.response.data.message;
        }
      );
    },
    fetchVendor(id) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      var data = {
        driverId: id.toString(),
        ownerType: this.driverType,
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/newDriverFlow/vendorDriverTransporter`, data, config)
        .then(
          (response) => {
            this.vendorNames = response.data.data.list.filter((x) => {
              if (x.vendorName) {
                if (this.driver.name == x.vendorName) {
                  this.vendorName = x.vendorName;
                }
                return x;
              } else {
                if (x.vatNumber) {
                  this.vendorVatNumber = x.vatNumber;
                }
                this.vendorName = this.driver.name;
                this.vendorId = this.driver._id;
                this.VendorNumber =
                  this.vendorName.VendorNumber || this.driver.phoneNo;
                this.countryCode =
                  this.vendorName.vendorCountry || this.driver.countryCode;
              }
            });
          },
          (error) => {
            this.loading = false;
            this.error = "Failed to fetch data";
          }
        );
    },
    checkVendorName() {
      this.VendorNumber = this.vendorName.VendorNumber;
      this.vendorVatNumber = this.vendorName.vendorVatNumber
        ? this.vendorName.vendorVatNumber.trim()
        : "";
      this.countryCode = this.vendorName.vendorCountry;
      this.VendorAddress = this.vendorName.vendorAddress;
      this.vendorId = this.vendorName._id;
      this.error = null;
      this.loading = false;
      this.errorName = false;
    },
    restvalue() {},
    fetchAssets() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;

      let data = {
        workingCountry: [this.country],
      };
      this.axios.post(`${apiUrl}/assets/freeAssetsListing`, data, config).then(
        (response) => {
          this.assets = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = this.$eventBus.parse(error);
        }
      );
    },
    closeDialog() {
      this.error = "";
      this.toShow = false;
      this.items = [];
      (this.isSearchResult = false), (this.errorDriver = null);
      this.searchText = null;
      this.VendorAddress = null;
      this.countryCode = null;
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.VendorNumber = null;
      this.milestone = null;
      this.flightNo = null;
      this.paymentAmount =
        this.error =
        this.pickupTime =
        this.driver.id =
        this.driver.name =
        this.assetId =
          null;
      this.driver.bidAmount = this.driver.bidCurrency = null;
      this.$emit("driverAssignClose");
      this.toggle_exclusive = "";
      this.$refs.form.resetValidation();
    },
    destroyed() {
      this.items = [];
      (this.isSearchResult = false), (this.vendorVatNumber = null);
      this.vendorName = [];
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.errorDriver = null;
      this.searchText = null;
      this.milestone = null;
      this.driver.id = null;
      this.driver.bidCurrency = null;
      this.driver.bidAmount = null;
      this.driver.name = null;
      this.assetId = null;
      this.paymentAmount = null;
      this.vendorName = [];
      this.VendorNumber = null;
      this.vendorVatNumber = null;
      this.VendorAddress = null;
      this.countryCode = "";
      this.truckNumber = null;
      this.flightNo = null;
    },
    setDriver(item) {
      this.errorDriver = null;
      this.vendorVatNumber = null;
      this.vendorName = [];
      this.VendorAddress = null;
      this.assetLabel = null;
      this.VendorNumber = null;
      this.countryCode = null;
      this.items.forEach((key, index) => {
        if (key.isSelect) {
          key.isSelect = false;
        }
      });

      if (item) {
        this.driver.phoneNo = item.phoneNo;
        this.driver.id = item.value;
        this.driver.countryCode = item.countryCode;
        this.driver.name = item.driverName;
        this.driver.truckNumber = item.truckNumber;
        (this.driver.totalOutStandingAmount =
          item.totalOutStandingAmount || "-"),
          (this.driver.isBid = item.isBid);
        item.isSelect = true;
        this.driver._id = item._id;
        this.driver.bidAmount = item.bidAmount;
        this.driver.bidCurrency = item.bidCurrency;
        this.truckNumber = item.truckNumber;
        this.truckNumber = this.driver.truckNumber;
        this.truckPrefix = this.driver.truckPrefix;
        this.driver.truckPrefix = item.truckPrefix;
        this.truckPrefix = this.driver.truckPrefix;
        this.flightNo = item.flightNo;
        this.paymentAmount = item.bidAmount ? Math.round(item.bidAmount) : "";
        this.checkDriverAvailability(item.value);

        this.$refs.form.resetValidation();
      }
    },
    assignDriver(item) {
      if (
        this.paymentAmount > this.assignmentDetail.perAssignmentPrice &&
        item !== "overPriced"
      ) {
        console.log("assign price is greater than");
        this.message = `The driver Price (${this.paymentAmount} ${this.currency}) is exceeding the customer price  ${this.assignmentDetail.perAssignmentPrice} ${this.currency} for this assignment. Are you sure you want to continue ? `;
        this.dialog = true;
        return;
      }
      this.errorName = false;
      this.errorDriver = null;
      if (this.vendorName != undefined) {
        if (this.vendorName.length == 0) {
          this.loading = false;
          this.error = "Please provide vendor's name";
          return false;
        }
      }
      if (!this.vendorName) {
        this.loading = false;
        this.error = "Please provide vendor's name";
        return false;
      }
      if (this.$refs.form.validate()) {
        this.error = null;
        this.loading = true;
        this.errorName = false;
        const { id, name, bidAmount, bidCurrency } = this.driver;
        if (!this.paymentAmount || this.paymentAmount <= 0) {
          this.loading = false;
          this.error = "Please provide payment amount above 0";
          return false;
        }
        if (this.vendorName != undefined) {
          if (this.vendorName.length == 0) {
            this.loading = false;
            this.error = "Please provide vendor's name";
            return false;
          }
        }
        if (!this.vendorName) {
          this.loading = false;
          this.error = "Please provide vendor's name";
          return false;
        }
        const data = {
          driverId: id.toString(),
          assignmentId: this.assignmentId.toString(),
          paymentAmount: this.paymentAmount.toString(),
          paymentCurrency: this.$props.currency,
          vendorName: this.vendorName,
          VendorNumber: this.VendorNumber,
          truckNumber: this.flightNo || "",
          vendorVatNumber: this.vendorVatNumber
            ? this.vendorVatNumber.trim()
            : "",
          vendorAddress: this.VendorAddress ? this.VendorAddress.trim() : "",
          vendorCountry: this.countryCode.dialing_code || this.countryCode,
          ownerId: this.vendorId,
          ownerType: this.driverType,
        };
        if (this.VendorAddress) data["vendorAddress"] = this.VendorAddress;
        if (this.vendorVatNumber)
          data["vendorVatNumber"] = this.vendorVatNumber;
        if (this.milestone && this.milestone.length > 0) {
          data.milestoneId = this.milestone;
        }

        if (this.assetId) {
          data.assetId = this.assetId.toString();

          let y = this.assets.filter((x) => {
            if (data.assetId === x._id) {
              return x.imeiNumber;
            }
          });

          data.imeiNumber = y[0].imeiNumber;
        }
        if (this.vendorName.vendorName) {
          data.vendorName = this.vendorName.vendorName;
        }
        if (!this.vendorName.vendorName) {
          data.vendorName = this.vendorName;
        }

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        if (item == "overPriced") {
          this.axios
            .post(`${apiUrl}/newDriverFlow/partiallyAssignDriver`, data, config)
            .then(
              (response) => {
                this.dialog = false;
                const { status, data } = response.data;
                this.loading = false;
                if (this.$props.type === "2") {
                  this.$emit("refresh-assign-list");
                } else {
                  this.$eventBus.$emit("job-driver-assign-success", {
                    driverId: id,
                    driverName: name,
                    assignmentId: this.$props.assignmentId,
                  });
                  this.$eventBus.$emit("refresh-job");
                  this.$emit("updateJobs");
                }
                this.destroyed();
                this.toShow = false;
                if (this.onclose) {
                  this.onclose({ driverId: id, driverName: name });
                }
                (this.truckPrefix = ""),
                  (this.truckNumber = ""),
                  (this.loading = false);
                this.$refs.form.resetValidation();
              },
              (error) => {
                this.dialog = false;
                this.error = error.response.data.message;
                this.loading = false;
              }
            );
        } else {
          this.axios
            .post(`${apiUrl}/newDriverFlow/newAssignDriver`, data, config)
            .then(
              (response) => {
                const { status, data } = response.data;
                this.loading = false;
                if (this.$props.type === "2") {
                  this.$emit("refresh-assign-list");
                } else {
                  this.$eventBus.$emit("job-driver-assign-success", {
                    driverId: id,
                    driverName: name,
                    assignmentId: this.$props.assignmentId,
                  });
                  this.$eventBus.$emit("refresh-job");
                  this.$emit("updateJobs");
                }
                this.destroyed();
                this.toShow = false;
                if (this.onclose) {
                  this.onclose({ driverId: id, driverName: name });
                }
                (this.truckPrefix = ""),
                  (this.truckNumber = ""),
                  (this.loading = false);
                this.$refs.form.resetValidation();
              },
              (error) => {
                this.error = error.response.data.message;
                this.loading = false;
              }
            );
        }
      }
    },
    searchDriver() {
      this.resetDriver();
      this.items = [];
      if (this.searchText) {
        this.isSearchResult = true;
        this.payload = {
          skip: "0",
          limit: "15",
          status: "Owner",
          assignmentId: this.$props.assignmentId,
          searchText: this.searchText,
        };
      } else {
        this.isSearchResult = false;
        this.payload = {
          skip: "0",
          limit: "15",
          status: "Owner",
          assignmentId: this.$props.assignmentId,
        };
      }
    },
    resetSearch() {
      this.resetDriver();
      this.searchText = "";
      this.payload = {
        skip: "0",
        limit: "15",
        status: this.driverType,
        assignmentId: this.$props.assignmentId,
      };
    },
  },
};
</script>
<style scoped>
.postion-set {
  word-break: break-all;
}
.cont {
  width: 100%;
  position: relative;
  z-index: 1;
}
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
  border: 2px solid white;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li:first-child:after {
  content: none;
}

.timelineHori li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background: orange;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.timelineHori li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: orange;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li {
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  list-style-type: none;
}

.customScrollAir {
  overflow-y: scroll !important;
  scroll-behavior: smooth !important;
  max-height: calc(100vh - 200px) !important;
}

.borderColor {
  border-top-style: solid !important;
  border-top-color: #dcdcdc !important;
}
.borderColorRight {
  border-right-style: solid !important;
  border-right-color: #dcdcdc !important;
}
.ft20 {
  font-size: 20px !important;
  color: lightgrey;
}
.spacerclass {
  max-height: 550px !important;
}
.spacerclassTable {
  max-height: 300px !important;
}
.iconStyle {
  font-size: 16px;
  position: absolute;
  top: 10 !important;
  right: 7;
}
.card-style {
  border-radius: 7px !important;
  border: 1px lightgrey solid !important;
  padding: 10px !important;
}
.padding-left {
  padding: 10px 30px 10px 30px !important;
}
</style>

<style lang="scss">
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  padding-top: 20px;
  overflow-y: hidden;
  &:-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
  }
}
.spacerFixAir {
  max-height: 300px !important;
}

.spacerFixAir:-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.spacerFixAir::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
  border-radius: 10px;
}

.spacerFixAir::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.spacerFixAir::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.spacerFixAir::-webkit-scrollbar-thumb {
  background-color: lightgrey !important;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
}

.hoverable {
  &:hover {
    color: rgb(255, 181, 21) !important;
  }
}
.spacerclass {
  max-height: 550px !important;
}
</style>
