<template>
  <div>
    <v-btn
      style="background: orange !important"
      v-if="type == 1"
      class="commentbtn"
      flat
      prepend-icon
      color="white darken-1"
      @click.stop="check()"
      >Comments</v-btn
    >
    <button
      v-else-if="type == 3"
      type="button"
      target="_blank"
      @click.stop="check()"
      class="track-ass-btn assign-button"
    >
      Comments
    </button>

    <v-btn
      class="blue-button comment_btn"
      v-else
      flat
      prepend-icon
      @click.stop="check()"
    >
      Add Comments</v-btn
    >

    <v-dialog v-model="dialog" persistent max-width="40%">
      <v-card>
        <v-card-title style="padding: 1px !important" class="bg-white">
          <span class="headline" style="padding: 2px 20px; height: 20px">
            Add Comments</span
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon class="heading grey--text text--darken-4">close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="bg-gray comment__form">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-layout class="">
              <v-btn
                v-if="section !== 'assignments'"
                :class="{ active: !toggle_exclusive }"
                style="text-transform: none; !important"
                @click="
                  assigIdShow = 0;
                  toggle_exclusive = 0;

                  commentsList();
                "
                >Job</v-btn
              >
              <v-btn
                :class="{ active: toggle_exclusive }"
                style="text-transform: none; !important"
                @click="
                  toggle_exclusive = 1;
                  assigIdShow = 1;
                  section !== 'assignments' ? assignmentList($event) : '';
                "
                >Assignment</v-btn
              >

              <v-flex
                xs3
                pt-3
                v-if="assigIdShow == 1 && section !== 'assignments'"
                style="margin-left: 130px"
              >
                <v-select
                  :items="AssignmentId"
                  label="Assignment Id*"
                  v-model="ids"
                  item-text="text"
                  item-value="value"
                  class="pt-0 currencyTitle"
                  @change="getassignmentIdChange"
                  single-line
                ></v-select>
              </v-flex>
              <!-- <v-btn
                style="    width: 30px;
                 height: 30px;cursor: pointer;"
                outline
                small
                fab
                @click="assignmentList($event)"
                color="orange"
              ></v-btn> -->
            </v-layout>
            <v-checkbox
              style="  !important;margin-left:0px;
                             margin-top: 0px; !important"
              v-model="isSelect"
              :label="'Tag people manually'"
              value="singleSelect"
              class="message"
            ></v-checkbox>
            <v-layout
              row
              wrap
              style="
                height: 200px !important;
                margin: 0px !important ;
                background-color: white;
              "
            >
              <v-flex md12 xs12>
                <v-autocomplete
                  v-model="communicateDialog.select"
                  v-if="isSelect == 'singleSelect'"
                  label="Tag people here"
                  :items="items"
                  chips
                  deletable-chips
                  hide-selected
                  hide-no-data
                  multiple
                  class="tag-input"
                  :rounded="true"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex class="" xs12 md12>
                <v-textarea
                  rows="1"
                  no-resize
                  label="Type your comment here"
                  pt-0
                  currencyTitle
                  v-model="communicateDialog.message"
                  :value="communicateDialog.message"
                  color="orange darken-1"
                  maxlength="500"
                />
              </v-flex>
              <small class="error" v-show="this.x.error">{{
                this.x.error
              }}</small>
            </v-layout>
            <v-flex md12>
              <small class="success" v-show="this.res">{{ this.res }}</small>

              <v-spacer></v-spacer>
              <!--<v-btn color="orange darken-1" flat @click="dialog = false">Close</v-btn>-->
              <v-btn
                style="background: cornflowerblue !important; text-align: end"
                flat
                class="float-right"
                prepend-icon
                color="white darken-1"
                :disabled="processing"
                @click="addComment"
                >Add Comment</v-btn
              >
            </v-flex>
          </v-form>
        </v-card-text>

        <v-card-text
          class="bg-gray"
          style="padding-right: 8px; overflow-x: hidden; padding-top: -10px"
        >
          <span v-if="this.totalData1">Comments({{ this.totalData1 }})</span>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex class="chat-history" xs12 md12>
                <vue-custom-scrollbar
                  class="scroll-area"
                  :settings="settings"
                  @ps-y-reach-start="scrollHanle"
                  id="commentHistory"
                >
                  <div
                    v-for="item in commentsListing"
                    :key="item._id"
                    class="row"
                  >
                    <div
                      v-if="currentUser != item.generateBy"
                      class="bg-color received-layout"
                    >
                      <p class="">
                        <span v-if="item.senderdData" class="tag-blue">
                          {{ item.senderdData.name }}
                        </span>

                        <span v-if="item.assignmentId" class="tag-black">
                          ( {{ item.assignmentId }})
                        </span>
                        <span v-if="item.senderdData" class="tag-gray">{{
                          getUtcTime(item.createdAt)
                        }}</span>
                      </p>

                      <p class="gray-dark">{{ item.comment }}</p>
                      <p class="tag-orange">
                        <span
                          v-for="(tags, index) in item.tagAdmins"
                          :key="tags._id"
                          >{{ tags.name }} ({{ tags.employeeID }})<span
                            v-if="index != item.tagAdmins.length - 1"
                            >,</span
                          ></span
                        >
                        <span v-if="item.tagAdmins.length == 0">
                          Everyone
                        </span>
                      </p>
                      <!-- <p class="tag-gray">
                        <span v-if="item.senderdData"></span>
                        <span class="text-left">{{
                          getUtcTime(item.createdAt)
                        }}</span>
                      </p> -->
                    </div>
                    <div
                      v-if="currentUser == item.generateBy"
                      class="received-layout bg-color"
                    >
                      <p class="">
                        <span v-if="item.senderdData" class="tag-blue">
                          {{ item.senderdData.name }}
                        </span>

                        <span v-if="item.assignmentId" class="tag-black">
                          ( {{ item.assignmentId }})
                        </span>
                        <span v-if="item.senderdData" class="tag-gray">{{
                          getUtcTime(item.createdAt)
                        }}</span>
                      </p>
                      <p class="gray-dark">{{ item.comment }}</p>
                      <p class="tag-orange">
                        <span
                          v-for="(tags, index) in item.tagAdmins"
                          :key="tags._id"
                        >
                          {{ tags.name }}({{ tags.employeeID }})<span
                            v-if="index != item.tagAdmins.length - 1"
                            >,</span
                          ></span
                        >
                        <span v-if="item.tagAdmins.length == 0">
                          Everyone
                        </span>
                      </p>
                      <!-- <p class="tag-gray">
                        <span v-if="item.senderdData"></span>
                        <span class="text-left">{{
                          getUtcTime(item.createdAt)
                        }}</span>
                      </p> -->
                    </div>
                  </div>
                  <div class="center" v-if="commentsListing.length == 0">
                    No Comments Found.
                  </div>
                </vue-custom-scrollbar>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
      <!--            <ErrorBox :error="x.error" />-->
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import vueCustomScrollbar from "vue-custom-scrollbar";
import moment from "moment";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { getCommentList, userList, addComment } from "../../constants/api-urls";
import axios from "axios";

const api = "https://hn.algolia.com/api/v1/search_by_date?tags=story";

export default {
  created() {
    this.assigIdShow = 0;
    if (this.$props.section) {
      this.assigIdShow = 1;
      this.toggle_exclusive = 1;
    }
    EventBus.$on("new-sales-comment", (data) => {
      if (this.$props.detail._id == data.jobId) {
        this.commentsListing.push(data);

        setTimeout(function () {
          var scroll = document.getElementById("commentHistory");
          if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
            scroll.animate({ scrollTop: scroll.scrollHeight });
          }
        }, 300);
      }
    });
  },
  data() {
    return {
      res: "",
      ids: "",
      workingCountry: "",
      opeartionName: "",
      isSelect: "",
      operationId: "",
      dialog: false,
      selected: ["email"],
      toggleClass: false,
      toggle_exclusive: 0,
      assigIdShow: 0,
      subject: null,
      message: "",
      loading: false,
      processing: false,
      commentsListing: [],
      valid: true,
      x: {
        error: "",
        message: "",
        loading: false,
        success: false,
        valid: true,
        currentUser: "",
        disableAdd: false,
      },

      communicateDialog: {
        error: "",
        success: "",
        show: false,
        message: "",
        type: "milestone",
        subject: null,
        cc: null,
        select: [],
        items: [],
        search: "", //sync search
      },
      items: [],
      AssignmentId: [],
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      skip: 0,
      limit: 10,
      totalData: 0,
      totalData1: 0,
    };
  },
  components: {
    ErrorBox,
    SuccessDialog,
    vueCustomScrollbar,
  },
  props: [
    "userId",
    "userName",
    "status",
    "viewOnly",
    "detail",
    "assignId",
    "type",
    "id",
    "section",
  ],
  watch: {
    communicateDialog(val) {},
  },
  methods: {
    assignmentList(e) {
      e.stopPropagation();
      this.loading = true;
      const data = {
        JobId: this.$props.id.toString(),
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/getAllAssignmentList`, data, config)
        .then(
          (response) => {
            this.ids = response.data.data.data[0]._id;
            response.data.data.data.forEach((e) => {
              this.AssignmentId.push({
                value: e._id,
                text: e.assignmentId,
              });
              this.commentsList();
            });
            this.loading = false;
          },

          (error) => {
            this.error = error.response.data.message;
            this.loading = false;
          }
        );
    },

    async check() {
      this.dialog = true;

      let userPermissions = JSON.parse(
        localStorage.getItem("permissions")
      ).permissions;

      this.currentUser = localStorage.getItem("user_Id");
      userPermissions.filter((x) => {
        this.operationId = x._id;
        return;
      });
      this.skip = 0;
      this.limit = 10;
      this.totalData = 0;
      this.commentsList();
      this.recipent();
      this.res = "";
      this.x.error = "";
      this.communicateDialog.message = "";
      this.communicateDialog.select = [];
      this.dialog = true;
    },
    async addComment() {
      let userPermissions = JSON.parse(
        localStorage.getItem("permissions")
      ).permissions;

      this.currentUser = localStorage.getItem("user_Id");

      this.sendComment();

      return;

      //this.dialog=true;
    },

    test(data) {
      debugger;
    },
    getassignmentIdChange() {
      (this.commentsListing = []), this.commentsList();
    },
    getUtcTime(date) {
      return moment(date).format("lll");
    },
    closeMessage() {
      this.x.success = false;
      this.$emit("on-block");
    },
    close() {
      // this.$emit("pagination-load", true);
      this.dialog = false;
    },

    scrollHanle(evt) {
      if (this.totalData > this.skip) {
        let url = getCommentList;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: this.$props.detail._id.toString(),
          limit: this.limit,
          skip: this.skip + this.limit,
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            if (response.data.data && response.data.data.list.length) {
              this.commentsListing.reverse();
              this.commentsListing.push(response.data.data.list);
              this.commentsListing.reverse();
              this.skip = this.skip + this.limit;
              /* setTimeout(function() {
                        var scroll = document.getElementById('commentHistory');
                        scroll.scrollTop = scroll.scrollHeight;
                        scroll.animate({scrollTop: scroll.scrollHeight},fast);

                    },300);*/
            }
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
      }
    },
    recipent() {
      this.items = [];

      let url = userList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        operationId: this.operationId,
      };
      if (this.assigIdShow == 1) {
      }
      if (!this.section) {
        body.jobId = this.$props.detail._id.toString();
      }
      if (this.section === "assignments") {
        body.jobId = this.detail.jobData._id;
      }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data) {
            let data = response.data.data;
            data.forEach((element) => {
              this.items.push({
                text: element.name + "(" + element.employeeID + ")",
                value: element._id,
              });
            });
          }

          // = response.data.data;
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },

    commentsList() {
      this.skip = 0;
      this.limit = 100;
      this.totalData = 0;
      this.commentsListing = [];
      this.totalData1 = "";
      let url = getCommentList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        limit: this.limit,
        skip: 0,
      };
      if (this.assigIdShow == 1) {
        if (this.section !== "assignments") {
          body.assignmentId = this.ids;
        }
      }
      if (this.section !== "assignments") {
        body.jobId = this.$props.detail._id.toString();
      }
      if (this.section === "assignments") {
        body.jobId = this.detail.jobData._id.toString();
        body.assignmentId = this.assigId;
      }

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data && response.data.data.list.length) {
            this.commentsListing = [];
            this.commentsListing = response.data.data.list;
            this.totalData1 = response.data.data.totalData;
            //  debugger

            setTimeout(function () {
              var scroll = document.getElementById("commentHistory");
              scroll.scrollTop = scroll.scrollHeight;
              scroll.animate({ scrollTop: scroll.scrollHeight });
            }, 300);
          } else {
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    sendComment() {
      this.x.error = "";
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let type = "";

        if (this.isSelect) {
          if (!this.communicateDialog.select.length) {
            this.x.error = "Please select at least one recipient.";
            setTimeout(() => (this.x.error = ""), 2000);
            this.processing = false;
            return false;
          }
        }

        if (!this.communicateDialog.message.trim()) {
          this.x.error = "Please add your comments.";
          setTimeout(() => (this.x.error = ""), 2000);
          this.processing = false;
          return false;
        }
        this.dialog = true;

        let url = addComment;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              this.workingCountry = key.value;
            }
          });
        }

        let body = {
          workingCountry: this.workingCountry,
          comment: this.communicateDialog.message.trim(),
          tagAdmins: this.communicateDialog.select,
          operationId: this.operationId,
          // assignmentId: this.ids,
        };
        if (this.assigIdShow == 1) {
          if (this.section !== "assignments") {
            body.assignmentId = this.ids;
          }
        }
        if (this.section !== "assignments") {
          body.jobId = this.$props.detail._id.toString();
        }
        if (this.section === "assignments") {
          body.jobId = this.detail.jobData._id.toString();
          body.assignmentId = this.assignId;
        }

        if (!this.isSelect) {
          body.sendToAll = true;
          body.tagAdmins = [];
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.processing = false;
            this.x.message = "Message has been successfully send.";
            this.communicateDialog.select = [];
            this.communicateDialog.message = "";
            this.disableAdd = false;
            this.$emit("pagination-load", true);
            this.commentsList();
            return;
            /* setTimeout(() => (this.res = '',
                        this.dialog=false ), 1000);*/
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.x.error = "Unable to Send Message.";
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.comment__form {
  padding: 11px !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: #fff;
}
.v-tabs__slider-wrapper {
  display: none;
}
form .layout.wrap {
  border: 1px solid rgba(26, 54, 126, 0.125) !important;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  margin: 20px 0;
}
.comment__form {
  overflow: auto;
}
.p--25 {
  padding: 25px !important;
}
.comment__form[data-v-404971df] {
  padding: 3px 10px !important;
}
.comment__form label.v-label.theme--light {
  font-size: 13px !important;
}
.v-select.v-select--chips .v-select__selections {
  height: 75px;
  overflow: auto;
}
.v-chip--removable .v-chip__content {
  font-size: 11px;
}
.ps__thumb-y {
  top: 0px;
  height: 32px;
}
.error {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: red;
  background: transparent !important;
}
.blue-button {
  border-radius: 5px;
  background-color: rgb(0, 140, 255) !important;
  color: #ffffff !important;
  border: 2px solid rgb(0, 140, 255) !important;
  padding: 2px 10px;
}
.assign-button {
  border-radius: 5px;
  background-color: rgb(0, 140, 255) !important;
  color: #ffffff !important;
  border: 2px solid rgb(0, 140, 255) !important;
  padding: 2px 2px;
}
.commentbtn {
  position: absolute;
  right: 300px;
  top: 8px;
}
.bg-grey {
  background-color: #bdc3c7;
}
.theme--light.v-chip {
  background: #feebd9 !important;
  color: rgb(248, 159, 69) !important;
}
.tag-input span.chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
}

.tag-input span.v-chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
  padding-left: 7px;
}

.tag-input span.v-chip::before {
  content: "label";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
}
.info {
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.chat-history {
  height: 180px;
  padding: 4px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  overflow-x: hidden;
}
.v-card__title.bg-grey {
  background: #f5f5f5 !important;
  border-bottom: 1px solid #e6e6e6;
}
.bg-gray {
  background: #ebebeb;
}
form .layout.wrap {
  border-radius: 0 px;
  padding: 15 px;
}
.tag-people {
  height: 150px;
  overflow: hidden;
}
.send-layout {
  background: #ffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px 0px 0px 10px;
  /* min-width: 320px;
    max-width: 320px; */
  width: 80%;
  float: right;
  margin-right: 11px;
  overflow-wrap: break-word;
}
.received-layout {
  background: white;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 0px 10px 10px 0px;
  /* min-width: 320px;
    max-width: 320px; */
  width: 100%;
  float: left;
  overflow-wrap: break-word;
}
.flex-tag {
  overflow-y: auto;
  height: 100px;
  max-height: 150px;
}
.tag-orange {
  color: #ff8c00;
  font-size: 13px;
}
.tag-gray {
  color: #b1abab;
  font-size: 11px;
}
.tag-blue {
  color: #1675f1;
  font-size: 12px;
}
.tag-black {
  color: black;
  font-size: 12px;
}
.gray-dark {
  color: #4d4d4d;
}
.chat-history .row p {
  margin-bottom: 5px;
}
.center {
  text-align: center;
}
.v-autocomplete {
  overflow-x: hidden;
}
.active {
  background-color: orange !important;
  color: #ffffff !important;
}
form .layout.wrap {
  padding: 10px 20px !important;
}
.comment__form {
  overflow: auto;
}
.v-card__title span.headline {
  font-size: 17px !important;
  line-height: 20px !important;
  display: inline-block;
}
.chat-history[data-v-404971df] {
  height: 115px;
}
.v-card__title span.headline[data-v-404971df] {
  padding: 0 20px;
}
</style>
