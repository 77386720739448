<template>
  <div>
    <button
      type="button"
      v-if="type === 'single' && assignStatus != 4"
      :disabled="assignStatus == 4 || assignStatus <= 1"
      @click.stop="check()"
      class="payy-ass-btn green-button ready_pay"
    >
      Ready To Pay
    </button>
    <v-btn
      class="ma-2"
      @click="check()"
      outline
      color="green"
      v-if="type === 'all' && assignStatus != 4"
      :disabled="assignStatus == 4 || assignStatus <= 1"
      @click.stop="check()"
    >
      Mark All Ready to Pay
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="500">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar
            dark
            style="background-color: #dcdcdc; color: black; padding-left: 10px"
            flat
          >
            <v-toolbar-title>
              <span class="headline"
                >Ready To Pay Advance</span
              ></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="close()">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-layout>
              <v-flex xs12 md12 class="text-style">
                {{ message }}
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12>
                <span class="dashboard-style"
                  >Driver’s Payment credit period</span
                >
                :
                {{
                  driverCreditDuration !== 0 &&
                  driverCreditDuration !== undefined
                    ? driverCreditDuration
                    : "N.A"
                }}
                {{
                  driverCreditDuration !== 0 &&
                  driverCreditDuration !== undefined
                    ? "days"
                    : ""
                }}
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <v-card-title class="title-layout-size">
                  <span class="heading">Comments</span>
                </v-card-title>
                <v-card-text class="title-layout-size">
                  <v-flex xs12>
                    <v-textarea
                      box
                      name="input-7-4"
                      class="pt-0 currencyTitle label__texxt"
                      label="Comments"
                      :rules="[rules.required, rules.noWhiteSpace]"
                      v-model="comments"
                    ></v-textarea>
                  </v-flex>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-layout style="justify-content: end">
              <v-btn color="red darken-1" flat @click="close()">{{
                cancelBtn
              }}</v-btn>
              <v-btn color="orange darken-1" flat @click="readyToPay()">
                {{ confirmBtn }}</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { StorageKeys, CancellationType } from "../../constants/constants";
import { readyToPayAdvance } from "../../constants/api-urls";
export default {
  props: {
    message: String,
    confirmationTitle: String,
    cancelBtn: String,
    confirmBtn: String,
    assignStatus: Number,
    type: String,
    details: Object,
    assignId: Number,
    driverCreditDuration: Number,
  },
  data: () => ({
    loading: false,
    valid: true,
    lazy: false,
    cancellationType: CancellationType,
    rules: {
      required: (value) => !!value || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
    },
    cancellationReason: "",
    cancelType: { text: "Other’s", value: "Other’s" },
    operationName: "",
    dialog: false,
    comments: "",
    error: null,
  }),
  created() {},
  methods: {
    async check() {
      //   this.operationName = "reject-customer";
      //   let y = await this.checkOpertaionPermission(this.operationName);
      //   if (y) {
      this.dialog = true;
      //   } else {
      //     return;
      //   }
    },
    close() {
      this.dialog = false;
      this.comments = "";
      this.$refs.form.resetValidation();
    },
    readyToPay() {
      if (this.$refs.form.validate()) {
        delete this.axios.defaults.headers.common["token"];
        let url = readyToPayAdvance;
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          assignmentId: this.assignId.toString(),
        };
        if (this.type === "all") {
          body.isReadyToPayAll = true;
          body.comment = this.comments;
        } else {
          body.isReadyToPayAll = false;
          body.comment = this.comments;
          body.advanceId = this.details._id;
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            // this.x.success = true;
            this.close();
            this.$emit("refresh-list");
            // this.$emit("assignment-list-refresh");
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.payy-ass-btn {
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 2px;
}
.text-style {
  font-size: 16px;
}
</style>
