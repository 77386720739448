<template>
  <div>
    <v-btn
      style="background: orange !important; margin-right: 170px"
      class="commentbtn"
      flat
      prepend-icon
      color="white darken-1"
      @click.stop="check()"
      >Change Customer</v-btn
    >

    <v-dialog v-model="dialog" persistent max-width="40%">
      <v-card>
        <v-card-title style="padding: 1px !important" class="bg-white">
          <span class="headline" style="padding: 2px 20px; height: 20px">
            Change Customer</span
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon class="heading grey--text text--darken-4">close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="comment__form">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-layout
              row
              wrap
              style="margin: 0px !important ; background-color: white"
            >
              <v-flex md12 xs12>
                <v-autocomplete
                  label="Choose New Customer "
                  :items="customers"
                  item-text="text"
                  item-value="value"
                  v-model="customerId"
                  hide-selected
                  hide-no-data
                  class="tag-input"
                  :rounded="true"
                >
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-flex md12>
              <small class="success" v-show="this.res">{{ this.res }}</small>

              <v-spacer></v-spacer>
              <!--<v-btn color="orange darken-1" flat @click="dialog = false">Close</v-btn>-->
              <v-btn
                style="background: cornflowerblue !important; text-align: end"
                flat
                class="float-right"
                prepend-icon
                color="white darken-1"
                :disabled="processing"
                @click="customerChange"
                >Change Customer</v-btn
              >
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>

      <v-snackbar
        :timeout="6000"
        bottom
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import vueCustomScrollbar from "vue-custom-scrollbar";
import moment from "moment";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { getCommentList, userList, addComment } from "../../constants/api-urls";
import axios from "axios";

const api = "https://hn.algolia.com/api/v1/search_by_date?tags=story";

export default {
  created() {},
  data() {
    return {
      res: "",
      ids: "",
      workingCountry: "",
      opeartionName: "",
      isSelect: "",
      operationId: "",
      dialog: false,
      selected: ["email"],
      toggleClass: false,
      toggle_exclusive: 0,
      assigIdShow: 0,
      subject: null,
      message: "",
      loading: false,
      processing: false,
      commentsListing: [],
      customerId: "",
      valid: true,
      x: {
        error: "",
        message: "",
        loading: false,
        success: false,
        valid: true,
        currentUser: "",
        disableAdd: false,
      },

      items: [],
      AssignmentId: [],

      customers: [],
    };
  },
  components: {
    ErrorBox,
    SuccessDialog,
    vueCustomScrollbar,
  },
  props: {
    id: String,
  },

  watch: {
    communicateDialog(val) {},
  },
  methods: {
    async check() {
      this.dialog = true;

      if (localStorage.getItem("workingCountries")) {
        let workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.x.customerCurrency = key.currency;
            this.workingCountry = key.value;
            this.fetchShippers(key.value);
          }
        });
      }
    },
    fetchShippers(val) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = { workingCountry: [val], section: "job" };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/shippers`, body, config)
        .then((response) => {
          const { data = null } = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i].fullName = data[i].name + "(" + data[i].companyName + ")";
          }

          data.forEach((element) => {
            if (element.companyName) {
              this.customers.push({
                text: element.fullName,
                value: element._id,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
              });
            } else {
              this.customers.push({
                text: element.name,
                value: element._id,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
              });
            }
          });
        });
    },
    async customerChange() {
      console.log("tryyyy", this.customerId);
      if (!this.customerId) {
        console.log("tryyyy", this.customerId);
        this.x.error = "Please select customer before moving ahead.";
        return false;
      }
      this.processing = true;
      let url = "/assignments/updateJobWithNewCustomer";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        customerId: this.customerId,
        jobId: this.id,
        // assignmentId: this.ids,
      };
      this.axios
        .post(this.constants.apiUrl + url, body, config)
        .then((response) => {
          this.processing = false;
          this.$eventBus.$emit("refresh-job");
          this.close();
        });

      //this.dialog=true;
    },

    close() {
      // this.$emit("pagination-load", true);
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.comment__form {
  padding: 11px !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: #fff;
}
.v-tabs__slider-wrapper {
  display: none;
}
form .layout.wrap {
  border: 1px solid rgba(26, 54, 126, 0.125) !important;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  margin: 20px 0;
}
.comment__form {
  overflow: auto;
}
.p--25 {
  padding: 25px !important;
}
.comment__form[data-v-404971df] {
  padding: 3px 10px !important;
}
.comment__form label.v-label.theme--light {
  font-size: 13px !important;
}
.v-select.v-select--chips .v-select__selections {
  height: 75px;
  overflow: auto;
}
.v-chip--removable .v-chip__content {
  font-size: 11px;
}
.ps__thumb-y {
  top: 0px;
  height: 32px;
}
.error {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: red;
  background: transparent !important;
}
.blue-button {
  border-radius: 5px;
  background-color: rgb(0, 140, 255) !important;
  color: #ffffff !important;
  border: 2px solid rgb(0, 140, 255) !important;
  padding: 2px 10px;
}
.assign-button {
  border-radius: 5px;
  background-color: rgb(0, 140, 255) !important;
  color: #ffffff !important;
  border: 2px solid rgb(0, 140, 255) !important;
  padding: 2px 2px;
}
.commentbtn {
  position: absolute;
  right: 300px;
  top: 8px;
}
.bg-grey {
  background-color: #bdc3c7;
}
.theme--light.v-chip {
  background: #feebd9 !important;
  color: rgb(248, 159, 69) !important;
}
.tag-input span.chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
}

.tag-input span.v-chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
  padding-left: 7px;
}

.tag-input span.v-chip::before {
  content: "label";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
}
.info {
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.chat-history {
  height: 180px;
  padding: 4px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  overflow-x: hidden;
}
.v-card__title.bg-grey {
  background: #f5f5f5 !important;
  border-bottom: 1px solid #e6e6e6;
}
.bg-gray {
  background: #ebebeb;
}
form .layout.wrap {
  border-radius: 0 px;
  padding: 15 px;
}
.tag-people {
  height: 150px;
  overflow: hidden;
}
.send-layout {
  background: #ffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px 0px 0px 10px;
  /* min-width: 320px;
      max-width: 320px; */
  width: 80%;
  float: right;
  margin-right: 11px;
  overflow-wrap: break-word;
}
.received-layout {
  background: white;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 0px 10px 10px 0px;
  /* min-width: 320px;
      max-width: 320px; */
  width: 100%;
  float: left;
  overflow-wrap: break-word;
}
.flex-tag {
  overflow-y: auto;
  height: 100px;
  max-height: 150px;
}
.tag-orange {
  color: #ff8c00;
  font-size: 13px;
}
.tag-gray {
  color: #b1abab;
  font-size: 11px;
}
.tag-blue {
  color: #1675f1;
  font-size: 12px;
}
.tag-black {
  color: black;
  font-size: 12px;
}
.gray-dark {
  color: #4d4d4d;
}
.chat-history .row p {
  margin-bottom: 5px;
}
.center {
  text-align: center;
}
.v-autocomplete {
  overflow-x: hidden;
}
.active {
  background-color: orange !important;
  color: #ffffff !important;
}
form .layout.wrap {
  padding: 10px 20px !important;
}
.comment__form {
  overflow: auto;
}
.v-card__title span.headline {
  font-size: 17px !important;
  line-height: 20px !important;
  display: inline-block;
}
.chat-history[data-v-404971df] {
  height: 115px;
}
.v-card__title span.headline[data-v-404971df] {
  padding: 0 20px;
}
</style>
