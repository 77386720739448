<template>
  <div class="log">
    <v-btn
      @click.stop="dialog = true"
      color="orange darken-2"
      @click="getBidDetails"
      class="white--text ml-1"
      >View Bid</v-btn
    >

    <v-dialog v-model="dialog" max-width="65%" persistent>
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title class="pd5"
              >Viewing Bid for Job id - {{ this.$props.jobId }} with Assignment
              id-{{ this.$props.assignmentId }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>

          <v-spacer class>
            <v-card-text class="nospace">
              <v-data-table
                id="jobsTable"
                hide-actions
                :headers="headers"
                :items="items.list"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    <span class="fontSize">Driver Id - </span
                    >{{ props.item.drivers[0].driverId }}
                    <br />
                    {{ props.item.drivers[0].name }}
                    <br />
                    {{
                      props.item.drivers[0].countryCode +
                      " " +
                      props.item.drivers[0].phoneNo
                    }}
                  </td>

                  <td>{{ getTime(jobStartDate) }}</td>
                  <td>
                    {{ props.item.bidAmount }} {{ props.item.bidCurrency }}
                  </td>
                  <td>{{ getDateTime(props.item.bidDate) }}</td>
                  <td>
                    {{ props.item.advanceAmount }}
                    {{ props.item.bidCurrency }}
                  </td>
                  <td v-if="props.item.status == 'PENDING'">
                    <span class="pending">{{ props.item.status }}</span>
                  </td>
                  <td v-else-if="props.item.status == 'ACCEPTED'">
                    <span class="accepted">{{ props.item.status }}</span>
                  </td>
                  <td v-else-if="props.item.status == 'REJECTED'">
                    <span class="rejected">{{ props.item.status }}</span>
                  </td>
                  <td v-else>
                    <span class="assigned">{{ props.item.status }}</span>
                  </td>
                  <td
                    v-if="
                      !props.item.isAccept && props.item.status == 'PENDING'
                    "
                  >
                    <v-btn
                      target="_blank"
                      color="orange darken-3"
                      class="white--text"
                      @click="
                        open(
                          props.item.assignmentId,
                          props.item.uniqueId,
                          props.item
                        )
                      "
                      >Accept</v-btn
                    >
                  </td>
                  <td v-else-if="props.item.status == 'REJECTED'">-</td>
                  <td v-else>-</td>
                </template>
              </v-data-table>
              <v-dialog
                v-model="dialogconfirm"
                persistent
                :max-width="options.width"
                :style="{ zIndex: options.zIndex }"
                @keydown.esc="cancel"
              >
                <v-card>
                  <v-toolbar dark :color="options.color" dense flat>
                    <v-toolbar-title class="white--text">{{
                      title
                    }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text v-show="!!message" class="pa-4">{{
                    message
                  }}</v-card-text>
                  <v-card-actions class="pt-0">
                    <v-btn color="primary darken-1" text @click.native="agree"
                      >Yes</v-btn
                    >
                    <v-btn color="grey" text @click.native="cancel"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>
<script>
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import { bidList } from "@/constants/api-urls.js";
import { jobDetailAssignmentViewBidList } from "@/constants/datatable-headers.js";
import { StorageKeys } from "../../constants/constants";

export default {
  components: {
    ErrorBox,
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  data() {
    return {
      message: "",
      title: "",
      data: null,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialogconfirm: false,
      jobStartDate: this.$props.startDate,
      //
      error: "",

      loading: false,
      x: {
        error: null,
      },

      processing: false,

      dialog: false,
      disStatus: false,

      rules: {
        required: (value) => !!value || "This field is required.",
      },
      items: [],
      headers: jobDetailAssignmentViewBidList,
    };
  },
  props: {
    id: String,
    jobId: Number,
    startDate: Number,

    assignmentId: Number,
  },
  created() {},

  methods: {
    open(val, type, data) {
      this.ids = val;
      this.uniqueBidId = type;
      this.data = data;
      this.dialogconfirm = true;
      this.title = "Confirmation";
      this.message = "Are you sure want to accept?";
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.accept(this.ids, this.uniqueBidId, this.data);
    },
    cancel() {
      this.resolve(false);
      this.dialogconfirm = false;
    },

    accept(assignId, BidId, data) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      this.processing = true;
      this.loading = true;
      let url = bidList.acceptDriverBid;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.ids,
        uniqueBidId: BidId,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialogconfirm = false;
          this.identify(localStorage.getItem("email"));
          this.track("Bid Accepted", {
            "Job Id": this.$props.jobId || "",
            // "Customer Name": this.address.customer,
            "Assignment Id": data.assignNumber || "",
            "Bid Date": data.bidDate || "",
            "Working Country": data.drivers[0].workingCountry || "",
            "Driver Id": data.drivers[0].driverId || "",
            "Driver Name": data.drivers[0].name || "",
            "Bid Amount": data.bidAmount || "",
            "Advance Amount": data.advanceAmount || "",
          });

          // this.items = response.data.data;

          this.isAcceptBid = true;

          this.processing = false;
          this.getBidDetails();
          this.loading = false;
        },
        (error) => {
          this.error = error.response.data.message;

          this.dialogconfirm = false;

          this.loading = false;
        }
      );
    },
    getTime(time) {
      return moment.unix(time).format("DD-MM-YYYY");
    },
    getDateTime(time) {
      return moment(time).format("DD-MM-YYYY , H:MM ");
    },

    getBidDetails() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      this.dialog = true;
      this.dialogconfirm = false;
      //   if (this.processing) {
      //     return;
      //   }
      this.processing = true;
      this.loading = true;
      let url = bidList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.$props.id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = true;
          this.dialogconfirm = false;
          this.items = response.data.data;

          this.processing = false;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Assignments";
          this.processing = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.assigned {
  background-color: orange !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.pending {
  background-color: grey !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
}
.rejected {
  background-color: red !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.accepted {
  background-color: green;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.v-dialog {
  overflow-y: auto !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;

  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.track-ass-btn[data-v-3cb02aee] {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
  margin-right: 5px;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.max {
  max-width: 70%;
}
.fix-width-td {
  max-width: 200px;
  word-break: break-all;
}
.count {
  background-color: green;
  color: white !important;
  font-size: 14px !important;

  border-radius: 5px;
}
.counts {
  background-color: red;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;

  border-radius: 5px;
}
.countss {
  background-color: grey;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;

  border-radius: 5px;
}
.flexx {
  display: flex;
}
.pd5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.timer-style {
  text-align-last: center;
  font-size: large;
}
.fontSize {
  font-style: italic;
}
</style>
