<template>
  <div class="messagebtn">
    <v-btn
      style="background: orange !important"
      flat
      prepend-icon
      :disabled="processing || status == 10 || viewOnly == 'view'"
      color="white darken-1"
      @click.stop="check()"
      >Send Message To Customer</v-btn
    >
    <v-dialog v-model="dialog" persistent max-width="40%">
      <v-spacer>
        <v-form ref="form" onSubmit="return false;">
          <v-card>
            <v-card-title>
              <span class="headline">Send Message to Customer</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md12>
                    <p>Select Mode of Communication</p>
                    <v-checkbox
                      v-model="selected"
                      label="SMS"
                      value="sms"
                      class="message"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="selected"
                      label="Email"
                      value="email"
                      class="message"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex v-if="selected.indexOf('email') >= 0" xs12 sm6 md12>
                    <v-text-field label="Subject" v-model="subject" />
                  </v-flex>
                  <v-flex md12>
                    <v-textarea
                      autocomplete="message"
                      label="Type your message here"
                      v-model="message"
                      rows="2"
                    ></v-textarea>
                  </v-flex>

                  <small class="success" v-show="this.res">{{
                    this.res
                  }}</small>
                  <small class="error" v-show="this.x.error">{{
                    this.x.error
                  }}</small>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-text>
              <v-flex v-if="message">Message Preview:</v-flex>
              <v-flex
                v-if="message"
                xs12
                sm12
                md12
                class="break_long_text"
                style="word-break: break-all"
                >Dear {{ userName }},<br />
                Subject - {{ subject }} <br /><br />{{ message }}</v-flex
              >
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="dialog = false" :disabled="processing">Close</v-btn
              >
              <v-btn color="blue darken-1" flat @click="sendMessage" :disabled="processing"
                >Send</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-spacer>
      <!--            <ErrorBox :error="x.error" />-->
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { sendMessage } from "@/constants/api-urls.js";

export default {
  data() {
    return {
      res: "",
      opeartionName: "",
      dialog: false,
      selected: ["email"],
      subject: null,
      message: null,
      loading: false,
      processing: false,
      x: {
        error: "",
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    ErrorBox,
  },
  props: ["userId", "userName", "status", "viewOnly"],
  methods: {
    async check() {
      this.processing=true;
      this.operationName = "send-message-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
          this.processing=false;
        this.dialog = true;
      } else {
          this.processing=false;
        return;
      }
    },
    sendMessage() {
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let type = "";
        if (this.selected.length === 2) {
          type = "both";
        } else if (this.selected.length === 1) {
          type = this.selected[0];
        } else if (!this.selected.length) {
          this.x.error = "Please select at least on mode.";
          setTimeout(() => (this.x.error = ""), 2000);
          this.processing = false;
          return false;
        }

        if (!this.subject) {
          this.x.error = "Please provide a suitable subject.";
          setTimeout(() => (this.x.error = ""), 2000);
          this.processing = false;
          return false;
        }

        if (!this.message) {
          this.x.error = "Please provide a message.";
          setTimeout(() => (this.x.error = ""), 2000);
          this.processing = false;
          return false;
        }
        this.dialog = true;
        let url = sendMessage;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          userId: this.userId.toString(),
          type: type,
          message: this.message,
        };
        if (type === "both" || type === "email") {
          body.subject = this.subject;
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.processing = false;
            this.res = "Customer has been successfully communicated";
            this.selected = ["email"];
            this.message = "";
            this.subject = "";
            setTimeout(() => (this.res = ""), 3000);
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.x.error = "Unable to Send Message.";
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.m--t-10 {
  margin-top: 10px;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: #fff;
}
.error {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  color: #fff;
}
.messagebtn {
  position: absolute;
  right: 20px;
  /* top: 25px;*/
}
</style>
