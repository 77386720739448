import { render, staticRenderFns } from "./EditPhysicalDoc.vue?vue&type=template&id=4f01a086&scoped=true&"
import script from "./EditPhysicalDoc.vue?vue&type=script&lang=js&"
export * from "./EditPhysicalDoc.vue?vue&type=script&lang=js&"
import style0 from "./EditPhysicalDoc.vue?vue&type=style&index=0&id=4f01a086&lang=scss&scoped=true&"
import style1 from "./EditPhysicalDoc.vue?vue&type=style&index=1&id=4f01a086&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f01a086",
  null
  
)

export default component.exports