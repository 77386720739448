<template>
  <div class="log">
    <v-icon
      color="orange darken-1"
      class="white--text"
      flat
      @click.stop="dialog = false"
      @click="check()"
      :disabled="status <= 1"
      target="_blank"
      title="View Proof"
      >visibility</v-icon
    >
    <!-- <v-btn
      class="ma-2"
      outline
      color="orange"
      @click.stop="dialog = false"
      @click="check()"
      :disabled="status <= 1"
      target="_blank"
    >
      View
    </v-btn> -->
    <v-dialog v-model="dialog" max-width="60%" persistent>
      <v-card class="nospace">
        <v-toolbar
          dark
          style="background-color: #dcdcdc; color: black; padding-left: 10px"
          flat
        >
          <v-toolbar-title>View Proof of delivery</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab small @click="dialog = false">
            <v-icon dark> close </v-icon>
          </v-btn>

          <!-- <v-btn icon>
              <v-icon
                class="heading grey--text text--darken-4"
                @click.prevent="dialog = false"
                >close</v-icon
              >
            </v-btn> -->
        </v-toolbar>

        <v-progress-linear
          indeterminate
          color="trukkin-theme darken-2"
          v-show="processing"
        ></v-progress-linear>
        <v-spacer>
          <v-card-text class="nospace pt-4">
            <v-data-table
              id="ListTable"
              hide-actions
              :headers="headers"
              :items="items"
              style="word-break: break-all"
            >
              <template slot="items" slot-scope="props">
                <td
                  v-if="props.item.description"
                  style="word-break: break-all; width: 30%"
                >
                  {{ props.item.description }}
                </td>
                <td v-else>-</td>
                <td v-if="props.item.updatedAt">
                  {{ getTime3(props.item.updatedAt) }}
                </td>
                <td v-else>-</td>
                <td v-if="props.item.uploadedBy">
                  {{ props.item.uploadedBy }}
                </td>
                <td v-else>-</td>
                <td style="width: 30%">
                  <v-layout style="width: 100px">
                    <ul
                      style="
                        position: relative;
                        list-style-type: none;
                        display: inline-block;
                        width: 500px;
                      "
                    >
                      <li
                        :key="i"
                        style="display: inline-block"
                        v-for="(doc, i) in props.item.document"
                        class="list"
                        ref="documentList"
                      >
                        <a
                          :href="doc"
                          class="document-link"
                          style="text-decoration: none; outline: none"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                        >
                          <img
                            src="../../assets/pfd.png"
                            height="40"
                            width="32"
                          />
                        </a>
                        <a
                          :href="doc"
                          style="text-decoration: none; outline: none"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../assets/docimg.webp"
                            height="40"
                            width="40"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-layout>
                </td>
              </template>
            </v-data-table>

            <span class="page-count-span totalpages"
              >Total Pages - {{ totalPages }}</span
            >

            <PaginationButtons
              v-if="dialog"
              :url="`/dmsAdmin/viewProofOfDeliveryForAssignment`"
              :payload="payload"
              :callback="callback"
              :componentKey="componentKey"
              v-on:pagination-payload="paginationPayload"
            />
            <!-- <success-dialog
              :content="x.message"
              :show="x.success"
              :onclose="closeMessage"
            /> -->
            <v-snackbar
              :timeout="3000"
              color="red darken-2"
              v-model="error"
              class="white--text"
              v-if="error"
              >{{ error }}</v-snackbar
            >
          </v-card-text>
        </v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import { proofOfDeliveryListDriver } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import moment from "moment";
import { StorageKeys, JobTypes, JobListTypes } from "../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import addProofOfDelivery from "../Pop-ups/addProofOfDelivery";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.$eventBus.$on("delivery-proof-dialog", () => {
      this.componentKey += 1;
    });
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });

    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    addProofOfDelivery,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
    assignmentId: String,
    status: Number,
  },

  data() {
    return {
      workingCountry: null,
      JobTypes: [],
      jobType: "",
      error: "",
      loading: true,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {},

      headers: proofOfDeliveryListDriver,
      items: [],
    };
  },
  watch: {},
  methods: {
    check() {
      this.dialog = true;
      this.payload = {
        skip: "0",
        limit: "15",
        assignmentId: this.assignmentId,
        workingCountry: this.workingCountry,
      };
    },
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    callback(res) {
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.loading = false;
      this.$eventBus.$emit("off-load");
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}

.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
</style>
