<template>
  <div>
    <button
      class="blue-button"
      flat
      prepend-icon
      color="white darken-1"
      style="padding: 5px !important"
      @click.stop="open()"
    >
      View Proof
    </button>

    <v-dialog v-model="dialog" max-width="30%">
      <v-form ref="form" onSubmit="return false;">
        <v-card>
          <v-card-title>
            <span class="headline">Proof </span>
          </v-card-title>
          <v-card-text>
            <v-spacer>
              <v-container>
                <v-layout wrap>
                  <v-flex xs12 style="text-align: center">
                    <ul class="listClass">
                      <li
                        :key="i"
                        v-for="(doc, i) in deliveryProof"
                        class="list"
                        ref="documentList"
                        style="display: inline; text-align: center"
                      >
                        <a
                          :href="doc"
                          class="document-link"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                        >
                          <img src="../../assets/pfd.png" />
                        </a>
                        <a
                          :href="doc"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../assets/docimg.webp"
                            height="72"
                            width="75"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-flex>
                </v-layout>
              </v-container>
              <small class="success" v-show="this.res">{{ this.res }}</small>
            </v-spacer>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="dialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
      <ErrorBox :error="x.error" />
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { getPaymentProofs } from "../../constants/api-urls";
export default {
  data() {
    return {
      document: [],
      res: "",
      dialog: false,
      selected: ["email"],
      message: null,
      loading: false,
      x: {
        error: "",
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    ErrorBox,
  },
  props: {
    deliveryProof: Array,
    type: String,
    assignId: String,
  },
  methods: {
    open() {
      if (this.$props.type === "finalPayProof") {
        this.getDoc();
      } else {
        this.dialog = true;
      }
    },
    getDoc() {
      this.error = null;
      this.loading = true;
      let url = getPaymentProofs;

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        assignmentId: this.assignId,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = true;
          this.deliveryProof = response.data.data[0].proof;
          this.processing = false;
        },
        (error) => {
          this.processing = false;
          this.error = error.response.data.message;
        }
      );
    },
  },
};
</script>
<style scoped>
.listClass {
  text-align: center;
  padding-left: 0px !important;
  display: inline !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: block;
}
.document-link {
  text-decoration: none;
}
.blue-button {
  border-radius: 5px;
  background-color: blue !important;
  color: #ffffff !important;
  border: 2px solid blue !important;
}
</style>
