import { render, staticRenderFns } from "./EditAdditionalCharges.vue?vue&type=template&id=008e6c38&scoped=true&"
import script from "./EditAdditionalCharges.vue?vue&type=script&lang=js&"
export * from "./EditAdditionalCharges.vue?vue&type=script&lang=js&"
import style0 from "./EditAdditionalCharges.vue?vue&type=style&index=0&id=008e6c38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008e6c38",
  null
  
)

export default component.exports