var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-container',{staticClass:"p-16",attrs:{"fluid":""}},[_c('v-divider',{staticClass:"my-1 mb-4"}),_c('div',{staticClass:"heading orange--text"},[_vm._v("Pricing details")]),_c('v-layout',{staticClass:"py-4",attrs:{"row":""}},[(_vm.pricing)?_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Customer Price","type":"number","disabled":_vm.readOnly},model:{value:(_vm.pricing.customerCost),callback:function ($$v) {_vm.$set(_vm.pricing, "customerCost", $$v)},expression:"pricing.customerCost"}}),(
              _vm.detail.jobStatus != 2 &&
              _vm.detail.jobStatus != 9 &&
              _vm.detail.jobStatus != -1
            )?_c('v-flex',[(!_vm.detail.isJobActivatedByScript)?_c('div',[_c('customerAccepted',{directives:[{name:"permissions",rawName:"v-permissions",value:('edit-price'),expression:"'edit-price'"}],attrs:{"jobId":_vm.detail.jobId,"detail":_vm.detail,"working":_vm.workingcountry,"viewOnly":_vm.viewOnly,"subJobDetail":_vm.subJobDetail}})],1):_vm._e()]):_vm._e()],1):_vm._e(),_c('v-flex',{attrs:{"xs1":""}}),(_vm.pricing)?_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{attrs:{"label":"Driver Price ","type":"number","disabled":_vm.readOnly},model:{value:(_vm.pricing.driverCost),callback:function ($$v) {_vm.$set(_vm.pricing, "driverCost", $$v)},expression:"pricing.driverCost"}}),(
              _vm.detail.jobStatus == 2 ||
              _vm.detail.jobStatus == 5 ||
              _vm.detail.jobStatus == 6 ||
              _vm.detail.jobStatus == 7 ||
              _vm.detail.jobStatus == 8 ||
              _vm.detail.jobStatus == 9 ||
              _vm.detail.jobStatus == 11 ||
              _vm.detail.jobStatus == 12
            )?_c('v-flex',[(!_vm.detail.isJobActivatedByScript)?_c('div',[(!_vm.subJobDetail.isCustomerReceiptInitiated)?_c('editDriverPrice',{directives:[{name:"permissions",rawName:"v-permissions",value:('edit_driver_price'),expression:"'edit_driver_price'"}],attrs:{"jobId":_vm.detail.jobId,"detail":_vm.detail,"working":_vm.workingcountry,"viewOnly":_vm.viewOnly,"subJobDetail":_vm.subJobDetail}}):_vm._e()],1):_vm._e()]):_vm._e()],1):_vm._e(),_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs3":""}},[(_vm.pricing.dutyCharges)?_c('v-text-field',{attrs:{"label":"Total Duty Amount","type":"number","disabled":_vm.readOnly},model:{value:(_vm.pricing.dutyCharges),callback:function ($$v) {_vm.$set(_vm.pricing, "dutyCharges", $$v)},expression:"pricing.dutyCharges"}}):_vm._e()],1)],1)],1),_c('success-dialog',{attrs:{"content":_vm.x.message,"show":_vm.x.success,"onclose":_vm.closeMessage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }