<template>
  <div class="log">
    <button
      small
      type="button"
      @click="open()"
      v-if="
        section === 'assignmentList' &&
        assignDetails.isReadyForFinalPayment &&
        assignDetails.assignmentStatus != 4 &&
        assignDetails.assignmentStatus != 3
      "
      class="track-ass-btn green-button"
    >
      Edit
    </button>
    <v-btn
      @click="open()"
      v-if="
        section === 'jobDetails' &&
        assignDetails.isReadyForFinalPayment &&
        assignDetails.assignmentStatus != 4 &&
        assignDetails.assignmentStatus != 3
      "
      color="green darken-2"
      class="white--text ml-1"
      >Edit Ready To Pay & Doc Collected</v-btn
    >
    <!-- <button
      small
      type="button"
      @click="open()"
      v-if="
        section === 'jobDetails' &&
          assignDetails.isReadyForFinalPayment &&
          assignDetails.assignmentStatus != 4
      "
      class="track-ass-btn"
    >
      Edit Ready To Pay
    </button> -->

    <v-dialog class="full__Size" v-model="dialog" max-width="900" persistent>
      <v-form
        ref="form"
        onsubmit="return false;"
        v-model="valid"
        lazy-validation
      >
        <v-card class="comments-view">
          <v-toolbar fixed flat style="height: 50px">
            <v-toolbar-title
              >Edit Document Collected & Final Payment Ready To
              Pay</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer style="max-height: 750px !important">
            <!-- <div class="heading pl-4 pt-4">
            Have you collected the physical document from the driver ?
          </div> -->

            <div class="heading pl-4 pt-1">
              <v-layout>
                <v-flex xs12 md12 pb-1 pt-1>
                  Have you collected the physical document from the driver ?
                  <br />
                  <div class="labelheading">
                    <label>
                      If yes, fill driver details, upload proof, enter date and
                      time when it was collected</label
                    >
                  </div>
                </v-flex></v-layout
              >
              <v-layout>
                <v-flex xs12 md12 pt-2>
                  <div>
                    Driver’s Payment credit period :
                    <span style="color: black" class="heading"
                      >{{
                        driverCreditDuration && driverCreditDuration != 0
                          ? driverCreditDuration
                          : ""
                      }}
                      {{ driverCreditDuration ? "days" : "N.A" }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-1">
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Total Driver Price :
                    <span style="color: black" class="heading"
                      >{{
                        assignDetails.paymentAmount +
                        (assignDetails.totalAdditionalAmount
                          ? assignDetails.totalAdditionalAmount
                          : 0)
                      }}
                      {{ assignDetails.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Driver Price :
                    <span style="color: black" class="heading"
                      >{{ assignDetails.paymentAmount }}
                      {{ assignDetails.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Additional Charges :
                    <span style="color: black" class="heading break-word">
                      {{ assignDetails.totalAdditionalAmount || "0" }}
                      {{ assignDetails.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-1">
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Advance Paid :
                    <span style="color: black" class="heading"
                      >{{ assignDetails.totalAdvancePaidAmount || "0" }}
                      {{ assignDetails.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Amount Paid Till Date:
                    <span style="color: black" class="heading"
                      >{{
                        Number(
                          Number(
                            (assignDetails.amountPaidToDriver
                              ? assignDetails.amountPaidToDriver
                              : 0) +
                              Number(
                                assignDetails.totalAdvancePaidAmount
                                  ? assignDetails.totalAdvancePaidAmount
                                  : 0
                              )
                          ).toFixed(2) || "0"
                        )
                      }}
                      {{ assignDetails.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Pending Amount :
                    <span style="color: black" class="heading"
                      >{{
                        assignDetails.paymentAmount -
                          assignDetails.totalAdvancePaidAmount -
                          assignDetails.amountPaidToDriver +
                          assignDetails.totalAdditionalAmount || "0"
                      }}
                      {{ assignDetails.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs12>
                  <v-tabs
                    style="margin-bottom: 20px"
                    v-model="tabStatus"
                    fixed-tabs
                    background-color="darkorange"
                    dark
                    class="main__tabs"
                  >
                    <v-tab href="#Driver"> Driver Payment Details</v-tab>
                    <v-tab href="#Docs"> Edit Driver Docs </v-tab>
                  </v-tabs>
                </v-flex>
                <!-- <v-flex xs3 class="pr-1 pt-1 pb-3 pt-100">
                  <button
                    class="track-ass-btn blue-button"
                    flat
                    prepend-icon
                    color="white darken-1"
                    @click.stop="goToProof(assignDetails._id)"
                  >
                    Edit Driver Docs
                  </button>
                </v-flex> -->
              </v-layout>

              <v-layout v-if="tabStatus === 'Driver'">
                <v-flex xs12>
                  <v-layout v-if="tabStatus === 'Driver'">
                    <v-flex xs12 md12>
                      <v-radio-group
                        class="mt-0 pt-0"
                        v-model="paymentMode"
                        row
                        :mandatory="true"
                        :rules="[rules.required]"
                      >
                        <div>
                          <div class>
                            <v-layout>
                              <v-radio
                                label="Cash"
                                value="Cash"
                                color="orange"
                                class="col-md-6"
                              ></v-radio>

                              <v-radio
                                label="Bank Transfer"
                                value="Bank Transfer"
                                color="orange"
                                class="col-md-6"
                              ></v-radio>
                              <!-- <v-radio
                            label="Demand Draft"
                            value="Demand Draft"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>

                          <v-radio
                            label="Cheque"
                            value="Cheque"
                            color="orange"
                            class="col-md-6"
                          ></v-radio> -->
                            </v-layout>
                          </div>
                        </div>
                      </v-radio-group>
                      <!-- <v-select
                    :items="[
                      'Cash',
                      'Cheque',
                      'Demand Draft(DD)',
                      'Bank Transfer',
                    ]"
                    v-model="paymentMode"
                    class="pt-0 currencyTitle"
                    label="Mode of Payment"
                    placeholder="Mode of Payment"
                    :rules="[rules.required]"
                  /> -->
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="this.paymentMode == 'Cash'">
                    <!-- <v-text-field
                    label="Voucher No."
                    placeholder="Enter Voucher No."
                    v-model="voucher"
                    class="pt-0 currencyTitle"
                    :rules="[rules.noWhiteSpace, rules.required]"
                  /> -->
                    <v-flex md5 class="payadvance">
                      <v-flex md12 pb-3>
                        <v-text-field
                          v-model="accountHolderName"
                          label="Account Holder Name"
                          placeholder="Enter account holder name"
                          class="pt-0 currencyTitle"
                          :value="accountHolderName"
                        ></v-text-field>
                      </v-flex>

                      <v-layout>
                        <v-flex md12 class="payadvance">
                          <v-text-field
                            v-model="ifscCode"
                            label="IBAN Number"
                            placeholder="Enter IBAN number"
                            class="pt-0 currencyTitle"
                            maxlength="25"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex md6 pl-5 class="payadvance">
                      <v-layout>
                        <v-flex md12 pb-3 class="payadvance">
                          <v-text-field
                            v-model="accountNumber"
                            label="Account Number"
                            placeholder=" Enter Account Number"
                            class="pt-0 currencyTitle"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-layout>
                          <v-flex md12 class="payadvance">
                            <v-text-field
                              v-model="swiftNumber"
                              label="Swift Number"
                              class="pt-0 currencyTitle"
                              placeholder="Enter Swift Number"
                              :value="swiftNumber"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>

                    <v-flex v-if="this.paymentMode == 'Cheque'" xs12 sm5 pb-3>
                      <v-text-field
                        label="Cheque No."
                        placeholder="Enter Cheque No."
                        v-model="cheque"
                        class="pt-0 currencyTitle"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      />
                    </v-flex>

                    <v-flex
                      v-if="this.paymentMode == 'Demand Draft'"
                      xs12
                      sm5
                      pb-3
                    >
                      <v-text-field
                        label="Draft No."
                        placeholder="Enter Draft No."
                        class="pt-0 currencyTitle"
                        v-model="draftNo"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="
                      this.paymentMode == 'Bank Transfer' &&
                      tabStatus === 'Driver'
                    "
                  >
                    <v-flex md5 class="payadvance">
                      <v-flex md12 pb-3>
                        <v-text-field
                          v-model="accountHolderName"
                          label="Account Holder Name"
                          placeholder="Enter account holder name"
                          class="pt-0 currencyTitle"
                          :value="accountHolderName"
                          :rules="[rules.noWhiteSpace, rules.required]"
                        ></v-text-field>
                      </v-flex>

                      <v-layout>
                        <v-flex md12 class="payadvance">
                          <v-text-field
                            v-model="ifscCode"
                            label="IBAN Number"
                            placeholder="Enter IBAN number"
                            class="pt-0 currencyTitle"
                            :rules="[rules.noWhiteSpace, rules.required]"
                            maxlength="25"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex md6 pl-5 class="payadvance">
                      <v-layout>
                        <v-flex md12 pb-3 class="payadvance">
                          <v-text-field
                            v-model="accountNumber"
                            label="Account Number"
                            placeholder=" Enter Account Number"
                            class="pt-0 currencyTitle"
                            :rules="[rules.required, rules.noWhiteSpace]"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-layout>
                          <v-flex md12 class="payadvance">
                            <v-text-field
                              v-model="swiftNumber"
                              label="Swift Number"
                              class="pt-0 currencyTitle"
                              placeholder="Enter Swift Number"
                              :value="swiftNumber"
                              :rules="[rules.noWhiteSpace, rules.required]"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex xs5 pt-20>
                      <span class="muted"></span>
                      <v-menu
                        ref="FilterDateBool"
                        lazy
                        v-model="FilterDateBool"
                        class="date__icon"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        full-width
                        :nudge-right="40"
                        min-width="290px"
                        :return-value.sync="startDate"
                      >
                        <v-text-field
                          slot="activator"
                          :label="'Select Actual Date '"
                          placeholder="Select Actual Date"
                          v-model="startDate"
                          prepend-icon="event"
                          readonly
                          class="pt-0 currencyTitle"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-date-picker
                          v-model="startDate"
                          :max="dateToday"
                          class="minHeight"
                          @change="$refs.FilterDateBool.save(startDate)"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs5 pl-5 style="position: relative">
                      <label class="label__position muted caption"
                        >Select Actual Time</label
                      >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="clock_account_pay"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                      </svg>
                      <vue-timepicker
                        class="fonttime"
                        :minute-interval="30"
                        v-model="pickupTime"
                        input-width="28em"
                        :label="'Select Actual Time'"
                        placeholder="Select Actual Time"
                        close-on-complete
                        :rules="[rules.required]"
                      ></vue-timepicker>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md12 class="pr-4" pt-3>
                      <v-text-field
                        :rules="[rules.noWhiteSpace, rules.required]"
                        class="pt-0 currencyTitle"
                        label="Comments"
                        placeholder="Comments"
                        v-model="Comments"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <deliveryProofListPopup
                v-if="tabStatus === 'Docs'"
                :assignmentId="this.assignDetails._id"
              />
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="white darken-1"
                :disabled="processing"
                @click="close"
                >No</v-btn
              >
              <v-btn
                color="orange darken-2"
                class="white--text"
                :disabled="processing"
                @click="addProofOfDelivery()"
                >Yes</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
    <!-- <success-dialog
      content="The proof of additional charge has been uploaded successfully"
      :show="x.success"
      :onclose="() => (x.success = false)"
    /> -->
  </div>
</template>

<script>
import moment from "moment";
import {
  addProofOfDelivery,
  uploadImage,
  editFinalPaymentDriverDetails,
} from "../../constants/api-urls";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys } from "../../constants/constants";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import deliveryProofListPopup from "../JobDocuments/deliveryProofListPopup.vue";

export default {
  data() {
    return {
      paymentMode: "Cash",
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      paymentType: null,
      accountHolderName: "",
      tabStatus: "Driver",
      swiftNumber: "",
      startDate: "",
      FilterDateBool: false,
      pickupTime: null,
      draftNo: "",
      accountNumber: "",
      ifscCode: "",
      advanceAmount: "",
      debtorPaymentMode: "Cash",
      banks: [],
      loading: false,
      cheque: "",
      draftNo: "",
      operationName: "",
      processing: false,
      advanceAmount: "",
      dialog: false,
      voucher: "",
      document: [],
      valid: true,
      lazy: false,
      documents: [],
      Comments: "",
      error: null,
      driverAmount: "",
      x: {
        error: null,
      },
      banks: [],
      bankName: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
    };
  },
  props: {
    assignDetails: Object,
    section: String,
    driverCreditDuration: Number,
  },
  created() {
    // this.getActivityLog();
    this.customerStatus = localStorage.getItem("cust-status");
  },
  components: {
    SuccessDialog,
    VueTimepicker,
    deliveryProofListPopup,
  },
  methods: {
    open() {
      this.dialog = true;
      this.paymentMode = this.assignDetails.creditorPaymentMode;
      this.ifscCode = this.assignDetails.creditorIBANNumber
        ? this.assignDetails.creditorIBANNumber
        : "";

      this.swiftNumber = this.assignDetails.creditorSwiftNo
        ? this.assignDetails.creditorSwiftNo
        : "";

      this.accountHolderName = this.assignDetails.creditorAccountHolderName
        ? this.assignDetails.creditorAccountHolderName
        : "";

      this.accountNumber = this.assignDetails.creditorAccountNumber
        ? this.assignDetails.creditorAccountNumber
        : "";

      this.Comments = this.assignDetails.commentForFinalPayment
        ? this.assignDetails.commentForFinalPayment
        : "";
      this.startDate = moment(this.assignDetails.actualRequestedDate).format(
        "YYYY-MM-DD"
      );
      if (this.assignDetails.actualRequestedTime) {
        this.pickupTime = this.assignDetails.actualRequestedTime;
      } else {
        this.pickupTime = moment().format("HH:mm");
      }
    },
    goToProof(_id) {
      this.$router.push(`/documents/${_id}?type=Driver_Proof`);
    },
    removeImage(key) {
      this.document.splice(key, 1);
    },
    close() {
      this.dialog = false;
      this.document = [];
      this.processing = false;
      (this.type = ""), (this.comments = "");
      this.$refs.form.resetValidation();
      this.bankName = "";
      this.draftNo = "";
      this.paymentMode = "Cash";

      this.swiftNumber = "";
      this.cheque = "";
      this.voucher = "";
      this.ifscCode = "";
      this.accountHolderName = "";
      this.accountNumber = "";
      this.startDate = "";
      this.Comments = "";
      this.FilterDateBool = false;
      this.pickupTime = null;
    },
    uploadDocument(e) {
      this.x.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 10) {
              this.document.push(response.data.data.original);
            } else {
              this.x.error = "Maximum 10 proofs can be uploaded!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;
      var time = this.pickupTime;

      if (time != "" && !time.match(re)) {
        this.x.error = "Please select the valid time before moving ahead!";
        time.focus();
        this.processing = false;
        return false;
      }
    },
    addProofOfDelivery() {
      //this.processing = true;

      if (this.$refs.form.validate()) {
        this.processing = true;
        if (this.pickupTime) {
          this.checkTime();

          let currentDate = moment(new Date()).format("YYYY-MM-DD, HH:mm");
          let selectedDateTime = this.startDate + "," + this.pickupTime;

          if (
            new Date(currentDate).getTime() <
            new Date(selectedDateTime).getTime()
          ) {
            this.loading = false;
            this.processing = false;
            this.x.error =
              "Please select the past time and date before moving ahead!";
            return false;
          }
        }

        if (!this.Comments) {
          this.x.error = "Please enter comments in driver payment details";
          this.processing = false;
          this.loading = false;
          return false;
        }
        //this.processing = true;
        let url = editFinalPaymentDriverDetails;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          assignmentId: this.assignDetails._id,
          //   paymentDocument: this.document,
          requestedDate: this.startDate,
          creditorPaymentMode: this.paymentMode.toString(),
          requestedTime: this.pickupTime,
          comment: this.Comments.trim(),
        };

        if (this.bankName) {
          body.bankName = this.bankName;
        }
        if (this.ifscCode) {
          body.creditorIBANNumber = this.ifscCode;
        }
        if (this.swiftNumber) {
          body.creditorSwiftNo = this.swiftNumber;
        }
        if (this.accountHolderName) {
          body.creditorAccountHolderName = this.accountHolderName;
        }
        if (this.accountNumber) {
          body.creditorAccountNumber = this.accountNumber;
        }

        if (this.document.length) {
          body.proof = this.document;
        }
        if (this.cheque && this.paymentMode === "Cheque") {
          body.creditorChequeNo = this.cheque;
        }
        if (this.draftNo && this.paymentMode === "Demand Draft") {
          body.creditorDraftNo = this.draftNo;
        }
        if (this.voucher && this.paymentMode === "Cash") {
          body.creditorVoucherNo = this.voucher;
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.document = [];
            //this.$refs.fileUpload.value = "";
            this.processing = false;
            this.loading = false;
            this.close();

            this.$emit("ready-to-pay");
          },
          (error) => {
            this.processing = false;
            this.loading = false;
            this.x.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.date__font .v-label {
  font-size: 13px !important;
}
</style>

<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.document-link {
  text-decoration: none;
}
.ttt button select {
  border: none !important;
}
.imageClose {
  position: absolute;
  width: 15px;
  bottom: 80px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  color: #fff !important;
  line-height: 15px;
  top: -6px;
  left: 2px;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}

.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: inline-block;
  position: relative;
}
.thumb {
  width: 80px;
  height: 80px;
}

.custom-file-upload {
  border: none;
  display: inline-block;
  background: orange;
  font-size: 16px;
  color: #fff;
  padding: 10px 12px;
  cursor: pointer;
}

.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
.fonttime {
  font-size: 12px !important;
}

.fonttime .dropdown.drop-down {
  top: -14%;
}
.label__position {
  position: relative;
  top: 5px;
}
</style>
