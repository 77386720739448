<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-card-text class="nospace">
        <loading :active.sync="loading" />

        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex xs3 pt-2 v-if="invoiceValue"
            ><span class="dashboard-style">Invoice Value: </span
            >{{ invoiceValue }}</v-flex
          >
          <v-flex xs3 pt-2 v-if="invoiceNumber"
            ><span class="dashboard-style" v-if="jobCurrency == 'PKR'">
              Invoice Number:
            </span>
            <span class="dashboard-style" v-else>
              Customer Reference Number:</span
            >
            {{ invoiceNumber }}</v-flex
          >
          <v-flex xs3 pt-2 v-if="weight"
            ><span class="dashboard-style">Weight: </span
            >{{ weight }} tonnes</v-flex
          >
          <v-flex style="text-align: end" class="pb-2">
            <AddDocument
              v-permissions="'add-documents-jobs'"
              :assId="this.$route.params._id"
              :status="this.status"
              :currency="jobCurrency"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="TransporterListTable"
        hide-actions
        :headers="headers"
        :items="items"
        style="word-break: break-all"
      >
        <template slot="items" slot-scope="props">
          <td style="width: 20%">
            {{ getType(documentType, props.item.documentNumber) }}
          </td>
          <td style="width: 20%">{{ props.item.uploadedBy }}</td>
          <td style="width: 20%">
            <v-layout>
              <ul style="list-style: none; display: flex">
                <li
                  :key="i"
                  v-for="(doc, i) in props.item.document"
                  class="list"
                  ref="documentList"
                >
                  <a
                    :href="doc"
                    class="document-link"
                    style="text-decoration: none; outline: none"
                    v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                    target="_blank"
                  >
                    <img src="../../assets/pfd.png" height="40" width="32" />
                  </a>
                  <a
                    :href="doc"
                    style="text-decoration: none; outline: none"
                    class="document-link"
                    target="_blank"
                    v-else
                  >
                    <img
                      src="../../assets/docimg.webp"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
              </ul>
            </v-layout>
          </td>
          <td style="width: 20%">{{ props.item.status }}</td>
          <td>
            <button
              v-if="props.item.status == 'In-Process' && jobCurrency == 'PKR'"
              class="track-ass-btn m-t-10 m-l-10 red-button"
              title="Block Customer"
              :disabled="
                status == 1 || status == -1 || customerStatus == 'view'
              "
              @click="open(props.item.assignmentId, props.item._id)"
            >
              Reject
            </button>
            <button
              class="track-ass-btn"
              v-if="props.item.status == 'In-Process' && jobCurrency == 'PKR'"
              :disabled="
                status == 1 || status == -1 || customerStatus == 'view'
              "
              @click.stop="verified(props.item.assignmentId, props.item._id)"
            >
              Verify
            </button>
            <button
              v-if="
                (jobCurrency == 'AED' || jobCurrency == 'SAR') &&
                props.item.status == 'In-Process'
              "
              class="track-ass-btn m-t-10 m-l-10 red-button"
              title="Block Customer"
              :disabled="
                status == 1 ||
                status == 3 ||
                status == 4 ||
                status == -1 ||
                customerStatus == 'view'
              "
              @click="open(props.item.assignmentId, props.item._id)"
            >
              Reject
            </button>
            <button
              class="track-ass-btn"
              v-if="
                (jobCurrency == 'AED' || jobCurrency == 'SAR') &&
                props.item.status == 'In-Process'
              "
              :disabled="
                status == 1 ||
                status == 3 ||
                status == 4 ||
                status == -1 ||
                customerStatus == 'view'
              "
              @click.stop="verified(props.item.assignmentId, props.item._id)"
            >
              Verify
            </button>
          </td>
        </template>
      </v-data-table>

      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/dmsAdmin/getShipmentDocument`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <v-dialog v-model="cancelDialog" max-width="40%" persistent>
        <v-form ref="form">
          <v-card style="overflow-x: hidden">
            <v-card-title
              style="
                background-color: #f5f5f5;
                padding-bottom: 5px;
                padding-top: 5px;
              "
            >
              <span class="headline">Cancel Advance</span>
              <v-spacer></v-spacer>
              <v-btn icon @click.prevent="closeAdvance()">
                <v-icon class="heading grey--text text--darken-4">close</v-icon>
              </v-btn>
            </v-card-title>

            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
              class="title-layout-size"
            >
              <v-layout>
                <v-flex md12>
                  <v-card-title class="title-layout-size">
                    <span>Reason for cancelling Advance*</span>
                  </v-card-title>
                  <v-card-text class="title-layout-size">
                    <v-layout>
                      <v-flex pt-2 pr-1>
                        <v-select
                          class="pt-0 currencyTitle"
                          v-model="cancelType"
                          :items="cancellationType"
                          label="Select your option"
                          persistent-hint
                          :menu-props="{ offsetY: true }"
                          return-object
                          single-line
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          class="pt-0 currencyTitle"
                          v-if="cancelType.value == 'Other’s'"
                          type="text"
                          single-line
                          label="Enter your reason"
                          required
                          v-model="cancellationReason"
                          :value="cancellationReason"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-flex>
              </v-layout>
            </v-container>
            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
              class="title-layout-size"
            >
            </v-container>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="closeAdvance()"
                >Cancel</v-btn
              >
              <v-btn color="orange darken-1" flat @click="reject()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>
<script>
  import _ from "lodash";
  import { EventBus } from "../../event-bus.js";
  import Loading from "vue-loading-overlay";
  import { shipmentsDocList } from "@/constants/datatable-headers.js";
  import AddDocument from "./AddDocument";
  import SuccessDialog from "@/components/Common/SuccessDialog";
  import ErrorBox from "@/components/Common/ErrorBox";
  import moment from "moment";
  import {
    StorageKeys,
    JobTypes,
    JobListTypes,
    CancellationType,
  } from "../../constants/constants";
  import { async } from "q";
  import PaginationButtons from "@/components/Pagination/Pagination-New";
  import {
    jobDocument,
    verifyShipmentDocument,
    rejectShipmentDocument,
  } from "../../constants/api-urls";
  export default {
    created() {
      this.customerStatus = localStorage.getItem("cust-status");
      this.getShipmentType();
      this.cancellationType = CancellationType;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
            this.currency = key.currency;
          }
        });
      }
      this.$eventBus.$on("success-shipment-doc", () => {
        this.x.success = true;
      });
      this.$eventBus.$on("stop-load", () => {
        this.loading = false;
      });

      EventBus.$on("countryChanged", (val) => {
        this.workingCountry = val.value;
        if (this.$route.name == "documents") {
          this.$router.push(`/jobs`);
        }
        this.loading = true;
      });
      this.payload = {
        skip: "0",
        limit: "15",
        assignmentId: this.$route.params._id,
        workingCountry: this.workingCountry,
      };
      this.JobTypes = JobTypes;
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
    },
    components: {
      PaginationButtons,
      SuccessDialog,
      ErrorBox,
      Loading,
      AddDocument,
    },
    props: {
      loadingstop: Function,
      loader: Boolean,
    },

    data() {
      return {
        workingCountry: null,
        JobTypes: [],
        jobCurrency: "",
        currency: "",
        customerStatus: "",
        documentType: [],
        cancellationType: [],
        cancellationReason: "",
        cancelType: "",
        jobType: "",
        error: "",
        status: null,
        invoiceNumber: "",
        weight: "",
        invoiceValue: "",
        cancelDialog: false,
        cancelreason: "",
        assignID: "",
        id: "",
        assignmentId: "",
        loading: true,
        dialog: false,
        dialog2: false,
        processing: false,
        x: {
          message: "",
          loading: true,
          success: false,
          menu: false,

          error: null,
          missingField: null,
        },
        search: "",
        componentKey: 0,

        totalPages: "",

        payload: {},

        headers: shipmentsDocList,
        items: [],
      };
    },
    watch: {},
    methods: {
      getType(arr = [], val) {
        let finalEl = arr
          .filter((val) => val !== undefined)
          .find((el) => el.documentNumber === val);
        return finalEl ? finalEl.documentName : "NA";
      },
      getShipmentType() {
        this.loading = true;
        let Url = jobDocument.getShipmentTypeForPopUP;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          assignmentId: this.$route.params._id,
          skip: "0",
          limit: "15",
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.documentType = response.data.data.data;
            this.loading = false;
          },
          (error) => {
            this.success = false;
            this.loading = false;
            this.error = error.response.data.message;
            setTimeout(() => {
              this.error = "";
            }, 6000);
          }
        );
      },

      open(assignmentId, _id) {
        this.cancelDialog = true;
        this.id = assignmentId;
        this.assignmentId = _id;
      },
      closeAdvance() {
        this.cancelDialog = false;
        this.cancelreason = "";
        this.cancelType = "";
      },
      verified(assignmentId, _id) {
        this.loading = true;
        let Url = verifyShipmentDocument;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          id: _id,
          assignmentId: assignmentId,
          isVerify: true,
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.x.success = true;
            this.loading = false;
            //this.cancelDialog = false;
          },
          (error) => {
            this.success = false;
            this.loading = false;
            this.error = error.response.data.message;
            setTimeout(() => {
              this.error = "";
            }, 6000);
          }
        );
      },
      reject() {
        this.loading = true;
        if (!this.cancelType) {
          this.error = "Please select cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          this.loading = false;
          return false;
        }
        if (this.cancelType.value == "Other’s") {
          if (!this.cancellationReason) {
            this.error = "Please enter cancellation reason before moving ahead";
            setTimeout(() => {
              this.x.error = "";
            }, 3000);
            this.loading = false;
            return false;
          }
        }
        this.processing = true;
        let Url = rejectShipmentDocument;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          id: this.assignmentId,
          assignmentId: this.id,
          isReject: true,
        };
        if (this.cancelType.value) {
          if (this.cancelType.value == "Other’s") {
            body.cancelReason =
              this.cancelType.value + " - " + this.cancellationReason;
          } else {
            body.cancelReason = this.cancelType.value;
          }
        }
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.x.success = true;
            this.loading = false;
            (this.cancellationReason = ""),
              (this.cancelType = ""),
              (this.cancelDialog = false);
          },
          (error) => {
            this.x.success = false;
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      },
      closeMessage() {
        this.x.success = false;
        this.componentKey += 1;
      },
      callback(res) {
        //   localStorage.setItem("payloadTransporter", JSON.stringify(this.payload));
        this.loading = false;
        this.$eventBus.$emit("off-load");
        this.$eventBus.$emit("data", res.assignmentDetails[0]);
        this.items = res.list;
        this.jobCurrency = res.assignmentDetails[0].customerCurrency;
        if (res.assignmentDetails[0].invoiceValue) {
          this.invoiceValue = res.assignmentDetails[0].invoiceValue;
        }
        if (res.assignmentDetails[0].inoviceNumber) {
          this.invoiceNumber = res.assignmentDetails[0].inoviceNumber;
        }
        this.weight = res.assignmentDetails[0].weight;
        this.status = res.assignmentDetails[0].assignmentStatus;
        this.assignID = res.assignmentDetails[0].assignmentId;
        this.totalPages = Math.ceil(res.totalData / 15);
        window.scrollTo(0, 0);
      },

      paginationPayload(event) {
        this.payload = event;
        this.loading = true;
      },
    },
  };
</script>
<style lang="scss">
  .dashboard-style {
    color: grey;
    font-size: 16px;
    font-weight: 700;
  }

  .table-header-item1 {
    font-size: 16px !important;
    color: orange;
    font-weight: bold;
  }

  td {
    padding: 0px 15px !important;
    font-size: 14px !important;
  }

  thead {
    background: #fbfbfb;
  }

  .table-header-item1 {
    font-size: 16px !important;
    color: orange;
    font-weight: bold;
  }

  td {
    padding: 0px 15px !important;
    font-size: 14px !important;
  }

  thead {
    background: #fbfbfb;
  }
</style>

<style scoped>
  .customer-actions {
    padding: 0;
    margin: auto 20px auto 0px;
    cursor: pointer;
  }
  .text-center {
    text-align: center;
  }
  .document {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    color: #666666;
  }
  .editCustomer {
    margin: auto 0;
  }
  .page-count-span {
    float: right;
    margin-top: 26px;
    color: darkorange;
  }
  .color-black {
    color: #000000 !important;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-l-10 {
    margin-left: 10px;
  }
</style>
