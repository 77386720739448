<template>
  <div class="log">
    <v-btn
      class="messagebtn white-button"
      title="Assigned"
      @click.stop="dialog = true"
      :disabled="jobStatus == 10 || viewOnly == 'view'"
      @click="viewAssetDetail"
      >Assigned</v-btn
    >

    <v-dialog v-model="dialog" max-width="55%" persistent>
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat style="background-color: #fee9d0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Manage Asset</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click.prevent="dialog = false"
              @click="readOnly = 'true'"
            >
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="address-pop">
            <v-card-text class="nospace">
              <v-container>
                <v-layout>
                  <v-flex md7 xs6>
                    <v-layout pb-4>
                      <v-flex xs6 md9>
                        <div class="subheading orange-color">ASSET LABEL</div>

                        <div class="heading" style="position: relative">
                          <v-text-field
                            label="Asset label"
                            v-model="assetLabel"
                            single-line
                            :disabled="readOnly"
                            class="pt-0 currencyTitle"
                            counter
                            maxlength="50"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs6 md2 pl-2 class="edit">
                        <v-icon
                          color="orange darken-1"
                          flat
                          @click="editLabel()"
                          title="Edit label"
                          >create</v-icon
                        >
                      </v-flex>

                      <v-spacer />
                    </v-layout>
                    <v-layout pb-4>
                      <v-flex xs12 md12>
                        <div class="subheading grey-color">ASSIGNED ON</div>
                        <div v-if="this.assignedDate" class="heading">
                          {{ getTime(this.assignedDate) }}
                        </div>
                        <div v-else class="heading">N/A</div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>
                    <v-layout pb-4>
                      <v-flex xs12 md12>
                        <div class="subheading grey-color">LAST REPORTED</div>
                        <div v-if="this.date" class="heading">
                          {{ getTime(this.date) }}
                        </div>
                        <div v-else class="heading">N/A</div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>
                    <v-layout pb-4>
                      <v-flex xs12 md12>
                        <div class="subheading grey-color">STATUS</div>
                        <div class="heading" v-if="this.status == 'parked'">
                          Parked
                        </div>
                        <div class="heading" v-else-if="this.status == 'idle'">
                          Idle
                        </div>
                        <div
                          class="heading"
                          v-else-if="this.status == 'moving'"
                        >
                          Moving
                        </div>
                        <div v-else>N/A</div>
                      </v-flex>

                      <v-spacer />
                    </v-layout>

                    <v-layout pb-4>
                      <v-flex xs6 md11>
                        <div class="subheading grey-color">ASSIGN IMEI</div>
                        <div class="heading">
                          <v-flex xs6 md12>{{
                            this.imeiNumber || "N/A"
                          }}</v-flex>
                        </div>
                      </v-flex>
                      <v-spacer />
                    </v-layout>
                  </v-flex>
                  <v-flex md5 xs3>
                    <div>
                      <GmapMap
                        :options="{
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: false,
                        }"
                        :center="center"
                        :zoom="3"
                        style="width: 330px; height: 340px"
                      >
                        <!-- <gmap-Polyline :options="{'strokeColor': '#FF0000'}" :path="path"></gmap-Polyline> -->
                        <GmapMarker
                          :key="m.markers"
                          v-for="m in markers"
                          :position="m.position"
                          :icon.sync="m.icon"
                          @click="center = m.position"
                        ></GmapMarker>
                      </GmapMap>
                    </div>
                    <div>
                      <v-btn
                        color="orange darken-1"
                        class="white--text tilt"
                        round
                        target="_blank"
                        @click="navigateMapping()"
                        style="
                          text-transform: none !important ;
                          margin-left: 200px;
                        "
                        :loading="loading"
                      >
                        Navigate
                        <v-icon style="transform: rotate(45deg)" right size="17"
                          >navigation</v-icon
                        >
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>

                <div
                  class="red--text"
                  style="width: 50%; margin: auto; text-align: center"
                  v-if="x.error !== null"
                >
                  {{ x.error }}
                </div>
                <v-spacer />
                <v-layout pt-3 style="text-align: center">
                  <v-flex xs12 md12>
                    <v-spacer />
                    <v-btn
                      color="orange darken-1"
                      class="white--text"
                      style="text-transform: none; !important"
                      @click="unassignAsset"
                      :loading="loading"
                      >Unassign Asset</v-btn
                    >
                    <v-spacer />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <!-- <v-snackbar
              :timeout="6000"
              bottom
              color="red darken-2"
              v-model="x.error"
              class="white--text"
              v-if="x.error"
            >{{x.error}}</v-snackbar>-->
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { EventBus } from "../../event-bus.js";
import { editLabel, viewAssetDetails, un } from "../../constants/api-urls";
export default {
  mounted() {},
  created() {
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.u = localStorage.getItem("user") ? localStorage.getItem("user") : "";
    this.t = config.headers.authorization ? config.headers.authorization : "";

    this.$eventBus.$on("show-nav-items", (val) => {
      this.toShowNavItems = val;
      this.adminName = localStorage.getItem("user");
      this.adminRole = localStorage.getItem("role");
    });
    this.$eventBus.$on("hide-navbar", () => {
      this.show = false;
    });

    EventBus.$on("logged-in-success", (data) => {
      this.adminName = data.name;
      this.adminRole = data.roleType;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `${token}`,
        },
      };
      this.u = localStorage.getItem("user");
      this.t = config.headers.authorization;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val;
      this.loading = true;
      this.payload = {
        skip: "0",
        limit: "15",
        workingCountry: this.workingCountry,
      };
    });
  },
  props: {
    assignId: Number,
    jobStatus: Number,
    viewOnly: String,
  },

  data() {
    return {
      mapStyle: {
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
        ],
      },

      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly",
      },
      workingCountry: [],
      center: {
        lat: 0,
        lng: 0,
      },
      path: [],
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        },
        {
          position: {
            lat: 0,
            lng: 0,
          },
        },
        {
          position: {
            lat: 0,
            lng: 0,
          },
          icon: {
            url: require("../../assets/marker-truck.png"),
            scaledSize: { width: 40, height: 40 },
          },
        },
      ],

      readOnly: true,
      assignedDate: "",
      assetId: null,
      sourceLat: "",
      sourceLng: "",
      isFullTracking: true,
      assetLabel: "",
      imeiNumber: "",
      assets: [],
      status: "",
      date: "",
      id: "",
      x: {
        error: null,
      },

      items: [],

      title: null,
      message: null,

      processing: false,
      dialog: false,

      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },

  methods: {
    navigateMapping() {
      window.open(
        this.constants.mappingUrlV2 +
          "/#/track/assignment?id=" +
          this.assignId +
          "&showFullTracking=" +
          this.isFullTracking
      );
    },
    getTimes(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime(date) {
      return moment(date).format("DD/MM/YYYY, HH:mm");
    },
    fetchAssets() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = workingCountry;
      }
      let data = {
        workingCountry: this.workingCountry,
      };
      this.axios.post(`${apiUrl}/assets/freeAssetsListing`, data, config).then(
        (response) => {
          this.assets = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = this.$eventBus.parse(error);
        }
      );
    },
    editLabel() {
      this.x.error = null;
      if (this.readOnly) {
        this.readOnly = false;
        return;
      }
      this.readOnly = true;

      this.x.error = null;
      this.loading = true;
      if (!this.assetLabel) {
        this.x.error = "Please enter asset label";
        this.loading = false;
        return;
      }
      let url = editLabel;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        assignmentId: this.assignId.toString(),
        label: this.assetLabel,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
        },
        (error) => {
          this.x.error = error.response.data.message;
          this.loading = false;
        }
      );
    },

    viewAssetDetail() {
      this.x.error = null;

      this.loading = true;
      let url = viewAssetDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        assignmentId: this.assignId.toString(),
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data[0].assetData) {
            this.status = response.data.data[0].assetData.movingStatus;
            this.date = response.data.data[0].assetData.updatedAt;
            this.imeiNumber = response.data.data[0].assetData.imeiNumber;
            this.isFullTracking = response.data.data[0].assetData
              .isFullTrackingOn
              ? response.data.data[0].assetData.isFullTrackingOn
              : "false";
          } else {
            this.status = "N/A";
            this.date = "N/A";
            this.imeiNumber = "N/A";
          }

          if (
            response.data.data[0].assetData &&
            response.data.data[0].assetData.pastLocation
          ) {
            for (
              let i = 0;
              i < response.data.data[0].assetData.pastLocation.length;
              i++
            ) {
              this.markers.push({
                position: {
                  lat: response.data.data[0].assetData.pastLocation[i]
                    .latLong[1],
                  lng: response.data.data[0].assetData.pastLocation[i]
                    .latLong[0],
                },

                icon: {
                  url: require("../../assets/marker.png"),
                  scaledSize: { width: 15, height: 15 },
                },
              });
            }
          }

          this.assetLabel = response.data.data[0].assetLabel;
          this.assignedDate = response.data.data[0].assetAssignedAt;
          if (response.data.data[0].assetData.currentLatLong) {
            this.center.lat = response.data.data[0].assetData.currentLatLong[1];
            this.center.lng = response.data.data[0].assetData.currentLatLong[0];
          }
          if (response.data.data[0].assetData.currentLatLong) {
            this.markers[2].position.lat =
              response.data.data[0].assetData.currentLatLong[1];
            this.markers[2].position.lng =
              response.data.data[0].assetData.currentLatLong[0];
          }
          if (!response.data.data[0].assetData.currentLatLong) {
            this.center.lat = response.data.data[0].sourceLatLong[1];
            this.center.lng = response.data.data[0].sourceLatLong[0];
          }

          this.center.lat = response.data.data[0].sourceLatLong[1];
          this.center.lng = response.data.data[0].sourceLatLong[0];
          this.markers[0].position.lat = response.data.data[0].sourceLatLong[1];
          this.markers[0].position.lng = response.data.data[0].sourceLatLong[0];
          this.markers[1].position.lat =
            response.data.data[0].destinationLatLong[1];
          this.markers[1].position.lng =
            response.data.data[0].destinationLatLong[0];

          this.loading = false;
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
        }
      );
    },
    unassignAsset() {
      this.loading = true;
      let url = "/dmsAdmin/unassignAsset";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        assignmentId: this.assignId.toString(),
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = false;
          this.$eventBus.$emit("refresh-job");
          this.loading = false;
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.tilt .v-btn .v-icon {
  transform: rotate(50deg);
}
</style>
<style scoped>
.dropdowns-content a {
  color: black;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:hover {
  background-color: #ddd;
}

.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}

.grey-color {
  color: grey;
}
.orange-color {
  color: orange;
}
.white-button {
  border-radius: 5px;
  background-color: white !important;
  color: grey !important;
  border: 2px solid grey !important;
}
.edit {
  position: relative;
  top: 30px;
}
.messagebtn {
  margin: 0 auto;
}
</style>
