<template>
  <v-card color="white" v-if="assignments">
    <v-card-title class="bg-clr">
      <v-layout>
        <v-flex xs12 class="heading" pt-2 pl-3>Job Assignments </v-flex>
        <!-- <v-flex xs2 v-if="this.$props.driverDoc"
          ><receive-driver-doc-popup :tabOrder="tabOrder" @error="errorDoc()"
        /></v-flex> -->

        <v-flex xs2 pl-3>
          <template>
            <div class="text-center">
              <v-menu
                :disabled="
                  profile.jobStatus == 10 || viewOnly == 'view' || noRole
                "
                offset-y
                class="profile-drop"
              >
                <v-btn
                  slot="activator"
                  color="primary"
                  class="communicate-btn"
                  :disabled="processing"
                  @click="checkCommunication()"
                  :readonly="noRole"
                  v-permissions="'communicate-customer-jobs'"
                  dark
                  >Send Communication</v-btn
                >
                <v-list>
                  <v-list-tile
                    v-if="
                      (profile.jobStatus >= 6 || profile.jobStatus == 2) &&
                      profile.jobStatus != -1
                    "
                    @click="
                      showCommunicate('milestone');
                      calculateDistance();
                    "
                  >
                    <v-list-tile-title>Milestone Updates</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="
                      (profile.jobStatus == 1 ||
                        profile.jobStatus == 3 ||
                        profile.jobStatus == 4 ||
                        profile.jobStatus == 5) &&
                      profile.jobStatus != -1
                    "
                    @click="
                      error =
                        'Kindly attach milestone and update atleast one status'
                    "
                  >
                    <v-list-tile-title>Milestone Updates</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="
                      (profile.jobStatus >= 5 || profile.jobStatus == 2) &&
                      profile.jobStatus != -1
                    "
                    @click="showCommunicate('driver')"
                  >
                    <v-list-tile-title>Driver Updates</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="
                      (profile.jobStatus == 1 ||
                        profile.jobStatus == 3 ||
                        profile.jobStatus == 4) &&
                      profile.jobStatus != -1
                    "
                    @click="error = 'Kindly assign driver'"
                  >
                    <v-list-tile-title>Driver Updates</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="profile.jobStatus == 8"
                    @click="showCommunicate('paymentPending')"
                  >
                    <v-list-tile-title>Payment Pending</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="profile.jobStatus == 9"
                    @click="showCommunicate('paymentDone')"
                  >
                    <v-list-tile-title>Payment Done</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="profile.jobStatus == -1"
                    @click="showCommunicate('cancel')"
                  >
                    <v-list-tile-title>Cancelled</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="profile.jobStatus == 2"
                    @click="
                      showCommunicate('complete');
                      calculateDistance();
                    "
                  >
                    <v-list-tile-title>Completed</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-flex>
        <v-flex
          xs1
          pt-2
          style="padding-left: 20px"
          v-if="profile.lastsentEmail"
        >
          <v-tooltip
            top
            class="milestone"
            content-class="tooltip-op"
            color="orange"
          >
            <template slot="activator">
              <v-icon>visibility</v-icon>
            </template>
            <span class="tooltip"
              >Last email sent - {{ profile.lastsentEmail }}</span
            >
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-card-title>

    <v-card-text class="nospace">
      <v-data-table
        id="dataTable"
        hide-actions
        item-key="assignmentId"
        :headers="headers"
        style="
          width: 100%;
          overflow-x: scroll !important;
          overflow-y: inherit !important;
        "
        single-expand
        show-expand
        :items="jobAssignments"
      >
        <template slot="items" slot-scope="props">
          <tr style="cursor: pointer" @click="props.expanded = !props.expanded">
            <td v-if="props.item.assignmentType == 'Value Added Service'">
              <span style="color: blue"> {{ props.item.assignmentId }}</span>
            </td>
            <td v-else>
              {{ props.item.assignmentId }}
            </td>

            <td>
              <v-flex
                v-if="
                  props.item.assignmentStatus != 1 &&
                  props.item.assignmentStatus != 3 &&
                  props.item.assignmentStatus != 4 &&
                  props.item.assignmentStatus != 6 &&
                  jobType == 1
                "
              >
                <AssetAssign
                  :assignId="props.item.assignmentId"
                  :assetId="props.item.assetId"
                  :jobStatus="profile.jobStatus"
                  v-if="
                    props.item.assignmentStatus >= 2 &&
                    props.item.assignmentStatus != 7 &&
                    !props.item.assetId &&
                    jobType == 1
                  "
                  :viewOnly="viewOnly"
                  :country="profile.workingCountry"
                />
                <AssetUnassign
                  :assetId="props.item.assetId"
                  :jobStatus="profile.jobStatus"
                  :assignId="props.item.assignmentId"
                  v-if="
                    props.item.assignmentStatus >= 2 &&
                    props.item.assetId &&
                    jobType == 1
                  "
                  :viewOnly="viewOnly"
                />
              </v-flex>
              <v-flex v-else>
                <div>-</div>
              </v-flex>
            </td>
            <td
              v-if="
                props.item.assetId &&
                (props.item.assignmentStatus == 6 ||
                  props.item.assignmentStatus == 3 ||
                  props.item.assignmentStatus == 4)
              "
            >
              <GpsCollectionPopup :assignmentData="props.item" />
            </td>
            <td v-else>-</td>
            <!-- <td><ValueAdded :assignmentDetails="props.item" /></td> -->
            <td>
              <v-btn
                style="border-radius: 5px"
                v-permissions="'add-duty'"
                v-if="
                  props.item.assignmentStatus != 1 &&
                  props.item.assignmentStatus != 3 &&
                  props.item.assignmentStatus != 4 &&
                  props.item.assignmentStatus != 6 &&
                  props.item.assignmentStatus != 7 &&
                  props.item.assignmentStatus != -1 &&
                  props.item.assignmentStatus != 8 &&
                  jobType == 1 &&
                  !props.item.hasVasAttached &&
                  props.item.assignmentType == 'Normal'
                "
                @click.stop="openVas(props.item)"
                color="orange darken-3 "
                :disabled="
                  profile.jobStatus === 10 || viewOnly == 'view' || loading
                "
                class="white--text"
                >ADD Duty</v-btn
              >
              <v-tooltip
                v-permissions="'add-duty'"
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
                style="border-radius: 5px"
                v-else-if="
                  (props.item.assignmentStatus == 1 &&
                    props.item.assignmentType == 'Normal') ||
                  (props.item.assignmentStatus == 3 &&
                    props.item.assignmentType == 'Normal') ||
                  (props.item.assignmentStatus == 6 &&
                    props.item.assignmentType == 'Normal') ||
                  (props.item.assignmentStatus == 7 &&
                    props.item.assignmentType == 'Normal') ||
                  (props.item.assignmentStatus == 4 &&
                    props.item.assignmentType == 'Normal') ||
                  (props.item.assignmentStatus == 8 &&
                    props.item.assignmentType == 'Normal')
                "
              >
                <template slot="activator">
                  <v-btn color="orange darken-3" disabled class="white--text"
                    >ADD Duty</v-btn
                  ></template
                >
                <span class="tooltip" v-if="props.item.assignmentStatus == 1"
                  >Please assign driver to create Duty for this assignment.
                </span>
                <span
                  class="tooltip"
                  v-else-if="props.item.assignmentStatus == 8"
                  >Required Driver Price Approval from Accounts(finance) team.
                </span>
                <span class="tooltip" v-else
                  >You can not create after the shipment is offloaded for an
                  assignment.
                </span>
              </v-tooltip>
              <span v-else>-</span>
            </td>
            <td>
              <v-flex>
                <div v-if="props.item.transporterData">
                  {{ props.item.transporterData.transporterName || "-" }}
                </div>
                <div v-else>N.A</div>
                <div
                  v-if="
                    !props.item.hasVasAttached &&
                    props.item.assignmentType == 'Normal'
                  "
                >
                  <button
                    type="button"
                    class="track-ass-btn"
                    v-permissions="'unassign-driver-jobs'"
                    v-if="
                      (props.item.assignedToTransporter &&
                        !props.item.isGenerated &&
                        props.item.totalAdditionalAmount == 0 &&
                        props.item.assignmentStatus === 1) ||
                      (props.item.assignedToTransporter &&
                        !props.item.isGenerated &&
                        props.item.totalAdditionalAmount == 0 &&
                        props.item.assignmentStatus === 2) ||
                      (props.item.assignedToTransporter &&
                        !props.item.isGenerated &&
                        props.item.totalAdditionalAmount == 0 &&
                        props.item.assignmentStatus === 5)
                    "
                    :disabled="profile.jobStatus === 10 || viewOnly == 'view'"
                    @click="openTransporterUnassign(props.item)"
                  >
                    Unassign
                  </button>
                </div>
              </v-flex>
            </td>
            <td>
              <v-flex>
                <div class="pl-3">
                  {{ assignmentStatus(props.item.assignmentStatus) }}
                  <br />

                  <div
                    v-if="
                      !props.item.hasVasAttached &&
                      props.item.assignmentType == 'Normal'
                    "
                  >
                    <button
                      type="button"
                      class="track-ass-btn"
                      v-permissions="'unassign-driver-jobs'"
                      v-if="
                        (!props.item.isGenerated &&
                          props.item.totalAdditionalAmount == 0 &&
                          props.item.assignmentStatus === 2) ||
                        (props.item.totalAdditionalAmount == 0 &&
                          !props.item.isGenerated &&
                          props.item.assignmentStatus === 5)
                      "
                      :disabled="profile.jobStatus === 10 || viewOnly == 'view'"
                      @click="openDriverUnassign(props.item)"
                    >
                      Unassign
                    </button>
                  </div>

                  <JobsProfileDriverAssign
                    v-if="
                      jobType == 1 &&
                      !props.item.isGenerated &&
                      props.item.assignmentStatus === 1
                    "
                    :milestoneId="props.item.milestoneId"
                    :assignmentId="props.item.assignmentId"
                    :assignmentDetail="props.item"
                    :jobStatus="profile.jobStatus"
                    @transporter-success="refresh()"
                    :currency="props.item.customerCurrency"
                    :country="profile.workingCountry"
                    :viewOnly="viewOnly"
                  />
                  <!-- <ChangeDriver
                    v-if="
                      (props.item.assignmentStatus == 2 ||
                        props.item.assignmentStatus == 5 ||
                        props.item.assignmentStatus == 6 ||
                        props.item.assignmentStatus == 7) &&
                      props.item.assignmentType != 'Value Added Service'
                    "
                    :milestoneId="props.item.milestoneId"
                    :assignmentId="props.item.assignmentId"
                    :assignmentDetail="props.item"
                    :jobStatus="profile.jobStatus"
                    @transporter-success="refresh()"
                    :currency="props.item.customerCurrency"
                    :country="profile.workingCountry"
                    :viewOnly="viewOnly"
                  /> -->
                  <jobProfileDriverAssignAir
                    v-if="jobType == 2"
                    :milestoneId="props.item.milestoneId"
                    :assignmentDetail="props.item"
                    :jobStatus="profile.jobStatus"
                    :assignmentId="props.item.assignmentId"
                    :country="profile.workingCountry"
                    :currency="props.item.customerCurrency"
                    :viewOnly="viewOnly"
                  />
                  <jobProfileDriverAssignSea
                    v-if="jobType == 3"
                    :assignmentDetail="props.item"
                    :jobStatus="profile.jobStatus"
                    :toShow="driverAssignModal"
                    :milestoneId="props.item.milestoneId"
                    :currency="props.item.customerCurrency"
                    :country="profile.workingCountry"
                    :assignmentId="props.item.assignmentId"
                    :viewOnly="viewOnly"
                  />
                  <!-- <button
                    type="button"
                    v-permissions="'assign-driver-jobs'"
                    v-if="
                      !props.item.isGenerated &&
                        props.item.assignmentStatus === 1
                    "
                    :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
                    class="track-ass-btn"
                    @click.stop="openAssignDriver(props.item)"
                  >
                    Assign
                  </button> -->
                </div>
              </v-flex>
            </td>
            <td>
              <v-flex v-if="props.item.driverFirstName">
                <div>
                  {{ getDriverAssignment(props.item) }}
                  <span
                    v-if="
                      props.item.assignmentStatus != -1 &&
                      props.item.assignmentStatus != 4 &&
                      props.item.assignmentStatus != 3
                    "
                  >
                    <!-- ({{ props.item.driverStatus }}) -->
                  </span>
                </div>
              </v-flex>
              <v-flex v-else>
                <div>
                  -
                  <!-- {{ props.item.driverStatus }} -->
                </div>
              </v-flex>
            </td>
            <td>
              <!-- <v-flex v-if="props.item">
                <div v-if="props.item.truckPrefix">
                  {{ props.item.truckPrefix }}-{{ props.item.truckNumber }}
                </div>
                <div v-else>
                  {{ props.item.driverData.truckPrefix }}-{{
                    props.item.driverData.truckNumber
                  }}
                </div>
              </v-flex> -->
              <!-- <v-flex v-if="props.item.truckPrefix">
                  {{ props.item.truckPrefix }}-{{ props.item.truckNumber }}
 </v-flex>
                <v-flex v-else>
                  {{ props.item.driverData.truckPrefix }}-{{
                    props.item.driverData.truckNumber
                  }}
                </v-flex> -->

              <v-flex v-if="props.item.driverData">
                <div v-if="props.item.truckPrefix">
                  {{ props.item.truckPrefix }}-{{ props.item.truckNumber }}
                </div>
                <div v-else>
                  {{ props.item.driverData.truckPrefix }}-{{
                    props.item.driverData.truckNumber
                  }}
                </div>
              </v-flex>
              <v-flex v-else>
                <div class="pr-2">-</div>
              </v-flex>
            </td>
            <td>
              <v-flex style="">
                <div v-if="props.item.wayBillNo">
                  <div class="text-center v-btn v-btn--flat v-btn--router">
                    <!-- USERS -->
                    <v-menu
                      transition="slide-x-transition"
                      offset-y
                      class="profile-drop"
                    >
                      <v-toolbar-side-icon slot="activator">
                        <v-icon>receipt</v-icon>
                      </v-toolbar-side-icon>
                      <v-list>
                        <v-list-tile
                          v-if="workingcountry != 'PAK'"
                          :to="`/assignments/${props.item.assignmentId}/UAE/waybill`"
                          target="_blank"
                          @click="selectActive(26)"
                          :color="
                            index === 26 ? 'orange darken-3' : 'grey darken-3'
                          "
                        >
                          <v-list-tile-title>UAE</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile
                          v-if="workingcountry != 'PAK'"
                          :to="`/assignments/${props.item.assignmentId}/UAE/waybill`"
                          target="_blank"
                          @click="selectActive(26)"
                          :color="
                            index === 26 ? 'orange darken-3' : 'grey darken-3'
                          "
                        >
                          <v-list-tile-title>UAE LA</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile
                          v-if="workingcountry != 'PAK'"
                          :to="`/assignments/${props.item.assignmentId}/KSA/waybill`"
                          target="_blank"
                          @click="selectActive(26)"
                          :color="
                            index === 26 ? 'orange darken-3' : 'grey darken-3'
                          "
                        >
                          <v-list-tile-title>KSA</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile
                          v-if="workingcountry != 'PAK'"
                          :to="`/assignments-old/${props.item.assignmentId}/KSA/waybill`"
                          target="_blank"
                          @click="selectActive(26)"
                          :color="
                            index === 26 ? 'orange darken-3' : 'grey darken-3'
                          "
                        >
                          <v-list-tile-title>KSA Old</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile
                          v-if="workingcountry == 'PAK'"
                          :to="`/assignments/${props.item.assignmentId}/PAK/waybill`"
                          target="_blank"
                          @click="selectActive(26)"
                          :color="
                            index === 26 ? 'orange darken-3' : 'grey darken-3'
                          "
                        >
                          <v-list-tile-title>PAK</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <div v-else>-</div>
              </v-flex>
            </td>
            <td>
              <v-flex>
                <v-menu
                  transition="slide-x-transition"
                  offset-y
                  class="profile-drop"
                >
                  <v-toolbar-side-icon slot="activator">
                    <button
                      class="track-ass-btn"
                      style="padding: 9px 15px !important"
                      type="button"
                      title="view"
                    >
                      View
                    </button>
                  </v-toolbar-side-icon>
                  <v-list>
                    <v-list-tile
                      @click="
                        goToProfile(
                          profile.jobId,
                          props.item.assignmentId,
                          props.item._id,
                          viewOnly
                        )
                      "
                      :color="
                        index === 26 ? 'orange darken-3' : 'grey darken-3'
                      "
                    >
                      <v-list-tile-title>Shipment Documents</v-list-tile-title>
                    </v-list-tile>
                    <!-- <v-list-tile
                      v-if="jobType == 1 && props.item.assignmentStatus > 1"
                      :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
                      @click="driverpdfDownload(props.item)"
                      :color="
                        index === 26 ? 'orange darken-3' : 'grey darken-3'
                      "
                    >
                      <v-list-tile-title>Driver Documents</v-list-tile-title>
                    </v-list-tile> -->
                    <v-list-tile
                      @click="driverErrorOpen(props.item)"
                      target="_blank"
                      v-if="jobType == 1"
                      :disabled="
                        profile.jobStatus == 10 ||
                        viewOnly == 'view' ||
                        props.item.assignmentStatus == -1
                      "
                      :color="
                        index === 26 ? 'orange darken-3' : 'grey darken-3'
                      "
                    >
                      <v-list-tile-title
                        >Driver Onboarding Form</v-list-tile-title
                      >
                    </v-list-tile>
                    <v-list-tile
                      v-if="jobType == 1"
                      :disabled="
                        profile.jobStatus == 10 ||
                        viewOnly == 'view' ||
                        props.item.assignmentStatus == -1
                      "
                      @click="errorOpen(props.item)"
                      :color="
                        index === 26 ? 'orange darken-3' : 'grey darken-3'
                      "
                    >
                      <v-list-tile-title>Asset Information</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-flex>
            </td>
            <td>
              <div v-if="profile.isJobActivatedByScript">N.A.</div>
              <div v-else>
                <v-flex>
                  <!-- <GenerateAdvance
                  :assId="assignID"
                  v-permissions="'generate-advance-jobs'"
                  :viewOnly="viewOnly"
                  :currency="profile.customerCurrency"
                  :invoiceCity="profile.invoicingCity"
                  :invoiceCompany="profile.invoicingCity"
                  :type="'1'"
                  @generateAdvance="closeMessage()"
                  :totalAmount="props.item.paymentAmount"
                  :totalAdvanceAmount="props.item.totalAdvancePaidAmount"
                /> -->
                  <generateAdvancePopup
                    :assignId="props.item._id"
                    :advancePaid="props.item.totalAdvancePaidAmount"
                    :additionalCharges="props.item.totalAdditionalAmount"
                    :viewOnly="viewOnly"
                    :jobCurrency="profile.customerCurrency"
                  />
                </v-flex>
              </div>
            </td>
            <td>
              <div v-if="profile.isJobActivatedByScript">N.A.</div>
              <div v-else>
                <v-flex @click="noInternet()">
                  <additionalChargesPopup
                    :isAssign="props.item._id"
                    :viewOnly="viewOnly"
                    :isCustomerReceiptInitiated="
                      props.item.isCustomerReceiptInitiated
                    "
                    :jobCurrency="profile.customerCurrency"
                    :jobSalesInvoice="profile.isSalesInvoiceGenerated"
                    :amountPaidToDriver="props.item.amountPaidToDriver"
                    :assignSalesInvoice="props.item.isSalesInvoiceGenerated"
                  />
                </v-flex>
              </div>
            </td>
          </tr>
        </template>
        <template
          slot="expand"
          slot-scope="props"
          style="
            width: 100%;
            overflow-x: scroll !important;
            overflow-y: inherit !important;
          "
        >
          <JobsProfileAssignmentDetail
            :detail="props.item"
            v-on:updateAssignment="updateAssignment"
            v-on:refresh-data="updateAssignment"
            :milestoneItemsList="milestoneItemsList"
            :customer="userDetail"
            :statusData="jobData"
            :assetId="props.item.assetId"
            :working="workingcountry"
            :milestoneList="milestoneItems"
            :profile="profile"
            :tabOrder="tabOrder"
            :jobType="jobType"
            :viewOnly="viewOnly"
          />
        </template>
      </v-data-table>
      <confirmation-dialog
        :content="`Are you sure to remove transporter from this assignment?`"
        :toShow="confirmationDialog.removeTransporterAssignment"
        :onClose="() => remove()"
        confirmTitle="Unassign"
        :loading="loading"
        :error="error"
        :onConfirm="() => removeTransporterAssignment(this.assignmentData)"
      />
      <confirmation-dialog
        :content="`Are you sure to remove ${
          this.assignmentData.driverData
            ? this.assignmentData.driverData.name
            : ''
        } from this assignment?`"
        :toShow="confirmationDialog.removeDriverAssignment"
        :onClose="removeDriver"
        confirmTitle="Unassign"
        :loading="loading"
        :error="error"
        :onConfirm="() => removeDriverAssignment(this.assignmentData)"
      />
    </v-card-text>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <!-- COMMUNICATE CUSTOMER DIALOG -->
    <template>
      <v-layout>
        <v-dialog v-model="communicateDialog.show" persistent max-width="50%">
          <v-form ref="form" onsubmit="return false;">
            <v-card class>
              <v-toolbar fixed flat>
                <v-toolbar-title>Send Updates</v-toolbar-title>
                <v-spacer></v-spacer>
                <small
                  class="error info"
                  v-show="this.communicateDialog.error"
                  >{{ this.communicateDialog.error }}</small
                >
                <small
                  class="success info"
                  v-show="this.communicateDialog.success"
                  >{{ this.communicateDialog.success }}</small
                >
                <v-btn
                  color="primary"
                  class="communicate-btn"
                  v-if="!loading"
                  dark
                  @click.prevent="checkSendCommunication()"
                  >Send</v-btn
                >
                <v-btn @click.prevent="communicateDialog.show = false" icon>
                  <v-icon class="heading grey--text text--darken-4"
                    >close</v-icon
                  >
                </v-btn>
              </v-toolbar>
              <v-spacer>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-text-field
                          label="To"
                          v-model="communicateDialog.to"
                          value="userDetail.email"
                          disabled
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-combobox
                          multiple
                          v-model="communicateDialog.select"
                          label="cc"
                          :items="items"
                          append-icon
                          chips
                          deletable-chips
                          hide-selected
                          hide-details
                          :persistent-hint="false"
                          class="tag-input"
                          v-on:keyup.17="checkValue"
                          :search-input.sync="search"
                          @keyup.tab="updateTags"
                          @keypress="smartRecipent"
                          @paste="updateTags"
                          @change="checkValue"
                          :rounded="true"
                        >
                        </v-combobox>
                      </v-flex>
                      <v-flex xs12 sm6 md12>
                        <v-text-field
                          label="Type your subject"
                          v-model="communicateDialog.subject"
                        />
                      </v-flex>
                      <!--DRIVER-UPDATES-ASSIGNED-TEMPLATE-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'driver' &&
                          jobDetails.jobInfo.jobStatus == 5
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            width: 100%;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/truckkinapi/profilePic_10045726.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad" style="padding: 24px 40px">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Mabrook! Your driver for shipment ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                has been
                                <strong style="font-weight: 600"
                                  >Assigned.</strong
                                >
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here are the details of the driver :
                              </p>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  margin-bottom: 20px;
                                "
                              >
                                <table
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    background: #fafafa;
                                    padding: 10px;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="
                                        vertical-align: top;
                                        background: #fafafa;
                                      "
                                    >
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 16px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Booking Confirmed
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.newCreatedAt
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Driver Assigned
                                          <br />

                                          {{ jobData[0].Assigned }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo
                                                .driverAssignedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10024807.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          In-Transit
                                          <br />
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10009777.png"
                                          style="
                                            width: 100%;
                                            margin-bottom: 2px;
                                          "
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Off-loaded/Delivered
                                          <br />
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="
                                    margin: 15px;
                                    display: inline-block;
                                    width: 100%;
                                  "
                                >
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=track'
                                    "
                                    style="
                                      float: left;
                                      margin-bottom: 10px;
                                      margin-right: 6px;
                                      border-radius: 4px;
                                      color: #fff;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: #0f67c9;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 180px;
                                    "
                                    target="_blank"
                                  >
                                    <img
                                      v-bind:href="
                                        this.constants.webAppUrl +
                                        '/#/tracking?jobId=' +
                                        jobDetails.jobInfo.jobId +
                                        '&type=documents'
                                      "
                                      src="https://s3.amazonaws.com/truckkinapi/profilePic_10068463.png"
                                      style="
                                        width: 16px;
                                        margin-right: 9px;
                                        vertical-align: middle;
                                      "
                                    />Track Your Shipment
                                  </a>
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=documents'
                                    "
                                    style="
                                      border: 2px solid #0f67c9;
                                      float: left;
                                      border-radius: 4px;
                                      color: #0f67c9;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: transparent;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 180px;
                                    "
                                    target="_blank"
                                    >View Documents</a
                                  >
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Driver details:
                                </h5>

                                <div
                                  v-for="item in assignments"
                                  :key="item.assignmentId"
                                  style="padding: 15px"
                                >
                                  <div class="img-profile">
                                    <img
                                      width="65"
                                      height="65"
                                      v-if="item.driverData"
                                      v-bind:src="
                                        item.driverData.profilePicURL
                                          ? item.driverData.profilePicURL
                                          : 'https://lakewangaryschool.sa.edu.au/wp-content/uploads/2017/11/placeholder-profile-sq.jpg'
                                      "
                                      style="
                                        border-radius: 50%;
                                        margin-right: 20px;
                                        border: 2px solid darkorange;
                                      "
                                    />
                                  </div>
                                  <div
                                    class="details-section"
                                    style="font-size: 14px"
                                  >
                                    <p
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #000;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Assignment Number :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          color: #000;
                                          word-break: break-all;
                                        "
                                        >{{ item.assignmentId }}</span
                                      >
                                    </p>
                                    <p
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #000;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                        >Driver Name :</span
                                      >
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          color: #000;
                                          word-break: break-all;
                                        "
                                        >{{
                                          item.driverFirstName
                                            ? item.driverFirstName +
                                              " " +
                                              item.driverLastName
                                            : "Driver not assigned yet"
                                        }}</span
                                      >
                                    </p>

                                    <p
                                      v-if="jobType == '1'"
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #000;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;

                                          display: inline-block;
                                        "
                                      >
                                        Vehicle Number :
                                      </span>
                                      <span
                                        >{{
                                          item.driverId
                                            ? item.driverData.truckPrefix
                                            : ""
                                        }}
                                        {{
                                          item.driverId
                                            ? item.driverData.truckNumber
                                            : "N.A."
                                        }}</span
                                      >
                                    </p>
                                    <p
                                      v-if="jobType == '2'"
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p
                                      v-if="jobType == '3'"
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p
                                      v-if="jobType == '1'"
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Truck Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          truckTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p
                                      v-if="jobType == '2'"
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          AirTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p
                                      v-if="jobType == '3'"
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          SeaTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 10px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>
                                    <p
                                      style="
                                        border-bottom: 1px solid #f2f2f2;
                                        padding-bottom: 20px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Off-loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--DRIVER-------UPDATES------TEMPLATES-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'driver' &&
                          (jobDetails.jobInfo.jobStatus == 7 ||
                            jobDetails.jobInfo.jobStatus == 8 ||
                            jobDetails.jobInfo.jobStatus == 9 ||
                            jobDetails.jobInfo.jobStatus == 2)
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            width: 100%;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/truckkinapi/profilePic_10014428.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad" style="padding: 24px 40px">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Congratulations! Your consignment with shipment
                                ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                has been
                                <strong style="font-weight: 600"
                                  >offloaded</strong
                                >
                                succesfully.
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here are the details of the driver :
                              </p>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  margin-bottom: 20px;
                                "
                              >
                                <table
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    background: #fafafa;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="
                                        vertical-align: top;
                                        background: #fafafa;
                                      "
                                    >
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 16px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Booking Confirmed
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.newCreatedAt
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Driver Assigned
                                          <br />
                                          {{ jobData[0].Assigned }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo
                                                .driverAssignedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          In-Transit
                                          <br />
                                          {{ jobData[0].transit }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.inTransitDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10023419.png"
                                          style="
                                            width: 100%;
                                            margin-bottom: 2px;
                                          "
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Off-loaded/Delivered
                                          <br />
                                          {{ jobData[0].shipment }}/{{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}

                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.offloadedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="
                                    margin: 15px;
                                    display: inline-block;
                                    width: 100%;
                                  "
                                >
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=track'
                                    "
                                    style="
                                      margin-bottom: 10px;
                                      margin-right: 10px;
                                      float: left;
                                      border-radius: 4px;
                                      color: #fff;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: #0f67c9;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 180px;
                                    "
                                    target="_blank"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/truckkinapi/profilePic_10068463.png"
                                      style="
                                        width: 16px;
                                        margin-right: 9px;
                                        vertical-align: middle;
                                      "
                                    />Track Your Shipment
                                  </a>
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=documents'
                                    "
                                    style="
                                      border: 2px solid #0f67c9;
                                      float: left;
                                      border-radius: 4px;
                                      color: #0f67c9;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: transparent;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 180px;
                                    "
                                    target="_blank"
                                    >View Documents</a
                                  >
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Driver details:
                                </h5>

                                <div
                                  v-for="item in assignments"
                                  :key="item.assignmentId"
                                  style="padding: 15px"
                                >
                                  <div class="img-profile">
                                    <img
                                      width="65"
                                      height="65"
                                      v-if="item.driverData"
                                      v-bind:src="
                                        item.driverData.profilePicURL
                                          ? item.driverData.profilePicURL
                                          : 'https://lakewangaryschool.sa.edu.au/wp-content/uploads/2017/11/placeholder-profile-sq.jpg'
                                      "
                                      style="
                                        border-radius: 50%;
                                        margin-right: 20px;
                                        border: 2px solid darkorange;
                                      "
                                    />
                                  </div>
                                  <div
                                    class="details-section"
                                    style="font-size: 14px"
                                  >
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Assignment Number:
                                      </span>
                                      <span
                                        style="color: #ea8328; font-weight: 600"
                                        >{{ item.assignmentId }}</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Driver Name :
                                      </span>
                                      <span>{{
                                        item.driverFirstName
                                          ? item.driverFirstName +
                                            " " +
                                            item.driverLastName
                                          : "Driver not assigned yet"
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '1'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Vehicle Number :
                                      </span>
                                      <span
                                        >{{
                                          item.driverId
                                            ? item.driverData.truckPrefix
                                            : ""
                                        }}
                                        {{
                                          item.driverId
                                            ? item.driverData.truckNumber
                                            : "N.A."
                                        }}</span
                                      >
                                    </p>
                                    <p v-if="jobType == '2'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '3'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '1'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Truck Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          truckTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '2'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          AirTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '3'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          SeaTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                          display: inline-block;
                                        "
                                      >
                                        Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>
                                    <p
                                      style="
                                        border-bottom: 1px solid #f2f2f2;
                                        padding-bottom: 20px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                          display: inline-block;
                                        "
                                      >
                                        Off-loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--DRIVER-------UPDATES---INTRANSIT---TEMPLATES-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'driver' &&
                          jobDetails.jobInfo.jobStatus == 6
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            width: 100%;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/truckkinapi/profilePic_10089047.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad" style="padding: 24px 40px">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Your consignment with shipment ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                has been
                                <strong style="font-weight: 600"
                                  >on the move.</strong
                                >
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here are the details of the driver :
                              </p>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  margin-bottom: 20px;
                                "
                              >
                                <table
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    background: #fafafa;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="
                                        vertical-align: top;
                                        background: #fafafa;
                                      "
                                    >
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 16px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Booking Confirmed
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.newCreatedAt
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Driver Assigned
                                          <br />
                                          {{ jobData[0].Assigned }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo
                                                .driverAssignedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10057175.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          In-Transit
                                          <br />
                                          {{ jobData[0].transit }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.inTransitDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10009777.png"
                                          style="
                                            width: 100%;
                                            margin-bottom: 2px;
                                          "
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Off-loaded/Delivered
                                          <br />
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="margin: 15px; display: inline-block"
                                >
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=track'
                                    "
                                    style="
                                      margin-right: 5px;
                                      float: left;
                                      border-radius: 4px;
                                      color: #fff;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: #0f67c9;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 158px;
                                    "
                                    target="_blank"
                                    >Track Your Shipment</a
                                  >
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=documents'
                                    "
                                    style="
                                      border: 2px solid #0f67c9;
                                      float: left;
                                      border-radius: 4px;
                                      color: #0f67c9;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: transparent;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 158px;
                                    "
                                    target="_blank"
                                    >View Documents</a
                                  >
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Driver details:
                                </h5>
                                <div
                                  v-for="item in assignments"
                                  :key="item.assignmentId"
                                  style="padding: 15px"
                                >
                                  <div class="img-profile">
                                    <img
                                      width="65"
                                      height="65"
                                      v-if="item.driverData"
                                      v-bind:src="
                                        item.driverData.profilePicURL
                                          ? item.driverData.profilePicURL
                                          : 'https://lakewangaryschool.sa.edu.au/wp-content/uploads/2017/11/placeholder-profile-sq.jpg'
                                      "
                                      style="
                                        border-radius: 50%;
                                        margin-right: 20px;
                                        border: 2px solid darkorange;
                                      "
                                    />
                                  </div>
                                  <div
                                    class="details-section"
                                    style="font-size: 14px"
                                  >
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Assignment Number :
                                      </span>
                                      <span
                                        style="color: #ea8328; font-weight: 600"
                                        >{{ item.assignmentId }}</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Driver Name :
                                      </span>
                                      <span>{{
                                        item.driverFirstName
                                          ? item.driverFirstName +
                                            " " +
                                            item.driverLastName
                                          : "Driver not assigned yet"
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '1'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Vehicle Number :
                                      </span>
                                      <span
                                        >{{
                                          item.driverId
                                            ? item.driverData.truckPrefix
                                            : ""
                                        }}
                                        {{
                                          item.driverId
                                            ? item.driverData.truckNumber
                                            : "N.A."
                                        }}</span
                                      >
                                    </p>
                                    <p v-if="jobType == '2'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '3'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '1'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Truck Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          truckTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '2'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          AirTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '3'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Type :
                                      </span>
                                      <span>{{
                                        getText(
                                          SeaTypes,
                                          jobDetails.jobInfo.truckType
                                        )
                                      }}</span>
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>
                                    <p
                                      style="
                                        border-bottom: 1px solid #f2f2f2;
                                        padding-bottom: 20px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Off-loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--MILESTONE-------UPDATES------TEMPLATES-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'milestone' &&
                          (jobDetails.jobInfo.jobStatus == 7 ||
                            jobDetails.jobInfo.jobStatus == 8 ||
                            jobDetails.jobInfo.jobStatus == 9 ||
                            jobDetails.jobInfo.jobStatus == 2)
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            width: 100%;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/trukkin.com/CONSIGNMENT-DELIVERED.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad" style="padding: 24px 40px">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Congratulations! Your consignment with shipment
                                ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                has been
                                <strong style="font-weight: 600"
                                  >offloaded</strong
                                >
                                successfully.
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here are your job details.
                              </p>
                              <div style="margin: 15px; margin-left: 0px">
                                <a
                                  v-bind:href="
                                    this.constants.webAppUrl +
                                    '/#/tracking?jobId=' +
                                    jobDetails.jobInfo.jobId
                                  "
                                  style="
                                    border-radius: 4px;
                                    color: #fff;
                                    text-decoration: none;
                                    box-sizing: border-box;
                                    text-align: center;
                                    background-color: #0f67c9;
                                    height: 37px;
                                    display: block;
                                    line-height: 37px;
                                    font-weight: normal;
                                    font-size: 13px;
                                    width: 158px;
                                  "
                                  target="_blank"
                                  >View Proof of Delivery</a
                                >
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  margin-bottom: 20px;
                                "
                              >
                                <table
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    background: #fafafa;
                                    padding: 10px;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="
                                        vertical-align: top;
                                        background: #fafafa;
                                      "
                                    >
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 16px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Booking confirmed,
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.newCreatedAt
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Driver Assigned
                                          <br />
                                          {{ jobData[0].Assigned }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo
                                                .driverAssignedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          In-Transit
                                          <br />
                                          {{ jobData[0].transit }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.inTransitDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10023419.png"
                                          style="
                                            width: 100%;
                                            margin-bottom: 2px;
                                          "
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Off-loaded/Delivered
                                          <br />
                                          {{ jobData[0].shipment }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.offloadedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                  margin-bottom: 10px;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Job details: {{ jobDetails.jobInfo.jobId }}
                                </h5>
                                <div style="padding: 15px">
                                  <div
                                    class="details-section"
                                    style="
                                      font-size: 14px;
                                      width: 100%;
                                      float: left;
                                    "
                                  >
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Job Status :
                                      </span>
                                      <span
                                        style="color: #409f52; font-weight: 600"
                                        >Delivered</span
                                      >
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 190px;
                                          float: left;
                                        "
                                        >Number of Assignments :</span
                                      >
                                      <span>{{
                                        jobDetails.jobInfo
                                          .numberOfAssignmentsRequired
                                      }}</span>
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Date :
                                      </span>
                                      <span
                                        >{{
                                          getDateTime2(
                                            jobDetails.jobInfo.pickupDate
                                          )
                                        }}
                                        {{ jobDetails.jobInfo.pickupTime }} ({{
                                          emailTimeZone
                                        }})</span
                                      >
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Off-Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  display: inline-block;
                                  margin-bottom: 15px;
                                  margin-top: 15px;
                                "
                              >
                                <div class="half-colm">
                                  <span
                                    style="
                                      color: #252525;
                                      line-height: 20px;
                                      font-weight: 600;
                                      width: 100%px;
                                      display: inline-block;
                                      padding-left: 40px;
                                    "
                                  >
                                    {{ this.journeyDis }}
                                    (approx) |
                                    {{ this.$props.journeyDays }} Days
                                  </span>
                                  <div
                                    style="
                                      width: 100%;
                                      position: relative;
                                      float: left;
                                      margin-top: 20px;
                                    "
                                  >
                                    <span
                                      class="from-to -line"
                                      style="
                                        width: 2px;
                                        background: #0967c9;
                                        float: left;
                                        height: 170px;
                                      "
                                    ></span>
                                    <span
                                      class="from-to"
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        background: #0967c9;
                                        float: left;
                                      "
                                    ></span>
                                    <p
                                      style="
                                        max-width: 80%;
                                        float: left;
                                        padding-left: 18px;
                                        margin: 0;
                                      "
                                    >
                                      {{
                                        getDateTime2(
                                          jobDetails.jobInfo.pickupDate
                                        )
                                      }}
                                      {{ jobDetails.jobInfo.pickupTime }} ({{
                                        emailTimeZone
                                      }})
                                      <br />
                                      <br />
                                      {{ jobDetails.jobInfo.sourceLocation }}
                                    </p>
                                  </div>
                                  <div style="width: 100%; position: relative">
                                    <span
                                      class="from-to"
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        background: #0967c9;
                                        float: left;
                                      "
                                    ></span>
                                    <p
                                      v-if="jobDetails.jobInfo.offloadedDate"
                                      style="
                                        width: 80%;
                                        float: left;
                                        padding-left: 18px;
                                        margin: 0;
                                      "
                                    >
                                      {{
                                        getDateTime3(
                                          jobDetails.jobInfo.offloadedDate
                                        )
                                      }}
                                      ({{ emailTimeZone }})
                                      <br />
                                      <br />
                                      {{
                                        jobDetails.jobInfo.destinationLocation
                                      }}
                                    </p>
                                    <p
                                      v-if="!jobDetails.jobInfo.offloadedDate"
                                      style="
                                        width: 80%;
                                        float: left;
                                        padding-left: 18px;
                                        margin: 0;
                                      "
                                    >
                                      <br />
                                      <br />
                                      {{
                                        jobDetails.jobInfo.destinationLocation
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--MILESTONE-------UPDATES--INTRANSIT----TEMPLATES-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'milestone' &&
                          jobDetails.jobInfo.jobStatus == 6
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            width: 100%;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/truckkinapi/profilePic_10089047.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Your consignment with shipment ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                is
                                <strong style="font-weight: 600">
                                  on the move.
                                </strong>
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here are your job details.
                              </p>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  margin-bottom: 20px;
                                "
                              >
                                <table
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    background: #fafafa;
                                    padding: 10px;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="
                                        vertical-align: top;
                                        background: #fafafa;
                                      "
                                    >
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 16px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Booking Confirmed
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.newCreatedAt
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Driver Assigned
                                          <br />
                                          {{ jobData[0].Assigned }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo
                                                .driverAssignedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          In-Transit
                                          <br />
                                          {{ jobData[0].transit }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.inTransitDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 2px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10009777.png"
                                          style="
                                            width: 100%;
                                            margin-bottom: 2px;
                                          "
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Off-loaded/Delivered
                                          <br />
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="
                                    margin: 15px;
                                    display: inline-block;
                                    width: 100%;
                                  "
                                >
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=track'
                                    "
                                    style="
                                      float: left;
                                      margin-bottom: 10px;
                                      margin-right: 6px;
                                      border-radius: 4px;
                                      color: #fff;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: #0f67c9;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 180px;
                                    "
                                    target="_blank"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/truckkinapi/profilePic_10068463.png"
                                      style="
                                        width: 16px;
                                        margin-right: 9px;
                                        vertical-align: middle;
                                      "
                                    />Track Your Shipment
                                  </a>
                                  <a
                                    v-bind:href="
                                      this.constants.webAppUrl +
                                      '/#/tracking?jobId=' +
                                      jobDetails.jobInfo.jobId +
                                      '&type=documents'
                                    "
                                    style="
                                      border: 2px solid #0f67c9;
                                      float: left;
                                      border-radius: 4px;
                                      color: #0f67c9;
                                      text-decoration: none;
                                      box-sizing: border-box;
                                      text-align: center;
                                      background-color: transparent;
                                      height: 37px;
                                      display: block;
                                      line-height: 37px;
                                      font-weight: normal;
                                      font-size: 13px;
                                      width: 180px;
                                    "
                                    target="_blank"
                                    >View Documents</a
                                  >
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                  margin-bottom: 10px;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Job details: {{ jobDetails.jobInfo.jobId }}
                                </h5>
                                <div style="padding: 15px">
                                  <div
                                    class="details-section"
                                    style="
                                      font-size: 14px;
                                      width: 100%;
                                      word-break: break-all;
                                    "
                                  >
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Job Status :
                                      </span>
                                      <span
                                        style="color: #ea8328; font-weight: 600"
                                        >{{
                                          getJobStatus(
                                            jobDetails.jobInfo.jobStatus
                                          )
                                        }}</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 190px;
                                          display: inline-block;
                                        "
                                      >
                                        Number of Assignments :
                                      </span>
                                      <span>{{
                                        jobDetails.jobInfo
                                          .numberOfAssignmentsRequired
                                      }}</span>
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Loading Date :
                                      </span>
                                      <span
                                        >{{
                                          getDateTime2(
                                            jobDetails.jobInfo.pickupDate
                                          )
                                        }}
                                        {{ jobDetails.jobInfo.pickupTime }} ({{
                                          emailTimeZone
                                        }})</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>
                                    <p
                                      style="
                                        border-bottom: 1px solid #f2f2f2;
                                        padding-bottom: 20px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Off-loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Assignments
                                </h5>
                                <div
                                  v-for="item in assignments"
                                  :key="item.assignmentId"
                                  style="padding: 15px"
                                >
                                  <div
                                    class="details-section"
                                    style="
                                      font-size: 14px;
                                      width: 100%;
                                      word-break: break-all;
                                    "
                                  >
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Assignment Number :
                                      </span>
                                      <span
                                        style="color: #ea8328; font-weight: 600"
                                        >{{ item.assignmentId }}</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Status :
                                      </span>
                                      <span
                                        style="color: #ea8328; font-weight: 600"
                                        >{{
                                          assignmentStatus(
                                            item.assignmentStatus
                                          )
                                        }}</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Driver Name :
                                      </span>
                                      <span>{{
                                        item.driverFirstName
                                          ? item.driverFirstName +
                                            " " +
                                            item.driverLastName
                                          : "Driver not assigned yet"
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '1'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Vehicle Number :
                                      </span>
                                      <span
                                        >{{
                                          item.driverId
                                            ? item.driverData.truckPrefix
                                            : ""
                                        }}
                                        {{
                                          item.driverId
                                            ? item.driverData.truckNumber
                                            : "N.A."
                                        }}</span
                                      >
                                    </p>
                                    <p v-if="jobType == '2'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Flight Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p v-if="jobType == '3'">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Container Number :
                                      </span>
                                      <span>{{
                                        item.driverId
                                          ? item.driverData.truckNumber
                                          : "N.A."
                                      }}</span>
                                    </p>
                                    <p
                                      style="
                                        border-bottom: 1px solid #f2f2f2;
                                        padding-bottom: 20px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Comment :
                                      </span>
                                      <span
                                        style="
                                          background: #f2f2f2;
                                          padding: 10px;
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-word;
                                        "
                                        >{{
                                          item.currentChecklist
                                            ? item.currentChecklist.comments
                                              ? item.currentChecklist.comments
                                              : "N.A."
                                            : "N.A."
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div style="border: 1.4px solid #e2e2e2;">
                            <h3
                              style="margin: 15px;text-align: center;color: #686868;font-weight: normal;"
                            >
                              Know your shipment
                              status on the go
                            </h3>
                            <div style="margin: 10px auto;width: 230px;">
                              <a
                                href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                style="display: inline-block;"
                              >
                                <img
                                  src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                  alt="apple-store-icons"
                                  style="width: 100px;"
                                />
                              </a>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                style="display: inline-block;"
                              >
                                <img
                                  src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                  alt="play-store-icon"
                                  style="width: 112px;"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="footer"
                            style="padding: 20px 20px;background-color: #041742;padding-bottom: 10px;"
                          >
                            <div>
                              <p
                                style="color:#c0c5d0;text-align:center;margin:0;font-size: 12px;margin-bottom: 20px;padding: 0px 15px;"
                              >
                                Trukkin is a member of National Association of Freight and Logistics (NAFL) and is bound by the
                                Standards Trading Conditions (STC, available at www.nafl.ae) to the extent applicable.
                              </p>
                              <p style="font-size: 12px;color:#c0c5d0;font-weight:500;line-height:22px;word-spacing: 10px;"> <span
                            style="color:#fff;width: 80px;float: left;">Serving: </span> KSA UAE Kuwait Bahrain Oman
                        Jordan Egypt Iraq Lebanon Pakistan</p>
                              <p
                                style="font-size: 12px;color:#c0c5d0;font-weight:500;line-height:22px;"
                              >
                                <span style="color:#fff;width: 80px;float: left;">Contact:</span>
                                <img
                                  src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                  style="width: 32px;vertical-align: middle;margin-right: 5px;top:0px !important"
                                />+971-5636-81471
                                <img
                                  src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                  style="width: 26px;margin-left: 15px;margin-right: 5px;vertical-align: middle;top:0px !important"
                                />+966-9200-04275
                                <img
                                  src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                  style="width: 26px;vertical-align: middle;margin-right: 5px;margin-left: 15px;top:0px !important"
                                />+92-0311-TRUKKIN
                              </p>
                              <p
                                style="font-size:14px;color:#c0c5d0;font-weight:500;line-height:22px;"
                              >
                                <span style="color:#fff;width: 80px;float: left;">Email Us:</span> operations@trukkin.com
                              </p>
                              <p
                                style="font-size:14px;color:#c0c5d0;font-weight:500;line-height:22px;margin-bottom: 0;"
                              >
                                <a
                                  href="https://www.facebook.com/trukkin/"
                                  style="display: inline-block;"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                    style="width: 26px;vertical-align: middle;"
                                  />
                                </a>
                                <a
                                  href="https://www.instagram.com/trukkinhq/"
                                  style="display: inline-block;"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                    style="width: 26px; vertical-align: middle;margin-left: 15px;"
                                  />
                                </a>
                                <a
                                  href="https://twitter.com/TrukkinHQ"
                                  style="display: inline-block;"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                    style="width: 26px;vertical-align: middle;margin-left: 15px;"
                                  />
                                </a>
                                <a
                                  href="https://www.linkedin.com/company/trukkin"
                                  style="display: inline-block;"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/link.png"
                                    style="width: 26px;vertical-align: middle;margin-left: 15px;"
                                  />
                                </a>
                              </p>
                              <div
                                class="botm"
                                style="font-size: 12px;color: #c0c5d0;line-height: 32px;display: inline-block;width: 100%;margin-bottom: 0;"
                              >
                                <div class="bottom">
                                  <a
                                    href="mailto:contact@trukkin.com"
                                    style="text-decoration:none;color: #c0c5d0;"
                                  >Disclaimers</a> |
                                  <a
                                    href="https://trukkin.com/terms-conditions/"
                                    target="_blank"
                                    style="text-decoration:none;color: #c0c5d0;"
                                  >Terms &amp; Conditions</a> |
                                  <a
                                    href="https://trukkin.com/policy/"
                                    target="_blank"
                                    style="text-decoration:none;color: #c0c5d0;"
                                  >Privacy Policy</a>
                                </div>
                                <img
                                  class="verified"
                                  src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                  alt="apple-store-icons"
                                  style="width: 150px;float: right;"
                                />
                              </div>
                            </div>
                          </div>-->
                        </div>
                      </template>
                      <!--CANCEL--JOB--TEMPLATE------------->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'cancel' &&
                          jobDetails.jobInfo.jobStatus == -1
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                            width: 100%;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/trukkin.com/cacellation.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Apologies, we had to cancel your booking with
                                shipment ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                <strong style="font-weight: 600"
                                  >due to unfavourable conditions.</strong
                                >
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Trukkin is eager to serve you for your next
                                shipment requirement.
                              </p>
                              <div
                                style="
                                  margin: 15px;
                                  margin-left: 0;
                                  display: inline-block;
                                "
                              >
                                <a
                                  v-bin-href="'tel:800 TRUKKIN'"
                                  style="
                                    border-radius: 4px;
                                    color: #fff;
                                    text-decoration: none;
                                    box-sizing: border-box;
                                    text-align: center;
                                    background-color: #0f67c9;
                                    height: 37px;
                                    display: block;
                                    line-height: 37px;
                                    font-weight: normal;
                                    font-size: 13px;
                                    width: 108px;
                                    float: left;
                                    margin-right: 10px;
                                  "
                                  target="_blank"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/truckkinapi/profilePic_10010428.png"
                                    style="
                                      width: 22px;
                                      vertical-align: middle;
                                      margin-right: 2px;
                                    "
                                  />Call Us
                                </a>
                                <a
                                  href="mailto:contact@trukkin.com"
                                  style="
                                    border-radius: 4px;
                                    color: #fff;
                                    text-decoration: none;
                                    box-sizing: border-box;
                                    text-align: center;
                                    background-color: #0f67c9;
                                    height: 37px;
                                    display: block;
                                    line-height: 37px;
                                    font-weight: normal;
                                    font-size: 13px;
                                    width: 108px;
                                    float: left;
                                  "
                                  target="_blank"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/truckkinapi/profilePic_10088410.png"
                                    style="
                                      width: 24px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                    "
                                  />Email Us
                                </a>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                  margin-bottom: 10px;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Job details: {{ jobDetails.jobInfo.jobId }}
                                </h5>
                                <div style="padding: 15px">
                                  <div
                                    class="details-section"
                                    style="
                                      font-size: 14px;
                                      width: 100%;
                                      float: left;
                                    "
                                  >
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Job Status :
                                      </span>
                                      <span
                                        style="color: #d83636; font-weight: 600"
                                        >Cancelled</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 190px;
                                          display: inline-block;
                                        "
                                      >
                                        Number of Assignments :
                                      </span>
                                      <span>{{
                                        jobDetails.jobInfo
                                          .numberOfAssignmentsRequired
                                      }}</span>
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          display: inline-block;
                                        "
                                      >
                                        Cancelled Date :
                                      </span>
                                      <span
                                        >{{ getDateTime3(date) }} ({{
                                          emailTimeZone
                                        }})</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Cancellation Reason :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.cancellationReason
                                        }}</span
                                      >
                                    </p>
                                    <p>
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>

                                    <p
                                      style="
                                        border-bottom: 1px solid #f2f2f2;
                                        padding-bottom: 20px;
                                      "
                                    >
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Off-loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--COMPLETE--JOB--TEMPLATE------------->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'complete' &&
                          jobDetails.jobInfo.jobStatus == 2
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                            width: 100%;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/trukkin.com/CONSIGNMENT-DELIVERED.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad" style="padding: 24px 40px">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Congratulations! Your consignment with shipment
                                ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                has been
                                <strong style="font-weight: 600"
                                  >offloaded</strong
                                >
                                successfully.
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here are your job details.
                              </p>
                              <div style="margin: 15px; margin-left: 0">
                                <a
                                  v-bind:href="
                                    this.constants.webAppUrl +
                                    '/#/tracking?jobId=' +
                                    jobDetails.jobInfo.jobId
                                  "
                                  style="
                                    border-radius: 4px;
                                    color: #fff;
                                    text-decoration: none;
                                    box-sizing: border-box;
                                    text-align: center;
                                    background-color: #0f67c9;
                                    height: 37px;
                                    display: block;
                                    line-height: 37px;
                                    font-weight: normal;
                                    font-size: 13px;
                                    width: 158px;
                                  "
                                  target="_blank"
                                  >View Proof of Delivery</a
                                >
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  margin-bottom: 20px;
                                "
                              >
                                <table
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    background: #fafafa;
                                    padding: 10px;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="
                                        vertical-align: top;
                                        background: #fafafa;
                                      "
                                    >
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 16px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Booking confirmed
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.newCreatedAt
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Driver Assigned
                                          <br />
                                          {{ jobData[0].Assigned }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo
                                                .driverAssignedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10018335.png"
                                          style="width: 100%"
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          In-Transit
                                          <br />
                                          {{ jobData[0].transit }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.inTransitDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 0px !important;
                                          padding-left: 5px;
                                          color: #252525;
                                          font-size: 12px;
                                          font-weight: 600;
                                        "
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/truckkinapi/profilePic_10023419.png"
                                          style="
                                            width: 100%;
                                            margin-bottom: 2px;
                                          "
                                        />
                                        <br />
                                        <small
                                          style="
                                            line-height: 18px;
                                            font-size: 67% !important;
                                          "
                                        >
                                          Off-loaded/Delivered
                                          <br />
                                          {{ jobData[0].shipment }} /
                                          {{
                                            jobDetails.jobInfo
                                              .numberOfAssignmentsRequired
                                          }}
                                          <br />
                                          {{
                                            getTime4(
                                              jobDetails.jobInfo.offloadedDate
                                            )
                                          }}
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                  display: inline-block;
                                  margin-bottom: 10px;
                                "
                              >
                                <h5
                                  style="
                                    padding: 15px;
                                    color: #252525;
                                    font-size: 20px;
                                    text-align: left;
                                    margin: 0;
                                    background: #f8f8f8;
                                  "
                                >
                                  Job details: {{ jobDetails.jobInfo.jobId }}
                                </h5>
                                <div style="padding: 15px">
                                  <div
                                    class="details-section"
                                    style="
                                      font-size: 14px;
                                      width: 100%;
                                      float: left;
                                    "
                                  >
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Job Status :
                                      </span>
                                      <span
                                        style="color: #409f52; font-weight: 600"
                                        >Delivered</span
                                      >
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 190px;
                                          float: left;
                                        "
                                      >
                                        Number of Assignments :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo
                                            .numberOfAssignmentsRequired
                                        }}</span
                                      >
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Date :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          getDateTime2(
                                            jobDetails.jobInfo.pickupDate
                                          )
                                        }}
                                        {{ jobDetails.jobInfo.pickupTime }} ({{
                                          emailTimeZone
                                        }})</span
                                      >
                                    </p>
                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Loading Loaction :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span
                                      >
                                    </p>

                                    <p style="margin-bottom: 20px">
                                      <span
                                        style="
                                          color: #252525;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 170px;
                                          float: left;
                                        "
                                      >
                                        Off-Loading Location :
                                      </span>
                                      <span
                                        style="
                                          display: table;
                                          border-radius: 5px;
                                          word-break: break-all;
                                        "
                                        >{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  display: inline-block;
                                  margin-bottom: 15px;
                                  margin-top: 15px;
                                "
                              >
                                <div class="half-colm">
                                  <span
                                    style="
                                      width: 100%;
                                      color: #252525;
                                      line-height: 20px;
                                      font-weight: 600;
                                      width: 100%px;
                                      display: inline-block;
                                      padding-left: 40px;
                                    "
                                  >
                                    {{ this.journeyDis }}
                                    (approx) |
                                    {{ this.$props.journeyDays }} Days
                                  </span>
                                  <div
                                    style="
                                      width: 100%;
                                      position: relative;
                                      float: left;
                                      margin-top: 20px;
                                    "
                                  >
                                    <span
                                      class="from-to -line"
                                      style="
                                        width: 2px;
                                        background: #0967c9;
                                        float: left;
                                        height: 170px;
                                      "
                                    ></span>
                                    <span
                                      class="from-to"
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        background: #0967c9;
                                        float: left;
                                      "
                                    ></span>
                                    <p
                                      style="
                                        max-width: 80%;
                                        float: left;
                                        padding-left: 18px;
                                        margin: 0;
                                      "
                                    >
                                      {{
                                        getDateTime2(
                                          jobDetails.jobInfo.pickupDate
                                        )
                                      }}
                                      {{ jobDetails.jobInfo.pickupTime }} ({{
                                        emailTimeZone
                                      }})
                                      <br />
                                      <br />
                                      {{ jobDetails.jobInfo.sourceLocation }}
                                    </p>
                                  </div>
                                  <div style="width: 100%; position: relative">
                                    <span
                                      class="from-to"
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        background: #0967c9;
                                        float: left;
                                      "
                                    ></span>
                                    <p
                                      v-if="jobDetails.jobInfo.offloadedDate"
                                      style="
                                        width: 80%;
                                        float: left;
                                        padding-left: 18px;
                                        margin: 0;
                                      "
                                    >
                                      {{
                                        getDateTime3(
                                          jobDetails.jobInfo.offloadedDate
                                        )
                                      }}
                                      ({{ emailTimeZone }})
                                      <br />
                                      <br />
                                      {{
                                        jobDetails.jobInfo.destinationLocation
                                      }}
                                    </p>
                                    <p
                                      v-if="!jobDetails.jobInfo.offloadedDate"
                                      style="
                                        width: 80%;
                                        float: left;
                                        padding-left: 18px;
                                        margin: 0;
                                      "
                                    >
                                      <br />
                                      <br />
                                      {{
                                        jobDetails.jobInfo.destinationLocation
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--PAYMENT-DONE--TEMPLATE-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'paymentDone' &&
                          jobDetails.jobInfo.jobStatus == 9
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                            width: 100%;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/trukkin.com/payment-complete.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                Shukraan! The payment of your consignment with
                                shipment ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                is
                                <strong style="font-weight: 600"
                                  >complete</strong
                                >
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here’s your job and payment break down.
                              </p>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                "
                              >
                                <table
                                  class="table-body"
                                  style="width: 100%; border-spacing: 0"
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #f8f8f8;
                                        line-height: 32px;
                                      "
                                    >
                                      <th
                                        style="
                                          background: #f8f8f8;
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 18px;
                                          text-align: left;
                                        "
                                      >
                                        Details:
                                      </th>
                                      <th style="background: #f8f8f8"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        <span>Company Name :</span>
                                      </td>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          userDetail.companyName
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        <span>Company Address :</span>
                                      </td>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{ userDetail.address }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 600;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>From :</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 15px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 600;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>To :</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 15px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table-body" style="width: 100%">
                                  <thead style>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                        "
                                      >
                                        Sr No
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                          line-height: 17px;
                                        "
                                      >
                                        Consignment Details
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                        "
                                      >
                                        Quantity
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                        "
                                      >
                                        Price
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                        "
                                      >
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>1</span>
                                      </td>
                                      <td
                                        v-if="jobType == '1'"
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          line-height: 17px;
                                        "
                                      >
                                        <span>{{
                                          getText(
                                            truckTypes,
                                            jobDetails.jobInfo.truckType
                                          )
                                        }}</span>
                                      </td>
                                      <td
                                        v-if="jobType == '2'"
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          line-height: 17px;
                                        "
                                      >
                                        <span>{{
                                          getText(
                                            AirTypes,
                                            jobDetails.jobInfo.truckType
                                          )
                                        }}</span>
                                      </td>
                                      <td
                                        v-if="jobType == '3'"
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          line-height: 17px;
                                        "
                                      >
                                        <span>{{
                                          getText(
                                            SeaTypes,
                                            jobDetails.jobInfo.truckType
                                          )
                                        }}</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo
                                            .numberOfAssignmentsRequired
                                        }}</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo.jobPrice /
                                          jobDetails.jobInfo
                                            .numberOfAssignmentsRequired
                                        }}</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span
                                          >{{ jobDetails.jobInfo.jobPrice }}
                                          {{
                                            jobDetails.jobInfo.customerCurrency
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="table-body"
                                  style="
                                    width: 100%;
                                    border-top: 1px dashed #333;
                                    border-bottom: 1px dashed #333;
                                    word-break: break-all;
                                  "
                                >
                                  <thead style>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 5px 15px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Sub-Total:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          width: 100px;
                                        "
                                      >
                                        <span
                                          >{{ jobDetails.jobInfo.jobPrice }}
                                          {{
                                            jobDetails.jobInfo.customerCurrency
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Tax:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          width: 100px;
                                        "
                                      >
                                        <span>-</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Adjustments:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          width: 100px;
                                        "
                                      >
                                        <span>-</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="table-body"
                                  style="width: 100%; word-break: break-all"
                                >
                                  <thead style>
                                    <tr
                                      style="
                                        background: #fafafa;
                                        line-height: 32px;
                                      "
                                    >
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Grand Total:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 100px;
                                        "
                                      >
                                        <span
                                          >{{ jobDetails.jobInfo.jobPrice }}
                                          {{
                                            jobDetails.jobInfo.customerCurrency
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!--PAYMENT-PENDING--TEMPLATE-->
                      <template
                        class="payment-body"
                        v-if="
                          communicateDialog.type == 'paymentPending' &&
                          jobDetails.jobInfo.jobStatus == 8
                        "
                      >
                        <loading :active.sync="loading" />
                        <div
                          style="
                            background: #f57c0026;
                            font-family: Helvetica Neue, Helvetica, Helvetica,
                              sans-serif;
                            letter-spacing: 0.4px;
                            width: 100%;
                          "
                        >
                          <div
                            style="
                              width: 100%;
                              max-width: 600px;
                              height: auto;
                              margin: 15px auto;
                              background: #fff;
                              box-sizing: border-box;
                            "
                          >
                            <div
                              style="
                                height: auto;
                                background: #041742;
                                color: #fff;
                                width: 100%;
                                height: 68px;
                              "
                            >
                              <div
                                class="logo"
                                style="float: left; margin-top: 10px"
                              >
                                <img
                                  alt="logo"
                                  src="https://s3.amazonaws.com/trukkin.com/logo.jpg"
                                  style="width: 116px; padding-left: 20px"
                                />
                              </div>
                              <div
                                class="right-text"
                                style="
                                  padding-right: 20px;
                                  font-size: 14px;
                                  float: right;
                                  margin-top: 25px;
                                "
                              >
                                {{ currentDate }}
                              </div>
                            </div>
                            <img
                              src="https://s3.amazonaws.com/trukkin.com/payment-pending.png"
                              style="max-width: 100%"
                            />
                            <div class="main-pad">
                              <p
                                style="
                                  font-size: 25px;
                                  color: #000;
                                  font-weight: 300;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                  font-family: inherit;
                                "
                              >
                                The payment of your consignment with shipment ID
                                <span style="color: #f48122"
                                  >#{{ jobDetails.jobInfo.jobId }}</span
                                >
                                is
                                <strong style="font-weight: 600"
                                  >yet to be complete.</strong
                                >
                              </p>
                              <p
                                style="
                                  font-size: 16px;
                                  color: #000;
                                  font-weight: 300;
                                  line-height: 24px;
                                  margin: 0px;
                                  margin-bottom: 20px;
                                "
                              >
                                Here’s your job and payment break down.
                              </p>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  border: 1.4px solid #e2e2e2;
                                "
                              >
                                <table
                                  class="table-body"
                                  style="
                                    width: 100%;
                                    border-spacing: 0;
                                    word-break: break-all;
                                  "
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #f8f8f8;
                                        line-height: 32px;
                                      "
                                    >
                                      <th
                                        style="
                                          background: #f8f8f8;
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 18px;
                                          text-align: left;
                                        "
                                      >
                                        Details:
                                      </th>
                                      <th style="background: #f8f8f8"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        <span>Company Name :</span>
                                      </td>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          this.$props.userDetail.companyName
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 600;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>Company Address :</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          this.$props.userDetail.address
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 600;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>From :</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px 15px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo.sourceLocation
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style="
                                          white-space: nowrap;
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 600;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>To :</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 15px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo.destinationLocation
                                        }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="table-body"
                                  style="width: 100%; border-spacing: 0"
                                >
                                  <thead>
                                    <tr
                                      style="
                                        background: #f8f8f8;
                                        line-height: 32px;
                                      "
                                    >
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                          line-height: 17px;
                                        "
                                      >
                                        Sr No
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                          line-height: 17px;
                                        "
                                      >
                                        Consignment Details
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                          line-height: 17px;
                                        "
                                      >
                                        Quantity
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                          line-height: 17px;
                                        "
                                      >
                                        Price
                                      </th>
                                      <th
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          text-align: left;
                                          line-height: 17px;
                                        "
                                      >
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>1</span>
                                      </td>
                                      <td
                                        v-if="jobType == '1'"
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          line-height: 17px;
                                        "
                                      >
                                        <span>{{
                                          getText(
                                            truckTypes,
                                            jobDetails.jobInfo.truckType
                                          )
                                        }}</span>
                                      </td>
                                      <td
                                        v-if="jobType == '2'"
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          line-height: 17px;
                                        "
                                      >
                                        <span>{{
                                          getText(
                                            AirTypes,
                                            jobDetails.jobInfo.truckType
                                          )
                                        }}</span>
                                      </td>
                                      <td
                                        v-if="jobType == '3'"
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          line-height: 17px;
                                        "
                                      >
                                        <span>{{
                                          getText(
                                            SeaTypes,
                                            jobDetails.jobInfo.truckType
                                          )
                                        }}</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo
                                            .numberOfAssignmentsRequired
                                        }}</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span>{{
                                          jobDetails.jobInfo.jobPrice /
                                          jobDetails.jobInfo
                                            .numberOfAssignmentsRequired
                                        }}</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                        "
                                      >
                                        <span
                                          >{{ jobDetails.jobInfo.jobPrice }}
                                          {{
                                            jobDetails.jobInfo.customerCurrency
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="table-body"
                                  style="
                                    border-spacing: 0;
                                    width: 100%;
                                    border-top: 1px dashed #333;
                                    border-bottom: 1px dashed #333;
                                    word-break: break-all;
                                  "
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Sub-Total:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          width: 100px;
                                        "
                                      >
                                        <span
                                          >{{ jobDetails.jobInfo.jobPrice }}
                                          {{
                                            jobDetails.jobInfo.customerCurrency
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Tax:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          width: 100px;
                                        "
                                      >
                                        <span>-</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Adjustments:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 5px 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          width: 100px;
                                        "
                                      >
                                        <span>-</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="table-body"
                                  style="
                                    width: 100%;
                                    word-break: break-all;
                                    border-spacing: 0;
                                  "
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 130px;
                                        "
                                      >
                                        <span>Grand Total:</span>
                                      </td>
                                      <td
                                        style="
                                          padding: 10px;
                                          color: #252525;
                                          font-size: 14px;
                                          line-height: 20px;
                                          font-weight: 600;
                                          width: 100px;
                                        "
                                      >
                                        <span
                                          >{{ jobDetails.jobInfo.jobPrice }}
                                          {{
                                            jobDetails.jobInfo.customerCurrency
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div style="border: 1.4px solid #e2e2e2">
                              <h3
                                style="
                                  margin: 15px;
                                  text-align: center;
                                  color: #686868;
                                  font-weight: normal;
                                "
                              >
                                Know your shipment status on the go
                              </h3>
                              <div style="margin: 10px auto; width: 230px">
                                <a
                                  href="https://apps.apple.com/us/app/trukkin/id1227697871"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/apple.png"
                                    alt="apple-store-icons"
                                    style="width: 100px"
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.trukkin&hl=en_IN"
                                  style="display: inline-block"
                                >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/play-store.png"
                                    alt="play-store-icon"
                                    style="width: 112px"
                                  />
                                </a>
                              </div>
                            </div>
                            <div
                              class="footer"
                              style="
                                padding: 20px 20px;
                                background-color: #041742;
                                padding-bottom: 10px;
                              "
                            >
                              <div>
                                <p
                                  style="
                                    color: #c0c5d0;
                                    text-align: center;
                                    margin: 0;
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    padding: 0px 15px;
                                  "
                                >
                                  Trukkin is a member of National Association of
                                  Freight and Logistics (NAFL) and is bound by
                                  the Standards Trading Conditions (STC,
                                  available at www.nafl.ae) to the extent
                                  applicable.
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    word-spacing: 6px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Serving:</span
                                  >
                                  KSA UAE Kuwait Bahrain Oman Jordan Egypt Iraq
                                  Lebanon Pakistan
                                </p>
                                <p
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Contact:</span
                                  >
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-1.png"
                                    style="
                                      width: 32px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      top: 0px !important;
                                    "
                                  />+971-5636-81471
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-2.png"
                                    style="
                                      width: 26px;
                                      margin-left: 15px;
                                      margin-right: 5px;
                                      vertical-align: middle;
                                      top: 0px !important;
                                    "
                                  />+966-9200-04275
                                  <img
                                    src="https://s3.amazonaws.com/trukkin.com/flag-3.png"
                                    style="
                                      width: 26px;
                                      vertical-align: middle;
                                      margin-right: 5px;
                                      margin-left: 15px;
                                      top: 0px !important;
                                    "
                                  />+92-0311-TRUKKIN
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                  "
                                >
                                  <span
                                    style="
                                      color: #fff;
                                      width: 80px;
                                      float: left;
                                    "
                                    >Email Us:</span
                                  >
                                  operations@trukkin.com
                                </p>
                                <p
                                  style="
                                    font-size: 14px;
                                    color: #c0c5d0;
                                    font-weight: 500;
                                    line-height: 22px;
                                    margin-bottom: 0;
                                  "
                                >
                                  <a
                                    href="https://www.facebook.com/trukkin/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/fb.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/trukkinhq/"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/insta.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://twitter.com/TrukkinHQ"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/twt.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/trukkin"
                                    style="display: inline-block"
                                  >
                                    <img
                                      src="https://s3.amazonaws.com/trukkin.com/link.png"
                                      style="
                                        width: 26px;
                                        vertical-align: middle;
                                        margin-left: 15px;
                                      "
                                    />
                                  </a>
                                </p>
                                <div
                                  class="botm"
                                  style="
                                    font-size: 12px;
                                    color: #c0c5d0;
                                    line-height: 32px;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 0;
                                  "
                                >
                                  <div class="bottom">
                                    <a
                                      href="mailto:contact@trukkin.com"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Disclaimers</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/terms-conditions/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Terms &amp; Conditions</a
                                    >
                                    |
                                    <a
                                      href="https://trukkin.com/policy/"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #c0c5d0;
                                      "
                                      >Privacy Policy</a
                                    >
                                  </div>
                                  <img
                                    class="verified"
                                    src="https://s3.amazonaws.com/trukkin.com/certified.jpg"
                                    alt="apple-store-icons"
                                    style="width: 150px; float: right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-spacer>
            </v-card>
          </v-form>
        </v-dialog>
      </v-layout>
    </template>
    <!---Asset-ERROR------->
    <v-dialog
      v-model="dialog6"
      persistent
      :max-width="450"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          style="background-color: #fee9d0; color: black; padding-left: 130px"
          flat
        >
          <v-toolbar-title>{{ errorTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click.prevent="dialog6 = false">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text style="text-align: center" class="pa-4">
            <div class="pb-4">
              <img
                src="../../../assets/Character-jumping.svg"
                height="300"
                width="300"
              />
            </div>
            <div class="subheading">{{ errorMessage }}</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none !important"
              color="orange white--text "
              darken-1
              @click="dialog6 = false"
              :loading="loading"
              text
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
    <!--Driver-Error-Popup-->
    <v-dialog
      v-model="dialog7"
      persistent
      :max-width="450"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 130px"
          flat
        >
          <v-toolbar-title>{{ errorTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialog7 = false"
            ></v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text style="text-align: center" class="pa-4">
            <div class="pb-4">
              <img
                src="../../../assets/Illustrations-groovy.svg"
                height="300"
                width="300"
              />
            </div>
            <div class="subheading">{{ errorMessage }}</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none !important"
              color="orange white--text "
              darken-1
              @click="dialog7 = false"
              :loading="loading"
              text
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog8"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel8"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>

        <v-card-actions class="pt-0">
          <v-btn
            color="primary darken-1"
            text
            @click.native="this.dialog8 = agree"
            >Yes</v-btn
          >
          <!-- <v-btn color="grey" text @click.native="cancel8">Cancel</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogForVas"
      persistent
      :max-width="650"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #f5f5f5; color: black" flat>
          <v-toolbar-title> Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialogForVas = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4"> {{ vasMessagePopup }} </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              medium
              class="pr-2"
              style="text-transform: none !important"
              darken-1
              :disabled="loading"
              text
              @click="dialogForVas = false"
              >Cancel</v-btn
            >
            <div class="pl-2">
              <ValueAdded
                :assignmentDetails="vasAssignmentData"
                @refresh-list-Vas="refreshListViaVas()"
              />
            </div>
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  StorageKeys,
  TruckTypes,
  AirTypes,
  SeaTypes,
} from "../../../constants/constants";
import JobsProfileAssignmentDetail from "./JobsProfileAssignmentDetail.vue";
import ReceiveDriverDocPopup from "./ReceiveDriverDocPopup";
import GpsCollectionPopup from "./GpsCollectionPopup";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
import addProofOfDelivery from "@/components/Pop-ups/addProofOfDelivery";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import AssetAssign from "@/components/Pop-ups/AssetAssign";
import AssetUnassign from "@/components/Pop-ups/AssetUnassign";
import AddDocument from "@/components/JobDocuments/AddDocument.vue";
import JobsProfileDriverAssign from "@/components/Jobs/AssignDriver/JobsProfileDriverAssign";
import ValueAdded from "@/components/Jobs/JobDetails/ValueAdded";
import ChangeDriver from "../AssignDriver/ChangeDriver.vue";

import jobProfileDriverAssignAir from "@/components/Jobs/AssignDriver/jobProfileDriverAssignAir";
import jobProfileDriverAssignSea from "@/components/Jobs/AssignDriver/jobProfileDriverAssignSea";
import documents from "@/components/JobDocuments/documents";
import additionalChargesPopup from "@/components/JobDocuments/additionalChargesPopup";
import generateAdvancePopup from "@/components/JobDocuments/generateAdvancePopup";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import MilestoneTemplate from "@/components/Common/MilestoneTemplate";
import Swal from "sweetalert2";
import moment from "moment";
import { jobDetailAssignmentList } from "@/constants/datatable-headers.js";
import Loading from "vue-loading-overlay";
import {
  driverDocPdf,
  removeDriver,
  jobsProfileAssignmentList,
} from "../../../constants/api-urls";
let momentTz = require("moment-timezone");
export default {
  created() {
    this.$eventBus.$on("job-details-purchase-invoice", (data) => {
      this.jobAssignments.filter((x) => {
        if (x.assignmentId == data.socketPurchInvoiceData.assignmentId) {
          this.$emit("updateJobs");
        }
      });
    });
    this.$eventBus.$on("job-details-purchase-order", (data) => {
      this.jobAssignments.filter((x) => {
        if (x.assignmentId == data.socketPurchData.assignmentId) {
          this.$emit("updateJobs");
        }
      });
    });
    this.$eventBus.$on("togglePurchaseInvoiceSwitch", (data) => {
      console.log(data);
      this.$emit("updateJobs");
    });

    this.$eventBus.$on("shpiment-dialog-close", () => {
      this.shipmentdialog = false;
    });
    this.$eventBus.$on("delivery-dialog-close", () => {
      this.deliveryDialog = false;
    });
    this.$eventBus.$on("advance-dialog-close", () => {
      this.advancedialog = false;
    });
    this.date = new Date();
    let options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (this.$props.workingcountry === "UAE") {
      this.timeZone = "Asia/Dubai";
      this.emailTimeZone = "Gulf Standard Time";
    } else if (this.$props.workingcountry === "KSA") {
      this.timeZone = "Asia/Riyadh";
      this.emailTimeZone = "Saudi Arabia Standard";
    } else if (this.$props.workingcountry === "PAK") {
      this.timeZone = "Asia/Karachi";
      this.emailTimeZone = "Pakistan Standard Time";
    } else {
      this.timeZone = "Asia/Dubai";
      this.emailTimeZone = "Gulf Standard Time";
    }

    options.timeZone = this.timeZone;
    this.currentDate = this.date.toLocaleString("en-US", options);
    this.$eventBus.$on("driver-assignment", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("job-details-sales-order", (data) => {
      console.log(data);
      if (this.$route.params.id == data.socketSalesOrderData.jobId) {
        this.$eventBus.$emit("refresh-job");
      }
    });
    this.$eventBus.$on("unassign-asset", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("assign-asset", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("sales-invoice-assignment", () => {
      setTimeout(() => {
        this.$eventBus.$emit("refresh-job");
      }, 5000);
      this.$emit("updateJobs");
    });
    this.$eventBus.$on("cancelled", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("payment-completed", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("unassigned-completed", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("milestoneChecked-completed", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.$eventBus.$on("assignment-completed", () => {
      this.$eventBus.$emit("refresh-job");
    });
    this.communicateDialog.to = this.$props.userDetail
      ? this.$props.userDetail.email
      : "";
  },
  beforeDestroy() {
    this.$eventBus.$off("unassigned-completed");
    this.$eventBus.$off("driver-assignment");
    this.$eventBus.$off("milestoneChecked-completed");
    this.$eventBus.$off("payment-completed");
    this.$eventBus.$off("cancelled");
    this.$eventBus.$off("assign-asset");
    this.$eventBus.$off("unassign-asset");
    this.$eventBus.$off("assignment-completed");
    this.$eventBus.$off("shpiment-dialog-close");
    this.$eventBus.$off("delivery-dialog-close");
    this.$eventBus.$off("advance-dialog-close");
  },
  mounted() {
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    this.t = config.headers.authorization;
  },
  props: [
    "workingcountry",
    "milestoneItemsList",
    "driverDoc",
    "assignments",
    "milestoneItemsList",
    "jobId",
    "journeyDays",
    "jobData",
    "job",
    "loader",
    "userDetail",
    "steps",
    "milestoneList",
    "profile",
    "tabOrder",
    "jobType",
    "viewOnly",
  ],
  data() {
    return {
      dialog6: false,
      dialog7: false,
      errorMessage: null,
      processing: false,
      assignmentData: {},
      errorTitle: null,

      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      confirmation: "",
      confirmationDialog: {
        cancelAssignment: false,
        makePayment: false,
        completeAssignment: false,
        removeDriverAssignment: false,
        removeTransporterAssignment: false,
      },
      headers: jobDetailAssignmentList,
      date: "",
      noRole: false,
      isassetForm: false,
      index: "",
      emailTimeZone: "Gulf Standard Time",
      timeZone: "",
      milestoneTrack: {
        milestone: [],
        assId: null,
      },
      shipmentdialog: false,
      advancedialog: false,
      additionalChargesdialog: false,
      deliveryDialog: false,
      journeyDis: "",
      currentDate: "",
      truckTypes: TruckTypes,
      AirTypes: AirTypes,
      SeaTypes: SeaTypes,
      documentStatuses: [
        { text: "Pending", value: "Pending" },
        { text: "Fulfilled", value: "Fulfilled" },
      ],
      documentStatus: "Pending",
      milestoneItems: [],
      podStatus: false,
      emailDialog: false,
      message: "",
      title: "",
      dialog8: false,
      dialogForVas: false,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      jobAssignments: this.assignments,
      customer: null,
      loading: false,
      x: {
        message: "",
        loading: false,
        success: false,
        error: null,
      },
      communicateDialog: {
        error: "",
        success: "",
        show: false,
        to: "",
        type: "milestone",
        subject: null,
        cc: null,
        select: [],
        items: [],
        search: "", //sync search
      },
      dialog: false,
      items: [],
      search: "",
      vasMessagePopup: "",
      vasAssignmentData: {},
      error: null,
      jobDetails: {
        jobInfo: {
          jobId: null,
          jobStatus: null,
          startDate: null,
          sourceLocation: null,
          destinationLocation: null,
        },
      },
      t: null,
    };
  },
  watch: {
    assignments(val) {
      this.jobAssignments = val;
    },
    milestoneList(val) {
      this.milestoneItems = val;
    },
  },
  components: {
    JobsProfileAssignmentDetail,
    jobProfileDriverAssignAir,
    ChangeDriver,
    GpsCollectionPopup,
    jobProfileDriverAssignSea,
    ConfirmationDialog,
    AddDocument,
    ReceiveDriverDocPopup,
    JobsProfileDriverAssign,
    ValueAdded,
    generateAdvancePopup,
    additionalChargesPopup,
    addProofOfDelivery,
    AssetUnassign,
    documents,
    Loading,
    AssetAssign,
    GenerateAdvance,
    ErrorBox,
    SuccessDialog,
    MilestoneTemplate,
  },
  methods: {
    refresh() {
      this.$emit("updateJobs");
    },
    refreshListViaVas() {
      console.log("refres------");
      this.dialogForVas = false;
      this.$emit("updateJobs");
    },
    openVas(assignData) {
      this.vasMessagePopup = ` Are you sure you want to add duty for assignment Id- ${assignData.assignmentId}?`;
      this.dialogForVas = true;
      this.vasAssignmentData = assignData;
    },
    closeMessage() {
      this.x.success = false;
      // this.componentKey += 1;
      this.$emit("on-block");
      this.$emit("account-list-refresh");
    },
    errorDoc() {
      this.error = "Kindly assign driver!";
    },
    openTransporterUnassign(assignData) {
      this.confirmationDialog.removeTransporterAssignment = true;
      this.assignmentData = { ...assignData };
    },
    openDriverUnassign(assignData) {
      this.confirmationDialog.removeDriverAssignment = true;
      this.assignmentData = { ...assignData };
    },
    driverpdfDownload(data) {
      this.$eventBus.$emit("on-load");
      let url = "/dmsAdmin/driverDocPdf";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { driverId: data.driverData.driverId };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          const a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          this.$emit("driverPdfPopUpClosed", false);
          // Set the HREF to a Blob representation of the data to be downloaded
          a.href = response.data.data;
          a.download = "documents" + data.driverData.driverId;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        },
        (error) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          this.error = error.response.data.message;
        }
      );
    },
    errorOpen(data) {
      if (!data.assetId) {
        this.dialog6 = true;
        this.errorTitle = "Error Message";
        this.errorMessage = "Please assign IMEI on the assignment !";
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      } else {
        this.$emit("driverAssetPopUpClosed", false);
        let routeData = this.$router.resolve(
          `/asset/${data.assignmentId}/asset-form`
        );
        window.open(routeData.href, "_blank");
      }
    },
    driverErrorOpen(data) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      if (data.assignmentStatus < 2) {
        this.dialog7 = true;
        this.errorTitle = "Error Message";
        this.errorMessage = "Please assign driver on the assignment !";
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      } else {
        this.$emit("driverFormPopUpClosed", false);
        let routeData = this.$router.resolve(
          `/driver/${data.driverData.driverId}/driver-form`
        );
        window.open(routeData.href, "_blank");
      }
    },
    removeDriver() {
      this.confirmationDialog.removeDriverAssignment = false;
    },
    remove() {
      this.confirmationDialog.removeTransporterAssignment = false;
    },
    removeTransporterAssignment(data) {
      if (
        data.assignmentStatus == 2 ||
        data.assignmentStatus == 5 ||
        data.assignmentStatus == 6
      ) {
        this.error = "Please unassign the driver first !";
        this.loading = false;
        return;
      }
      this.loading = true;
      let url = "/dmsAdmin/unassignTransporter";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        assignmentId: data.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.confirmationDialog.removeTransporterAssignment = false;
          this.$emit("updateJobs");
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },
    removeDriverAssignment(data) {
      this.loading = true;
      if (!data.driverData) {
        this.loading = false;

        return;
      }
      let url = "/dmsAdmin/removeDriver";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        driverId: data.driverData.driverId.toString(),
        assignmentId: data.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.$emit("updateJobs");
          this.loading = false;
          this.confirmationDialog.removeDriverAssignment = false;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },
    noInternet() {
      if (!navigator.onLine) {
        this.loading = false;
        Swal.fire({
          title: "Oops!",
          text: "Your internet connection seems to be offline.Please try again.",
          type: "error",
          allowOutsideClick: false,
          confirmButtonColor: "orange",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.value) {
          }
        });
      }
    },

    async checkCommunication(section) {
      // this.processing=true;
      this.noRole = false;
      this.operationName = "communicate-customer-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.noRole = false;
      } else {
        this.processing = false;
        this.noRole = true;
        return;
      }
    },
    async checkSendCommunication() {
      this.noRole = false;
      this.processing = true;
      this.operationName = "communicate-customer-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.sendCommunication();
      } else {
        this.processing = false;
        return;
      }
    },
    goToProfile(jobId, assignId, _id) {
      this.$router.push(`/documents/${_id}`);
      localStorage.setItem("cust-status", this.viewOnly);
    },
    calculateDistance() {
      let url = jobsProfileAssignmentList.journeyDistance;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        JobId: this.jobId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.journeyDis = response.data.data.journeyDis;
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },

    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    smartRecipent() {
      this.items = [];

      if (this.search && this.search.trim().length > 0) {
        let url = jobsProfileAssignmentList.smartRecipent;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          jobId: this.$props.jobId.toString(),
          searchText: this.search.trim(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.items = response.data.data;
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
      } else {
        this.search = "";
      }
    },
    changeDocumentStatus(Id, index) {
      let url = jobsProfileAssignmentList.changeDocumnetStatus;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: Id.toString(),
        status: this.assignments[index].docStatus,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.message;
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    open() {
      this.dialog8 = true;
      this.title = "POD Status";
      this.message = "Are sure want to change the status?";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
      this.podStatus = true;
    },
    cancel8() {
      this.resolve(false);
      this.dialog8 = false;
    },
    checkValue() {
      if (this.communicateDialog.select) {
        this.communicateDialog.select = this.communicateDialog.select.filter(
          function (el) {
            return el.trim().length > 0;
          }
        );
        this.items = [];
      }
      this.search = "";
    },
    updateTags() {
      this.$nextTick(() => {
        this.search = this.search.trim();
        this.communicateDialog.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
    sendCommunication() {
      this.communicateDialog.success = "";
      this.communicateDialog.error = "";

      let url = jobsProfileAssignmentList.sendCommunication;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      if (!this.communicateDialog.subject) {
        this.communicateDialog.error = "Please provide a suitable subject";
        setTimeout(() => {
          this.communicateDialog.error = "";
        }, 7000);
        this.loading = false;
        return false;
      }
      let body = {
        jobId: this.jobDetails.jobInfo.jobId.toString(),
        subject: this.communicateDialog.subject,
        type: "",
      };
      if (this.communicateDialog.select.length) {
        let count_valid = 0;
        // let regex = '/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/';
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let k = 0; k < this.communicateDialog.select.length; k++) {
          this.communicateDialog.select[k] =
            this.communicateDialog.select[k].trim();

          if (re.test(this.communicateDialog.select[k])) {
            count_valid = count_valid + 1;
          }
        }
        if (count_valid !== this.communicateDialog.select.length) {
          this.communicateDialog.error =
            "Please make sure all the emails are properly formed";
          setTimeout(() => {
            this.communicateDialog.error = "";
          }, 7000);
          this.loading = false;
          return false;
        }
      }
      if (this.communicateDialog.select.length) {
        body.cc = this.communicateDialog.select;
      }
      if (this.communicateDialog.type === "milestone") {
        if (this.profile.jobStatus == 6) {
          body.status = "InTransit";
        }
        if (
          this.profile.jobStatus == 7 ||
          this.profile.jobStatus == 8 ||
          this.profile.jobStatus == 9 ||
          this.profile.jobStatus == 2
        ) {
          body.status = "Offloaded";
        }
        body.type = "milestone";
      } else if (this.communicateDialog.type === "paymentPending") {
        body.type = "paymentPending";
      } else if (this.communicateDialog.type === "paymentDone") {
        body.type = "paymentDone";
      } else if (this.communicateDialog.type === "complete") {
        body.type = "complete";
      } else if (this.communicateDialog.type === "cancel") {
        body.type = "cancel";
      } else {
        if (this.profile.jobStatus == 6) {
          body.status = "InTransit";
        }
        if (
          this.profile.jobStatus == 7 ||
          this.profile.jobStatus == 8 ||
          this.profile.jobStatus == 9 ||
          this.profile.jobStatus == 2
        ) {
          body.status = "Offloaded";
        }
        body.type = "driver";
      }
      let { apiUrl } = this.constants;
      this.loading = true;
      this.axios
        .post(`${apiUrl}/dmsAdmin/sendCommunication`, body, config)
        .then(
          (response) => {
            this.communicateDialog.success =
              "Email has been sent successfully!";
            this.communicateDialog.subject = "";
            this.communicateDialog.select = [];
            this.items = [];
            this.search = "";
            this.loading = false;
            this.$eventBus.$emit("refresh-job");
            setTimeout(() => (this.communicateDialog.success = ""), 7000);
          },
          (error) => {
            this.communicateDialog.error = error.response.data.message;
            setTimeout(() => (this.communicateDialog.error = ""), 7000);
            this.loading = false;
          }
        );
    },
    getTime3(date) {
      return moment.utc(date).format("MMM DD YYYY HH:mm");
    },
    getTime4(date) {
      return moment.utc(date).format("MMM DD YYYY ");
    },
    getDateTime2(time) {
      return moment.utc(time).format("DD-MM-YYYY");
    },
    getDateTime3(time) {
      return moment(time).tz(this.timeZone).format("DD-MM-YYYY HH:mm");
    },
    getDateTimeCurrent(time) {
      return moment(time).format("DD-MM-YYYY HH:mm");
    },
    getDateTime(time) {
      // let x = new Date(time);
      return moment.unix(time).format("DD/MM/YYYY hh:mm A");
    },
    getJobStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Completed";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Off-Loaded/Delivered";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";
        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    showCommunicate(type) {
      this.communicateDialog.to = this.$props.userDetail.email;

      this.communicateDialog.success = "";
      this.communicateDialog.error = "";
      this.communicateDialog.subject = "";
      this.communicateDialog.select = [];
      if (type === "milestone") {
        this.communicateDialog.type = "milestone";
      } else if (type === "paymentPending") {
        this.communicateDialog.type = "paymentPending";
      } else if (type === "paymentDone") {
        this.communicateDialog.type = "paymentDone";
      } else if (type === "complete") {
        this.communicateDialog.type = "complete";
      } else if (type === "cancel") {
        this.communicateDialog.type = "cancel";
      } else {
        this.communicateDialog.type = "driver";
      }

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.$props.jobId.toString(),
      };
      let { apiUrl } = this.constants;
      this.loading = true;
      this.communicateDialog.show = true;
      this.axios.post(`${apiUrl}/dmsAdmin/jobDetails`, body, config).then(
        (response) => {
          this.loading = false;
          const data = response.data.data;
          this.items = [];
          this.search = "";

          this.jobDetails = response.data.data;
          if (this.jobDetails.jobInfo.cc)
            this.communicateDialog.select = this.jobDetails.jobInfo.cc;

          if (this.jobDetails.jobInfo.subject)
            this.communicateDialog.subject = this.jobDetails.jobInfo.subject;
          for (let i = 0; i < this.assignments.length; i++) {
            if (this.assignments[i].completedCheckList) {
              this.assignments[i].currentChecklist =
                this.assignments[i].completedCheckList[
                  this.assignments[i].completedCheckList.length - 1
                ];
              this.assignments[i].updatedChecklistOn =
                this.assignments[i].completedCheckList[
                  this.assignments[i].completedCheckList.length - 1
                ].updatedAt;

              this.assignments[i].upcomingChecklistName = "";
              this.assignments[i].currentChecklistName = "";
              for (
                let j = 0;
                j < this.assignments[i].milestoneData.checkList.length;
                j++
              ) {
                if (
                  this.assignments[i].currentChecklist.checkListId ===
                  this.assignments[i].milestoneData.checkList[j]._id
                ) {
                  this.assignments[i].currentChecklistName =
                    this.assignments[i].milestoneData.checkList[j].name;
                }
              }
            }
          }
        },
        (error) => {
          this.error = "Something went wrong. Please try again later!";
          this.loading = false;
        }
      );
    },
    closeMessage() {
      this.x.success = false;
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    updateCustomer(jobId) {
      let url = jobsProfileAssignmentList.updateTrackStatusToCust;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: jobId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.message;
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    updateAssignment() {
      this.$emit("updateJobs");
    },
    getDriverAssignment(assignment) {
      return assignment.driverFirstName
        ? `${assignment.driverFirstName} ${assignment.driverLastName}`
        : "-";
    },
  },
};
</script>
<style lang="scss">
.v-table__overflow {
  width: 100%;
  overflow-x: auto !important;
  overflow-y: inherit !important;
}
.minHeight {
  min-height: 300px;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
th {
  background: none !important;
  font-weight: bolder !important;
  color: black !important;
}
.payment-body {
  margin: 0 auto !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.updateCustomer {
  margin-left: 55%;
}

.waybill-btn {
  padding: 10px;
  background-color: #ff9800;
  color: #fff;
  text-decoration: none;
  position: relative;
  top: 8px;
  left: 50px;
}
.m-l-5 {
  margin-left: 5px;
}
.communicate-btn {
  border: 2px solid darkorange !important;
  color: darkorange !important;
  background-color: transparent !important;
}
.orange-color {
  border-radius: 2px;
  background-color: darkorange !important;
  color: #ffffff !important;
  border: 2px solid darkorange !important;
}
.comments-view .v-toolbar {
  width: 50%;
  margin-left: 25%;
}
.theme--light.v-chip {
  background: #feebd9 !important;
  color: rgb(248, 159, 69) !important;
}
.tag-input span.chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
}

.tag-input span.v-chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
  padding-left: 7px;
}

.tag-input span.v-chip::before {
  content: "label";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.info {
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px;
}
.error.info {
  background-color: #ef5350 !important;
}
.success.info {
  background-color: #66bb6a !important;
}
.table-body tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

.v-toolbar--fixed {
  position: inherit;
}
.white-button {
  border-radius: 5px;
  background-color: white !important;
  color: grey !important;
  border: 2px solid grey !important;
}
@media screen and (max-width: 767px) {
  .main-pad {
    padding: 10px !important;
  }

  .details-section p span {
    width: 100% !important;
  }

  .details-section {
    width: 100%;
  }

  .details-section p span {
    width: 100% !important;
    display: inline-block;
  }

  .footer p span {
    width: 100% !important;
  }

  .botm {
    text-align: left;
  }

  tr {
    display: grid;
  }

  .verified {
    float: none !important;
    margin: 0 auto !important;
  }

  .bottom {
    width: 100%;
    font-size: 11px;
  }

  .img-profile {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .footer p {
    font-size: 12px !important;
  }

  .footer p img {
    margin: 0 !important;
    margin-right: 0px;
    margin-right: 13px !important;
  }
}

.main-pad {
  padding: 24px 40px;
}

@media screen and (min-width: 767px) {
  .details-section {
    width: 80%;
  }

  .img-profile {
    width: 20%;
  }
}

.bottom {
  float: left;
}
p {
  margin-bottom: 15px !important;
}
</style>
