<template>
  <div>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-card-title class="bg-clr">
        <v-btn fab flat @click.native="goback">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>

        <customerChangePopup
          :id="detail._id"
          v-if="checkCustomerChange(detail.jobStatus)"
        />

        <SendMessage
          :userId="detail.customerId"
          :userName="detail.customer"
          :status="detail.jobStatus"
          :viewOnly="viewOnly"
          v-permissions="'send-message-jobs'"
        />
        <SendComments
          :userId="detail.customerId"
          :userName="detail.customer"
          :status="detail.jobStatus"
          :id="detail.jobId"
          :detail="detail"
          :type="1"
          :viewOnly="viewOnly"
        />
      </v-card-title>
      <v-card-text>
        <v-card>
          <v-layout>
            <v-flex xs1><div class="heading pb-3 f-20">Details</div></v-flex>
            <v-flex xs11 pr-3><v-divider class="my-5" /></v-flex>
          </v-layout>

          <v-layout pb-5>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Job ID</div>
              <div class="heading">{{ detail.jobId }}</div>
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Customer ID</div>
              <div
                class="heading"
                v-if="userDetail && userDetail.customerSerialNumber"
              >
                {{ userDetail.customerSerialNumber }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs3 pr-3 v-if="detail.customer">
              <div class="subheading muted">Customer Name</div>
              <div class="heading" v-if="detail.customer">
                {{ detail.customer || "NA" }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs3 pr-3 v-else>
              <div class="subheading muted">Customer Email</div>
              <div class="heading">
                {{ detail.customerEmail }}
              </div>
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Company Name</div>
              <div class="heading" v-if="detail.companyName">
                {{ detail.companyName || "NA" }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
          </v-layout>
          <v-layout pb-5>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Job Status</div>
              <span class="heading" style="color: red">{{
                this.jobsStatus(detail.jobStatus)
              }}</span>
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted" v-if="detail.natureOfCargo">
                Industry
              </div>
              <div class="heading" v-if="detail.natureOfCargo">
                {{ detail.natureOfCargo.name }}
              </div>

              <v-select
                v-else
                class="dropdown-width"
                :items="natureOfCargos"
                item-text="text"
                v-model="natureOfCargo"
                single-line
                label="Industry"
                @change="updateJob(detail.jobId)"
              />
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Quote needed by date\time</div>
              <div
                class="heading"
                v-if="detail.quoteNeededBy"
                style="color: blue"
              >
                {{ getTime4(detail.quoteNeededBy) || "NA" }}
              </div>
              <div class="heading" v-else style="color: blue">NA</div>
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted" v-if="detail.packingType">
                Packing Type
              </div>
              <div class="heading" v-if="detail.packingType">
                {{ getText(packingTypes, detail.packingType) }}
              </div>
              <v-select
                v-else
                :items="packingTypes"
                class="dropdown-width"
                v-model="packingType"
                single-line
                label="Packing Type"
                @change="updateJob(detail.jobId)"
              />
            </v-flex>
          </v-layout>
          <v-layout v-if="detail.proof && detail.proof.length">
            <v-flex xs4 pr-4 pb-5>
              <div class="subheading muted">Proof</div>

              <ProofOfDelivery :deliveryProof="detail.proof" />
            </v-flex>
          </v-layout>
        </v-card>
        <v-card v-permissions="'billing-status-section'">
          <v-layout>
            <v-flex xs2
              ><div class="heading f-20 pb-3">Billing & Status</div></v-flex
            >
            <v-flex xs11 pr-3><v-divider class="my-5" /></v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs4 pr-3>
              <div class="subheading muted">Billing Address</div>
              <div class="heading" v-if="userDetail && userDetail.address">
                {{ userDetail.address }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs4 pr-3>
              <div class="subheading muted">Invoicing Country</div>
              <div class="heading" v-if="detail.invoicingCompany">
                {{ getInvoiceBy(detail.invoicingCompany) }}
              </div>
              <div
                class="heading"
                v-else-if="userDetail && userDetail.invoicingCountry"
              >
                {{ getInvoiceBy(userDetail.invoicingCountry) }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs4 md4 sm4 pr-4>
              <div class="subheading muted">Invoicing Branch</div>
              <div
                class="heading"
                v-if="detail.invoicingCity == 1 && detail.invoicingCompany == 2"
              >
                FZ
              </div>
              <div
                class="heading"
                v-else-if="
                  detail.invoicingCity == 2 && detail.invoicingCompany == 2
                "
              >
                LLC
              </div>
              <div
                class="heading"
                v-else-if="
                  detail.invoicingCity == 1 && detail.invoicingCompany == 1
                "
              >
                Damman
              </div>
              <div
                class="heading"
                v-else-if="
                  detail.invoicingCity == 2 && detail.invoicingCompany == 1
                "
              >
                Jeddah
              </div>
              <div
                class="heading"
                v-else-if="
                  detail.invoicingCity == 3 && detail.invoicingCompany == 1
                "
              >
                Riyadh
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 1 &&
                  userDetail.invoicingCountry == 2
                "
              >
                FZ
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 2 &&
                  userDetail.invoicingCountry == 2
                "
              >
                LLC
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 1 &&
                  userDetail.invoicingCountry == 1
                "
              >
                Damman
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 2 &&
                  userDetail.invoicingCountry == 1
                "
              >
                Jeddah
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 1 &&
                  userDetail.invoicingCountry == 4
                "
              >
                DXB
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 3 &&
                  userDetail.invoicingCountry == 1
                "
              >
                Riyadh
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 1 &&
                  userDetail.invoicingCountry == 3
                "
              >
                Lahore
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 2 &&
                  userDetail.invoicingCountry == 3
                "
              >
                Karachi
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 3 &&
                  userDetail.invoicingCountry == 3
                "
              >
                Faisalabad
              </div>

              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 3 &&
                  userDetail.invoicingCountry == 5
                "
              >
                Salalah
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 2 &&
                  userDetail.invoicingCountry == 5
                "
              >
                Muscat
              </div>
              <div
                class="heading"
                v-else-if="
                  userDetail &&
                  userDetail.invoicingCity == 1 &&
                  userDetail.invoicingCountry == 5
                "
              >
                Sohar
              </div>
              <div
                class="heading"
                v-else-if="userDetail && userDetail.invoicingCity"
              >
                {{ userDetail.invoicingCompany }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs4 md4 sm4 pr-3>
              <div class="subheading muted" v-if="detail.paymentEntity">
                Payment Entity
              </div>
              <div class="heading pb-5" v-if="detail.paymentEntity">
                {{ detail.paymentEntity }}
              </div>
              <v-radio-group
                :disabled="detail.jobStatus == 10 || viewOnly == 'view'"
                class="mt-0 pt-0"
                v-else
                v-model="charge"
                @change="updateJob(detail.jobId)"
                :mandatory="false"
              >
                <div class="subheading muted">Who Will Pay Charge</div>
                <div class="heading">
                  <v-radio
                    row
                    label="Shipper"
                    value="shipper"
                    color="black"
                  ></v-radio>
                  <v-radio
                    row
                    label="Receiver"
                    value="receiver"
                    color="black"
                  ></v-radio>
                </div>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout pb-5>
            <v-flex xs3 pr-3 v-if="detail.commodity">
              <div class="subheading muted">Commodity</div>
              <span class="heading">{{ detail.commodity }}</span>
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted">
                {{
                  detail.workingCountry != "PAK" ? "VAT Number" : "STRN Number"
                }}
              </div>
              <div
                class="heading"
                v-if="userDetail && userDetail.companyVatNumber"
              >
                {{ userDetail.companyVatNumber }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex
              xs3
              pr-3
              v-permissions="'generate-sales-invoice'"
              v-if="
                detail.isReadyToGenerateSalesInvoice &&
                !detail.isSalesInvoiceGenerated &&
                detail.workOrderCompleted &&
                detail.workingCountry != 'PAK' &&
                detail.workingCountry != 'OMAN' &&
                !detail.isSaleOrderInQueue &&
                !detail.isAssignmentCustomerReceiptInitiated &&
                detail.jobStatus != -1
              "
            >
              <div
                class="subheading muted"
                v-permissions="'generate-sales-invoice'"
              >
                Generate Sales Invoice
              </div>

              <div class="heading">
                <button
                  @click="fetchBank()"
                  type="button"
                  v-permissions="'generate-sales-invoice'"
                  v-if="!detail.isSaleInvoviceBackgroundProcess"
                  :disabled="viewOnly == 'view'"
                  class="track-ass-btn m-t-10 m-l-10 orange-button"
                  title="Generate"
                >
                  Generate
                </button>
                <div
                  v-if="detail.isSaleInvoviceBackgroundProcess"
                  style="word-break: break-word"
                  class="subheading muted"
                >
                  Background process is ongoing.
                </div>
              </div>

              <div v-if="detail.assignementSalesInvoiceGenerated">
                *Assignment level sales invoice has already been generated for
                this.
              </div>
            </v-flex>

            <v-flex
              xs3
              pr-3
              v-if="
                detail.invoiceNumber &&
                detail.isSalesInvoiceGenerated &&
                detail.workOrderCompleted &&
                detail.workingCountry != 'PAK'
              "
            >
              <div class="subheading muted">Sales Invoice Number</div>
              <div class="heading">
                {{ detail.invoiceNumber }}
              </div>
            </v-flex>

            <v-flex
              xs3
              pr-3
              v-permissions="'view-sales-invoice'"
              v-if="
                detail &&
                detail.invoicePdfUrl &&
                detail.isSalesInvoiceGenerated &&
                detail.workOrderCompleted &&
                detail.workingCountry != 'PAK'
              "
            >
              <div class="subheading muted">View Sales Invoice</div>
              <div v-if="detail.invoiceNumber == 'NA'">N.A</div>
              <div v-else>
                <div class="heading">
                  <v-btn
                    :disabled="viewOnly == 'view'"
                    outline
                    style="padding: 0 5px"
                    @click="
                      checkViewInvoice(
                        detail.invoicePdfUrl,
                        detail.isDownloadInvocie,
                        detail.isSaleInvoviceBackgroundProcess,
                        detail.invoiceNumber
                      )
                    "
                    color="orange"
                    ><v-icon style="margin: 0px !important" right dark
                      >download</v-icon
                    >
                    Download
                  </v-btn>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
        <v-card v-permissions="'price-section'">
          <v-layout>
            <v-flex xs1> <div class="heading pb-3 f-20">Price</div></v-flex>
            <v-flex xs11 pr-3><v-divider class="my-5" /></v-flex>
          </v-layout>

          <v-layout pb-5>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Total Price</div>
              <span class="heading" v-if="detail.jobPrice"
                >{{ detail.jobPrice }} {{ detail.customerCurrency }}</span
              >
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs3>
              <div class="subheading muted" v-if="detail.customerCurrency">
                Currency
              </div>
              <div class="heading" v-if="detail.customerCurrency">
                {{ detail.customerCurrency }}
              </div>
              <v-select
                v-else
                v-model="currency"
                :items="currency"
                item-text="currency"
                item-value="abbr"
                label="Currency"
                persistent-hint
                return-object
                single-line
                :disabled="viewOnly == 'view'"
                @change="updateJob(detail.jobId)"
              ></v-select>
            </v-flex>
            <v-flex xs3 pr-3>
              <div class="subheading muted">Price Type</div>
              <span class="heading" v-if="detail.chargesType">{{
                detail.chargesType
              }}</span>
              <div class="heading" v-else>Exclusive</div>
            </v-flex>
          </v-layout>
          <v-layout v-if="detail.workingCountry == 'PAK'">
            <v-flex xs1> <div class="heading pb-3 f-20">Contact</div></v-flex>
            <v-flex xs11 pr-3><v-divider class="my-5" /></v-flex>
          </v-layout>

          <v-layout pb-5 v-if="detail.workingCountry == 'PAK'">
            <v-flex xs3 pr-3>
              <div class="subheading muted">Name</div>
              <span class="heading" v-if="detail.contactPersonName">{{
                detail.contactPersonName
              }}</span>
              <div class="heading" v-else>NA</div>
            </v-flex>
            <v-flex xs3>
              <div class="subheading muted" v-if="detail.customerCurrency">
                Address
              </div>
              <div class="heading" v-if="detail.contactAddress">
                {{ detail.contactAddress || N.A }}
              </div>
              <div class="heading" v-else>NA</div>
            </v-flex>
          </v-layout>

          <v-layout row class="py-2"> </v-layout>
          <v-form
            ref="customerForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-layout
              v-if="!userDetail.address || !userDetail.companyVatNumber"
            >
              <v-flex xs5 class="px-2">
                <span class="muted caption">Billing Address*</span>
                <v-text-field
                  placeholder="Provide Customer Billing Address"
                  v-model="customer.customerAddress"
                  :value="customer.customerAddress"
                  single-line
                  :rules="[rules.noWhiteSpace, rules.required]"
                  maxlength="100"
                  class="pt-0 currencyTitle"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 class="px-2">
                <span class="muted caption">{{
                  detail.workingCountry != "PAK" ? "VAT Number*" : "STRN Number"
                }}</span>
                <v-text-field
                  :placeholder="
                    detail.workingCountry != 'PAK'
                      ? 'VAT Number*'
                      : 'STRN Number*'
                  "
                  v-model="customer.companyVatNumber"
                  :value="customer.companyVatNumber"
                  single-line
                  :rules="[rules.vatNumber, rules.required]"
                  counter
                  maxlength="20"
                  class="pt-0 currencyTitle"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <button
                  class="track-ass-btn"
                  flat
                  prepend-icon
                  color="white darken-1"
                  :disabled="detail.jobStatus == 10 || viewOnly == 'view'"
                  @click="updateCustomerInfo(detail.jobId)"
                  :_id="detail._id"
                  :loading="loading"
                >
                  Update Info
                </button>
              </v-flex>
            </v-layout>
          </v-form>
          <v-layout>
            <v-flex xs3 pr-4>
              <div class="subheading muted">Additional Details</div>
              <div class="heading">{{ detail.otherDetails || "N.A. " }}</div>
            </v-flex>

            <v-flex
              xs3
              pr-4
              v-if="
                detail.workingCountry != 'PAK' &&
                detail.workingCountry != 'OMAN'
              "
            >
              <div class="subheading muted">Work Order</div>

              <div
                v-if="
                  userDetail &&
                  userDetail.address &&
                  userDetail.companyVatNumber &&
                  detail.workOrderCompleted == true
                "
              >
                Ref Id: {{ detail.workOrderRefId }}
              </div>
              <div
                v-else-if="
                  !userDetail.invoicingCity && userDetail.invoicingCountry
                "
              >
                Please update customer invoicing details.
              </div>
              <div
                v-else-if="
                  !userDetail.invoicingCity &&
                  !userDetail.invoicingCountry &&
                  !detail.invoicingCompany &&
                  !detail.invoicingCity
                "
              >
                Please update customer invoicing details.
              </div>
              <div
                v-else-if="
                  userDetail &&
                  userDetail.address &&
                  userDetail.companyVatNumber &&
                  detail.workOrderCompleted == false
                "
              >
                <button
                  v-if="
                    (userDetail &&
                      userDetail.invoicingCity &&
                      userDetail.invoicingCountry) ||
                    (detail.invoicingCompany &&
                      detail.invoicingCity &&
                      !detail.isWorkOrderInQueue)
                  "
                  class="track-ass-btn"
                  flat
                  prepend-icon
                  color="white darken-1"
                  @click="retry(detail.jobId)"
                  :_id="detail._id"
                  :loading="loading"
                  :disabled="viewOnly == 'view' || detail.jobStatus == 10"
                >
                  Retry
                </button>
                <div v-if="isWorkOrderInQueue">
                  Background process is ongoing
                </div>
              </div>
              <div
                v-else-if="
                  userDetail &&
                  userDetail.companyVatNumber &&
                  userDetail.address
                "
              >
                <button
                  class="track-ass-btn"
                  v-if="!detail.isWorkOrderInQueue"
                  flat
                  prepend-icon
                  color="white darken-1"
                  @click="retry(detail.jobId)"
                  :_id="detail._id"
                  :loading="loading"
                  :disabled="viewOnly == 'view'"
                >
                  Create
                </button>
                <div v-if="detail.isWorkOrderInQueue">
                  Background process is ongoing
                </div>
              </div>
              <div v-else>
                Please Update Customer Billing address and Vat number*
              </div>
            </v-flex>
            <v-flex
              xs3
              pr-4
              v-if="
                detail.workingCountry != 'PAK' &&
                detail.workingCountry != 'OMAN'
              "
            >
              <div
                class="subheading muted"
                v-if="detail.voucherIdRealBooks || detail.errorWhileRealbooks"
              >
                Sales Voucher No
              </div>
              <div v-if="detail.isSaleOrderInQueue">
                Background process is ongoing
              </div>
              <div v-else-if="!detail.workOrderCompleted">
                Work order is not generated
              </div>
              <div v-else-if="detail.jobStatus == -1">Job is cancelled</div>
              <div
                v-else-if="
                  detail.isSalesOrderIntiated && detail.voucherIdRealBooks
                "
              >
                {{ detail.voucherIdRealBooks }}
              </div>
              <div
                v-else-if="
                  detail.isSalesOrderIntiated == false &&
                  detail.workOrderCompleted == true &&
                  detail.errorWhileRealbooks == true
                "
              >
                <span v-if="detail.isSaleOrderInQueue">
                  Background process are ongoing
                </span>
                <span
                  v-if="
                    !detail.isSaleOrderInQueue &&
                    detail.isSaleOrderBackgroundProcess &&
                    detail.errorWhileRealbooks
                  "
                  >Data inappropriate, kindly update data</span
                >
                <button
                  class="track-ass-btn"
                  flat
                  v-if="
                    !detail.isSaleOrderInQueue &&
                    !detail.isSaleOrderBackgroundProcess &&
                    detail.errorWhileRealbooks
                  "
                  prepend-icon
                  color="white darken-1"
                  @click="retrySO(detail.jobId)"
                  :_id="detail._id"
                  :loading="loading"
                  :disabled="viewOnly == 'view'"
                >
                  Retry
                </button>
              </div>
              <div
                v-else-if="
                  detail.isReadyToGenerateSalesInvoice &&
                  detail.isSalesOrderIntiated == false &&
                  !detail.voucherIdRealBooks &&
                  detail.workOrderCompleted == true
                "
              >
                <span v-if="detail.isSaleOrderInQueue">
                  Background process is ongoing
                </span>
                <button
                  v-if="!detail.isSaleOrderInQueue"
                  class="track-ass-btn"
                  flat
                  prepend-icon
                  color="white darken-1"
                  @click="retrySO(detail.jobId)"
                  :_id="detail._id"
                  :loading="loading"
                  :disabled="viewOnly == 'view'"
                >
                  Create
                </button>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog1"
      persistent
      :max-width="700"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title>Generate Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="closeBankInfo()"
              :disabled="loading"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4">
            <!-- <div class="pb-4">
              <img
                src="../../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div> -->
            <div
              v-if="!this.userDetail.invoiceDay"
              style="padding-left: 15px; text-align: left !important"
              class="subheading muted"
            >
              Please enter credit limit duration
            </div>
            <v-layout v-if="!this.userDetail.invoiceDay">
              <v-flex xs9 pt-3 pl-3>
                <div class="heading">
                  <v-text-field
                    placeholder="Credit Duration"
                    v-model="invoiceDay"
                    :value="invoiceDay"
                    suffix="days"
                    type="number"
                    single-line
                    class="pt-0 currencyTitle"
                  ></v-text-field>
                </div>
              </v-flex>
              <v-flex pt-3 pl-4>
                <button
                  class="track-ass-btn"
                  style="float: left"
                  type="button"
                  @click="addCreditDuration()"
                  title="view"
                >
                  Save
                </button>
              </v-flex>
            </v-layout>
            <div v-if="this.userDetail.invoiceDay">
              <div class="fonthead">
                Are you sure want to generate invoice number?
              </div>
              <v-layout class="pt-2">
                <v-flex xs6 md6>
                  <v-autocomplete
                    placeholder=" Please Choose Bank"
                    :items="bankInfo"
                    class="pt-0 currencyTitle"
                    v-model="bank"
                    item-text="text"
                    item-value="value"
                    @change="setCustomerValues($event)"
                  />
                </v-flex>
                <v-flex xs6 md6 v-if="bank.length">
                  <div class="sub-heading muted">
                    Account Number:
                    <span style="color: black" class="heading">{{
                      accountNo
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>

              <v-layout class="pb-3" v-if="bank.length">
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    IBAN NO:
                    <span style="color: black" class="heading">{{
                      ibanNo
                    }}</span>
                  </div>
                </v-flex>
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Branch Code :
                    <span style="color: black" class="heading">{{
                      branchCode
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3" v-if="bank.length">
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Swift Code :
                    <span style="color: black" class="heading">{{
                      swiftCode
                    }}</span>
                  </div>
                </v-flex>
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Beneficiary Name :
                    <span style="color: black" class="heading">{{
                      beneficiaryName
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>

              <!-- <v-card-actions class="pt-2 pb-2" style="justify-content: right">
                <v-btn
                  medium
                  style="text-transform: none; !important"
                  color="red white--text "
                  darken-1
                  :disabled="loading"
                  text
                  @click="invoiceDialog = false"
                  >No</v-btn
                >

                <v-btn
                  medium
                  style="text-transform: none; !important"
                  color="green white--text "
                  v-permissions="'generate-purchase-invoice'"
                  darken-1
                  @click="generateInvoiceNumber()"
                  :disabled="loading"
                  text
                  >Yes</v-btn
                >
              </v-card-actions> -->
            </div>
          </v-card-text>

          <v-card-actions
            class="pt-2 pb-2"
            style="justify-content: right"
            v-if="this.userDetail.invoiceDay"
          >
            <v-btn
              medium
              style="text-transform: none !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="closeBankInfo()"
              >No</v-btn
            >
            <v-btn
              medium
              style="text-transform: none !important"
              color="green white--text "
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
  </div>
</template>

<script>
import { StorageKeys } from "../../../constants/constants";
import { EventBus } from "../../../event-bus.js";
import {
  TruckTypes,
  PackingTypes,
  NatureOfCargos,
  JobTypes,
} from "../../../constants/constants";
import SendMessage from "@/components/Pop-ups/SendMessage";
import SendComments from "@/components/Pop-ups/SendComments";
import SuccessDialog from "@/components/Common/SuccessDialog";
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import customerChangePopup from "@/components/Pop-ups/customerChangePopup";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import {
  editInvoiceDay,
  generateSalesInvoice,
  sendSMStoDriver,
  generateWorkOrder,
  generateSaleOrder,
  updateJob,
} from "../../../constants/api-urls";
import Loading from "vue-loading-overlay";
import downloadMixin from "@/mixins/downloadMixin";
import "vue-loading-overlay/dist/vue-loading.min.css";
export default {
  mixins: [downloadMixin],

  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }
    this.jobTypes = JobTypes;
    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete();
          this.initUnloadingAutocomplete();
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);

    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    this.t = config.headers.authorization;
  },
  components: {
    Loading,
    ErrorBox,
    ProofOfDelivery,
    SendMessage,
    SuccessDialog,
    SendComments,
    customerChangePopup,
  },
  props: {
    workingcountry: String,
    viewOnly: String,
    userDetail: Object,
    detail: {
      sourceCountry: String,
      sourceCountryArabic: String,
      destinationLocation: String,
      customer: String,
      packingType: Number,
      otherDetails: String,
      sourceCity: String,
      sourceCityArabic: String,
      destinationCity: String,
      destinationCityArabic: String,
      customerCurrency: String,
      invoiceCountry: String,
      invoiceCity: String,
      truckType: Number,
      numberOfAssignmentsRequired: Number,
      jobId: Number,
      _id: String,
      sourceLocation: String,
      natureOfCargo: Number,
      createdAt: Number,
      startDate: Number,
      jobStatus: Number,
      destinationCountry: String,
      destinationCountryArabic: String,
      destinationLatLong: Array,
      sourceLatLong: Array,
      deliveryProof: Array,
      jobType: String,
      jobGeoType: String,
      jobMode: String,
      invoicingCompany: String,
      invoicingCity: String,
      workingCountry: String,
    },
  },
  watch: {
    detail(val) {},
  },
  data() {
    return {
      workingCountry: "",
      invoiceDay: null,
      confirmation: "",
      bankInfo: [],
      bankName: [],
      bank: "",
      beneficiaryName: "",
      branchCode: "",
      ibanNo: "",
      swiftCode: "",
      accountNo: "",
      bankId: "",
      valid: true,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      invoiceCountry: "",
      invoiceCountry: "",
      charge: "",
      natureOfCargo: "",
      packingType: "",
      currency: [
        { currency: "AED", abbr: "aed" },
        { currency: "SAR", abbr: "sar" },
      ],
      x: {
        message: "",
        loading: false,
        success: false,
        error: null,
      },
      dialog: false,
      loading: false,
      error: null,
      readOnly: true,
      profileUpdateLoading: false,
      natureOfCargos: NatureOfCargos,
      truckTypes: TruckTypes,
      packingTypes: PackingTypes,
      updateInfo: {
        sourceLocation: this.detail.sourceLocation,
        sourceCity: this.detail.sourceCity,
        sourceCityArabic: this.detail.sourceCityArabic,
        sourceCountry: this.detail.sourceCountry,
        sourceCountryArabic: this.detail.sourceCountryArabic,

        destinationLocation: this.detail.destinationLocation,
        destinationCity: this.detail.destinationCity,
        destinationCityArabic: this.detail.destinationCityArabic,

        destinationCountry: this.detail.destinationCountry,
        destinationCountryArabic: this.detail.destinationCountryArabic,

        destinationlong: this.detail.destinationLatLong,
        destinationlat: this.detail.destinationLatLong[1],
        sourcelong: this.detail.sourceLatLong[0],
        sourcelat: this.detail.sourceLatLong[1],
      },
      customer: {
        customerAddress: null,
        companyVatNumber: null,
      },
      rules: {
        number: (v) => /^[1-9]*$/.test(v) || "Please enter valid amount",
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Vat Number",
      },
    };
  },
  updated: function () {},
  methods: {
    addCreditDuration() {
      if (!this.invoiceDay) {
        this.loading = false;
        this.error = "Please enter the credit duration";
        return;
      }

      if (!this.invoiceDay.match(/^([1-9][0-9]{0,3}|10000)$/)) {
        this.loading = false;
        this.error =
          "Please enter valid duration greater than 0 less than 10000";
        return;
      }
      this.loading = true;
      let url = editInvoiceDay;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: this.userDetail._id,
        invoiceDay: parseInt(this.invoiceDay),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.loading = false;
          this.x.success = true;
          this.$eventBus.$emit("refresh-job");
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    async checkViewInvoice(
      url,
      isDownloadInvocie,
      isSaleInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.loading = true;
      this.operationName = "view-sales-invoice";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (!isDownloadInvocie || isSaleInvoviceBackgroundProcess) {
          this.loading = false;
          this.error = "Background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Sales Invoice" + " " + invoiceNumber);
      } else {
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    checkCustomerChange(e) {
      if (e == 4 || e == 5 || e == 6 || e == 7 || e == 11) {
        return true;
      } else {
        return false;
      }
    },
    closeBankInfo() {
      this.dialog1 = false;
      this.invoiceDay = null;
      this.bankInfo = [];
      this.bank = "";

      (this.beneficiaryName = ""),
        (this.branchCode = ""),
        (this.accountNo = ""),
        (this.ibanNo = ""),
        (this.bankId = ""),
        (this.swiftCode = "");
    },
    async generateInvoiceNumber() {
      this.loading = true;
      this.operationName = "generate-sales-invoice";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (!this.bank) {
        this.loading = false;
        this.error = "Please choose bank before generating the invoice";
        return;
      }
      if (y) {
        this.loading = true;
        if (!this.userDetail.invoiceDay) {
          this.loading = false;
          this.error = "Please enter the credit limit duration";
          return;
        }
        let url = generateSalesInvoice;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { jobId: this.$route.params.id, bankId: this.bankId };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.items = response.data.data;
            this.loading = false;
            this.x.success = true;
            this.dialog1 = false;
            this.$eventBus.$emit("refresh-job");
            // this.x.message = "Drivers notified";
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },
    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime4(date) {
      return moment(date).format("ll LT");
    },

    setShipmentValues() {
      this.detail.jobGeoType = 1;
      this.detail.jobMode = 1;
      this.detail.JobType = 1;
    },

    notifyDrivers() {
      let url = sendSMStoDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: this.$route.params.id };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;

          this.x.loading = false;
          this.x.success = true;
          this.x.message = "Drivers notified";
        },
        (error) => {
          this.loading = false;
          this.error = "Failed to update inquiry status";
        }
      );
    },
    driverJob(id) {
      this.$router.push(`/driverfilters/${id}`);
    },
    retry(jobId) {
      this.loading = true;
      let url = generateWorkOrder;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: jobId.toString() };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.data.message;
          this.loading = false;
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    retrySO(jobId) {
      let url = generateSaleOrder;
      this.loading = true;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: jobId.toString() };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          //this.x.success = true;
          this.$emit("updateJobs");
          this.loading = false;
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    removeEmptyOrNull(myObj) {
      Object.keys(myObj).forEach(
        (key) =>
          (myObj[key] == null ||
            myObj[key] == "" ||
            myObj[key] === undefined) &&
          delete myObj[key]
      );

      return myObj;
    },
    closeMessage() {
      this.x.success = false;
      this.$emit("updateJobs");
      this.reset();
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Complete";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    getText(arr = [], val) {
      let finalEl = arr
        .filter((val) => val !== undefined)
        .find((el) => el.value === val);
      return finalEl ? finalEl.text : "NA";
    },
    getInvoiceBy(type) {
      switch (type) {
        case "1":
          return "KSA";
        case "2":
          return "UAE";
        case "3":
          return "PAK";
        case "4":
          return "UAE LA";
        case "5":
          return "OMAN";
        default:
          return "NA";
      }
    },
    getLocation(...arr) {
      return arr ? arr.reduce((prev, curr) => prev + ", " + curr) : "NA";
    },
    reset() {
      this.readOnly = true;
      this.updateInfo = Object.assign(
        {},
        {
          sourceLocation: this.updateInfo.sourceLocation,
          sourceCity: this.updateInfo.sourceCity,
          sourceCityArabic: this.updateInfo.sourceCityArabic,
          sourceCountry: this.updateInfo.sourceCountry,
          sourceCountryArabic: this.updateInfo.sourceCountryArabic,
          destinationLocation: this.updateInfo.destinationLocation,
          destinationCity: this.updateInfo.destinationCity,
          destinationCityArabic: this.updateInfo.destinationCityArabic,

          destinationCountry: this.updateInfo.destinationCountry,
          destinationCountryArabic: this.updateInfo.destinationCountryArabic,

          destinationlong: this.updateInfo.destinationLatLong[0],
          destinationlat: this.updateInfo.destinationLatLong[1],
          sourcelong: this.updateInfo.sourceLatLong[0],
          sourcelat: this.updateInfo.sourceLatLong[1],
        }
      );
    },

    setCustomerValues(val) {
      var data = this.bankInfo.filter((v) => {
        if (v.value === val) return v;
      });

      (this.beneficiaryName = data[0].beneficiaryName),
        (this.branchCode = data[0].branchCode),
        (this.accountNo = data[0].accountNo),
        (this.ibanNo = data[0].ibanNo),
        (this.bankId = data[0].bankId),
        (this.swiftCode = data[0].swiftCode);
      // this.beneficiaryName = data[0].beneficiaryName;
    },
    fetchBank() {
      this.dialog1 = true;

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        invoicingCity: {
          code: this.detail.invoicingCity,
        },
        invoicingCountry: {
          code: this.detail.invoicingCompany,
        },
      };
      let { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/accounts/getBankDetails`, body, config).then(
        (response) => {
          const { data = null } = response.data;
          data.forEach((element) => {
            this.bankInfo.push({
              text: element.bankName,
              value: element._id,
              beneficiaryName: element.beneficiaryName,
              branchCode: element.branchCode,
              accountNo: element.accountNo,
              ibanNo: element.ibanNo,
              bankId: element._id,
              swiftCode: element.swiftCode,

              // companyName: element.companyName,
              // companyVatNumber: element.companyVatNumber,
              // address: element.address
              //   ? element.address.replaceAll("\n", ", ")
              //   : "",
              // invoicingCity: element.invoicingCity,
              // invoicingCountry: element.invoicingCountry,
              // selectedIndustry: element.selectedIndustry,
            });
          });

          // this.bankName = response.data.data[2].bankName;
        },
        (error) => {
          this.error = "Something went wrong. Please try again later!";
          this.loading = false;
        }
      );
    },

    initLoadingAutocomplete() {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete")
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.updateInfo.sourceLocation = place.formatted_address;
        this.updateInfo.sourcelat = place.geometry.location.lat();
        this.updateInfo.sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          country: "country",
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.sourceCountry = x[1];
          this.updateInfo.sourceCity = x[0];
        } else {
          this.updateInfo.sourceCountry = y[1];
          this.updateInfo.sourceCity = y[0];
        }
        if (!this.updateInfo.sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.sourceCity = "";
        }
      });
    },
    initUnloadingAutocomplete() {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete2")
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.updateInfo.destinationLocation = place.formatted_address;
        this.updateInfo.destinationlong = place.geometry.location.lng();
        this.updateInfo.destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          country: "country",
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.destinationCountry = x[1];
          this.updateInfo.destinationCity = x[0];
        } else {
          this.updateInfo.destinationCountry = y[1];
          this.updateInfo.destinationCity = y[0];
        }
        if (!this.updateInfo.destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.destinationCity = "";
        }
      });
    },
    updateLocation() {
      this.profileUpdateLoading = true;
      if (!this.updateInfo.sourcelong && !this.updateInfo.sourcelat) {
        this.error =
          "Please select source address from the suggestions dropdown!";
        this.profileUpdateLoading = false;
        return;
      }

      if (!this.updateInfo.destinationlong && !this.updateInfo.destinationlat) {
        this.error =
          "Please select destination address from the suggestions dropdown!";
        this.profileUpdateLoading = false;
        return;
      }

      if (!this.updateInfo.sourceCity) {
        this.error = "Please select source city before moving ahead!";
        this.profileUpdateLoading = false;
        return;
      }

      if (!this.updateInfo.sourceCountry) {
        this.error = "Please select source country before moving ahead!";
        this.profileUpdateLoading = false;
        return;
      }

      if (!this.updateInfo.destinationCity) {
        this.error = "Please select destination city before moving ahead!";
        this.profileUpdateLoading = false;
        return;
      }

      if (!this.updateInfo.destinationCountry) {
        this.error = "Please select destination country before moving ahead!";
        this.profileUpdateLoading = false;
        return;
      }
      let { jobId } = this.detail;
      jobId = jobId.toString();

      let {
        sourceLocation,
        sourceCity,
        sourceCityArabic,
        sourceCountry,
        sourceCountryArabic,
        destinationLocation,
        destinationCity,
        destinationCityArabic,
        destinationCountry,
        destinationCountryArabic,
        destinationlong,
        destinationlat,
        sourcelong,
        sourcelat,
      } = this.updateInfo;

      destinationlong = destinationlong.toString();
      destinationlat = destinationlat.toString();
      sourcelong = sourcelong.toString();
      sourcelat = sourcelat.toString();

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/dmsAdmin/assignmentLocation`,
          {
            jobId,
            sourceLocation,
            sourceCity,
            sourceCityArabic,
            sourceCountry,
            sourceCountryArabic,
            destinationLocation,
            destinationCity,
            destinationCityArabic,
            destinationCountry,
            destinationCountryArabic,
            destinationlong,
            destinationlat,
            sourcelong,
            sourcelat,
          },
          config
        )
        .then(
          (response) => {
            const { status, data } = response.data;
            if (status === 200) {
              this.x.success = true;
              this.x.message = "Location updated successfully";
              this.updateInfo = Object.assign(
                {},
                {
                  sourceLocation,
                  sourceCity,
                  sourceCityArabic,
                  sourceCountry,
                  sourceCountryArabic,
                  destinationLocation,
                  destinationCity,
                  destinationCityArabic,
                  destinationCountry,
                  destinationCountryArabic,
                  sourcelat,
                  sourcelong,
                  destinationlong,
                  destinationlat,
                }
              );
            }
            this.profileUpdateLoading = false;
            this.readOnly = true;
          },
          (error) => {
            this.error = error.response.data.message;
            this.profileUpdateLoading = false;
          }
        );
    },
    updateCustomerInfo(jobId) {
      if (this.$refs.customerForm.validate()) {
        this.loading = true;
        let url = updateJob;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          jobId: jobId.toString(),
          customerVatNo: this.customer.companyVatNumber.trim(),
          customerAddress: this.customer.customerAddress.trim(),
        };
        body = this.removeEmptyOrNull(body);
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.x.success = true;
            this.x.message = response.data.message;
            this.loading = false;
            setTimeout(() => (this.dialog = false), 100);
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
    updateJob(jobId) {
      this.loading = true;
      let url = updateJob;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: jobId.toString(),
        customerCurrency: this.currency.currency,
        invoicingCompany: this.invoicingCompany,
        paymentEntity: this.charge,
        packingType: this.packingType.toString(),
        natureOfCargo: this.natureOfCargo.toString(),
      };
      body = this.removeEmptyOrNull(body);
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.message;
          this.loading = false;
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    updateInvoice(jobId) {
      this.loading = true;
      let url = updateJob;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: jobId.toString(),
        invoicingCompany: this.detail.invoicingCompany,
      };
      body = this.removeEmptyOrNull(body);
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.message;
          this.loading = false;
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    goback() {
      if (localStorage.getItem("activeMenu") == 2) this.$router.push(`/jobs`);
      else if (localStorage.getItem("activeMenu") == 13)
        this.$router.push(`/procurements`);
      else if (localStorage.getItem("activeMenu") == 13)
        this.$router.push(`/sales`);
      else this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.send-message {
  margin: 0 auto;
}
.status {
  margin: 0 20px;
}
.dropdown-width {
  width: 80%;
}
.location {
  align-self: flex-start;
}
.heading {
  word-break: break-all;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.track-job-button {
  background-color: darkorange;
  color: #ffffff;
  padding: 10px;
  text-decoration: none;
}
.driverbtn {
  position: absolute;
  right: 270px;
  /*top: 25px;*/
}
.notifybtn {
  position: absolute;
  right: 430px;
  top: 25px;
}
.f-20 {
  font-size: 22px;
  font-weight: bolder;
}
.my-5 {
  margin-top: 22px !important;
}
.spacerclass {
  max-height: 550px !important;
}
.fonthead {
  font-size: 20px;
}
</style>
