<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-card-text class="nospace">
        <loading :active.sync="loading" />

        <v-layout
          style="border-top: 1px solid #efefef; justify-content: end"
          class="p-16"
        >
          <v-flex xs5 md5 pt-2 v-if="advanceAmount && advanceStatus"
            ><span class="dashboard-style">Total Advance Requested: </span>
            {{ advanceAmount }} {{ currency }}</v-flex
          >
          <v-flex xs5 md5 pt-2 v-if="totaldata"
            ><span class="dashboard-style">Driver Price: </span
            >{{ paymentAmount }} {{ currency }}</v-flex
          >
          <v-flex xs12 class="button__align" pl-4>
            <ReadyToPayPopup
              v-if="$props.section !== 'Accounts' && isMarkedAllRead"
              :confirmationTitle="'Comments'"
              :cancelBtn="'Cancel'"
              :confirmBtn="'Add Comment'"
              :assignId="$route.params._id"
              :assignStatus="status"
              @refresh-list="refreshList()"
              :type="'all'"
            />
            <GenerateAdvance
              :assId="assignID"
              v-permissions="'generate-advance-jobs'"
              :status="status"
              :currency="currency"
              :invoiceCity="invoiceCity"
              :invoiceCompany="invoiceCompany"
              :assignedToTransporter="assignedToTransporter"
              @generateAdvance="closeMessage()"
              :totalAmount="this.paymentAmount"
              :ownerType="ownerType"
              :totalAdvanceAmount="this.advanceAmount"
            />
            <v-btn
              class="ma-2"
              v-if="advanceStatus"
              outline
              v-permissions="'cancel-advance'"
              color="red"
              @click="check('all')"
              :disabled="customerStatus == 'view' || status <= 1"
            >
              Cancel All Advance
            </v-btn>

            <!-- <v-btn
              class="ma-2"
              outline
              v-permissions="'cancel-advance'"
              color="red"
              @click="check()"
              :disabled="
                customerStatus == 'view' || status <= 1 || !advanceStatus
              "
            >
              Cancel Advance
            </v-btn> -->
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="TransporterListTable"
        hide-actions
        :headers="headers"
        :items="items"
        style="word-break: break-all"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.advanceAmount }} {{ currency }}</td>
          <td v-if="props.item.actualAmountPaid">
            {{ props.item.actualAmountPaid }} {{ currency }}
          </td>
          <td v-else>N.A</td>
          <td v-if="props.item.updatedAt">
            {{ props.item.employeeName }}({{ props.item.employeeId }})<br />
            {{ getTime3(props.item.updatedAt) }}
          </td>
          <td v-else>
            {{ props.item.employeeName }}({{ props.item.employeeId }})
          </td>

          <td v-if="props.item.creditorModeOfPayment == 'Cash'">
            {{ props.item.creditorModeOfPayment }}
          </td>
          <td v-else-if="props.item.creditorModeOfPayment == 'Bank Transfer'">
            {{ props.item.creditorModeOfPayment }}<br />
            Swift No. -
            {{ props.item.swiftNumber || "N.A" }} <br />
            IBAN No. -
            {{ props.item.IBANNumber || "N.A" }}<br />
            Account No. -
            {{ props.item.accountNumber || "N.A" }} <br />Account Holder Name-
            {{ props.item.accountHolderName || "N.A" }}
          </td>
          <td v-else>-</td>
          <td>
            {{ props.item.modeOfPayment || "N.A" }}<br />
            {{ props.item.bankName }}
          </td>
          <td>
            {{
              props.item.actualPaidDate && props.item.actualPaidTime
                ? getDate(props.item.actualPaidDate) +
                  " " +
                  props.item.actualPaidTime
                : "-"
            }}
          </td>
          <td>
            {{
              assignmentDetails.driverFirstName
                ? assignmentDetails.driverFirstName +
                  " " +
                  assignmentDetails.driverLastName
                : ""
            }}(via {{ ownerType }})
            <br />
            {{ assignDetails.driverData ? assignDetails.driverId : "" }}<br />
            <span v-if="assignDetails.driverData">
              <template v-if="assignDetails.truckNumber">
                {{ assignDetails.truckPrefix }}-{{
                  assignDetails.truckNumber
                }}</template
              >
              <template v-else>
                {{ assignDetails.driverData.truckPrefix }}-{{
                  assignDetails.driverData.truckNumber
                }}</template
              >
            </span>
          </td>
          <td>{{ getStatus(props.item.advanceStatus) }}</td>
          <td>
            <span v-if="props.item.adjustAmounted">
              {{ props.item.adjustAmounted }} {{ currency }} <br />{{
                props.item.remarksForAdjustment
                  ? +"(" + props.item.remarksForAdjustment + ")"
                  : ""
              }}</span
            >
            <span v-else>N.A</span>
          </td>

          <td style="word-break: break-word; width: 20%">
            {{ props.item.cancelAdvanceReason || "-" }}
          </td>
          <td>
            <ReadyToPayPopup
              v-if="
                $props.section !== 'Accounts' &&
                props.item.advanceStatus === 'Issued'
              "
              :confirmationTitle="'Comments'"
              :details="props.item"
              :cancelBtn="'Cancel'"
              :assignId="$route.params._id"
              :driverCreditDuration="
                assignDetails.driverData
                  ? assignDetails.driverData.driverPaymentDuration
                  : null
              "
              :confirmBtn="'Add Comment'"
              @refresh-list="closeMessage()"
              :type="'single'"
            />
            <v-btn
              v-if="
                props.item.advanceStatus === 'Ready' ||
                props.item.advanceStatus === 'Issued' ||
                props.item.advanceStatus === 'Rejected'
              "
              class="cancel-ass-btn"
              small
              outline
              v-permissions="'cancel-advance'"
              color="red"
              @click="check('single', props.item._id)"
              :disabled="customerStatus == 'view' || status <= 1"
            >
              Cancel
            </v-btn>

            <editAdvance
              :assId="assignID"
              v-if="props.item.advanceStatus === 'Issued'"
              :modeOfPayment="props.item.modeOfPayment"
              v-permissions="'manage-job'"
              :isCancel="props.item.isCancel"
              :driverCreditDuration="
                assignDetails.driverData
                  ? assignDetails.driverData.driverPaymentDuration
                  : null
              "
              :serialId="props.item._id"
              :bankNames="props.item.bankName"
              :currency="currency"
              :invoiceCity="invoiceCity"
              :invoiceCompany="invoiceCompany"
              @editAdvance="closeMessage()"
              :details="props.item"
              :advance="props.item.advanceAmount.toString()"
              :totalAmount="paymentAmount"
              :totalAdvanceAmount="advanceAmount"
            />
            <proof-of-delivery
              v-if="
                props.item.advanceStatus == 'Paid' &&
                props.item.document &&
                props.item.document.length
              "
              :deliveryProof="props.item.document"
            />
          </td>
        </template>
      </v-data-table>

      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/dmsAdmin/getAdvanceList`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <v-dialog v-model="cancelDialog" max-width="40%" persistent>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card style="overflow-x: hidden">
            <v-card-title
              style="
                background-color: #f5f5f5;
                padding-bottom: 5px;
                padding-top: 5px;
              "
            >
              <span class="headline">Cancel Advance</span>
              <v-spacer></v-spacer>
              <v-btn icon @click.prevent="closeAdvance()">
                <v-icon class="heading grey--text text--darken-4">close</v-icon>
              </v-btn>
            </v-card-title>

            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
              class="title-layout-size"
            >
              <v-layout>
                <v-flex md12>
                  <v-card-title class="title-layout-size">
                    <span>Reason for cancelling Advance*</span>
                  </v-card-title>
                  <v-card-text class="title-layout-size">
                    <v-layout>
                      <v-flex pt-2 pr-1>
                        <v-select
                          class="pt-0 currencyTitle"
                          v-model="cancelType"
                          :items="cancellationType"
                          label="Select your option"
                          persistent-hint
                          :menu-props="{ offsetY: true }"
                          return-object
                          single-line
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          class="pt-0 currencyTitle"
                          v-if="cancelType.value == 'Other’s'"
                          type="text"
                          single-line
                          label="Enter your reason"
                          required
                          v-model="cancellationReason"
                          :value="cancellationReason"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-flex>
              </v-layout>
            </v-container>
            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
              class="title-layout-size"
            >
            </v-container>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="closeAdvance()"
                >Cancel</v-btn
              >
              <v-btn color="orange darken-1" flat @click="cancelAdvance()"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import SuccessDialog from "@/components/Common/SuccessDialog";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
import { generateAdvanceList } from "@/constants/datatable-headers.js";
import editAdvance from "@/components/Pop-ups/editAdvance";
import ReadyToPayPopup from "@/components/Pop-ups/ReadyToPayPopup.vue";
import ErrorBox from "@/components/Common/ErrorBox";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
  CancellationType,
} from "../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    this.customerStatus = localStorage.getItem("cust-status");
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
    this.cancellationType = CancellationType;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.payload = {
      skip: "0",
      limit: "15",
      assignmentId: this.$route.params._id,
      workingCountry: this.workingCountry,
    };
    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.x.error = "Please check your internet connection";
      setTimeout(() => {
        this.x.error = "";
      }, 3000);
      return;
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("advance-dialog-close");
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    editAdvance,
    GenerateAdvance,
    ReadyToPayPopup,
    ProofOfDelivery,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
  },

  data() {
    return {
      opeartionName: "",
      workingCountry: null,
      isMarkedAllRead: false,
      bankName: "",
      ownerType: "",
      invoiceCompany: "",
      invoiceCity: "",
      customerStatus: "",
      isCancelAll: null,
      advanceId: null,
      JobTypes: [],
      totaldata: "",
      advanceAmount: null,
      advanceStatus: "",
      currency: "",
      cancellationType: [],
      cancellationReason: "",
      cancelType: "",
      status: "",
      jobType: "",
      assignDetails: {},
      jobId: "",
      assignID: "",
      paymentAmount: "",
      error: "",
      valid: true,
      assignedToTransporter: Boolean,
      lazy: false,
      cancelDialog: false,
      cancelreason: "",
      loading: true,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "The advance has been successfully cancelled",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {},

      headers: generateAdvanceList,
      items: [],
    };
  },
  watch: {},
  methods: {
    getStatus(status) {
      switch (status) {
        case "Paid":
          return "Paid";
        case "Issued":
          return "Requested";
        case "Rejected":
          return "Rejected";
        case "Cancel":
          return "Cancelled";
        case "Ready":
          return "Ready To Pay";
        case "PartiallyPaid":
          return "Partial Paid";

        default:
          return "Unknown";
      }
    },
    refreshList() {
      this.componentKey++;
    },
    getBankName() {
      if (this.invoiceCity == "1" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Dammam";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Jeddah";
      } else if (this.invoiceCity == "3" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Riyadh";
      } else if (this.invoiceCity == "1" && this.invoiceCompany == "2") {
        this.bankName = "Mashreq Bank PSJC";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "2") {
        this.bankName = "EMIRATES NBD Bank";
      }
      return this.bankName;
    },
    async check(type, id) {
      this.operationName = "cancel-advance";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.cancelDialog = true;
        this.isCancelAll = type;
        this.advanceId = id;
      } else {
        return;
      }
    },
    closeAdvance() {
      this.cancelDialog = false;
      this.cancelType = "";
      this.cancellationReason = "";
    },
    async cancelAdvance() {
      this.operationName = "cancel-advance";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = true;
        if (!this.cancelType) {
          this.error = "Please select cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          this.loading = false;
          return false;
        }
        if (this.cancelType.value == "Other’s") {
          if (!this.cancellationReason) {
            this.error = "Please enter cancellation reason before moving ahead";
            setTimeout(() => {
              this.x.error = "";
            }, 3000);
            this.loading = false;
            return false;
          }
        }
        const data = {
          assignmentId: this.$route.params._id,
        };
        if (this.isCancelAll == "single") {
          data.advanceId = this.advanceId;
          data.isCancelAll = false;
        }
        if (this.isCancelAll == "all") {
          data.isCancelAll = true;
        }
        if (this.cancelType.value) {
          if (this.cancelType.value == "Other’s") {
            data.cancelAdvanceReason =
              this.cancelType.value + " - " + this.cancellationReason;
          } else {
            data.cancelAdvanceReason = this.cancelType.value;
          }
        }

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        this.axios.post(`${apiUrl}/dmsAdmin/cancelAdvance`, data, config).then(
          (response) => {
            const { data } = response.data;
            this.x.success = true;
            this.cancelDialog = false;
            this.cancelType = "";
            this.cancellationReason = "";
            this.loading = false;
          },
          (error) => {
            this.error = error.response.data.message;
            this.loading = false;
          }
        );
      } else {
        return;
      }
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    callback(res) {
      console.log(res);
      this.loading = false;
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.assignDetails = res.assignmentDetails[0];
      this.advanceAmount = res.assignmentDetails[0].advanceAmount;
      this.totaldata = res.assignmentDetails[0].totalAmount;
      this.ownerType = res.assignmentDetails[0].ownerType;
      this.assignedToTransporter =
        res.assignmentDetails[0].assignedToTransporter;

      this.isMarkedAllRead = res.isReadyToPay;
      this.$eventBus.$emit("off-load");
      this.invoiceCity = res.invoicingCity;
      this.invoiceCompany = res.invoicingCompany;
      this.paymentAmount = res.assignmentDetails[0].paymentAmount;
      this.currency = res.assignmentDetails[0].customerCurrency;
      this.advanceStatus = res.isCancel;

      this.assignID = res.assignmentDetails[0].assignmentId;
      this.status = res.assignmentDetails[0].assignmentStatus;
      this.items = res.list;

      this.getBankName();
      this.totalPages = Math.ceil(res.totalData / 15);
      window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;

  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
</style>
