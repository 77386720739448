<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-card-text class="nospace">
        <loading :active.sync="loading" />

        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex xs4 pt-2 v-if="totalAddtionalCharges"
            ><span class="dashboard-style"
              >Driver Total Additional Charge : </span
            >{{ totalAddtionalCharges }} {{ jobCurrency }}</v-flex
          >
          <v-flex xs4 pt-2 v-if="totalCostMargin"
            ><span class="dashboard-style"
              >Customer Total Additional Charge : </span
            >{{ totalCostMargin }} {{ jobCurrency }}</v-flex
          >
          <v-flex xs4 pt-2 v-if="totalMiscellaneousChargePopup"
            ><span class="dashboard-style"> Miscellaneous Total Charge : </span
            >{{ totalMiscellaneousChargePopup }} {{ jobCurrency }}</v-flex
          >
          <v-flex style="text-align: end" class="pb-2">
            <div v-if="amountPaidToDriver <= 0">
              <AddAdditionalCharges
                v-if="
                  checkInvoiceGenerated(
                    jobSalesInvoiceSameComp,
                    assignSalesGenSameComp
                  )
                "
                :assId="assignID"
                v-permissions="'edit-additional-charges'"
                :jobId="jobId"
                :status="status"
                :chargesType="chargesType"
                :destinationCountry="destinationCountry"
                :sourceCountry="sourceCountry"
                :destinationCity="destinationCity"
                :VendorWorkingCountry="VendorWorkingCountry"
                :isCustomerReceiptInitiate="isCustomerReceiptInitiate"
                @successAdditionalCharges="closeMessage()"
                :assign_id="this.$route.params._id"
                :currency="jobCurrency"
                :customerId="customerId"
              />

              <div
                v-if="
                  !checkInvoiceGenerated(
                    jobSalesInvoiceSameComp,
                    assignSalesGenSameComp
                  )
                "
              >
                <AddAdditionalCharges
                  :assId="assignID"
                  v-permissions="'add-additional-charges-master'"
                  :jobId="jobId"
                  :status="status"
                  :chargesType="chargesType"
                  :destinationCountry="destinationCountry"
                  :sourceCountry="sourceCountry"
                  :destinationCity="destinationCity"
                  :VendorWorkingCountry="VendorWorkingCountry"
                  :isCustomerReceiptInitiate="isCustomerReceiptInitiate"
                  @successAdditionalCharges="closeMessage()"
                  :assign_id="this.$route.params._id"
                  :currency="jobCurrency"
                  :customerId="customerId"
                />
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="ListTable"
        hide-actions
        :headers="headers"
        :items="items"
        style="word-break: break-word"
      >
        <template slot="items" slot-scope="props">
          <td
            v-if="
              props.item.costMargin != 0 && props.item.miscellaneousCharge == 0
            "
          >
            {{ props.item.chargesType || "Exclusive" }}
          </td>
          <td v-else>N.A</td>
          <td style="width: 10%">
            {{
              Number.isInteger(props.item.costMargin)
                ? props.item.costMargin
                : props.item.costMargin.toFixed(2)
            }}
            {{ jobCurrency }}
          </td>

          <td>
            {{
              Number.isInteger(props.item.amount)
                ? props.item.amount
                : props.item.amount.toFixed(2)
            }}
            {{ jobCurrency }}
          </td>
          <td style="width: 10%">
            {{ props.item.miscellaneousCharge }}
            {{ jobCurrency }}
          </td>

          <td>{{ props.item.comments }}</td>
          <td style="width: 20%">
            {{ props.item.description ? props.item.description : "N.A." }}
          </td>
          <td v-if="props.item.editDate">
            {{ getTime3(props.item.editDate) }}
          </td>

          <td v-else></td>
          <td>
            {{ props.item.userData ? props.item.userData.name : "-" }}({{
              props.item.userData ? props.item.userData.employeeID : ""
            }})
          </td>
          <td
            style="width: 20% !important"
            v-if="props.item.documentForAdditionalCharges.length"
          >
            <v-layout style="width: 500px">
              <ul
                style="
                  position: relative;
                  list-style: none;
                  display: inline-block;
                  width: 500px;
                "
              >
                <li
                  :key="i"
                  v-for="(doc, i) in props.item.documentForAdditionalCharges"
                  class="list"
                  style="display: inline-block"
                  ref="documentList"
                >
                  <a
                    :href="doc"
                    class="document-link"
                    style="text-decoration: none; outline: none"
                    v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                    target="_blank"
                  >
                    <img src="../../assets/pfd.png" height="40" width="32" />
                  </a>
                  <a
                    :href="doc"
                    style="text-decoration: none; outline: none"
                    class="document-link"
                    target="_blank"
                    v-else
                  >
                    <img
                      src="../../assets/docimg.webp"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
              </ul>
            </v-layout>
          </td>
          <td v-else>-</td>
          <td>
            <div v-if="amountPaidToDriver <= 0">
              <v-layout
                class="justfy_c"
                v-if="
                  checkInvoiceGenerated(
                    jobSalesInvoiceSameComp,
                    assignSalesGenSameComp
                  )
                "
              >
                <EditAdditionalCharges
                  v-permissions="'edit-additional-charges'"
                  :currency="jobCurrency"
                  :customerId="customerId"
                  :chargesTypeJob="chargesType"
                  :destinationCountry="destinationCountry"
                  :sourceCountry="sourceCountry"
                  :isCustomerReceiptInitiate="isCustomerReceiptInitiate"
                  :assignId="assignID"
                  :assignDetails="props.item"
                  :status="status"
                  :destinationCity="destinationCity"
                  :VendorWorkingCountry="VendorWorkingCountry"
                  :jobId="jobId"
                  :chargesType="props.item.chargesType"
                  @editAdditionalSuccess="closeMessage()"
                  :assign_id="$route.params._id"
                />
                <button
                  class="track-ass-btn red-button"
                  v-permissions="'edit-additional-charges'"
                  v-if="status != 1"
                  type="button"
                  @click="open(props.item, props.index)"
                  title="Delete"
                >
                  Delete
                </button>
              </v-layout>
              <v-layout
                v-if="
                  !checkInvoiceGenerated(
                    jobSalesInvoiceSameComp,
                    assignSalesGenSameComp
                  )
                "
              >
                <EditAdditionalCharges
                  v-permissions="'edit-additional-charges-master'"
                  :currency="jobCurrency"
                  :customerId="customerId"
                  :chargesTypeJob="chargesType"
                  :destinationCountry="destinationCountry"
                  :sourceCountry="sourceCountry"
                  :isCustomerReceiptInitiate="isCustomerReceiptInitiate"
                  :assignId="assignID"
                  :assignDetails="props.item"
                  :status="status"
                  :destinationCity="destinationCity"
                  :VendorWorkingCountry="VendorWorkingCountry"
                  :jobId="jobId"
                  :chargesType="props.item.chargesType"
                  @editAdditionalSuccess="closeMessage()"
                  :assign_id="$route.params._id"
                />
                <button
                  class="track-ass-btn red-button"
                  v-permissions="'delete-additional-charges-master'"
                  v-if="status != 1"
                  type="button"
                  @click="open(props.item, props.index)"
                  title="Delete"
                >
                  Delete
                </button>
              </v-layout>
            </div>
          </td>
        </template>
      </v-data-table>

      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/dmsAdmin/getAdditionalCharges`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <v-dialog
        v-model="dialogDelete"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-show="!!message" class="pa-4">
            {{ message }}
            <v-text-field
              type="text"
              label="Please add comments here"
              required
              v-model="statusComments"
              :value="statusComments"
            />
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn
              color="primary darken-1"
              text
              @click.native="agree"
              :disabled="loading"
              >Yes</v-btn
            >
            <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import { additionalChargesList } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import AddAdditionalCharges from "@/components/Pop-ups/AddAdditionalCharges";
import EditAdditionalCharges from "@/components/Pop-ups/EditAdditionalCharges";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import { deleteAdditionalCharges } from "../../constants/api-urls";
import { StorageKeys, JobTypes, JobListTypes } from "../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
          this.currency = key.currency;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });

    this.payload = {
      skip: "0",
      limit: "15",
      assignId: this.$route.params._id,
      workingCountry: this.workingCountry,
    };
    this.$eventBus.$on("success", () => {
      this.x.success = true;
    });
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("edit-success", () => {
      this.x.success = true;
    });
    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    AddAdditionalCharges,
    EditAdditionalCharges,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,

    customerStatus: String,
    jobSalesInvoice: Boolean,
    assignSalesInvoice: Boolean,
  },

  data() {
    return {
      workingCountry: null,
      totalAddtionalCharges: "",
      totalCostMargin: "",
      totalMiscellaneousChargePopup: "",
      operationName: "",
      title: null,
      message: null,
      statusComments: "",
      dialog: false,
      dialogDelete: false,
      isCustomerReceiptInitiate: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      chargesType: "",
      destinationCountry: "",
      sourceCountry: "",
      destinationCity: "",
      VendorWorkingCountry: "",
      customerId: null,
      assignSalesGenSameComp: Boolean,
      jobSalesInvoiceSameComp: Boolean,
      jobCurrency: "",
      JobTypes: [],
      jobId: "",
      status: null,
      assignID: null,
      assignId: null,
      currency: "",
      jobType: "",
      error: "",
      documents: [],
      document: [],
      loading: true,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: 0,
      amountPaidToDriver: 0,

      payload: {},

      headers: additionalChargesList,
      items: [],
    };
  },
  watch: {},
  methods: {
    open(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      this.statusComments = "";
      this.additionalchargesId = val._id;
      this.dialogDelete = true;
      this.title = "Confirmation";
      this.message = "Are you sure you want to delete this additional charge?";
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    checkInvoiceGenerated(joblevel, SalesLevel) {
      // console.log(joblevel, SalesLevel);
      if (!Boolean(joblevel) && !Boolean(SalesLevel)) {
        return true;
      } else {
        return false;
      }
    },
    agree() {
      this.resolve(true);
      if (this.statusComments.trim()) {
        this.deleteDevice();
      } else {
        this.error = "Please provide comments before moving ahead";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return false;
      }
    },
    cancel() {
      this.resolve(false);
      this.dialogDelete = false;
    },
    async deleteDevice() {
      this.loading = true;
      this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        //this.processing = true;
        let Url = deleteAdditionalCharges;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: this.jobId,
          assignmentId: this.assignId,
          additionalchargesId: this.additionalchargesId,
          comments: this.statusComments,
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.dialogDelete = false;
            this.loading = false;
            this.componentKey += 1;
            this.$emit("on-block");
            this.$eventBus.$emit("refresh-job");
          },
          (error) => {
            this.x.success = false;
            this.loading = false;
            this.dialogDelete = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },
    showConfirmation(confirmCallback, valueCallback) {
      this.confirmationDialog = {
        toShow: true,
        title: `Confirmation`,
        content: `Are you sure to delete additional charge ?`,
        valueCallback,
      };
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTime3(date) {
      return moment(date).format("ll LT ");
    },
    callback(res) {
      // localStorage.setItem("payloadTransporter", JSON.stringify(this.payload));
      this.loading = false;
      this.$eventBus.$emit("off-load");
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.items = res.list;
      this.jobId = res.jobId;
      this.chargesType = res.chargesType;
      this.destinationCountry = res.assignmentDetails[0].destinationCountry;
      this.sourceCountry = res.assignmentDetails[0].sourceCountry;
      console.log(res.assignmentDetails[0].amountPaidToDriver);
      this.amountPaidToDriver = res.assignmentDetails[0].amountPaidToDriver;
      this.destinationCity = res.assignmentDetails[0].destinationCity;
      if (res.assignmentDetails[0].WorkingCountry) {
        this.VendorWorkingCountry = res.assignmentDetails[0].WorkingCountry;
      } else {
        this.VendorWorkingCountry =
          res.assignmentDetails[0].VendorWorkingCountry;
      }
      console.log(res.assignmentDetails[0]);
      this.totalAddtionalCharges = Number.isInteger(res.totalAdditionalAmount)
        ? res.totalAdditionalAmount
        : res.totalAdditionalAmount.toFixed(2);

      this.totalCostMargin = Number.isInteger(res.totalCostMargin)
        ? res.totalCostMargin
        : res.totalCostMargin.toFixed(2);

      this.totalMiscellaneousChargePopup = Number.isInteger(
        res.totalMiscellaneousCharge
      )
        ? res.totalMiscellaneousCharge
        : res.totalMiscellaneousCharge.toFixed(2);

      this.assignSalesGenSameComp =
        res.assignmentDetails[0].isSalesInvoiceGenerated;
      this.jobSalesInvoiceSameComp = res.jobData.isSalesInvoiceGenerated;
      this.assignID = res.assignmentDetails[0].assignmentId;
      this.assignId = res.assignmentDetails[0]._id;
      this.jobCurrency = res.assignmentDetails[0].customerCurrency;
      this.status = res.assignmentDetails[0].assignmentStatus;
      this.customerId = res.assignmentDetails[0].customerId;
      this.totalPages = Math.ceil(res.totalData / 15);
      window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
</style>
